import { Component, OnInit, Input, Inject } from '@angular/core';
import { DialogService } from 'src/app/services/base/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-my-list-popup',
  templateUrl: './my-list-popup.component.html',
  styleUrls: ['./my-list-popup.component.scss']
})
export class MyListPopupComponent implements OnInit { 

  constructor(
    public dialogService:DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public theme:ThemeService
  ) { }

  ngOnInit(): void {
  }

  onListItemClick(obj){
    this.dialogService.closeDialog(obj);
  }

}
