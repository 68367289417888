import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.scss']
})
export class DialogBaseComponent implements OnInit {

  route:ActivatedRoute;
  Scode:string = "";

  constructor() {
    var _scode = this.route.snapshot.data['Scode'];
    if(_scode){
      this.Scode = _scode;
    }
   }

  ngOnInit(): void {

  }




  

}
