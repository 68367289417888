export class Customer {
    ID: number = 0;
    CompanyID: number = null;
    CustomerName: string = null;
    City: string = null;
    State: string = null;
    ZipCode: number = null;
    Phone: string = null;
    Email: string = null;
    Website: string = null;
    Address: string = null;
    Address2: string = null;
    OpenningDate: Date = null;
    OpenningAmount: number = null;
    CreatedDate: string = null;
    CreatedBy: number = null;
    CreatedTerminal: string = null;
    CreatedTerminalIp: string = null;
    ModifiedDate: string = null;
    ModifiedBy: number = null;
    ModifiedTerminal: string = null;
    ModifiedTerminalIp: string = null;
    NTNNumber: number = null;
}