export class Supplier {
    ID:number = 0;
    CompanyID: number = null;
    SupplierName: string = null;
    City: string = null;
    State:string = null;
    ZipCode:string = null;
    Phone:string = null;
    Email:string = null;
    Website:string = null;
    Address:string =null;
    Address2:string = null;
    OpenningDate:string = null;
    OpenningAmount: number = null;
    CreatedDate: string = null;
    CreatedBy: number = null;
    CreatedTerminal: string = null;
    CreatedTerminalIp: string = null;
    ModifiedDate: string = null;
    ModifiedBy: number = null;
    ModifiedTerminal: string = null;
    ModifiedTerminalIp: string = null;


}