import { Component, OnInit } from '@angular/core';
import { AccountInfo, AuthenticationResult, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { error } from 'console';
import { b2cPolicies, protectedResources } from 'src/app/auth-config';
import { Company } from 'src/app/models/company.model';
import { JwtHelperService } from 'src/app/services/base/jwt-helper.service';
import { SignupService } from 'src/app/services/base/signup.service';
import { TokenService } from 'src/app/services/base/token.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
    private jwtHelper:JwtHelperService,
    private tokenService:TokenService,
    private signupService:SignupService
  ) { }

  ngOnInit(): void {

    this.decodeToken();
  }

  decodeToken(){
  
    // Decode the token to use it later
    var token = null;
    var token_decoded = null;
   
    var url: string = window.location.href;
    if (url.includes("#id_token=")) {
        token = this.tokenService.getId_Token(url);
        token_decoded = this.jwtHelper.decodeToken(token);

        var objCompany:Company = new Company();
        objCompany.OwnerName = token_decoded.given_name;
        objCompany.CompanyName = token_decoded.given_name + " Company";
        objCompany.Email = token_decoded.emails[0];

        this.acuirToken(token);

        //this.signupService.signup(objCompany).subscribe(res =>{
           // this.manageSession();
        // }, error =>{

        // });
      //  if(token_decoded.ownerId){
      //     localStorage.setItem("ownerId", token_decoded.ownerId);
      //  }
    }

 
  }
  private clientApplication: PublicClientApplication;
  manageSession(){
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    this.clientApplication = new PublicClientApplication({
      auth: {
        clientId: 'e5447d9f-0502-4278-8110-e997ee89738d',
        authority: b2cPolicies.authorities.signUp.authority,
        redirectUri: 'http://localhost:4200',
        postLogoutRedirectUri: 'http://localhost:4200',
        knownAuthorities: [b2cPolicies.authorityDomain],
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      },
    });

    //this.acuirToken();
  }
  accessToken:any = null;
  acuirToken(idToken){
        // Define your MSAL configuration
      const msalConfig = {
        auth: {
            clientId: 'e5447d9f-0502-4278-8110-e997ee89738d',
            authority: b2cPolicies.authorities.signUp.authority,
            redirectUri: 'http://localhost:4200', // Redirect URI configured in Azure AD
            knownAuthorities: [b2cPolicies.authorityDomain],
        },
      };

      // Create a PublicClientApplication instance
      const pca = new PublicClientApplication(msalConfig);

      // Assuming you have the id_token
      //const idToken = 'your_id_token';

      // Acquire a token silently using the id_token
      const request = {
        account: { idToken: idToken } as AccountInfo,
        scopes: protectedResources.todoListApi.scopes,
        interactionType: InteractionType.Silent,
      };

      pca.acquireTokenSilent(request)
        .then((response) => {
            console.log('Access token acquired', response.accessToken);
        })
        .catch((error) => {
            console.error('Error acquiring token:', error);
        });
  }

  
}
