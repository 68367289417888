import { Component,Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-my-table-filter-modal',
  templateUrl: './my-table-filter-modal.component.html',
  styleUrls: ['./my-table-filter-modal.component.scss']
})
export class MyTableFilterModalComponent implements OnInit {
  panelOpenState = false;
  columns: any[] =[];
  tableQueryData:any[]=[];

  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.log("filter data", this.data);
    this.columns = this.data.data.columns;
    this.tableQueryData = this.data.data.tableQueryData;
  }

  filterChange(column, event){
    console.log("column", column);
    console.log("event", event);
  }


}
