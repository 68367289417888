<button 
*ngIf="add || edit"
type="button" [disabled]="valid || theme.spinner"  (click)="onClick()"  mat-button  class="me-2" 
[class]="theme.spinner ? 'primary' : theme.myIconBgColor" >
    
    <div class="d-flex justify-content-center align-items-center">
        <mat-icon [class]="theme.myIconColor" class="me-1">save</mat-icon>  
         <span >Save</span>  
         <mat-spinner *ngIf="theme.spinner" class="ms-2" diameter="20"></mat-spinner>      
    </div>
    
</button>





