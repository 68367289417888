import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { DialogService } from 'src/app/services/base/dialog.service';
import { PermissionService } from 'src/app/services/base/permission.service';
import { ScreenConfService } from 'src/app/services/base/screen-conf.service';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-ribbon-save-dialog',
  templateUrl: './ribbon-save-dialog.component.html',
  styleUrls: ['./ribbon-save-dialog.component.scss']
})
export class RibbonSaveDialogComponent implements OnInit {
  @Input() valid:boolean = false;
  @Output() myClick =  new EventEmitter();

  add:boolean=false;
  edit:boolean=false;
  scode: string = '';
  constructor(
    public theme:ThemeService,
    public permissionService:PermissionService,
    public screenConfService: ScreenConfService,
    public activatedRoute: ActivatedRoute,
    public dialogService:DialogService) {
      
  
     }
 

  ngOnInit(): void {
    this.setScode();
    this.getAddperimssion();
    this.getEditperimssion();
    
  }

  onClick(){
    this.myClick.emit();
  }

  getAddperimssion() {

    this.add = this.permissionService.getAddPerm(this.scode);
  
  }

  getEditperimssion() {

    this.edit = this.permissionService.getEditPerm(this.scode);
  }


  setScode() {
    if (this.dialogService.getDialogScode())
      this.scode = this.dialogService.getDialogScode()
    else
      this.scode = this.screenConfService.ActiveScode;
  }

}
