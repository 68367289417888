<div class="  rounded headingtop d-flex d-row justify-content-between" [class]="theme.myHeadingColor">
        <span class="d-flex flex-column" style="line-height: 1;"> 
                <span> {{heading}} </span> 
                <span *ngIf="DocumentNo" class="mob" style="font-size: .7rem;padding-top: 0.5px;">{{DocumentNo}}</span> 
        </span>
        <span class="spacer"></span>
        <app-audit-info class="desktop" (audit)="setDocumentNo($event)"></app-audit-info>
        <mat-icon  *ngIf="DocumentNo" class="mob" matRipple  [class]="theme.myHeadingColor" (click)="openBottomSheet()">info</mat-icon>
        <!-- <a href="">
                <span style="color: gray;"> User Management / Users / Add </span>
        </a> -->
        
</div> 
