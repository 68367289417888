import { Component, OnInit } from '@angular/core';
import{Quick_Journal_Voucher} from 'src/app/models/finance/quick-journal-voucher.model';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { GlAccountsService } from 'src/app/services/finance/gl-accounts.service';
import { GLComponent } from '../gl/gl.component';
import moment from 'moment';
import { JvHeaderService } from 'src/app/services/finance/jv-header.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-quick-journal-voucher',
  templateUrl: './quick-journal-voucher.component.html',
  styleUrls: ['./quick-journal-voucher.component.scss']
})
export class QuickJournalVoucherComponent implements OnInit {
 obj : Quick_Journal_Voucher;
 dataglaccount: any[]=[];
 selectedDebit: any = null;

 selectedCredit: any = null; 
  constructor(
    public glaccountservice : GlAccountsService,

    public jvHeaderService:JvHeaderService,
    public dialogService:DialogService,
    public hlp : ConfHelpService,

    public matDialogRef:MatDialogRef<QuickJournalVoucherComponent>

  ) {
    this.obj = new Quick_Journal_Voucher();
   }

  ngOnInit(): void {

    Promise.all([
      this.getglaccount()
    ]).then(res => {

    })

    this.obj.Date = new Date();
  }


  setDropDowns(){
    if(this.dataglaccount.filter(res => res.ID == this.obj.ID).length > 0 ){
      this.selectedDebit = this.dataglaccount.filter(res => res.ID == this.obj.ID)[0];
    }
    if(this.dataglaccount.filter(res => res.ID == this.obj.ID).length > 0 ){
      this.selectedCredit = this.dataglaccount.filter(res => res.ID == this.obj.ID)[0];
    }

  }



async getglaccount(){
  await this.hlp.getFormDataByHelpNo("invglaccount").toPromise().then(res =>{
    this.dataglaccount = res['value'] as any[];
  })
}

onSave(){
    this.obj.Date = moment(this.obj.Date).format('YYYY-MM-DD'); 

    if(this.selectedCredit && this.selectedDebit){
    this.obj.DebitAccountHeadID = this.selectedDebit.AccountHeadID;

    this.obj.CreditAccountHeadID = this.selectedCredit.AccountHeadID;

    this.obj.CreditAccountID = this.selectedCredit.ID;

    this.obj.DebitAccountID = this.selectedDebit.ID;
    }

    this.jvHeaderService.saveQuickJV(this.obj);
    this.matDialogRef.close();
}

onGlAccountCreate(){
  this.dialogService.openQuickCreateDialog_List(GLComponent, 'fin_01')
  this.dialogService.getDialog_List('fin_01').afterClosed().subscribe(result => {
    if(result){
      if(result == "save"){
        this.getglaccount();
      }
    }
  })
}
}
