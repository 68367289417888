import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { RouteService } from 'src/app/services/base/route.service';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    public routeService:RouteService,
    public router:Router,
    public theme:ThemeService,
    public authenticateService:AuthenticateService
  ) { }

  ngOnInit(): void {
  }

  onRouteClick(module:string){

    this.router.navigate([this.routeService.openRoute(module, "Home")]);
    return;

   
  }

  openLink(row, module) {
    this.router.navigate([this.routeService.openRoute(module, row.name)]);
  }

  openRecent(row:any){
    // Inventory
    var arr = this.routeService.inventoryRoutes.filter(a => a.Scode == row.scode && a.type == "form");
    if (arr.length > 0) {
      this.router.navigate([this.routeService.openRoute("inventory", arr[0].name)], {queryParams:{ID:row.RecordID}});
    }
  }

}
