import { Injectable } from '@angular/core';
import { EnModules } from 'src/app/models/enums/enum.model';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  allRoutes:any[] = [];
  routeLoading:boolean = false;

   inventoryRoutes: any[] = []; 
   //[
  //   { route: "/app/inventory/home", name: "Home", label: 'Home', type: ""  },
  //   { route: "/app/inventory/item-list", name: "items", label: 'Items', type: "list", Scode:"inv_01" },
  //   { route: "/app/inventory/category-list", name: "category", label: 'Categories', type: "list", Scode:"inv_02" },
  //   { route: "/app/inventory/item", name: "item", label: 'Item', type: "form", Scode:"inv_01" },
  //   { route: "/app/inventory/category", name: "category", label: 'Category', type: "form", Scode:"inv_02" },
  //   { route: "/app/inventory/store", name: "store", label: 'Store', type: "form" },
  //   { route: "/app/inventory/units", name: "units", label: 'Units', type: "form", Scode:"inv_03" },
  //   { route: "/app/inventory/units-list", name: "units-list", label: 'Units', type: "list", Scode:"inv_03" },
  //   { route: "/app/inventory/plants", name: "plants", label: 'Plant', type: "form", Scode:"inv_04" },
  //   { route: "/app/inventory/plant-list", name: "plant-list", label: 'Plants', type: "list", Scode:"inv_04" },
  //   { route: "/app/inventory/brands", name: "brands", label: 'Brands', type: "form", Scode:"inv_05" },
  //   { route: "/app/inventory/brands-list", name: "brands-list", label: 'Brands', type: "list", Scode:"inv_05" },
  //   { route: "/app/inventory/colors", name: "colors", label: 'colors', type: "form" },
  //   { route: "/app/inventory/colors-list", name: "colors-list", label: 'Colors List', type: "form" },
  //   { route: "/app/inventory/goods-opening", name: "goods-opening", label: 'Goods Opening', type: "form", Scode:"inv_06" },
  //   { route: "/app/inventory/goods-opening-list", name: "goods-opening-list", label: 'Goods Opening', type: "list", Scode:"inv_06" },
  //   { route: "/app/inventory-report/as-on-stock-report", name: "as-on-stock-report", label: 'As On Stock', type: "report" },
  //   { route: "/app/inventory-report/stock-ledger-report", name: "stock-ledger-report", label: 'Stock Ledger', type: "report" },
  //   { route: "/app/inventory/itemPic", name: "itemPic", label: 'Item (PIC)', type: "form", Scode:"inv_01" },
  //   { route: "/app/inventory/itemPic-list", name: "itemPic-list", label: 'Item (PIC)', type: "list", Scode:"inv_01" },
  // ]

  purchaseRoutes: any[] = [];
  //[
  //   { route: "/app/supplier/form", name: "form", label: 'Supplier ', type: "form", Scode:"pur_01" },
  //   { route: "/app/supplier/suppliers-list", name: "suppliers-list", label: 'Suppliers', type: "list", Scode:"pur_01" },
  //   { route: "/app/purchase/home", name: "Home", label: 'Home', type: "" },
  //   { route: "/app/purchase/purchase-form", name: "purchase-form", label: 'Purchase Form', type: "form", Scode:"pur_02" },
  //   { route: "/app/purchase/form-return", name: "form-return", label: 'Purchase Return', type: "form", Scode:"pur_03" },
  //   { route: "/app/purchase/supplier-payment", name: "supplier-payment", label: 'Supplier Payment', type: "form", Scode:"pur_04" },
  //   { route: "/app/purchase/purchase-list", name: "purchase-list", label: 'Purchase', type: "list", Scode:"pur_02" },
  //   { route: "/app/purchase/list-return", name: "list-return", label: 'Purchase Returns', type: "list", Scode:"pur_03" },
  //   { route: "/app/purchase/supplier-payment-list", name: "supplier-payment-list", label: 'Supplier Payments', type: "list", Scode:"pur_04" },
  //   { route: "/app/purchase/purchase-order", name: "purchase-order", label: 'Purchase Order', type: "form" },
  //   //{  route: "/app/purchase/purchase-order-list", name: "purchase-order-list", label: 'Purchase Orders', type: "list" },
  //   { route: "/app/purchase/goods-receipt-note", name: "goods-receipt-note", label: 'Goods Receipts Notes', type: "form" },
  //   //{  route: "/app/purchase/goods-receipt-note-list", name: "goods-receipt-note-list", label: 'Goods Receipts Notes', type: "list" },
  //   { route: "/app/purchase-report/purchase-summary-report", name: "purchase-summary-report", label: 'Purchase Summary', type: "report" },
  //   { route: "/app/purchase-report/purchase-invoice-report", name: "purchase-invoice-report", label: 'Purchase Invoice', type: "report" },
  //   { route: "/app/finance-report/supplier-ledger-report", name: "supplier-ledger-report", label: 'Supplier Ledger', type: "report" },
  //   { route: "/app/finance-report/supplier-summary-report", name: "supplier-summary-report", label: 'Supplier Summary', type: "report" },


  // ]

  salesRoutes: any[] = [];
  // [
  //   { route: "/app/sale/home", name: "Home", label: 'Home', type: "" },
  //   { route: "/app/sale/list", name: "salelist", label: 'Sale Invoices', type: "list", Scode:"sal_01" },
  //   { route: "/app/sale/form-return", name: "form-return", label: 'Sale Return', type: "form", Scode:"sal_02" },
  //   { route: "/app/sale/list-return", name: "list-return", label: 'Sale Returns', type: "list", Scode:"sal_02" },
  //   { route: "/app/sale/form", name: "sale", label: 'Sale Invoice', type: "form", Scode:"sal_01" },
  //   { route: "/app/sale/view", name: "saleview", label: 'Sale Invoice', type: "form" },
  //   { route: "/app/sale/sale-order", name: "sale-order", label: 'Sale Order', type: "form" },
  //   //{ route: "/app/sale/sale-order-list", name: "sale-order-list", label: 'Sale Orders', type: "list"  }, 
  //   { route: "/app/sale-report/sale-summary-report", name: "saleSummaryReport-report", label: 'Sale Summary', type: "report" },
  //   { route: "/app/sale/customer-receipt", name: "customerReceipt", label: 'Customer Receipt', type: "form", Scode:"sal_03" },
  //   { route: "/app/sale/customer-receipt-list", name: "customerReceiptList", label: 'Customer Receipts', type: "list", Scode:"sal_03" },
  //   { route: "/app/sale-report/customer-ledger-report", name: "customer-ledger-report", label: 'Customer Ledger', type: "report" },
  //   { route: "/app/sale-report/customer-summary-report", name: "customer-summary-report", label: 'Customer Summary', type: "report" },
  //   { route: "/app/sale-report/sale-invoice-report", name: "sale-invoice-report", label: 'Sale Invoice', type: "report" },
  //   { route: "/app/customer/list", name: "customer", label: 'Customers', type: "list", Scode:"sal_04" },
    
  //   { route: "/app/sale/sale-invoice2", name: "sale-invoice2", label: 'Sale Invoice2', type: "form", Scode:"sal_01" },
  //   { route: "/app/sale/sale-invoice2-list", name: "sale-invoice2-list", label: 'Sale Invoice (2)', type: "list", Scode:"sal_01" },
  //   { route: "/app/sale/sale-invoice-box", name: "sale-invoice-box", label: 'Sale Invoice Box', type: "form", Scode:"sal_05" },
  //   { route: "/app/sale/sale-invoice-box-list", name: "sale-invoice-box-list", label: 'POS', type: "list", Scode:"sal_05" },
  //   { route: "/app/sale/broker-list-copy", name: "broker-list-copy", label: 'Broker COPY', type: "list", Scode:"sal_06" },
  //   { route: "/app/sale/broker-copy", name: "broker-copy", label: 'Broker COPY', type: "form", Scode:"sal_06" },
  //   { route: "/app/sale/broker-list", name: "broker-list", label: 'Broker', type: "list", Scode:"sal_06" },
  //   { route: "/app/sale/broker", name: "broker", label: 'Broker ', type: "form", Scode:"sal_06" },

  // ]

  financeRoutes: any[] = [];
  // [
  //   { route: "/app/finance/home", name: "Home", label: 'Home', type: "" },
  //   { route: "/app/finance/gl", name: "gl", label: 'GL Accounts', type: "form", Scode:"fin_01" },
  //   { route: "/app/finance/gl-list", name: "gl-list", label: 'GL Accounts', type: "list", Scode:"fin_01" },
  //   { route: "/app/finance/bank", name: "bank", label: 'Bank', type: "form", Scode:"fin_02" },
  //   { route: "/app/finance/bank-list", name: "bank-list", label: 'Banks', type: "list", Scode:"fin_02" },
  //   { route: "/app/finance/bank-account", name: "bank-account", label: 'Bank Account', type: "form", Scode:"fin_03" },
  //   { route: "/app/finance/bank-account-list", name: "bank-account-list", label: 'Bank Accounts', type: "list", Scode:"fin_03" },
  //   { route: "/app/finance/journal-voucher", name: "journal-voucher", label: 'General Entry', type: "form", Scode:"fin_04" },
  //   { route: "/app/finance/journal-voucher-list", name: "journal-voucher-list", label: 'General Entry', type: "list", Scode:"fin_04" },
  //   { route: "/app/finance-report/balance-sheet-report", name: "balance-sheet-report", label: 'Balance Sheet', type: "report" },
  //   { route: "/app/finance-report/general-ledger-report", name: "general-ledger-report", label: 'General Ledger', type: "report" },
  //   { route: "/app/finance-report/income-statement-report", name: "income-statement-report", label: 'Income Statement', type: "report" },
  //   { route: "/app/finance-report/trial-balance-report", name: "trial-balance-report", label: 'Trial Balance', type: "report" },
  //   { route: "/app/finance/GLIntegration", name: "GLIntegration", label: 'GL Integration', type: "form", Scode:"fin_05" },
  //   { route: "/app/finance/GLIntegration-list", name: "GLIntegration-list", label: 'GL Integrations', type: "list", Scode:"fin_05" },
  // ]

  userRoutes: any[] = [];
  // [
  //   { route: "/app/user/Users-home", name: "Home", label: 'Home', type: "" },
  //   { route: "/app/user/RBAC_User", name: "RBAC_User", label: 'User', type: "form", Scode:"usr_01" },
  //   { route: "/app/user/RBAC_User-list", name: "RBAC_User-list", label: 'Users', type: "list", Scode:"usr_01" },
  //   { route: "/app/user/RBAC_Group", name: "RBAC_Group", label: 'RBAC Group', type: "form", Scode:"usr_02" },
  //   { route: "/app/user/RBAC_Group-list", name: "RBAC_Group-list", label: 'Group', type: "list", Scode:"usr_02" },
  //   { route: "/app/user/RBAC_Permission", name: "RBAC_Permission", label: 'Permissions', type: "form" },
  //   { route: "/app/user/RBAC_Permission-list", name: "RBAC_Permission-list", label: 'Permissions', type: "list" },

  // ]

  productionRoutes:any[] = [];
   //[
    // { route: "/app/production/bom-list", name: "bom-list", label: 'Bill Of Material', type: "list", Scode:"prd_01" },
    // { route: "/app/production/bom", name: "bom", label: 'Bill Of Material', type: "form", Scode:"prd_01" },
    // { route: "/app/production/workorder-list", name: "workorder-list", label: 'Work Order', type: "list", Scode:"prd_02" },
    // { route: "/app/production/workorder", name: "workorder", label: 'Work Order', type: "form", Scode:"prd_02" },
   //]


  customerRoutes:any[]=[];
  // [
  //   { route: "/app/customer/list", name: "customer", label: 'Customers', type: "list", Scode:"sal_04" },    
  // ]

  adminRoutes:any[]=[];

  hrRoutes:any[]=[];

  emRoutes:any[]=[];

  constructor() { }

  openRoute(module:string, name:string){
    if (module == "sales") {
      var index = this.salesRoutes.findIndex(a => a.name == name);
      return this.salesRoutes[index].route;
    }
      else if (module == "inventory") {
      var index = this.inventoryRoutes.findIndex(a => a.name == name);
      return this.inventoryRoutes[index].route;
    } else if (module == "customer") {
      var index = this.customerRoutes.findIndex(a => a.name == name);
      return this.customerRoutes[index].route;
    }  
      else if (module == "purchase") {
      var index = this.purchaseRoutes.findIndex(a => a.name == name);
      return this.purchaseRoutes[index].route;
    }
    else if (module == "finance") {
      var index = this.financeRoutes.findIndex(a => a.name == name);
      return this.financeRoutes[index].route;
    }
    else if (module == "users") {
      var index = this.userRoutes.findIndex(a => a.name == name);
      return this.userRoutes[index].route;
    }
    else if (module == "production") {
      var index = this.productionRoutes.findIndex(a => a.name == name);
      return this.productionRoutes[index].route;
    }

    else if (module == "adminpanel") {
      var index = this.adminRoutes.findIndex(a => a.name == name);
      return this.adminRoutes[index].route;
    }

    else if (module == "hr") {
      var index = this.hrRoutes.findIndex(a => a.name == name);
      return this.hrRoutes[index].route;
    }

    else if (module == "em") {
      var index = this.emRoutes.findIndex(a => a.name == name);
      return this.emRoutes[index].route;
    }


  }

  // getModuleHomeRoute(module:string){
  //   if (module == "sales") {
  //     return this.salesRoutes.filter(a => a.type == "home");
  //   }
  //   else if (module == "inventory") {
  //     return this.inventoryRoutes.filter(a => a.type == "home");
  //   } else if (module == "customer") {
  //     return this.customerRoutes.filter(a => a.type == "home");
  //   }
  //   else if (module == "purchase") {
  //     return this.purchaseRoutes.filter(a => a.type == "home");
  //   }
  //   else if (module == "finance") {
  //     return this.financeRoutes.filter(a => a.type == "home");
  //   }
  //   else if (module == "users") {
  //     return this.userRoutes.filter(a => a.type == "home");
  //   }
    
  // }

  getRouteByType(module: string, type: string) {
    if (module == "sales") {
      return this.salesRoutes.filter(a => a.type == type);
    } else if (module == "inventory") {
      return this.inventoryRoutes.filter(a => a.type == type);
    } else if (module == "customer") {
      return this.customerRoutes.filter(a => a.type == type);
    }
    else if (module == "purchase") {
      return this.purchaseRoutes.filter(a => a.type == type);
    }
    else if (module == "finance") {
      return this.financeRoutes.filter(a => a.type == type);
    }
    else if (module == "users") {
      return this.userRoutes.filter(a => a.type == type);
    }
    else if (module == "production") {
      return this.productionRoutes.filter(a => a.type == type);
    }

    else if (module == "adminpanel") {
      return this.adminRoutes.filter(a => a.type == type);
    }

    
    else if (module == "hr") {
      return this.hrRoutes.filter(a => a.type == type);
    }
    else if (module == "em") {
      return this.emRoutes.filter(a => a.type == type);
    }

  }

  getHomeRoute(){
    return "/app/home";
    //return "/app/hr/dashboard";
  }

  getUnauthorizedAccessRoute(){
    return "/accessdenied";
  }

  setRoutes(){
   this.inventoryRoutes =  this.allRoutes.filter(a => a.ModuleID == EnModules.Inventory);
   this.purchaseRoutes =  this.allRoutes.filter(a => a.ModuleID == EnModules.Purchase);
   this.salesRoutes =  this.allRoutes.filter(a => a.ModuleID == EnModules.Sales);
   this.financeRoutes =  this.allRoutes.filter(a => a.ModuleID == EnModules.Finance);
   this.userRoutes =  this.allRoutes.filter(a => a.ModuleID == EnModules.User);
   this.productionRoutes =  this.allRoutes.filter(a => a.ModuleID == EnModules.Production);
   this.adminRoutes =  this.allRoutes.filter(a => a.ModuleID == EnModules.Admin);
   this.hrRoutes =  this.allRoutes.filter(a => a.ModuleID == EnModules.HR);
   this.emRoutes =  this.allRoutes.filter(a => a.ModuleID == EnModules.EM);
  }

}
