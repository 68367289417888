import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from 'src/app/services/base/permission.service';
import { ScreenConfService } from 'src/app/services/base/screen-conf.service';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-button-save',
  templateUrl: './button-save.component.html',
  styleUrls: ['./button-save.component.scss']
})
export class ButtonSaveComponent implements OnInit {
  @Input() valid:boolean = false;
  @Output() myClick =  new EventEmitter();

  add:boolean=false;
  edit:boolean=false;
  
  constructor(
    public theme:ThemeService,
    public permissionService:PermissionService,
    public screenConfService: ScreenConfService,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getAddperimssion();
    this.getEditperimssion();
  }

  onClick(){
    this.myClick.emit();
  }

  getAddperimssion() {
    this.add = this.permissionService.getAddPerm(this.screenConfService.getscodeAc(this.activatedRoute));
  }

  getEditperimssion() {

    this.edit = this.permissionService.getEditPerm(this.screenConfService.getscodeAc(this.activatedRoute));
  
  }

}
