import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import * as conf_dictionary from 'src/assets/files/conf_dictionary.json';
import { of } from 'rxjs';
import { CustomRxjsService } from './base/custom-rxjs.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentModeService {

  readonly controllerName:string = "PaymentModes";
  
  constructor(
    private base:BaseService,
    private rxjsService: CustomRxjsService
    ) {    
   }

    getAll() {
    //return await this.base.sql.get(this.controllerName);
    return this.rxjsService.convertToObservableAndGetData(conf_dictionary.payment_modes);
  }

 

   async getAll2() {
    /*
     this.base.sql.get(this.controllerName).then(res=> {
      var user = JSON.stringify(res['value']);
      localStorage.setItem("payment_mode", user);
    });
    */
  }

  
  
}
