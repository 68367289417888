<div class="top-ribbon-dialog">
    <app-ribbon-save-dialog [hidden]="Broker.invalid" (myClick)="onSave()" [valid]="Broker.invalid"></app-ribbon-save-dialog>
    <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>
<app-my-heading-dialoge mat-dialog-title heading="Broker Information"></app-my-heading-dialoge>

    
   
<form #Broker="ngForm" class=" rounded needs-validation">
    
    <div class="row g-2">
        
               <div class="col-md-12">
                    
                    <app-my-input label="Broker Name" class="col-md-12" name="BrokerName" required="true" [(ngModel)]="obj.BrokerName">
                    </app-my-input>
                </div>                    
                    <app-my-input label="Contact No " class="col-md-6 col-6" name="ContactNo" [(ngModel)]="obj.ContactNo">
                    </app-my-input>
                    <app-my-input label="Email" class="col-md-6 col-6" name="Email" [(ngModel)]="obj.Email">
                    </app-my-input>
                    <app-my-input label="State" class="col-md-6 col-6" name="State" [(ngModel)]="obj.State">
                    </app-my-input>
                    <app-my-input label="City" class="col-md-6 col-6" name="City" [(ngModel)]="obj.City">
                    </app-my-input>
                    <app-my-input label="ZipCode" class="col-md-6 col-6" name="ZipCode" [(ngModel)]="obj.ZipCode">
                    </app-my-input>
                    <app-my-input label="Address" class="col-md-6 col-6" name="Address1" [(ngModel)]="obj.Address1">
                    </app-my-input>
                    <app-my-input label="Address (2)" class="col-md-6 col-6" name="Address2" [(ngModel)]="obj.Address2">
                    </app-my-input>
                    <div align="end" >                
                        <app-button-save-dialog (myClick)="onSave()" [valid]="Broker.invalid"></app-button-save-dialog>
                     </div>

                
    </div>
            

        

   
  



</form>
