import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { catchError } from 'rxjs/operators';
import { CustomErrorHanderService } from './base/custom-error-hander.service';
import { LocalStorageService } from './base/local-storage.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  readonly controllerName:string = "Customers";
    
  constructor(private base:BaseService,
    public errorService:CustomErrorHanderService,
    public localStorageService: LocalStorageService,
  ) {    
   }

   async getAll() {
    return await this.base.sql.get(this.controllerName);
  }

  async getAllBySp() {
    var qry = "exec sp_GetCustomers ";
    return  await this.base.sql.getBySqlQueryBody(qry);
  }

   getCustomerBalance(customerID:number) {
    var qry = "exec sp_GetCustomerBalance " + customerID;
    return   this.base.sql.getBySqlQueryBody(qry);
  }

  async getById(ID: number) {
    return await this.base.sql.getById(this.controllerName, ID);
  }

  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody,'sal_04').pipe( catchError((err=> this.errorService.handleError(err))) );;

  }

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  delete(ID: number) {
    return this.base.sql.delete(this.controllerName, ID)
  }

  upsert(CustomerArray:any[]){
    for (let index = 0; index < CustomerArray.length; index++) {
      const jsonBody = CustomerArray[index];
      if (jsonBody['CreatedBy'] == null)
        jsonBody['CreatedBy'] = this.localStorageService.getCurrentUser().ID;
    
      if (jsonBody['CreatedDate'] == null) {
        console.log(moment().utc(true));
        console.log(
          moment().utc(true).format('YYYY-MM-DDThh:mm:ss.SSS') + '-07:00'
        );
        moment().utc(true);
        jsonBody['CreatedDate'] =
          moment().utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'); //+ '-07:00'; // new Date();
      }
    
      if (jsonBody['ModifiedBy'] == null)
        jsonBody['ModifiedBy'] = this.localStorageService.getCurrentUser().ID;
    
      if (jsonBody['ModifiedDate'] == null)
        jsonBody['ModifiedDate'] =
          moment().utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'); //+ '-07:00'; //new Date();
    
        jsonBody['Scode'] = "inv_01";
    }
    return this.base.sql.postAny(`api/${this.controllerName}/upsert`, CustomerArray);
  }

}
