import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { RouteService } from 'src/app/services/base/route.service';
import { RecentActivityService } from 'src/app/services/recent-activity.service';

@Component({
  selector: 'app-recen-activity-table',
  templateUrl: './recen-activity-table.component.html',
  styleUrls: ['./recen-activity-table.component.scss']
})
export class RecenActivityTableComponent implements OnInit {

  data: any[] = [];
  loading:boolean = null;
  constructor(
    public recentActivityService: RecentActivityService,
    public routeService:RouteService,
    public router:Router
  ) { }

  ngOnInit(): void {
    this.getActivityData()
    console.log(this.data)
  }


  async getActivityData(){
    this.loading =true;
    await this.recentActivityService.getData().subscribe(res => {
      this.loading =false;
      this.data =res['value'] as any[];
    })
  }

  openRoute(route, recordid){
    this.router.navigate([route], { queryParams: { ID: recordid } });  
  }

 

}
