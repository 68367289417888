
<div class="d-flex align-items-center justify-content-between">
        <h2>Filter</h2>
        <button class="me-2" mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<br>


<app-my-table-filter 
                *ngFor="let column of columns; let index = index;"                
                [hidden]="false" 
                label="Filter" 
                [data]="[]" 
                [title]="column.header"
                [name]="column.columnDef" 
                [model]="column.filterValue"                
                [column] = "column"
                [tableQueryData]="tableQueryData"
                (change)="filterChange(column,$event)"
                class="w-100 mt-3 columnFilter"
                [hidden]="index == 0">`
</app-my-table-filter>



<app-my-button title="Apply" icon="done" class="float-end me-2"></app-my-button>