import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { AppComponent, UserProviderFactory } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteService } from './services/base/route.service';
import { ReportsHomeComponent } from './reports/reports-home/reports-home.component';
//import { SqlService } from './services/base/sql.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './services/base/interceptor/http-config.interceptor';
import { BaseService } from './services/base/base.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PageNotFoundComponent } from './modules/main/page-not-found/page-not-found.component';
import { BaseComponent } from './modules/base/base.component';
import { AppInjectorService } from './services/base/app-injector.service';
import { SignupComponent } from './modules/main/signup/signup.component';
import { ParentBaseComponent } from './modules/base/parent-base/parent-base.component';
import { TokenValidateService } from './services/base/token-validate.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DialogsModule } from './modules/dialogs/dialogs.module';
import { DashboardComponent } from './modules/main/dashboard/dashboard.component';
import { NewDashComponent } from './modules/main/new-dash/new-dash.component';
import { NewdashMoreComponent } from './modules/main/new-dash/newdash-more/newdash-more.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SalesDashComponent } from './modules/main/sales-dash/sales-dash.component';
import { SalesChartComponent } from './modules/main/sales-dash/sales-chart/sales-chart.component';
import { Salev3DashComponent } from './modules/main/salev3-dash/salev3-dash.component';
import { LoginNewComponent } from './modules/main/login-new/login-new.component';
import { DialogBaseComponent } from './modules/base/dialog-base/dialog-base.component';
import { AuthGuardService } from './services/base/auth-guard.service';
import { UnauthorizedComponent } from './modules/main/unauthorized/unauthorized.component';
import { RecenActivityTableComponent } from './modules/main/recen-activity-table/recen-activity-table.component';
import { SigninComponent } from './modules/main/signin/signin.component';
//import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


/* Changes start here. */
// Import MSAL and MSAL browser libraries. 
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

// Import the Azure AD B2C configuration 
import { msalConfig, protectedResources } from './auth-config';
import { TestViewscreenComponent } from './modules/main/test-viewscreen/test-viewscreen.component';
import { RegisterComponent } from './modules/main/register/register.component';
import { WelcomeBoardComponent } from './modules/main/welcome-board/welcome-board.component';
import { CustomerDetailComponent } from './modules/main/customer-detail/customer-detail.component';
import { SaleDashboardComponent } from './modules/main/sale-dashboard/sale-dashboard.component';
import { DueDaysComponent } from './modules/main/due-days/due-days.component';
import { AllReportsComponent } from './modules/main/all-reports/all-reports.component';
import { AllFormsDialogComponent } from './modules/main/all-forms-dialog/all-forms-dialog.component';
import { CustomNumberFormatPipe } from './pipes/custom-number-format.pipe';
import { ConnectionStatusComponent } from './modules/main/connection-status/connection-status.component';
import { QuickActionComponentComponent } from './modules/main/quick-action-component/quick-action-component.component';
import { IdTokenComponent } from './modules/main/id-token/id-token.component';






/*
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
*/

@NgModule({
  declarations: [
    AppComponent,
    RoutingComponents,    
    ReportsHomeComponent,
    PageNotFoundComponent,
    BaseComponent,
    SignupComponent,
    ParentBaseComponent,
    DashboardComponent,
    NewDashComponent,
    NewdashMoreComponent,
    SalesDashComponent,
    SalesChartComponent,
    Salev3DashComponent,
    LoginNewComponent,
    DialogBaseComponent,
    UnauthorizedComponent,
    RecenActivityTableComponent,
    SigninComponent,
    TestViewscreenComponent,
    RegisterComponent,
    WelcomeBoardComponent,
    CustomerDetailComponent,
    SaleDashboardComponent,
    DueDaysComponent,
    AllReportsComponent,
    AllFormsDialogComponent,
    CustomNumberFormatPipe,
    ConnectionStatusComponent,
    QuickActionComponentComponent,
    IdTokenComponent
 
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    
    SharedModule,
    NgApexchartsModule,
    MaterialModule,
    NgxSpinnerModule,
    //NgxSkeletonLoaderModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    DialogsModule,
   
      // Initiate the MSAL library with the MSAL configuration object
      MsalModule.forRoot(new PublicClientApplication(msalConfig),
      {
        // The routing guard configuration. 
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: protectedResources.todoListApi.scopes
        }
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [protectedResources.todoListApi.endpoint, protectedResources.todoListApi.scopes]
        ])
      }),
    /* Changes end here. */


    /*firebase*/
    /*
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule, */
  ],
  providers: [
    BaseService,
    RouteService,    
    AuthGuardService,
    CustomNumberFormatPipe,
     /* Changes start here. */
    //  {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    MsalGuard,
    
    /* Changes end here. */

    {provide:HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi:true}    
  ],
  bootstrap: [
    AppComponent,
   /* Changes start here. */
   MsalRedirectComponent
   /* Changes end here. */],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule { 
  constructor(injector: Injector) {    
    AppInjectorService.injector = injector;
  }
}
