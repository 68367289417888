 <div class="myForm">





    <form class=" p-3 mb-5 rounded mt-4">
        <div class="d-flex justify-content-center">
            <div class="logo">
                <img src="assets/img/big-logo.png" alt="Logo" style="width: 300px;">
            </div>
        </div>

        <div class="row">

            <div class="col-md-12 pt-5 text-center">
                <app-my-heading heading="Log In"></app-my-heading>
            </div>
        </div>


        <div class="row">

            <app-my-input label="Email or Mobile Number" class="col-md-12" required="true" name="UserId"
                [(ngModel)]="userId">
            </app-my-input>
            <app-my-input-password label="Password" class="col-md-12" required="true" name="Password"
                [(ngModel)]="password">
            </app-my-input-password>
        </div>

        <mat-error>{{errorMessage}}</mat-error>

         <div class="row mt-4">

            <div class="col-md-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="rememberme" id="rememberme">
                    <label class="form-check-label" for="rememberme"> Remember me </label>
                </div>
            </div>

        </div> 

        <div class="row">
            <div class="col-md-12 mt-4">
              
                <button [disabled]="validate()" class="btn btn-block w-100" [class]="theme.myButtonBgColor"
                    type="button" (click)="openLink()">
                    Sign In
                </button>

            </div>
        </div>

      

    </form>
</div>   

<!-- <div class="content myForm">
    <div class="d-flex justify-content-center mb-3">
        <div class="logo mt-3">
            <img src="assets/img/big-logo.png" alt="Logo" style="width: 350px;">
            <h4 class="text-center me-4" style="font-size: 20px;"> Log in to BMS </h4>
        </div>
    </div>
    <div class="forms">
        <mat-form-field appearance="legacy" class="mt-4 w-25">
            <mat-label>Email Or Password</mat-label>
            <input matInput placeholder="sufyan@gmail.com">
          </mat-form-field>
    </div>

</div> -->