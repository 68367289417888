<!-- <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{label}}</mat-label>
    <input 
    [disabled]="disabled"
    type="email"
    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
    matInput  
    autocomplete="off"
    #name="ngModel"
    [required]="required" 
    [(ngModel)]="model"     
    (ngModelChange)="onChange(model, name)">    
    <mat-hint>{{hint}}</mat-hint>
    
    <mat-error *ngIf="name.touched && name.errors?.required">This field is required</mat-error>
    <mat-error *ngIf="name.touched && name.errors?.pattern">This is not a valid Email!!!</mat-error>
    
</mat-form-field> -->


<label for="">
  {{label}} <span *ngIf="required" style="color: #f44336;">*</span>
</label>

<input 
  type="email" 
  [disabled]="disabled"
  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
  placeholder="Enter" 
  autocomplete="off" 
  class="form-control form-control-sm my-2 myinput w-100"
  #name="ngModel" 
  [required]="required" 
  [(ngModel)]="model" 
  (ngModelChange)="onChange(model, name)"> 

<!-- <p *ngIf="name.invalid && (name.dirty || name.touched)" style="color: #f44336;"> This field is required</p> -->
<p *ngIf="name.touched && name.errors?.required">This field is required</p>
<p *ngIf="name.touched && name.errors?.pattern">This is not a valid Email!!!</p>
