import { Injectable } from '@angular/core';
import { PaymentModeService } from './payment-mode.service';

@Injectable({
  providedIn: 'root'
})
export class ConfDictionaryDataService {

  constructor(
    public paymentModeService:PaymentModeService
  ) { }


  async getAllData(){
    //await this.paymentModeService.getAll2();
    //console.log(this.paymentModeService.getPaymentModes());
  }
}
