export class FN_GLAccount {
    ID:number = 0;
    AccountHeadID:number = null;
    CompanyID:number = 0;
    GLAccountNo:string = null;
    GLAccountName:string = null;
    NatureID: number = null;

    IsSubAccount:boolean = null;
    ParentAccountID:number= null;
    CreatedBy:number = null;
    CreatedDate:string = null;
    ModifiedBy:number = null;
    ModifiedDate:string = null;
    IsSysGenerated:boolean = null;
    DimensionType:string = null;
}