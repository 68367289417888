import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import * as customConfig from 'src/assets/files/customConfig.json';
import { SortDirection } from '@angular/material/sort';
import { AuthenticateService } from '../base/authenticate.service';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from '../base/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConfTableService {
  readonly rootUrl: string = customConfig.api;

  conf_table_data: any = null;
  table_data: any[] = [];

  constructor(
    public base: BaseService,
    public http: HttpClient,
    public authenticate: AuthenticateService,
    public localStorageService: LocalStorageService
  ) {}

  //  getTableConfData(scode:string){
  //   var qry = "exec sp_ConfTable " + scode;
  //   return  this.base.sql.getBySqlQueryBody(qry);
  // }

  async getTableConfData(scode: string): Promise<any> {
    await this.getAllTableConfData();

    var valueData = this.conf_table_data['value'].filter(
      (a) => a.scode == scode
    );
    var table1Data = this.conf_table_data['Table1'].filter(
      (a) => a.scode == scode
    );

    var obj = {
      value: valueData,
      Table1: table1Data,
    };

    return obj;
  }

  setConfTable_localStorage(obj: any) {
    this.localStorageService.removeData('tbl');
    this.localStorageService.setData('tbl', obj);
  }

  getConfTable_localStorage(): any {
    return this.localStorageService.getData('tbl');
  }

  async getAllTableConfData(): Promise<any[]> {
    var obj: any[] = this.getConfTable_localStorage();

    if (obj) {
      this.conf_table_data = obj;
      return;
    }

    // sameer: 1/20/2024
    if (this.conf_table_data != null) return this.conf_table_data;

    var qry: string = this.rootUrl + `api/Conf_Table/GetAllData`;
    await this.http
      .get(qry)
      .toPromise()
      .then((res) => {
        this.conf_table_data = res as any[];
        this.setConfTable_localStorage(this.conf_table_data);
        return this.conf_table_data;
      });

    return null;
  }

  getDataByScode(scode:string){
    var qry:string = this.rootUrl + `api/Conf_Table/GetData/${scode}`;
    return this.http.get(qry);
  }

  /*
  async getDataByScode_Cache(scode: string): Promise<any[]> {
    var data_index = this.table_data.findIndex((a) => a.scode == scode);

    if (data_index > -1) {
      return this.table_data[data_index].data;
    }

    await this.getDataByScode(scode);

    return this.table_data[this.table_data.length - 1].data;
  }

  async getDataByScode(scode: string) {
    
    var data_index = this.table_data.findIndex((a) => a.scode == scode);

    if (data_index > -1) {
       this.table_data.splice(data_index, 1);
    }

    var qry: string = this.rootUrl + `api/Conf_Table/GetData/${scode}`;
    await this.http
      .get(qry)
      .toPromise()
      .then((res) => {
        this.table_data.push({ scode: scode, data: res });
      });
  }
  */


  getDataByScodeLazyLoaded(scode:string, pagenumber:number, pagesize, sorton:string, sortby:string, search:string){
    var qry:string = this.rootUrl + `api/Conf_Table/GetDataLazyLoaded/${scode}/${pagenumber}/${pagesize}/${sorton}/${sortby}/${search}`;
    return this.http.get(qry);
  }

  
  getDataSQL(query:string){
    var qry = query;
    return  this.base.sql.getBySqlQueryBody(qry);
  }

   getData(query:string, sort: string, order: SortDirection, page: number) {    
    var skip:number = 0;
    if(page == 0)
      skip = 0;
    else
      skip = page * 20;

    if(sort.includes('.')){
      sort = sort.replace('.', '/');
    }

    var qry:string = "";
    if(query.includes("?")){
      qry = this.rootUrl + query + "&" + "$orderby=" + sort + " " + order + "&$top=20&$skip=" + skip + "&$count=true";
    } else {
      qry = this.rootUrl + query + "?" + "$orderby=" + sort + " " + order + "&$top=20&$skip=" + skip + "&$count=true";
    }
    
    return  this.http.get(qry);
  }

  getUniqueData(query:string) {        
    var qry:string = this.rootUrl + query;        
    return  this.http.get(qry);
  }

}
