import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';
import { ConfTableService } from 'src/app/services/inventory/conf-table.service';


@Component({
  selector: 'app-my-table-filter',
  templateUrl: './my-table-filter.component.html',
  styleUrls: ['./my-table-filter.component.scss']
})
export class MyTableFilterComponent implements OnInit, OnChanges {
  @Input() label:string = "";
  @Input() title:string = "";
  @Input() data:any[]= null;
  @Input() name:any[]= null;
  @Input() model:any = null;
  @Input() column: any = null;
  @Input() tableQueryData: any[] =[];
  name_nested:any = null;

  @Output() change = new EventEmitter<any>();

  constructor(
    public theme:ThemeService,
    public confTableService:ConfTableService
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.name.length > 0){
      this.name_nested = String(this.name).split('.');
   }
   
  }

  getData(){
    if(this.tableQueryData.length == 0 || this.data.length > 0)
    return;
    //Suppliers?$apply=groupby((SupplierName))
    var query:string = this.tableQueryData[0].MyQuery;
    var controller: string = query.substring(0, query.indexOf("?") == -1 ? query.length : query.indexOf("?"));
    var columnName: string = String(this.name).replace(".", "/");
    var new_query: string = controller + "?" + "$apply=groupby((" + columnName + "))";

    this.confTableService.getUniqueData(new_query).toPromise().then(res =>{
        this.data = res['value'];
        //var option = this.data[0];
        //var nn = option?.[this.name_nested[0]];
        //var nn = option?.[this.name_nested[0]]?.[this.name_nested[1]]
    })
  }

  onChange(value:any){   
    this.change.emit(value);
  }

 
  getValue(option:any){    
    return this.name_nested.length == 1 ? option?.[this.name_nested[0]] : option?.[this.name_nested[0]]?.[this.name_nested[1]];
  }

getText(option:any){  

  if(option)
      return this.name_nested.length == 1 ? option?.[this.name_nested[0]] : option?.[this.name_nested[0]]?.[this.name_nested[1]];
    else
      return '';
}

getNumber(option){
  if(option)
      return this.name_nested.length == 1 ? option?.[this.name_nested[0]] : option?.[this.name_nested[0]]?.[this.name_nested[1]];
    else
      return '';
}

}
