import { Injectable } from '@angular/core';
import { MessageDto } from './MessageDto.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as signalR from '@microsoft/signalr'; // import signalR
import { TokenService } from '../base/token.service';
import * as customConfig from 'src/assets/files/customConfig.json';
import { LocalStorageService } from '../base/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DataHubService {

  // token:string = "";

  // options = {
  //   accessTokenFactory: () => this.token,
  //   transport: signalR.HttpTransportType.WebSockets,
  //   skipNegotiation: true,
  // };
  private connection: any ;
  // private connection: any = new signalR.HubConnectionBuilder()
  //   .withUrl('https://localhost:7043/chatHub', this.options) // mapping to the chathub as in startup.cs
  //   .configureLogging(signalR.LogLevel.Information)
    
  //   .build();
   readonly POST_URL = 'https://localhost:7043/api/chat/send';

   private receivedMessageObject: MessageDto = new MessageDto();
   private sharedObj = new Subject<MessageDto>();

  constructor(private http: HttpClient, private tokenService:TokenService, private localStorageService: LocalStorageService) {
//Sameer: 08/23/2024: Hub code committed
  //   tokenService.acquireTokenSilently().subscribe(res => {
  //   //  debugger;
  //     var data =  res as any;
  //     this.token = data.accessToken;
     
  //   })

  //  // debugger;
  //   this.connection.onclose(async () => {
  //     await this.start();
  //   });
  //   this.connection.on('ReceiveOn', (user: string, message: string)  => {
      
  //     console.log("reveived scode", user + message);
  //     //this.mapReceivedMessage(user, message);
  //   });
  //   this.start();
  }

  // Strart the connection
  public async start() {
    try {
      await this.connection.start();
      console.log('connected');
    } catch (err) {
      console.log(err);
      setTimeout(() => this.start(), 5000);
    }
  }

  private mapReceivedMessage(user: string, message: string): void {
    this.receivedMessageObject.user = user;
    this.receivedMessageObject.msgText = message;
    this.sharedObj.next(this.receivedMessageObject);
  }

  /* ****************************** Public Mehods **************************************** */

  // Calls the controller method
  public broadcastMessage(msgDto: any) {
    this.http
      .post(this.POST_URL, msgDto)
      .subscribe((data) => console.log(data));
    // this.connection.invoke("SendMessage1", msgDto.user, msgDto.msgText).catch(err => console.error(err));    // This can invoke the server method named as "SendMethod1" directly.
  }

  public retrieveMappedObject(): Observable<MessageDto> {
    return this.sharedObj.asObservable();
  }

  public addToGroup(groupName: string) {
    this.connection.invoke('AddToGroup', groupName)
      .catch((err: any) => console.error(err));
  }

  public removeFromGroup(groupName: string) {
    this.connection.invoke('RemoveFromGroup', groupName)
      .catch((err: any) => console.error(err));
  }

  public sendMessageToGroup(groupName: string, message: string) {
    this.connection.invoke('SendMessageToGroup', groupName, message)
      .catch((err: any) => console.error(err));
  }

  public addReceiveMessageListener(callback: (message: string) => void) {
    this.connection.on('ReceiveMessage', callback);
  }
}
