import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { RouteService } from 'src/app/services/base/route.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { RBAC_User } from 'src/app/models/rbac_user.model';
import { TokenService } from 'src/app/services/base/token.service';
import { NotifciationMessageService } from 'src/app/services/notification/notifciation-message.service';
import { RecentActivityService } from 'src/app/services/recent-activity.service';


@Component({
  selector: 'app-right-nav',
  templateUrl: './right-nav.component.html',
  styleUrls: ['./right-nav.component.scss']
})
export class RightNavComponent implements OnInit  {
  @Input('drawer') drawer: MatSidenav;
  panelOpenState = true;

  constructor(
    public routeService:RouteService,
    public router: Router,
    public authenticate: AuthenticateService,
    public notificationService: NotifciationMessageService,
    public tokenService:TokenService,
    public recentActivityService:RecentActivityService
    ) { }

  ngOnInit(): void {
   //this.getNotification();
  }

  // async getNotification(){
  //   await this.notificationService.getAll().then(res => {
  //     this.dataNotification = res['value'] as any[];
  //   })
  // }

 

  openRoute(route, recordid){
    this.router.navigate([route], { queryParams: { ID: recordid } });
    this.drawer.close();
  }



}