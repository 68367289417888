<div class="w-100 text-end mt-3">
       <button [disabled]="valid" style="    border-top-right-radius: 0px;
       border-bottom-right-radius: 0px;
       border-right: solid 1px #9066c9;" mat-flat-button color='primary' (click)="ClickSaveOnly()">
              <mat-icon class="me-2">save</mat-icon>
              <span>Save</span>
       </button>
       <button style="    border-top-left-radius: 0px;
       border-bottom-left-radius: 0px;" mat-flat-button color='primary'  [matMenuTriggerFor]="menu">
              <mat-icon >expand_more</mat-icon>
              <mat-menu #menu="matMenu" xPosition="before" class="btnsavediv">
                 
                     <button mat-menu-item (click)="ClickSaveandprint()">
                            <mat-icon class="saveprint">print</mat-icon>
                            <span class="me-2">Save & Print</span>
                     </button>
                     <button mat-menu-item (click)="ClickSaveAndNew()">
                            <mat-icon class="saveedit">add</mat-icon>
                           <span class="me-2">Save & New</span> 
                     </button>
              </mat-menu>

       </button>
       <!-- <button  mat-flat-button color='primary' class="mx-2 ">
              <span>Save</span>
       </button>
       <button type="button" mat-flat-button color='primary' data-bs-toggle="dropdown">
              <mat-icon>more_vert</mat-icon>
       </button>
       <div class="dropdown-menu">
         <a class="dropdown-item" href="#">Tablet</a>
         <a class="dropdown-item" href="#">Smartphone</a>
       </div> -->
     
</div>
