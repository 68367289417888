import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/base/loader.service';

@Component({
  selector: 'app-my-quick-dialog',
  templateUrl: './my-quick-dialog.component.html',
  styleUrls: ['./my-quick-dialog.component.scss']
})
export class MyQuickDialogComponent implements OnInit,AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<MyQuickDialogComponent>,
    public loaderService:LoaderService,
    private renderer: Renderer2, 
    private elementRef: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: any)
    
   { }
  ngAfterViewInit(): void {
    this.hideElementsByClass('top-ribbon-dialog');
  }

  hideElementsByClass(className: string) {
    const elements = this.elementRef.nativeElement.querySelectorAll('.' + className);
    elements.forEach((element: any) => {
      this.renderer.setStyle(element, 'display', 'none');
    });
  }

  ngOnInit(): void {
    // var elements = document.getElementsByClassName('top-ribbon-dialog') as  HTMLCollectionOf<HTMLElement>;
    //   elements[0].style.display = 'none';


      

  }

  

}
