export enum EnPaymentMode {
    Cash = 1,
    Credit = 2
}

export enum EnFN_Nature{
    Debit = 101,
    Credit = 102
}

export enum EnFN_PostStatus{
    Park = 1,    
    Post = 2,
    
}


export enum EnFN_JVType{
    OP = 1,    
    SV = 2,
    PV = 3,
    SP = 4,
    JV = 5,
    CC = 6,
    SR = 7,
    PR = 8,
    GE = 9,
    BD = 10,
    BP = 11,
    CR = 12 ,
}

export enum EnModules{
    Inventory = 1,    
    Purchase = 2,
    Sales = 3,
    Finance = 4,
    User = 5,
    Production = 6,  
    Admin = 7 ,
    HR = 8,
    EM = 9

}

export enum EnTrackingStatus{
    Appointment = 1,    
    Joining = 2,
    Transfer = 3,
    Promotion = 4,
    AdditionalDuty = 5,
    Suspension = 6,  
    Reinstatement = 7 
}

export enum EnEmployeeStatus{
    Active = 1,    
    Suspended = 2,
    Retired = 3,
    Inactive = 4    
}

export enum EnPaymentTypes{
    Cash = 1,    
    Cheque = 2,
    OnlineTransfer = 3,
    BankDeposit = 4    
}

export enum EnOnboardStepper{
    Name = 0,
    Company = 1,
    ModuleSelect = 2,
    Last = 3,
}