<div class=" row d-flex justify-content-center   ">
        <div class=" mt-3 mobilediv">
                <h2 class="heading m-2 "> Quick Action </h2>
                <div class="quickActions   ">

                        <!-- <div (click)="onQuickJVCreate()" class="d-flex align-items-center gap-3 cstmActionDiv">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div>New Quick General Entry</div>
                        </div> -->
                        <div (click)="onItemCreate()"
                                class="d-flex align-items-center mbcard gap-2 cstmActionDiv itemdiv col-md-3 ">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div class="mbcardText"> Item</div>
                        </div>
                        <div (click)="openLink('sales', 'sale')"
                                class="d-flex align-items-center gap-2 mbcard cstmActionDiv itemdiv col-md-3 ">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div class="mbcardText"> Sale Invoice</div>
                        </div>
                        <div (click)="openLink('sales', 'delivery-challan')"
                                class="d-flex align-items-center gap-2 mbcard cstmActionDiv itemdiv col-md-3 ">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div class="mbcardText"> Delievery Challan</div>
                        </div>
                        <div (click)="openLink('purchase', 'purchase-form')"
                                class="d-flex align-items-center gap-2 mbcard cstmActionDiv itemdiv col-md-3 ">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div class="mbcardText"> Purchase Invoice</div>
                        </div>
                        <div (click)="openLink('sales', 'customerReceipt')"
                                class="d-flex align-items-center gap-2 mbcard cstmActionDiv itemdiv col-md-3 ">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div class="mbcardText"> Customer Receipt</div>
                        </div>

                </div>
        </div>
</div>
<!-- <div class="col-md-3  ">
        <div class=" mt-3 mobilediv">
                <h2 class="heading m-2 "> Quick Action </h2>
                <div class="quickActions   ">

                        <div (click)="onItemCreate()"
                                class="d-flex align-items-center gap-2 cstmActionDiv itemdiv">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div>New Item</div>
                        </div>
                        <div (click)="openLink('sales', 'sale')"
                                class="d-flex align-items-center gap-2 cstmActionDiv itemdiv">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div>New Sale Invoice</div>
                        </div>
                        <div (click)="openLink('sales', 'delivery-challan')"
                                class="d-flex align-items-center gap-2 cstmActionDiv itemdiv">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div>New Delievery Challan</div>
                        </div>
                        <div (click)="openLink('purchase', 'purchase-form')"
                                class="d-flex align-items-center gap-2 cstmActionDiv itemdiv">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div>New Purchase Invoice</div>
                        </div>
                        <div (click)="openLink('sales', 'customerReceipt')"
                                class="d-flex align-items-center gap-2 cstmActionDiv itemdiv">
                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                <div>New Customer Receipt</div>
                        </div>

                </div>
        </div>
</div> -->