import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Brands } from 'src/app/models/inventory/brands.model';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { CategoryService } from 'src/app/services/category.service';
import { BrandsService } from 'src/app/services/inventory/brands.service';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {



  primaryKey:number = 0;
  obj: Brands;

  dataCategory:any[] = [];
  selectedCategory: any = null;

  constructor(
    public brandService:BrandsService,
    public categoryService:CategoryService,
    public dialogService:DialogService,
    public hlp : ConfHelpService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.obj = new Brands();
    this.loaderService.startLoader();
   }

  ngOnInit(): void {

    Promise.all([
      this.getCategories()
    ]).then(res => {

      if(this.data.primaryKey > 0)
      this.getData();
      else
      this.setDefaultValues();
      this.loaderService.endLoader();
    })
   
    
  }

  setDefaultValues(){
    if(this.dataCategory.length > 0){
      this.selectedCategory = this.dataCategory[0];      
    }
  }
  
  setDropDowns(){
    if (this.dataCategory.filter(res => res.ID == this.obj.CategoryID).length > 0) {
      this.selectedCategory = this.dataCategory.filter(res => res.ID == this.obj.CategoryID)[0];      
    }
  }

  async getCategories(){
  //   await this.categoryService.getAll().then(res=>{          
  //     this.dataCategory = res['value'] as any[];    
  //   })
  // }
  await this.hlp.getFormDataByHelpNo("invitemcategory").toPromise().then(res=>{          
    this.dataCategory = res['value'] as any[];    
  })

}

getData(){
  this.primaryKey = this.data.primaryKey;
    this.brandService.getById(this.primaryKey).then(res => {
      this.obj = res as Brands;
      this.setDropDowns();
      this.loaderService.endLoader();
    })
}

onSave(){

  this.loaderService.startLoaderOnSave();
  
  // this.obj.CategoryID = this.selectedCategory.ID

  if (this.primaryKey == 0) {
    this.brandService.post(this.obj).subscribe(res => {
      // this.dialogService.closeDialog("save");
      this.dialogService.closeDialog_List('inv_05', "save");
    });
  }
  else {
    this.brandService.update(this.obj, this.primaryKey).subscribe(res=>{
      this.loaderService.endLoaderOnSave();
      this.dialogService.closeDialog_List('inv_05', "save");
      // this.dialogService.closeDialog("save");
    }); 
  }
}

onDelete(){
  if (this.primaryKey > 0 ) {
    this.dialogService.openSmallDialog(YesNoComponent);

    this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
      if (result) {
          if (result == "yes") {
            this.brandService.delete(this.primaryKey).subscribe(res => {
              //this.dialogService.closeDialog("delete");
              this.dialogService.closeDialog_List('inv_05', "delete");
            })
          }
      }
    })
  }
}

}
