import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { PurchaseViewComponent } from '../purchase-view/purchase-view.component';
import { PrintDialogComponent } from 'src/app/shared/Dialog/print-dialog/print-dialog.component';
import { LoaderService } from 'src/app/services/base/loader.service';

@Component({
  selector: 'app-supplier-payment-view',
  templateUrl: './supplier-payment-view.component.html',
  styleUrls: ['./supplier-payment-view.component.scss']
})
export class SupplierPaymentViewComponent implements OnInit {
  dataHdr:any = null;
  dataAll:any[] = [];
  constructor(
    public router:Router,
    public routeService:RouteService,
    public routerService:RouteService,
    public dialogService:DialogService,
    public helpservice:ConfHelpService,
    public loaderService:LoaderService,
    public dialogRef: MatDialogRef<PurchaseViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getViewData();
  }

  async getViewData() {
    this.loaderService.startLoader();
    var filter = {
      id : this.data.ID,
      
    }

    await this.helpservice.getFormDataByHelpNo("pursupplierpayment", filter).toPromise().then(res => {
      this.dataHdr = res['value'][0] as any;
      this.dataAll = res['value'] as any[];
      this.loaderService.endLoader();
      console.log(this.dataAll)
    })
  }

  onEdit(){
    this.dialogRef.close();
    this.router.navigate([this.routeService.openRoute("purchase", "supplier-payment")], { queryParams: { ID: this.data.ID, parent: 'supplier-payment-list' } });
  }

  print(){
    if(this.data.ID == 0)
      return;

    var obj = {
      reportno:'rpt12',
      queryparam:'id='+this.data.ID,
    }
    this.dialogService.openPrintDialog(PrintDialogComponent, obj);  
   
  }

}
