<div class="top-ribbon-dialog" [class]="theme.myTopNavColor">         
    <h5>Select Customer</h5>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>
<!-- <app-my-heading-dialoge mat-dialog-title heading="Select Customer"></app-my-heading-dialoge> -->
<br><br>
<app-my-list 
    [search]="true"
    [data]="data?.data?.data" 
    [title]="data?.data?.title" 
    [icon]="data?.data?.icon" 
    [val1]="data?.data?.val1"
    [val2]="data?.data?.val2"                
    [val2]="data?.data?.val3"                
    (myClick)="onListItemClick($event)">
</app-my-list> 