import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Company } from 'src/app/models/company.model';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(
    private baseService:BaseService
    ) {
   
  }

   signup(companyObj: Company) {
    
    return this.baseService.sql.http.post(this.baseService.sql.rootUrl + "api/SignUp",companyObj);
  }
}
