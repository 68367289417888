import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-save-btn',
  templateUrl: './save-btn.component.html',
  styleUrls: ['./save-btn.component.scss']
})
export class SaveBtnComponent implements OnInit {

  @Input() valid:boolean = false; 

  @Output() btn_SaveOnly:EventEmitter<any> = new EventEmitter<any>();

  @Output() btn_Saveandprint:EventEmitter<any> = new EventEmitter<any>();

  @Output() btn_SaveAndNew:EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ClickSaveOnly(){
    this.btn_SaveOnly.emit();
  }

  ClickSaveandprint(){
    this.btn_Saveandprint.emit();
  }

  ClickSaveAndNew(){
    this.btn_SaveAndNew.emit();
  }

}
