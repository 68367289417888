<!-- <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{label}}</mat-label>
        <input 
        [type]="hide ? 'password' : 'text'"
        autocomplete="off"   
        maxlength="20"             
        matInput  
        autocomplete="off"
        #name="ngModel"
        [required]="required" 
        [(ngModel)]="model"     
        (ngModelChange)="onChange(model)">     -->
        <!-- <button tabindex="-1" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>   -->
        <!-- <mat-hint>{{hint}}</mat-hint>
        
        <mat-error *ngIf="name.invalid || name.dirty">This field is required</mat-error>
    </mat-form-field> -->
    

    <label for="" class="lbl">
        {{label}}
        <span *ngIf="required" style="color: #f44336;">*</span>
    </label>
    <input  
    [type]="hide ? 'password' : 'text'"
    [placeholder]="placeHolder" 
    autocomplete="off" 
    class="form-control form-control-sm myinput w-100" 
    maxlength="20"            
    #name="ngModel"
    [required]="required" 
    [(ngModel)]="model"     
    (ngModelChange)="onChange(model)"
    >

    <p *ngIf="name.invalid && (name.dirty || name.touched)" style="color: #f44336;"> This field is required</p>