<div class="container-fluid ">
        <div class="closeBtnDiv mt-2 ">
                <h2 class="mt-2 mx-2 ">Create Bulk Items</h2>

                <button class="btn" mat-dialog-close> <mat-icon class="closeColor">close</mat-icon>
                </button>



        </div>

        <div class="row">
                <div class="col-md-12">
                        <div class="card-body ">
                                <div class="table-responsive ">
                                        <table class="table table-bordered ">
                                                <thead class="title">
                                                        <tr>
                                                                <td width="20"></td>
                                                                <td width="70">Item Name</td>
                                                                <td width="30">Unit</td>
                                                                <td width="70">Item Type</td>
                                                                <td width="70">Category</td>
                                                                <td width="70">Brand</td>
                                                                
                                                               
                                                                <td width="30">Cost Price</td>
                                                                <td width="30">Sale Price</td>
                                                                <!-- <td width="30">Opening Stock</td> -->
                                                                <th width="10">
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        <tr *ngFor="let item of objDetail; let index = index">
                                                                <td width="20"
                                                                style="text-align: center; vertical-align: middle;">
                                                                <app-my-icon-button-table
                                                                        (myClick)="onRowDuplicate(index)"
                                                                        icon="content_copy"></app-my-icon-button-table>
                                                                 </td>

                                                                <td width="70">
                                                                        <app-my-input-table label="Item Name"
                                                                                required="true" name="ItemName"
                                                                                [(ngModel)]="item.ItemName">
                                                                        </app-my-input-table>
                                                                </td>
                                                                  <td width="30">
                                                                        <app-my-select-table title="UnitName"
                                                                                [data]="dataUnit"
                                                                                [(ngModel)]="item.selectedUnit"
                                                                                [createOption]="false"
                                                                                optionName="Unit"></app-my-select-table>
                                                                </td>
                                                                <td width="70"> 
                                                                        <app-my-select-table title="ItemTypeName" [data]="dataItemType"
                                                                        [(ngModel)]="item.selectedItemType"
                                                                        [createOption]="false"
                                                                        optionName="ItemType"></app-my-select-table>
                                                                </td>
                                                              
                                                                <td width="70">
                                                                        <app-my-select-table title="CategoryName"
                                                                                [data]="dataCatogery"
                                                                                [(ngModel)]="item.selectedCatogery"
                                                                                [createOption]="false"
                                                                                optionName="Category"></app-my-select-table>
                                                                </td>
                                                                <td width="70">
                                                                        <app-my-select-table title="MakeName"
                                                                                [data]="dataBrand"
                                                                                [(ngModel)]="item.selectedBrand"
                                                                                [createOption]="false"
                                                                                optionName="Brand"></app-my-select-table>
                                                                </td>
                                                               
                                                                <td width="30">
                                                                        <app-my-input-number-table title="CostPrice"
                                                                                [(ngModel)]="item.CostPrice">

                                                                        </app-my-input-number-table>
                                                                </td>
                                                                <td width="30">
                                                                        <app-my-input-number-table title="SalePrice"
                                                                                [(ngModel)]="item.SalePrice"></app-my-input-number-table>
                                                                </td>
                                                                <!-- <td width="30">
                                                                        <app-my-input-number-table [(ngModel)]="item.SalePrice"></app-my-input-number-table>
                                                                </td> -->
                                                                <td width="10"
                                                                        style="text-align: center; vertical-align: middle;">
                                                                        <app-my-icon-button-table
                                                                                (myClick)="onRowDelete( index)"
                                                                                icon="delete_outline"></app-my-icon-button-table>
                                                                </td>

                                                        </tr>
                                                </tbody>
                                        </table>
                                </div>
                        </div>
                        <div class="divbtn">
                                <app-my-small-button class="addbtn" title="Add Item" icon="add"
                                        (myClick)="onItemAdd()"></app-my-small-button>

                                <button  class="btn btn-info savebtn" (click)="onSave()">Save</button>
                        </div>
                </div>

        </div>

</div>