import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-ribbon-print',
  templateUrl: './ribbon-print.component.html',
  styleUrls: ['./ribbon-print.component.scss']
})
export class RibbonPrintComponent implements OnInit {

  @Output() myClick = new EventEmitter<any>();

  constructor(public theme:ThemeService) { }

  ngOnInit(): void {
  }

  onClick(){
    this.myClick.emit();
  }

}
