export class Bank{
        ID:number = 0;
        BankNo:string = null;
        BankName:string = null;
        ContactNo:string = null;
        Fax: number = null;
        Email: string = null;
        Website: string = null;
        CompanyID : number = null;
        CreatedDate: any = null;
        CreatedBy: number = null;
        ModifiedDate: any = null;
        ModifiedBy: number = null;
        
}