import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { CustomerService } from 'src/app/services/customer.service';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';

@Component({
  selector: 'app-customer-bulk-screen',
  templateUrl: './customer-bulk-screen.component.html',
  styleUrls: ['./customer-bulk-screen.component.scss']
})
export class CustomerBulkScreenComponent implements OnInit {

  primaryKey:number = 0;

  obj:Customer;

  objDetail: Customer_DTO_Detail[] = [];

  constructor(
    public CustomerService:CustomerService,

    public hlp : ConfHelpService,
    public dialogService:DialogService,

    public LoaderService:LoaderService,
  ) { }

  ngOnInit(): void {
  Promise.all([

    this.setDefaultValues(),
  ])
   
  
    
  }
onSave(){
 
  var custom_arr :  Customer[] = [];

  for (let index = 0 ; index < this.objDetail.length; index ++){
    const element = this.objDetail[index];
    var dtl = new Customer();
    if(element.CustomerName){
      dtl.CustomerName = element.CustomerName;
      dtl.City = element.City;
      dtl.Phone = element.Phone;
      dtl.NTNNumber = element.NTNNumber;
      dtl.State = element.State;
      dtl.ZipCode = element.ZipCode;
      dtl.Address = element.Address;
      dtl.Address2 = element.Address2;
      dtl.Email = element.Email;
      dtl.Website = element.Website;
      custom_arr.push(dtl);
    }
  }
     this.LoaderService.startLoaderOnSave()
     this.CustomerService.upsert(custom_arr).subscribe(res => {
      this.dialogService.closeDialog_List('sal_04', "save");
      this.LoaderService.endLoaderOnSave(); 
     })
  }
  


  onCustomerAdd(){
    this.objDetail.push(new Customer_DTO_Detail());
  }

  setDefaultValues(){
    for (let index = 0; index < 10; index++) {
      var obj = new Customer_DTO_Detail;    
      this.objDetail.push(obj);
    }
  }

  onRowDuplicate(index: number) {
    const duplicatedItem = { ...this.objDetail[index] }; // Deep copy 
    this.objDetail.splice(index + 1, 0, duplicatedItem); 
  }

  onRowDelete(index){
    this.dialogService.openSmallDialog(YesNoComponent);
    this.dialogService.dialogRefSmall.afterClosed().subscribe(result =>{
      if(result){
        if(result == "yes"){
          this.objDetail.splice(index , 1);
        }
      }
    })
  }
}


export class Customer_DTO_Detail {
  ID: number = 0;
  CompanyID: number = null;
  CustomerName: string = null;
  City: string = null;
  State: string = null;
  ZipCode: number = null;
  Phone: string = null;
  Email: string = null;
  Website: string = null;
  Address: string = null;
  Address2: string = null;
  OpenningDate: Date = null;
  OpenningAmount: number = null;
  CreatedDate: string = null;
  CreatedBy: number = null;
  CreatedTerminal: string = null;
  CreatedTerminalIp: string = null;
  ModifiedDate: string = null;
  ModifiedBy: number = null;
  ModifiedTerminal: string = null;
  ModifiedTerminalIp: string = null;
  NTNNumber: number = null;
}