import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticateService } from './authenticate.service';
import * as customConfig from 'src/assets/files/customConfig.json';
import { LocalStorageService } from './local-storage.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SqlService {
  //readonly rootUrl: string = "http://192.168.1.101:45455/";
  //readonly rootUrl: string = "http://localhost:57046/";
  //readonly rootUrl: string = "https://bmsapi.samy-soft.com/";
  readonly rootUrl: string = customConfig.api;

  Scode: string = '';

  constructor(
    public http: HttpClient,
    public authenticate: AuthenticateService,
    public localStorageService: LocalStorageService
  ) {
  }

  async get(controllerName: string, query?: any) {
    var qry = this.queryBuilder('?', query);
    return await this.http.get(this.rootUrl + controllerName + qry).toPromise();
  }

  

  async getById(controllerName: string, ID: number, query?: any) {
    var url: string = `${this.rootUrl}api/${controllerName}/${ID}`;
    return await this.http.get(url).toPromise();
  }

  async getBySqlQuery(query: string) {
    var url:string = `${this.rootUrl}api/Config/GetDataByQuery(${query})`;
    return this.http.get(url);
  }

  getBySqlQueryBody(query: string) {
    var obj = {
      Query: query,
    };
    var url:string = `${this.rootUrl}api/Config/GetDataByBody`;
    return this.http.post(url, obj);
  }

  post(controller: string, jsonBody: any, manual_scode?: string) {
  

    if (jsonBody['CreatedBy'] == null)
      jsonBody['CreatedBy'] = this.localStorageService.getCurrentUser().ID;

    if (jsonBody['CreatedDate'] == null) {
      console.log(moment().utc(true));
      console.log(
        moment().utc(true).format('YYYY-MM-DDThh:mm:ss.SSS') + '-07:00'
      );
      moment().utc(true);
      jsonBody['CreatedDate'] =
        moment().utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'); //+ '-07:00'; // new Date();
    }

    if (jsonBody['ModifiedBy'] == null)
      jsonBody['ModifiedBy'] = this.localStorageService.getCurrentUser().ID;

    if (jsonBody['ModifiedDate'] == null)
      jsonBody['ModifiedDate'] =
        moment().utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'); //+ '-07:00'; //new Date();

   
    if (manual_scode) {
      jsonBody['Scode'] = manual_scode;
    } else if (jsonBody['Scode'] == null && this.Scode != '')
      jsonBody['Scode'] = this.Scode;

    var url:string = `${this.rootUrl}api/${controller}`
    return this.http.post(url, jsonBody);
  }

  postAny(controller: string, jsonBody: any) {
    var url:string = `${this.rootUrl}${controller}`
    return this.http.post(url, jsonBody);
  }

  update(controller: string, jsonBody: any, ID: number) {
    delete jsonBody['@odata.context'];

    if (jsonBody['ModifiedBy'] != null)
      jsonBody['ModifiedBy'] = this.localStorageService.getCurrentUser().ID;

    if (jsonBody['ModifiedDate'] != null)
      jsonBody['ModifiedDate'] =
        moment().utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'); //+ '-07:00'; // new Date();

    if (jsonBody['Scode'] == null && this.Scode != '')
      jsonBody['Scode'] = this.Scode;

    var url: string = `${this.rootUrl}api/${controller}/${ID}`;
    return this.http.put(url, jsonBody);
  }

  delete(controller: string, ID: number) {
    var url: string = `${this.rootUrl}api/${controller}/${ID}`;
    return this.http.delete(url);
  }

  queryBuilder(url: string, query: any): string {
    if (query) {
      if (query.select) {
        url += ((url.indexOf('?') === -1) ? '?' : '&') + '$select=' + query.select;
      }
      if (query.filter) {
        url += ((url.indexOf('?') === -1) ? '?' : '&') + '$filter=' + query.filter;
      }
      if (query.expand) {
        url += ((url.indexOf('?') === -1) ? '?' : '&') + '$expand=' + query.expand;
      }
      if (query.orderby) {
        url += ((url.indexOf('?') === -1) ? '?' : '&') + '$orderby=' + query.orderby;
      }
      if (query.top) {
        url += ((url.indexOf('?') === -1) ? '?' : '&') + '$top=' + query.top;
      }
      if (query.skip) {
        url += ((url.indexOf('?') === -1) ? '?' : '&') + '$skip=' + query.skip;
      }
    }
    return url;
  }

 
}
