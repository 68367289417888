import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { empty, of } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit {

  searchMoviesCtrl = new FormControl();
  EventData: any[] = [];
  isLoading = false;
  errorMsg: string;

  constructor( public dialogService: DialogService,
    public route: Router,
    public routeService: RouteService,
    public dashboardService:DashboardService,
    public cdr :ChangeDetectorRef,
    private _bottomSheetRef: MatBottomSheetRef<SearchDialogComponent>) { }

  ngOnInit(): void {
    this.searchMoviesCtrl.setValue('')
    this.searchMoviesCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.EventData = [];
          this.isLoading = true;
        }),

        switchMap((value) =>
          this.search(value)
            .pipe(
              catchError(error => {
                console.log(error);
                return empty;
              }),
              finalize(() => {

                this.isLoading = false;
                this.cdr.detectChanges();
              }),
            )
        )
      )
      .subscribe(data => {
        if (data['value'] == undefined) {
          this.errorMsg = data['Error'];
          this.EventData = [];
        } else {
          this.errorMsg = "";
          this.EventData = data['value'];
        }
        //this.isLoading = false;
        this.cdr.detectChanges();
        console.log(this.EventData);;
      },
        (error) => {
          console.log(error);
        }
      );
  }

  search(searchValue: string = "") {
    if(searchValue == ""){
      this.EventData = [];
      return of();
    }
    return this.dashboardService.GlobalSearch(searchValue);
  }

  openGroundProfile(ID: number) {
    this.route.navigate([this.routeService.openRoute('youplay', 'GroundProfile')], { queryParams: { groundID: ID } });
  }

  openPlayerProfile(ID: number) {
    // this.dialogService.openDialogCenter(PlayerProfileComponent, ID);

    // this.dialogService.dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //   }
    // });
  }

  onEventClick(item) {
    // if (item.searchType == "Ground")
    //   this.openGroundProfile(item.ID);
    // else if (item.searchType == "Player")
    //   this.openPlayerProfile(item.ID);

    this.searchMoviesCtrl.setValue('')
  }

  openRoute(route, recordid){
    this.searchMoviesCtrl.setValue('');    
    this.route.navigate([route], { queryParams: { ID: recordid } });
    this._bottomSheetRef.dismiss();
    
  }

}
