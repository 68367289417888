<div class="main">




        <!-- Top Area -->
        <div class="topdiv px-4">
                <h2>Mr. Sufyan Ahmed</h2>
                <div class="topdiv_btns">
                        <button class="btn btn-light">Edit</button>
                        <button class="btn">
                                <mat-icon>attach_file</mat-icon>
                        </button>
                        <div class="dropdown">
                                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                                        New Transaction
                                </button>
                                <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Link 1</a></li>
                                        <li><a class="dropdown-item" href="#">Link 2</a></li>
                                        <li><a class="dropdown-item" href="#">Link 3</a></li>
                                </ul>
                        </div>
                        <div class="dropdown">
                                <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">
                                        More
                                </button>
                                <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Link 1</a></li>
                                        <li><a class="dropdown-item" href="#">Link 2</a></li>
                                        <li><a class="dropdown-item" href="#">Link 3</a></li>
                                </ul>
                        </div>
                </div>
        </div>



        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#overview">Overview</a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#comments">Comments</a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#transactions">Transactions</a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#mails">Mails</a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#statements">Statements</a>
                </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
                <div id="overview" class="container-fluid tab-pane active"><br>
                        <div class="row">

                                <div class="col-md-4  card-body usercard">
                                        <div class="row">
                                                <div class="col-md-2 iconDiv">
                                                        <mat-icon class="iconUser">account_box</mat-icon>
                                                </div>
                                                <div class="col-md-10 userNameDiv">
                                                        <div class="userName">
                                                                Mr. Sufyan Ahmed
                                                        </div>
                                                        <div class="Email">
                                                                hisokacsgo2000@gmail.com
                                                        </div>
                                                        <div class="linkDIv mt-3">
                                                                <a href="#" class="Edit">Edit</a>

                                                                <a href="#" class="Send">Send Email</a>

                                                                <a href="#" class="Inviteto">Invite to Portal</a>

                                                                <a href="#" class="dlt">Delete</a>

                                                        </div>
                                                </div>
                                        </div>
                                        <br>
                                        <div class="d-flex flex-column ps-2">
                                                <h3 style="border-bottom: solid 1px #ddd; color: #707070;"
                                                        class="fw-bold pb-2 mb-2">ADDRESS</h3>

                                                <div class="d-flex flex-column">
                                                        <h3 class="fw-bold">Billing Address</h3>
                                                        <p>No Billing Address - <a href=""> Add new address</a> </p>
                                                </div>
                                                <div>
                                                        <h3 class="fw-bold">Shiping Address</h3>
                                                        <p>No Shipping Address - <a href="">Add new address</a></p>

                                                </div>
                                        </div>

                                        <br>

                                        <div class="d-flex flex-column ps-2">
                                                <h3 style="border-bottom: solid 1px #ddd; color: #707070;"
                                                        class="fw-bold pb-2 mb-2">OTHER DETAILS</h3>
                                                <br>
                                                <div class="d-flex DetailUser">
                                                        <p style="color: #707070;">Customer Type</p>
                                                        <p class="fw-bold">Business</p>
                                                </div>
                                                <div class="d-flex DetailUser">
                                                        <p style="color: #707070;">Default Currency</p>
                                                        <p class="fw-bold">PKR</p>

                                                </div>
                                                <div class="d-flex DetailUser">
                                                        <p style="color: #707070;">Payment Terms</p>
                                                        <p class="fw-bold">Due On Receipt</p>

                                                </div>
                                                <div class="d-flex DetailUser">
                                                        <p style="color: #707070;">Portal Stage</p>
                                                        <p style="color: red;"> Disabled</p>

                                                </div>
                                                <div class="d-flex DetailUser">
                                                        <p style="color: #707070;">Portal Language</p>
                                                        <p class="fw-bold"> English</p>

                                                </div>


                                        </div>




                                </div>

                                <div class="col-md-8  card-body">
                                        <div class="row">
                                                <div class="col-md-1 iconDiv1">
                                                        <mat-icon class="turnRight">turn_sharp_right</mat-icon>

                                                </div>
                                                <div class="col-md-10 informationDiv">
                                                        <div class="qustion">
                                                                What"s Next For Your Customer?
                                                        </div>
                                                        <div class="answer">
                                                                Your customer has been added. Create and send an quote
                                                                or an invoice to
                                                                your customer for the items you want to sell to them
                                                        </div>

                                                        <div class="d-flex button2">

                                                                <div>
                                                                        <button class="btn btn-light">New Quite</button>
                                                                </div>
                                                                <div>
                                                                        <button class="btn btn-primary">New
                                                                                Invoice</button>
                                                                </div>

                                                                <div class="moreIconDiv">
                                                                        <mat-icon class="moreIcon">more_vert</mat-icon>
                                                                </div>

                                                        </div>
                                                </div>        
                                                        

                                                        <div class=" informationDiv1" >
                                                                <p style="color: #b4adad;">Payment Due Method </p>
                                                                <p>Due On Receipt</p>
                                                        </div>

                                                

                                        </div>

                                </div>
                        </div>
                </div>
                <div id="comments" class="container tab-pane fade"><br>
                        <h3>Menu 1</h3>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                commodo consequat.
                        </p>
                </div>
                <div id="transactions" class="container tab-pane fade"><br>
                        <h3>Menu 2</h3>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, totam rem
                                aperiam.</p>
                </div>
        </div>


</div>