import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenConfService {

  private Scode:string = "";
  public ActiveScode:string = "";

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public base: BaseService,
  ) {

  }

  getConfScodeData(scode:string){
    var qry = "select () from RBAC_Section where scode eq '" + scode + "'";
    return  this.base.sql.getBySqlQueryBody(qry);
  }
   
  getscode(): string {
    //console.log(this.router);
    if (this.route.snapshot.data.Scode) {
      this.Scode = this.route.snapshot.data.Scode;
      return this.Scode;
    }
    return "";
  }

  getscodeAc(activRoute:ActivatedRoute ): string {
    
    if (activRoute.snapshot.data.Scode) {
      this.Scode = activRoute.snapshot.data.Scode;
      return this.Scode;
    }
    return "";
  }
}
