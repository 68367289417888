<div class="top-ribbon-dialog">
    <app-ribbon-save-dialog [hidden]="Units.invalid" (myClick)="onSave()" [valid]="Units.invalid"></app-ribbon-save-dialog>
    <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>
<app-my-heading-dialoge mat-dialog-title heading="Units Information"></app-my-heading-dialoge>


<form #Units="ngForm" class="rounded needs-validation ">

    <div class="row g-2">
        <app-my-input label="Unit Name"  required="true"  name="UnitName" [(ngModel)]="obj.UnitName">
        </app-my-input>                             
    </div>        
    <div align="end" >                
        <app-button-save-dialog (myClick)="onSave()" [valid]="Units.invalid"></app-button-save-dialog>
    </div>
</form>

