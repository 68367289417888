import { FN_JvDetail } from "./fn_jvDetail.model";

export class FN_JVHeader {
    ID:number = 0;
    DocumentDate:any = null;
    DocumentNo:string = null;
    CompanyID:number = 0;
    PostDate:any = null;
    PostStatusID:number = null;
    VoucherNo:number = null;
    JVTypeID:number = null;
    Description:string = null;
    ReferenceType:string = null;
    RefereneceID:number = null;
    ReferenceNo:string = null;
    CreatedDate: string = null;
    CreatedBy: number = null;
    CreatedTerminal: string = null;
    CreatedTerminalIp: string = null;
    ModifiedDate: string = null;
    ModifiedBy: number = null;
    ModifiedTerminal: string = null;
    ModifiedTerminalIp: string = null;
    FN_JVDetail: FN_JvDetail[] = [];
    
}