<div class="topBar">
        <div class="d-flex align-items-center justify-content-between">
                <div>
                        <div class="d-flex align-items-center">
                        
                        <h2 class="ms-3">Sale</h2>
                         </div>
                </div>
                <div>
                        <div class="d-flex">
                                <div class="topBtns">
                                        <!-- <button class="btn btnTopIcons" (click)="printHtml()"> <mat-icon class="topNavIcon printIconColor">print</mat-icon> Print -->
                                        <button class="btn btnTopIcons" (click)="directPrint()" [disabled]="!this.report_content"> 
                                                <mat-icon class="topNavIcon printIconColor" *ngIf="this.report_content">print</mat-icon>
                                                <span *ngIf="this.report_content">Print</span>
                                                <div class="w-100 d-flex justify-content-center ">
                                                        <div class="loader " *ngIf="!this.report_content"></div>
                                                    </div>
                                        </button>
                                        <button class="btn btnTopIcons" (click)="onEdit()"> <mat-icon class="topNavIcon editIconColor">edit</mat-icon> Edit
                                        </button>
                                        <!-- <a [href]="edit_url" target="_self" class="btn btnTopIcons" > <mat-icon class="topNavIcon editIconColor">edit</mat-icon> Edit
                                        </a> -->
                                        <!-- <button class="btn btnTopIcons"> <mat-icon class="topNavIcon deleteIconColor">delete</mat-icon> Delete
                                        </button> -->
                                </div>
                                <div class="topBtnForMob">
                                        <button class="btn btnTopIcons" (click)="print()"> 
                                                <mat-icon class="topNavIcon printIconColor">print</mat-icon>
                                        </button>
                                </div>
                        
                                <div class="closeBtnDiv">
                                        <button class="btn" mat-dialog-close> <mat-icon class="closeColor">close</mat-icon>
                                        </button>
                                </div>
                        
                        </div>
                        
                </div>
        </div>
        <hr>


</div>

<div id="pdfContent" class="contentDiv">
        <div class="row" id="pdfContent2">
                <div class="col-md-4">

                        <div class="card mb-3 cardHeight ">
                                <div class="txtTitle">
                                     {{dataHdr?.CustomerName}}
                                </div>
                                <hr>
                                <table class="table table-borderless">                                 
                                        <tbody class="leftTable">
                                            <tr>
                                                <td>Sale #</td>
                                                <td>{{dataHdr?.DocumentNo}}</td>
                                            </tr>
                                            <tr>
                                                <td>Date</td>
                                                <td>{{dataHdr?.TransactionDate | date}}</td>
                                            </tr>
                                            <tr>
                                                <td>Payment Mode</td>
                                                <td>{{dataHdr?.PaymentModeName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Due Date</td>
                                                <td class="dueDateColor">{{dataHdr?.DueDate | date}}</td>
                                            </tr> 
                                            <tr>
                                                <td>Company</td>
                                                <td>{{dataHdr?.CompanyName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Plant</td>
                                                <td>{{dataHdr?.PlantName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Reference #</td>
                                                <td>{{dataHdr?.ReferenceNo}}</td>
                                            </tr>         
                                            <tr>
                                                <td>Customer</td>
                                                <td>{{dataHdr?.CustomerName}}</td>
                                            </tr>  
                                            <tr>
                                                <td>Contact Person</td>
                                                <td>{{dataHdr?.ContactPersonName}}</td>
                                            </tr>  
                                            <tr>
                                                <td>Contact #</td>
                                                <td>{{dataHdr?.ContactNo}}</td>
                                            </tr> 
                                            <tr>
                                                <td>Remarks</td>
                                                <td>{{dataHdr?.Remarks}}</td>
                                            </tr> 
                                            <tr>
                                                <td>Non GST</td>
                                                <td>{{ dataHdr?.NonGST == true ? "Yes" : "No" }}</td>
                                            </tr>

                                        </tbody>                
                                </table> 


                                <!-- <table class="table table-mobile table-borderless">
                                        
                                        <thead>
                                        <tr>
                                                <th width="100">Item</th>
                                                <th width="100">Units</th>
                                                <th width="30">Quantity</th>
                                                <th width="50">Price</th>
                                                <th width="100">Amount</th>
                                                <th width="50">Discount</th>
                                                <th width="50">Net Amount</th>
                                                <th width="50">
                                                    <app-my-small-button title="Add" icon="add" (myClick)="onRowAdd()">
                                                    </app-my-small-button>
                                                </th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody class="leftTable">
                                            <tr>
                                                <td>Sale #</td>
                                                <td>{{dataHdr?.DocumentNo}}</td>
                                            </tr>
                                            <tr>
                                                <td>Date</td>
                                                <td>{{dataHdr?.TransactionDate | date}}</td>
                                            </tr>
                                            <tr>
                                                <td>Payment Mode</td>
                                                <td>{{dataHdr?.PaymentModeName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Due Date</td>
                                                <td class="dueDateColor">{{dataHdr?.DueDays}}</td>
                                            </tr> 
                                            <tr>
                                                <td>Company</td>
                                                <td>{{dataHdr?.CompanyName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Plant</td>
                                                <td>{{dataHdr?.PlantName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Reference #</td>
                                                <td>{{dataHdr?.ReferenceNo}}</td>
                                            </tr>         
                                            <tr>
                                                <td>Customer</td>
                                                <td>{{dataHdr?.CustomerName}}</td>
                                            </tr>  
                                            <tr>
                                                <td>Contact Person</td>
                                                <td>{{dataHdr?.ContactPersonName}}</td>
                                            </tr>  
                                            <tr>
                                                <td>Contact #</td>
                                                <td>{{dataHdr?.ContactNo}}</td>
                                            </tr> 
                                            <tr>
                                                <td>Remarks</td>
                                                <td>{{dataHdr?.Remarks}}</td>
                                            </tr> 
                                            

                                        </tbody>                
                                </table>  -->
                                 
                        </div>

                      
                </div>

                <div class="col-md-8">

                <div class="forMobOnly">
                        Item Detail
                </div>
                
                <table class="tableDetail  mb-3">
                        <thead class="detailTH">
                                <tr>
                                        <th>#</th>
                                        <th class="item">Item</th>
                                        <th>Unit</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Amount</th>
                                        <th>Discount</th>
                                        <th [hidden]="dataHdr?.NonGST == true" >GST %</th>
                                        <th [hidden]="dataHdr?.NonGST == true">GST</th>
                                        <!-- <th>NTN</th> -->
                                        <th>Net Amount</th>
                                </tr>
                        </thead>  
                        <tbody>
                                <tr *ngFor="let data of dataAll;let index = index">
                                        <td data-label="S#" class="serial">{{index+1}}</td>
                                        <td data-label="Items" class="item">{{data?.ItemName}}</td>
                                        <td data-label="Unit" class="unit">{{data?.UnitName}}</td>
                                        <td data-label="Quantity" class="qty">{{data?.Quantity}}</td>
                                        <td data-label="Price" class="price">{{data?.Price | number}}</td>
                                        <td data-label="Amount" class="amount">{{data?.Amount | number}}</td>
                                        <td data-label="Discount" class="discount">{{data?.Discount | number}}</td>
                                        <td  [hidden]="dataHdr?.NonGST == true" data-label="GST %" class="GST">{{data?.GST_Perc | number}}</td>
                                        <td [hidden]="dataHdr?.NonGST == true" data-label="GST" class="GST">{{data?.GST | number}}</td>
                                        <!-- <td class="GST">{{data?.NTN | number}}</td> -->
                                        <td data-label="Net Amount" class="netamount">{{data?.NetAmount | number}}</td>
                                </tr>
                                
                        </tbody>
                </table>

                <div class="cstmCard">
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Amount</div>
                                <div class="txtMainSummary">{{dataHdr?.TotalAmount | number}}</div>
                        </div>
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Discount</div>
                                <div class="txtMainSummary">{{dataHdr?.TotalDiscount | number}}</div>
                        </div>
                        <div class="d-flex justify-content-between">
                                <div [hidden]="dataHdr?.NonGST == null" class="txtSubSummary">Total GST</div>
                                <div [hidden]="dataHdr?.NonGST == null" class="txtMainSummary">{{dataHdr?.TotalGST | number}}</div>
                        </div>
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Income Tax</div>
                                <div class="txtMainSummary">{{dataHdr?.TotalNTN | number}}</div>
                        </div>
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Account Receivible</div>
                                <div class="txtMainSummary">{{dataHdr?.AccountReceivable | number}}</div>
                        </div>

                         <hr style="margin: 0 !important;">
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Net Amount</div>
                                <div class="txtNetSummary">{{dataHdr?.TotalNetAmount | number}}</div>
                        </div>
                </div>






                </div>
        </div>
</div>
