<div class="row mt-4">
        <div class="col-md-3 mb-2" *ngFor="let  d of data">
                <div class="card cardEmployee" [class]="isImage ? '' : 'cardwithoutimage'" (click)="onEditClick(d)">
                        <div class="d-flex justify-content-end">
                                <!-- <div>
                                        <h5 style="font-size: 14px;
                                        color: gray;
                                        font-weight: 500;">{{ val3?.type == 'currency' ? (getVal3(d) | number: '1.2-2') : val3?.type == 'number' ? (getVal3(d) |
                                                number: '1.2-2') : val3?.type == 'date' ? (getVal3(d) | date : 'MM/dd/yyyy') : val3?.type == 'text' ?
                                                getVal3(d) : ''}} </h5>
                                </div> -->
                                <div>
                                        <button 
                                                *ngIf="edit"
                                                mat-icon-button 
                                                matTooltip="View" 
                                                (click)="onEditClick(d)"
                                                >
                                                <mat-icon class="btn-edit table-button" [class]="theme.myIconColor">open_in_new</mat-icon>
                                        </button>                                        
                                        
                                </div>
                              
                        </div>
                        <div class="d-flex flex-column  justify-content-center">
                                <img *ngIf="isImage && !d?.myimageurl" class="shadow" src="./assets/img/users.png" alt="..">
                                <img *ngIf="isImage && d?.myimageurl && CardImage != null" class="shadow" [src]="d?.myimageurl" alt="..">
                                <h3 class="mt-2" style="font-weight: 500;"> <span class="flex-grow-1 bd-highlight titleFont cardtitle"  [matTooltip]="getValue(d)" [class]="theme.myIconColor" >{{getValue(d)}}</span></h3>
                                <!-- <h5 class="grayColor">Manager</h5> -->

                                        <span *ngIf="val1" class="w-100 d-flex justify-content-left">
                                                <!-- <span> {{val1?.title}}:</span>  -->
                                                <h5> {{ val1?.type == 'currency' ? (getVal1(d) | number: '1.2-2') : val1?.type == 'number' ? (getVal1(d) |
                                                        number: '1.2-2') : val1?.type == 'date' ? (getVal1(d) | date : 'MM/dd/yyyy') : val1?.type == 'text' ?
                                                        getVal1(d) : ''}} </h5>
                                        </span>
                                        
                                        <span *ngIf="val2" class="w-100 d-flex justify-content-left">
                                                <!-- <span>{{val2?.title}}:</span> -->
                                                <h5 *ngIf="val2">{{ val2?.type == 'currency' ? (getVal2(d) | number: '1.2-2') : val2?.type == 'number' ? (getVal2(d) |
                                                        number: '1.2-2') : val2?.type == 'date' ? (getVal2(d) | date : 'MM/dd/yyyy') : val2?.type == 'text' ?
                                                        getVal2(d) : ''}}
                                                </h5> 
                                                <span *ngIf="val2 && val3" class="px-2"> | </span>                                                                                                       
                                                <h5 >{{ val3?.type == 'currency' ? (getVal3(d) | number: '1.2-2') : val3?.type == 'number' ? (getVal3(d) |
                                                        number: '1.2-2') : val3?.type == 'date' ? (getVal3(d) | date : 'MM/dd/yyyy') : val3?.type == 'text' ?
                                                        getVal3(d) : ''}} 
                                                </h5>
                                        </span>
                                        <!-- <span *ngIf="val3" class="w-100 d-flex justify-content-center">
                                                <h5 >{{ val3?.type == 'currency' ? (getVal3(d) | number: '1.2-2') : val3?.type == 'number' ? (getVal3(d) |
                                                number: '1.2-2') : val3?.type == 'date' ? (getVal3(d) | date : 'MM/dd/yyyy') : val3?.type == 'text' ?
                                                getVal3(d) : ''}} </h5>
                                        
                                        </span> -->
                        </div>
                        <!-- <div class="divDetail">
                                <div class="d-flex justify-content-between">
                                        <div class="d-flex flex-column align-items-center">
                                                <h5 class="grayColor">Department</h5>
                                                <h4 class="fontBold">Design Team</h4>
                                        </div>
                                        <div class="d-flex flex-column align-items-center">
                                                <h5 class="grayColor">Date Joined</h5>
                                                <h4 class="fontBold">8/18/2021</h4>
                                        </div>
                                </div>
                                <hr>
                                <div class="d-flex align-items-center">
                                        <mat-icon class="grayColor iconSize me-1">mail</mat-icon>
                                        <h5 class="fontBold grayColor">johnanthan581@gmail.com</h5>
                                </div>
                                <div class="d-flex align-items-center">
                                        <mat-icon class="grayColor iconSize me-1">call</mat-icon>
                                        <h5 class="fontBold grayColor">+9248918591</h5>
                                </div>
                        </div> -->
                </div>
        </div>
       
       
       
</div>