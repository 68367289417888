import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { PdfprintService } from 'src/app/services/pdfprint.service';
import { PrintDialogComponent } from 'src/app/shared/Dialog/print-dialog/print-dialog.component';
import { PurchaseViewComponent } from '../../purchase/purchase-view/purchase-view.component';
import { LoaderService } from 'src/app/services/base/loader.service';

@Component({
  selector: 'app-goods-opening-view',
  templateUrl: './goods-opening-view.component.html',
  styleUrls: ['./goods-opening-view.component.scss']
})
export class GoodsOpeningViewComponent implements OnInit {

  dataHdr:any= null;
  dataAll:any[] = [];

  constructor(
    public router:Router,
    public routeService:RouteService,
    public dialogService:DialogService,
    public helpservice:ConfHelpService,
    public dialogRef: MatDialogRef<PurchaseViewComponent>,
    public pdfService:PdfprintService,

    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getViewData();
  }

  async getViewData() {
    this.loaderService.startLoader();
    var filter = {
      id : this.data.ID,
      
    }

    await this.helpservice.getFormDataByHelpNo("invgop", filter).toPromise().then(res => {
      this.dataHdr = res['value'][0] as any;
      this.dataAll = res['value'] as any[];
      this.loaderService.endLoader();
      console.log(this.dataAll)
    })
  }

  print(){
   
  }

  onEdit(){
    this.dialogRef.close();
    this.router.navigate([this.routeService.openRoute("inventory", "goods-opening")], { queryParams: { ID: this.data.ID, parent: 'goods-opening-list' } });
  }



}
