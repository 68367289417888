import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { catchError } from 'rxjs/operators';
import { CustomErrorHanderService } from '../base/custom-error-hander.service';
@Injectable({
  providedIn: 'root'
})
export class GlAccountsService {
  
  readonly controllerName:string = "FN_GLAccount";

  constructor( private base:BaseService,
    public errorService:CustomErrorHanderService) { }

  async getAll(){
    var query = {
      expand: "FN_Nature,FN_AccountHead",
      filter:""
    }
    return await this.base.sql.get(this.controllerName, query);
  }

  async getById(ID: number) {
    return await this.base.sql.getById(this.controllerName, ID);
  }

  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody,'fin_01').pipe( catchError((err=> this.errorService.handleError(err))) );

  }

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  delete(ID: number) {
    return this.base.sql.delete(this.controllerName, ID)
  }

}
