<table class="table">
        <tbody>
            <tr   *ngFor="let datas of data"   (click)="openRoute(datas.route, datas.RecordID)">
                <td matRipple style="color: #0d6efd;text-decoration: underline;">{{datas.DocumentNo}}</td>
                <td matRipple > {{datas.SectionName}} </td>                
                <td matRipple>{{datas.CreatedDate | date:'MM/dd/yyyy hh:mm aa'}}</td>
            </tr>
            <tr class="text-center" *ngIf="data.length == 0 && loading == false">
                No Activity Found!
            </tr>
            <!-- skeleton -->
            <tr *ngIf="loading == true">
                <span class="d-flex flex-column-reverse w-100 mt-2" >
                    <ngx-skeleton-loader  count="4" appearance="line"></ngx-skeleton-loader> 
                </span>
            </tr>
        </tbody>
    
            
        
    </table>


