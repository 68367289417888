<div class="row gx-2 gy-2">
  <div class="d-flex justify-content-between">
    <div class="card cardDiv col-md-7 shadow" id="chart1">
      <h2 class="ms-2"> Sales Last 6 month </h2>
      <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
        [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid" [stroke]="chartOptions.stroke"
        [markers]="chartOptions.markers"></apx-chart>
    </div>

    <div class="card col-md-5 cardDiv shadow" id="chart2">
      <h2> Sales</h2>
      <apx-chart [series]="barchartOptions.series" [chart]="barchartOptions.chart" [xaxis]="barchartOptions.xaxis"
        [title]="barchartOptions.title">
      </apx-chart>
    </div>
  </div>
</div>