import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Autocomplete } from '../../Autocomplete';

@Component({
  selector: 'app-my-select-search-table',
  templateUrl: './my-select-search-table.component.html',
  styleUrls: ['./my-select-search-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MySelectSearchTableComponent),
      multi: true
    }
  ]
})
export class MySelectSearchTableComponent implements OnInit , ControlValueAccessor {

  @Input() label:string = "";
  @Input() data:any[]= null;
  @Input() required: boolean = false;
  @Input() model: any = null;
  @Input() name: string = "";
  @Input() title:string = "";
  @Input() multiple:boolean = false;
  @Output() modelChange = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<any>();
  @Input() hint: string = null;
  searchValue:any = null;
  @Input() search:boolean = true;

  AutoComp:Autocomplete<any> = new Autocomplete<any>(this.title, this.title);      

  constructor() {
   
   }

  ngOnChanges(): void {

    // For material select - 24/06/2023
    
    this.AutoComp.filterIDName = this.title;
    this.AutoComp.displayValueName = this.title;
    this.AutoComp.data = this.data;
    this.AutoComp.resultObserve();  
    
  }
  
  writeValue(obj: any): void {
    if (obj !== undefined){
      if(obj == null )
        this.model = obj;
      else if(obj.length > 0 )
        this.model = obj[0];

    }
      
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }


  ngOnInit(): void {
  }

  onChange(value: any) {
    var obj:any[]= [];
    obj.push(value)
    this.propagateChange(obj);
    
    this.valueChanged.emit(obj);
  }

  getValue(option){
     return option[this.title];
  }

  

}
