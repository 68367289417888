<div class="container-fluid">
    <div class="row gx-4 gy-4 mb-4">
        <div class="col-md-2">
            <div class="card cardDiv shadow">
                <div>
                    <h3 class="H3text"> Today </h3>
                </div>
                <hr>
                <div>
                    <h4 class="fw-bold ms-2"> 1,229 PKR </h4>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="card cardDiv shadow">
                <div>
                    <h3 class="H3text"> Yesterday </h3>
                </div>
                <hr>
                <div>
                    <h4 class="fw-bold ms-2"> 2,220 PKR </h4>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="card cardDiv shadow">
                <div>
                    <h3 class="H3text"> Last 7 Days </h3>
                </div>
                <hr>
                <div>
                    <h4 class="fw-bold ms-2"> 54,300 PKR </h4>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card cardDiv shadow">
                <div>
                    <h3 class="H3text"> Total Sales </h3>
                </div>
                <hr>
                <div>
                    <h4 class="fw-bold ms-2"> 322,110 PKR </h4>
                </div>
            </div>
        </div>

    </div>
    <div>
        <app-sales-chart></app-sales-chart>
    </div>
</div>