<div class="myForm">
        <form class=" p-3 mb-5 rounded mt-4">
            <div class="d-flex justify-content-center">
                <div class="logo">
                    <img src="assets/img/big-logo.png" alt="Logo" style="width: 300px;">
                    
                </div>
            </div>
    
            <div class="row">
    
                <div class="col-md-12 pt-5 text-center">
                    <app-my-heading heading="Sign Up"></app-my-heading>
                    <p>to create your account</p>
                </div>
            </div>
    
    
            <div class="row">
    
                <!-- <app-my-input label="Full Name" class="col-md-12" required="true" name="FullName" [(ngModel)]="obj.FullName">
                </app-my-input> -->
                <app-my-input label="Email" class="col-md-12" required="true" name="Email" [(ngModel)]="obj.Email">
                </app-my-input>
                <!-- <app-my-input label="Phone" class="col-md-12" required="true" name="Phone" [(ngModel)]="obj.Phone">
                </app-my-input>
                <app-my-input-password [hint]="getPasswordLength() + ' / 20'" label="Password" class="col-md-12" required="true" name="Password" [(ngModel)]="password">
                </app-my-input-password>
                <app-password-strength-bar  [passwordToCheck]="password" barLabel="Password strength:">  
                </app-password-strength-bar>
                <app-my-input-password *ngIf="getPasswordLength() >= 8" label="Repeat Password" class="col-md-12" required="true" name="RepeatPassword" [(ngModel)]="repeatPassword">
                </app-my-input-password> -->
                <mat-error *ngIf="otherError()" class="mx-3"> {{errorMessage}}</mat-error>
                <!--                 
                <br>
                <br>
                <div style="display: flex;
                justify-content: flex-start;
                align-items: baseline;">
                        <mat-checkbox>I accept the </mat-checkbox> <br> <a mat-stroked-button class="ms-2"> Terms and Conditions </a> 
                </div> -->
            </div>
              
            <div class="row">
                <div class="col-md-12 mt-4">
    
                    <button [disabled]="validate()" class="btn btn-block w-100" [class]="theme.myButtonBgColor"
                        type="button" (click)="onSave()">
                        Register
                    </button>
    
                </div>
            </div>
    
    
        </form>
    </div>