import { Component, OnInit } from '@angular/core';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { GlAccountsService } from 'src/app/services/finance/gl-accounts.service';
import { PaymentTypeService } from 'src/app/services/payment-type.service';

export class subJV{
        ID:number = 0;
        Date: any = null;
        ExpenseAccount: number = null;
        PaymentTypeID: number = null;
        Amount : number = null;
        BankID:number = null;
        BankAccountID:number = null;
        ChequeNo:string = null;
        ChequeDate:any = null
        Notes : string = null;
        Scode : string = null;
}

@Component({
  selector: 'app-quick-sub-jv',
  templateUrl: './quick-sub-jv.component.html',
  styleUrls: ['./quick-sub-jv.component.scss']
})
export class QuickSubJvComponent implements OnInit {

  obj:subJV
  dataExpense: any[]=[];
  selectedExpense:any= null;

  dataBank:any[] = [];
  dataBankAccount:any[] = [];

  selectedPaymentID:any = null;
  dataPayment:any[] = [];

  constructor(
    public glaccountservice : GlAccountsService,
    public hlp : ConfHelpService,

    public paymentTypeService:PaymentTypeService,

  ) {
    this.obj = new subJV()
   }

  ngOnInit(): void {
    this.getglaccount();
    this.getPaymentTypes();
  }

  async getglaccount(){
    await this.hlp.getFormDataByHelpNo("invglaccount").toPromise().then(res =>{
      let data = res['value'] as any[];
      this.dataExpense = data.filter(res=> res.AccountHeadID === 2)
      console.log(this.dataExpense)
    })
  }

  onPayment(data:any){
    this.selectedPaymentID = data;
  }

  async getPaymentTypes(){
    await this.paymentTypeService.getAll().then(res=>{          
      this.dataPayment = res['value'] as any[];    
    })
  }

  
  async getBank(){
    await this.hlp.getFormDataByHelpNo("finbank").toPromise().then(res=>{          
      this.dataBank = res['value'] as any[];    
    })}
  
    
    async getBankAcc(){
 
    await this.hlp.getFormDataByHelpNo("finbankaccounts").toPromise().then(res=>{          
      this.dataBankAccount= res['value'] as any[];    
    })}

  isSelected(data: any): boolean {
    return this.selectedPaymentID === data;
  }

}
