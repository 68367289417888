import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-grid-table',
  templateUrl: './view-grid-table.component.html',
  styleUrls: ['./view-grid-table.component.scss']
})
export class ViewGridTableComponent implements OnInit {

searchText:string = "";
selectedRows:any[] = [];
  objColumn:any[] = [
    {ID:1, ColumnText:'', ColumnName:''},
    {ID:2, ColumnText:'S#', ColumnName:'S #'},
    {ID:3, ColumnText:'Item', ColumnName:'Item'},
    // {ID:4, ColumnText:'Brands', ColumnName:'Brand'},
    // {ID:5, ColumnText:'Category', ColumnName:'Category'},
    {ID:6, ColumnText:'Unit', ColumnName:'Unit'},
    {ID:7, ColumnText:'Cost Price', ColumnName:'CostPrice'},
    {ID:8, ColumnText:'Sale Price', ColumnName:'SalePrice'},
  ];

  objData:any[] = [];
  constructor(
    public dialogRef: MatDialogRef<ViewGridTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { items: any[], selected_items: any[] }
  ) {
    this.objData = [...this.data.items];
  

    for (let index = 0; index < this.data.selected_items.length; index++) {
      const element = this.data.selected_items[index];
      
      var item_index =  this.objData.findIndex(a => a.ID == element.ItemID );
      if(item_index > -1){
        this.objData['isSelected'] = true;
      }
    }

    console.log(this.data.items);

   }

  ngOnInit(): void {
  }

  toggleSelection(item: any, $event) {
    // const index = this.selectedRows.indexOf(item);
    // if (index >= 0) {
    //   this.selectedRows.splice(index, 1);
    // } else {
    //   this.selectedRows.push(item);
    // }

    if ($event){
      item['isSelected'] = $event.checked;
    }
      

  }

  onDone(){

    
    for (let index = 0; index < this.objData.length; index++) {
      const element = this.objData[index];
      if(element.isSelected){
        this.selectedRows.push(element);
      }  
    }

    this.dialogRef.close(this.selectedRows);
  }
}
