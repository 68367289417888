<div class="container-fluid">
<div class="row marginTop">
        <div class="col-md-5 backgroundColor ">
                <div>
                        <img class="imgLogo" src="assets/icons/bizmanto/default.svg" alt="...">
                </div>

                <div>
                        <h2 class="display-1 text-center text-white">Tool - System - Universe. <br>
                        The Perfect Place to Manage your Business-1</h2>
                </div>

        </div> 
        <div class="col-md-7">
                
                <div class="row divRight">
                        <div class="mb-2 py-2">
                                <h1 class="text-center"> Log In</h1> 
                        </div> 
                        <div class="col-md-8">
                                <hr>
                                <div class="row">
                                <div class="d-flex flex-column justify-content-center">
                                <app-my-input label="Email or Mobile Number" class="col-md-12" required="true" name="UserId">
                                 </app-my-input>
                                <app-my-input-password label="Password" class="col-md-12" required="true" name="Password">
                                 </app-my-input-password>
                                </div>
                        </div>
                        </div>
                        <div class="col-md-8">
                               <p>
                                <mat-checkbox color="primary" class="me-1"></mat-checkbox>
                                 Remember Me
                        </div>
                        <div class="col-md-5 mt-4">
                                <div class="d-flex justify-content-center">
                                <button class="btn btnSubmit"> 
                                        Submit
                                </button>
                                </div>
                        </div>

                </div>
              
        </div>
</div>
</div>