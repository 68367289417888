export class FN_JvDetail {
    ID:number = 0;
    JVHeaderID: number = null;
    AccountHeadID: number = null;
    GLAccountID: number = null;
    NatureID:number = null;
    BankID:number = null;
    Amount:number = null;
    DimensionType: string = null;
    DimensionID:number = null;
    ReferenceType:string = null;
    ReferenceID:number = null;
    ChequeNo:string = null;
    ChequeDate:string = null;
    Description:string = null;

}