import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/base/local-storage.service';
import { RouteService } from 'src/app/services/base/route.service';
import { CompanyService } from 'src/app/services/company.service';
import { ViewDialogDataService } from 'src/app/services/view-dialog-data.service';
import { ItemComponent } from '../../inventory/item/item.component';
import { DialogService } from 'src/app/services/base/dialog.service';
import { AllReportsComponent } from '../../main/all-reports/all-reports.component';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { QuickJournalVoucherComponent } from '../../finance/quick-journal-voucher/quick-journal-voucher.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { QuickActionComponentComponent } from '../../main/quick-action-component/quick-action-component.component';
import { QuickSubJvComponent } from '../../finance/quick-sub-jv/quick-sub-jv.component';

@Component({
  selector: 'app-quick-dashboard',
  templateUrl: './quick-dashboard.component.html',
  styleUrls: ['./quick-dashboard.component.scss']
})
export class QuickDashboardComponent implements OnInit {


  customerCount : number = null;
  itemCount : number = null; 
  supplierCount : number = null; 
  userCount : number = null; 

  isMobile = this.document.body.clientWidth < 768;
  minHeight = this.isMobile ? '100vh' : '550px';
  width = this.isMobile ? '100vh' : 'auto';
  minWidth = this.isMobile ? '10vh' : '480px';
  maxWidth = this.isMobile ? '100vh': '1024px';
  height = this.isMobile ? '100vh': 'auto';

  RecentInvoiceData:any[] =[];

  companyName:string = '';
  constructor( 
    private _bottomSheet: MatBottomSheet,
    public companyService:CompanyService,
    public routeService:RouteService,
    public viewDialogDataService:ViewDialogDataService,

    public localStorageService:LocalStorageService,
    public dialogService:DialogService,

    public dialog:MatDialog,

    public router:Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.getRecentInvoices();
    this.getCurrentUser();
  }

  getRecentInvoices(){
    this.companyService.getQuickDashboard().toPromise().then(res =>{
      this.RecentInvoiceData = res['value'] as any;
      this.itemCount = res["Table1"][0]["ItemCount"];
      this.customerCount = res["Table2"][0]["CustomerCount"];
      this.userCount = res["Table3"][0]["UserCount"];
      this.supplierCount = res["Table4"][0]["SupplerCount"];
      console.log(res)
    })
  }

  onSaleDashboard(){
    this.router.navigateByUrl("/app/sale/dashboard")
  }

  openBottomSheet(): void {
    this._bottomSheet.open(QuickActionComponentComponent);
    // this._bottomSheet.dismiss();
  }

  onLinkClick(row:any){
    this.viewDialogDataService.openViewDialog(row.Scode, row.ID);
  }

  openLink(module:string, routeName:string ){
    this.router.navigate([this.routeService.openRoute(module, routeName)]);
  }

  onItemCreate(){
    this.dialogService.openQuickCreateDialog_List(ItemComponent, 'inv_01');
    this.dialogService.getDialog_List('inv_01').afterClosed().subscribe(result => {
      if (result) {
        if (result == "save") {
          console.log("ITEM SAVED");
         }
        }
      });
  }
  onQuickJVCreate(){
    this.dialogService.openQuickCreateDialog_List(QuickJournalVoucherComponent, 'fin_04');
    this.dialogService.getDialog_List('fin_04').afterClosed().subscribe(result => {
      if (result) {
        if (result == "save") {
          console.log("JV SAVED");
         }
        }
      });
  }

  onSubJv(){
    this.dialogService.openQuickCreateDialog_List(QuickSubJvComponent, 'fin_04');
    this.dialogService.getDialog_List('fin_04').afterClosed().subscribe(result => {
      if (result) {
        if (result == "save") {
          console.log("JV SAVED");
         }
        }
      });
  }


  username:string = '';
getCurrentUser(){
  this.username = this.localStorageService.getCurrentUser()?.FullName;
  this.companyName = this.localStorageService.getCurrentUser()?.CompanyName;
}

openReportDialog(){
  const dialogRef = this.dialog.open(AllReportsComponent, {
    minHeight: this.minHeight,
    minWidth:this.minWidth,
    height:this.height,
    width:this.width,
    maxWidth:this.maxWidth,
    autoFocus: false ,
});
}



}
