import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {
  private onlineStatus: boolean = navigator.onLine;
  public statusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { 
    window.addEventListener('online', () => this.updateStatus());
    window.addEventListener('offline', () => this.updateStatus());
  }

  private updateStatus() {
    this.onlineStatus = navigator.onLine;
    this.statusChanged.emit(this.onlineStatus);
  }

  public isOnline(): boolean {
    return this.onlineStatus;
  }
}
