import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class SaleSummaryService {

  constructor(public base:BaseService) { }

  getReportData(datefrom:string, dateto:string, itemID:number){
    var qry = "exec sp_saleSummary '" + datefrom + "', '" + dateto + "', " + itemID;
    return  this.base.sql.getBySqlQueryBody(qry);
  }
}
