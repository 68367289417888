import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { catchError } from 'rxjs/operators';
import { CustomErrorHanderService } from '../base/custom-error-hander.service';

@Injectable({
  providedIn: 'root'
})
export class BanksService {

  readonly controllerName:string = "Banks";

  constructor(private base:BaseService,
    public errorService:CustomErrorHanderService) { }

  async getAll(){
    return await  this.base.sql.get(this.controllerName)
  }

  async getById(ID: number) {
    return await this.base.sql.getById(this.controllerName, ID);
  }

  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody,'fin_02').pipe( catchError((err=> this.errorService.handleError(err))) );
  }

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  delete(ID: number) {
    return this.base.sql.delete(this.controllerName, ID)
  }

}
