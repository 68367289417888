import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as customConfig from 'src/assets/files/customConfig.json';
import { LocalStorageService } from './local-storage.service';
import { LoaderService } from './loader.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FRReportService {

  readonly rootUrl: string = customConfig.api;
  conf_table_data:any= null;

  constructor(
    public http: HttpClient,
    public localStorageService:LocalStorageService,
    public loaderService:LoaderService,
    ) {
   
  }

   getFilters(scode:string) {
    return this.http.get(this.rootUrl + "api/FRReport/GetFilters/" + scode );
  }

  getDataByReportCode(reportcode:string, jsonFilters:any){
    var objToken = {
      reportcode:reportcode,
      token:jsonFilters
    }
      return this.http.post(this.rootUrl + "api/FRReport/GetReportData", objToken);

  }

  

 async getConfDataByReportCode(reportcode:string):Promise<any>{
    
    await this.getAllTableConfData();

    /*
    var qry:string = this.rootUrl + `api/FRReport/GetReportConfData/${reportcode}`;
    return this.http.get(qry);
    */

    var valueData = this.conf_table_data['value'].filter(a => a.reportcode == reportcode);
   // var table1Data = this.conf_table_data['Table1'].filter(a => a.reportcode == reportcode);

    var obj = {
      'value':  valueData,
     // 'Table1': table1Data
    }

    return obj; 
  }

  async getAllTableConfData(): Promise<any[]> {

    var obj: any[] = this.getConfTable_localStorage();

    if (obj) {
      this.conf_table_data = obj;
      return;
    }

    // sameer: 1/20/2024
    if (this.conf_table_data != null)
      return this.conf_table_data;

    var qry: string = this.rootUrl + `api/FRReport/GetConfAllData`;
    await this.http.get(qry).toPromise().then(res => {
      this.conf_table_data = res as any[];
      this.setConfTable_localStorage(this.conf_table_data);
      return this.conf_table_data;
    });

    return null;
  }

  setConfTable_localStorage(obj:any){
    this.localStorageService.removeData('rpt');
    this.localStorageService.setData('rpt', obj);
  }

  getConfTable_localStorage():any{
    return this.localStorageService.getData('rpt');
  }

  downloadPdf(reportno:string, param:any) {
    const host = customConfig.reportprintapi;
    var obj = {
      "companyObjectId":  this.localStorageService.getCurrentUser().CompanyObjectId,
      "reportno": reportno,
      "param": param
    }
    return this.http.post<any>(host + "api/FastReportApi/Print", obj);
  }

  async downloadReport(reportno:string, param:any) {
    this.loaderService.startLoaderOnSave();
    //var a = await this.getConfDataByReportCode(reportno);
    this.downloadPdf(reportno, param).subscribe((response) => {
      debugger;

     // if (response.filestreamresult && response.filestreamresult.FileStream && response.filestreamresult.FileStream._buffer) {
      if (response.filestreamresult && response.filestreamresult.FileContents ) {
        
        const fileByte = response.filestreamresult.FileContents;
        const base64EncodedString = fileByte;
        const bytes = this.base64ToArrayBuffer(base64EncodedString);
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = this.getFileName();
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        this.loaderService.endLoaderOnSave();
      }
    
    }, error => {
      this.loaderService.endLoaderOnSave();
      console.error('Download error:', error);
    });
  }

  async getReportContent(reportno:string, param:any):Promise<string | null> {
  
      const response = await this.downloadPdf(reportno, param).toPromise();

 

      // if (response.filestreamresult && response.filestreamresult.FileStream && response.filestreamresult.FileStream._buffer) {
      if (response.filestreamresult && response.filestreamresult.FileContents) {

        const fileByte = response.filestreamresult.FileContents;
        const base64EncodedString = fileByte;
        const bytes = this.base64ToArrayBuffer(base64EncodedString);
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        return url;
      }

      return null;


   
  }


  getFileName(): string {
    const date = new Date();
  
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
  
    const dateTimeString = `${year}${month}${day}_${hours}${minutes}${seconds}`;
  
    return `print_${dateTimeString}.pdf`;
  }

  private base64ToArrayBuffer(base64: string): Uint8Array {
   
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
  

  
}
