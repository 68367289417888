import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  color: string = "purple";
  //color: string = "blue";

  //#region  All elements style variable
  myTopNavColor = "";
  myLeftNavColor = "";
  myHeadingColor = "";
  myIconColor = "";
  myIconBgColor = "";
  myButtonBgColor = "";
  myDashboardBgColor = "";
  myUserText = "";
  myCardBtn = "";
  myRptHdrFtrColorBlue = "";
  //  myCardBackBtn = "";

  //#endregion
  
  constructor() { 
    this.myTopNavColor = "my-bg-top-heading-" + this.color;
    this.myLeftNavColor = "my-bg-left-nav-" + this.color;
    this.myHeadingColor = "my-form-heading-" + this.color;
    this.myIconColor = "my-icon-color-" + this.color;
    this.myIconBgColor = "my-icon-bg-color-" + this.color;
    this.myButtonBgColor = "my-bg-button-" + this.color;
    this.myDashboardBgColor =  "my-bg-card-dashboard-" + this.color;
    this.myUserText = "my-user-text-" + this.color;
    this.myCardBtn = "my-card-btn-" +this.color;
    this.myRptHdrFtrColorBlue = "my-rpt-hdr-ftr-color-"+this.color;
    //  this.myCardBackBtn = "my-card-btnText-" +this.color
    
  }

  spinner:boolean = false;

  showSpinner(){
    this.spinner = true;
  }

  hideSpinner(){
    this.spinner = false;
  }

}
