import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Bank_Account } from 'src/app/models/finance/bank_account.model';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { BankAccountsService } from 'src/app/services/finance/bank-accounts.service';
import { BanksService } from 'src/app/services/finance/banks.service';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';
import { BaseComponent } from '../../base/base.component';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BankComponent } from '../bank/bank.component';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss']
})
export class BankAccountComponent implements OnInit {
  obj: Bank_Account;
  primaryKey:number = 0;
  dataBank:any[] = [];
  selectedBank:any = null;



  constructor(
    public bankAccountService: BankAccountsService,
    public bankService: BanksService,

    public dialogService:DialogService,
    public hlp : ConfHelpService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loaderService.startLoader();
    this.obj = new Bank_Account();
   }

  ngOnInit(): void {

    Promise.all([
      this.getBanks()
    ]).then(res=>{
      if (this.data.primaryKey > 0)
        this.getData();
      else {
        this.loaderService.endLoader();
      }
    })
  }

  async getData() {    
    this.primaryKey = this.data.primaryKey;
    this.bankAccountService.getById(this.primaryKey).then(res => {
      this.obj = res as Bank_Account;
      this.setDropDowns();
      this.loaderService.endLoader();
    });
  }

  setDropDowns(){
    if (this.dataBank.filter(res => res.ID == this.obj.BankID).length > 0) {
      this.selectedBank = this.dataBank.filter(res => res.ID == this.obj.BankID)[0];     
    }
  }

  async getBanks() {
    //   await this.bankService.getAll().then(res => {      
    //     this.dataBank = res['value'] as any[];
    //   })
    // }
    await this.hlp.getFormDataByHelpNo("finbank").toPromise().then(res => {
      this.dataBank = res['value'] as any[];
    })

  }


  onSave() {


    this.loaderService.startLoaderOnSave();


    this.obj.BankID = this.selectedBank.ID;

    if (this.primaryKey == 0) {
      this.bankAccountService.post(this.obj).subscribe(res => {
        this.loaderService.endLoaderOnSave();
        //this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('fin_03', "save");
      });
    }
    else {
      this.bankAccountService.update(this.obj, this.primaryKey).subscribe(res => {
        this.loaderService.endLoaderOnSave();
        // this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('fin_03', "save");
      }); // Updating Data
    }


  }

  onDelete() {
    if (this.primaryKey > 0) {
      this.dialogService.openSmallDialog(YesNoComponent);

      this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
        if (result) {
          if (result == "yes") {
            this.bankAccountService.delete(this.primaryKey).subscribe(res => {
              //this.dialogService.closeDialog("delete");
              this.dialogService.closeDialog_List('fin_03', "delete");
            })
          }
        }
      });

    }
  }

  onBankCreate(){
    this.dialogService.openQuickCreateDialog_List(BankComponent, 'fin_02');
    this.dialogService.getDialog_List('fin_02').afterClosed().subscribe(result => {
      if (result) {
        if (result == "save") {
         this.getBanks().then(res=>{
          this.selectedBank = this.dataBank[this.dataBank.length - 1];
         })
        }
      }
    });

  }



}
