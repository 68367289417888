import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/base/snackbar.service';
import { AppInjectorService } from 'src/app/services/base/app-injector.service';
import { Observable } from 'rxjs';
import { ParentBaseComponent } from './parent-base/parent-base.component';
import { RecentActivityService } from 'src/app/services/recent-activity.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent extends ParentBaseComponent implements OnInit {
  
  isModal:boolean = false;
  primaryKey:number = 0;
  snackBar:SnackbarService;
  recentActivityService:RecentActivityService

  constructor(        
    public route: ActivatedRoute,
    public router: Router,
  ) { 
    super(router);

    this.snackBar = AppInjectorService.injector.get(SnackbarService), 
    this.recentActivityService = AppInjectorService.injector.get(RecentActivityService);   
  }

  ngOnInit(): void {
    if (this.isModal == false)
      window.scroll(0, 0);

    this.route.queryParams.subscribe(params => {
      this.primaryKey = Number(params['ID']) || 0;
    });
  }

  BeforeGetData(){

  }

  GetData(){
    
  }

  AfterGetData(){
    
  }


  BeforeSave(){

  }

  ValidateBeforeSave():boolean{
    return true;
  }
  
  onSave(flag?:any) {
    var isValid:boolean =  this.ValidateBeforeSave(); 
    if(isValid == false)
      return;
      
    this.BeforeSave();
   this.Save().subscribe(res => {
      
      if(res){
        this.primaryKey = res['ID']
      }        

      this.AfterSave(flag).then(res =>{
        this.snackBar.openSuccess();
        this.recentActivityService.recentCount++;
       
      });
    }, error => {
      console.log(error);
      
    });
  }
  
  Save():Observable<Object>{
        return null;
  }

  async AfterSave(flag?:any){
  
  }

  BeforeEdit(){

  }

  Edit(){

  }

  AfterEdit(){

  }

  BeforeDelete(){

  }

  Delete(){

  }

  AfterDelete(){

  }



}
