<div class="row card-margin">
        <div class="col-md-12">
            <app-my-table-search (change)="applyFilter($event)" *ngIf="search"></app-my-table-search>
            <mat-selection-list #forms [multiple]="false">
                <mat-list-option *ngFor="let d of searchList" [value]="d" (click)="onClick(d)">
                    <div class="list-box">                        
                        <div class="d-flex justify-space-between align-items-center visible-area w-100" >
                            <span class="flex-grow-1 bd-highlight titleFont" [class]="theme.myIconColor" >{{getValue(d)}}</span>
                            <button type="button" mat-icon-button>
                                <mat-icon [class]="theme.myIconColor">{{icon}}</mat-icon>
                            </button>
                        </div>
                        <div class="d-flex flex-column w-100 heading"> 
                            <span *ngIf="val1" class="w-100 d-flex justify-content-between"> 
                               <span> {{val1?.title}}:</span> 
                               <span> {{ val1?.type == 'currency' ? (getVal1(d) | number: '1.2-2') : val1?.type == 'number' ? (getVal1(d) | number: '1.2-2') : val1?.type == 'date' ? (getVal1(d) | date : 'MM/dd/yyyy') : val1?.type == 'text' ? getVal1(d) : ''}} </span>
                            </span>
                            <span *ngIf="val2" class="w-100 d-flex justify-content-between"> 
                                <span>{{val2?.title}}:</span>
                                <span>{{ val2?.type == 'currency' ? (getVal2(d) | number: '1.2-2') : val2?.type == 'number' ? (getVal2(d) | number: '1.2-2') : val2?.type == 'date' ? (getVal2(d) | date : 'MM/dd/yyyy') : val2?.type == 'text' ? getVal2(d) : ''}}</span> 
                            </span>  
                            <span *ngIf="val3" class="w-100 d-flex justify-content-between"> 
                                <span>{{val3?.title}}:</span> 
                                <span>{{ val3?.type == 'currency' ? (getVal3(d) | number: '1.2-2') : val3?.type == 'number' ? (getVal3(d) | number: '1.2-2') : val3?.type == 'date' ? (getVal3(d) | date : 'MM/dd/yyyy') : val3?.type == 'text' ? getVal3(d) : ''}} </span>
                            </span>  
                        </div>    
                    </div>
                </mat-list-option>
            </mat-selection-list>
        </div>
</div>