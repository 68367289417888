import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';
import * as customConfig from 'src/assets/files/customConfig.json';

@Component({
  selector: 'app-my-table-card',
  templateUrl: './my-table-card.component.html',
  styleUrls: ['./my-table-card.component.scss']
})
export class MyTableCardComponent implements OnInit, OnChanges {

  @Input() data:any[]=[];
  @Input() title:string="";
  @Input() icon:string="";
  @Input() search:boolean=false;
  @Output() myClick = new EventEmitter<any>();;
  @Input() isImage:boolean=false;
  searchList:any[]=[];

  @Input() val1: any = null;
  @Input() val2: any = null;
  @Input() val3: any = null;
  @Input() CardImage: any = null;

  @Input() edit:boolean = false;

  @Output() onEdit = new EventEmitter<any>();


  constructor(
    public theme:ThemeService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
   this.setImages();
  }

  ngOnInit(): void {
  }

  onEditClick(row){
    this.onEdit.emit(row);
  }

  setImages(){
    this.data.forEach(a =>{
     a['myimageurl'] = this.getSetUrl(a);
    })
  }

  getValue(option){
    //return option[this.title];


    if (this.title) {
      if (this.title.includes('.')) {
        var multi_keys = this.title.split('.');
        for (let index = 0; index < multi_keys.length; index++) {
          const element = multi_keys[index];
          multi_keys[index] = element.replace('?', '');
        }
        
        if (multi_keys.length == 2) {
          if (option[multi_keys[0]][multi_keys[1]] != null) {
            return option[multi_keys[0]][multi_keys[1]];
          }
        }
        else if (multi_keys.length == 3) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]];
          }
        }
        else if (multi_keys.length == 4) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]];
          }
        }
      }
      else
        return option[this.title] == null ? '' : option[this.title];
    }      
    else
      return '';

  }


  getVal1(option){    
    if (this.val1) {
      if (this.val1.name.includes('.')) {
        var multi_keys = this.val1.name.split('.');
        for (let index = 0; index < multi_keys.length; index++) {
          const element = multi_keys[index];
          multi_keys[index] = element.replace('?', '');
        }
        
        if (multi_keys.length == 2) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]] != null) {
            return option[multi_keys[0]]?.[multi_keys[1]];
          }
        }
        else if (multi_keys.length == 3) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]] != null) {
            return option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]];
          }
        }
        else if (multi_keys.length == 4) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]];
          }
        }
      }
      else
        return option[this.val1.name] == null ? '' : option[this.val1.name];
    }
      
    else
      return '';
  }

  getVal2(option){    
    if (this.val2){
      if (this.val2.name.includes('.')) {
        var multi_keys = this.val2.name.split('.');
        if (multi_keys.length == 2) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]] != null) {
            return option?.[multi_keys[0]]?.[multi_keys[1]];
          }
        }
        else if (multi_keys.length == 3) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]] != null) {
            return option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]];
          }
        }
        else if (multi_keys.length == 4) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]];
          }
        }
      }
       else
        return option[this.val2.name] == null ? '' : option[this.val2.name];
    }      
    else
      return '';
  }

  getVal3(option){
    if(this.val3){
      if (this.val3.name.includes('.')) {
        var multi_keys = this.val3.name.split('.');
        if (multi_keys.length == 2) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]] != null) {
            return option?.[multi_keys[0]]?.[multi_keys[1]];
          }
        }
        else if (multi_keys.length == 3) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]] != null) {
            return option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]];
          }
        }
        else if (multi_keys.length == 4) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]];
          }
        }
      }
      else
        return option[this.val3.name] == null ? '' : option[this.val3.name];
    }      
    else 
      return '';
  }

  getSetUrl(option){
   var url:string = "";
   var imageVal:string = "";
    if(this.CardImage){
      if (this.CardImage.includes('.')) {
        var multi_keys = this.CardImage.split('.');
        if (multi_keys.length == 2) {
          if (option?.[multi_keys[0]].length > 0) {
            if (option?.[multi_keys[0]]?.[0][multi_keys[1]] != null && option?.[multi_keys[0]]?.[0][multi_keys[1]] != undefined) {
              imageVal = option?.[multi_keys[0]]?.[0][multi_keys[1]];
            }
          }
        }
        else if (multi_keys.length == 3) {
          if (option[multi_keys[0]][0][multi_keys[1]][multi_keys[2]] != null && option[multi_keys[0]][0][multi_keys[1]][multi_keys[2]] != undefined) {
            imageVal =  option[multi_keys[0]][0][multi_keys[1]][multi_keys[2]];
          }
        }
        else if (multi_keys.length == 4) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != null && option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != undefined) {
            imageVal =  option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]];
          }
        }
      }
      else
      imageVal =  option[this.CardImage] == null ? '' : option[this.CardImage];

      if(imageVal)
        url = customConfig.imagePath + imageVal;

        return url;
    }      
    else 
      return url;
  }

}
