import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddHomeComponent } from './add-home/add-home.component';



@NgModule({
  declarations: [AddHomeComponent],
  imports: [
    CommonModule,      
    ReactiveFormsModule,
    FormsModule,    
    MaterialModule,
    SharedModule,
  ]
})
export class DialogsModule { }
