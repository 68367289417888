<div class="top-ribbon-dialog">
    <app-ribbon-save-dialog [hidden]="category.invalid" (myClick)="onSave()" [valid]="category.invalid"></app-ribbon-save-dialog>
    <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>
<app-my-heading-dialoge mat-dialog-title heading="Category Information"></app-my-heading-dialoge>


<form #category="ngForm" class="rounded needs-validation ">

    <div class="row g-2">
        <app-my-input label="Category Name"  required="true"  name="CategoryName" [(ngModel)]="obj.CategoryName">
        </app-my-input>                             
    </div>        
    <div align="end" >                
        <app-button-save-dialog (myClick)="onSave()" [valid]="category.invalid"></app-button-save-dialog>
    </div>
</form>
