import { Component, Inject, OnInit } from '@angular/core';
import { LoaderService } from './services/base/loader.service';
import { TokenValidateService } from './services/base/token-validate.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticateService } from './services/base/authenticate.service';
import { DataHubService } from './services/hubs/data-hub.service';
import { MessageDto } from './services/hubs/MessageDto.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'BMS';
  isNavActive:boolean = false;
  imgUrl = "<img src='assets/icons/bizmanto/without-slogan/sbca.png' />"
  

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    public loaderService:LoaderService,
    public authenticate:AuthenticateService,   
    public data:DataHubService
    
    ){
      this.authenticate.isLoggedIn = true;
      
      

/*
      this.authenticate.isLoggedIn = true;
      this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.authenticate.isLoggedIn = true;
      })
    */
  }
  ngOnInit(): void {

    //Sameer: 08/23/2024: Hub code committed
    //this.data.retrieveMappedObject().subscribe( (receivedObj: MessageDto) => { this.addToInbox(receivedObj);});  // calls the service method to get the new messages sent
      

  }

  addToInbox(obj: MessageDto) {
    
  }

  setLoginDisplay() {
    //this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  /* Changes end here. */

  onOpenNav(){
    this.isNavActive = true;
  }

  onCloseNav(){
    this.isNavActive = false;
  }

  

  
}

export function UserProviderFactory(userProvider: TokenValidateService) {
  return () => userProvider.load();
}
