<div class="row">

        <div class="col-12 d-flex justify-content-between align-items-center mb-4">
            <h2>Filters</h2>
            <app-my-small-button  icon="close"  mat-dialog-close></app-my-small-button>
        </div>

        <div class="col-12" *ngFor="let item of report_filters">
            <app-my-input-date *ngIf="item.FieldType == 'date'" [label]="item.FieldLabel"  [(ngModel)]="item.val">
            </app-my-input-date>     
            <app-my-select *ngIf="item.FieldType == 'dropdown'" [label]="item.FieldLabel" [data]="item.objData" [title]="item.DisplayMember" [name]="item.DisplayMember"
                [(ngModel)]="item.val">
            </app-my-select>
        </div>

        <div class="col-12 d-flex justify-content-between align-items-center">
            <app-my-small-button  title="Clear" icon="remove_done" (myClick)="clearFilter()">
            </app-my-small-button>
            <app-my-button  title="Apply" icon="done_all" (myClick)="applyFilter()">
            </app-my-button>
        </div>

</div>
