<div *ngIf="primaryKey > 0 && !isLoading" class="desktop">
        <span style="font-size: 15px; font-weight: 500;" class="me-2" [class]="theme.myHeadingColor"> {{DocumentNo}} <span *ngIf="DocumentNo">|</span></span>
        <span style="    font-size: 12px;" [class]="theme.myHeadingColor">
                <mat-icon *ngIf="FullName" style="font-size: 11px; height:18px; width:16px;">edit</mat-icon>
                <span *ngIf="FullName"> Last Modified by </span>
                <span style="text-decoration: underline;">{{FullName}}</span>
                <span *ngIf="ModifiedDate"> on </span>
                <span style="text-decoration: underline;">{{ModifiedDate | date:'MM/dd/yyyy hh:mm aa'}}</span>
                <!-- <span *ngIf="CreatedDate"> C on </span>
                <span style="text-decoration: underline;">{{CreatedDate | date:'MM/dd/yyyy hh:mm aa z'}}</span> -->
                

        </span>        
</div>

<div *ngIf="primaryKey > 0 && isLoading" class="desktop">
        <div class="uploader-status">
                <mat-spinner [diameter]="20"></mat-spinner>
        </div>
</div>



<div *ngIf="primaryKey > 0 && !isLoading" class="mob py-2 px-2" >
        <h3 class="px-2"  [class]="theme.myUserText"> {{DocumentNo}} </h3> <hr>
        <div class="d-flex justify-content-between mt-2">
                <div class="d-flex">
                <mat-icon class="me-2">edit</mat-icon>
                <h4> Last Modified by: </h4>
                </div>
                                <h4 style="text-decoration: underline;" [class]="theme.myUserText">{{FullName}}</h4>
        </div>
        <div class="d-flex justify-content-between">
                <div class="d-flex">
                <mat-icon class="me-2">edit_calendar</mat-icon>
                <h4> Modified Date: </h4>
                </div>
                <h4 style="text-decoration: underline;" [class]="theme.myUserText">{{ModifiedDate | date:'MM/dd/yyyy hh:mm aa'}}</h4>
        </div>
        
</div>
