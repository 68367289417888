import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

import * as conf_dictionary from 'src/assets/files/conf_dictionary.json';
import { CustomRxjsService } from '../base/custom-rxjs.service';

@Injectable({
  providedIn: 'root'
})
export class NatureService {
  readonly controllerName:string = "FN_Nature";

  constructor( private base:BaseService,
    private rxjsService: CustomRxjsService) { }

  async getAll(){
    // return await this.base.sql.get(this.controllerName);
    return this.rxjsService.convertToObservableAndGetData(conf_dictionary.fn_Nature);

  }

  async getById(ID: number) {
    return await this.base.sql.getById(this.controllerName, ID);
  }
}
