import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogService } from 'src/app/services/base/dialog.service';
import { FRReportService } from 'src/app/services/base/frreport.service';
import { LocalStorageService } from 'src/app/services/base/local-storage.service';
import { ThemeService } from 'src/app/services/base/theme.service';
import * as customConfig from 'src/assets/files/customConfig.json';

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss']
})
export class PrintDialogComponent implements OnInit {
  readonly rootreporturl: string = customConfig.reportapi;
   reportno:string=""
   reportparams:string="";

  constructor(
    public theme:ThemeService,
    private sanitizer: DomSanitizer,
    public dialogService:DialogService,
    public localStorageService:LocalStorageService,
    public fRReportService:FRReportService,

    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(this.data);
      this.reportno = this.data.data.reportno;
      //this.reportparams = this.data.data.queryparam;
      this.setReportParams(this.data.data.queryparam)
     }

  ngOnInit(): void {
    this.getFastReportQry()
  }

  
  setReportParams(reportparams:string){

    if(this.reportparams == ""){
      var companyobjectid_encode = encodeURIComponent(this.localStorageService.getCurrentUser().CompanyObjectId);
      this.reportparams = "CompanyObjectId=" + companyobjectid_encode;
    }
        //this.reportparams = "CompanyObjectId=" +  this.localStorageService.getCurrentUser().CompanyObjectId;

    this.reportparams += "&" + this.data.data.queryparam;

    //this.reportparams += "&" +  field + "=" + value; 
  }

  frurl:SafeResourceUrl = null;
  getFastReportQry(){
    if(this.rootreporturl && this.reportno && this.reportparams){
      var url = this.rootreporturl + this.reportno +'&'+ this.reportparams;
      url = encodeURI(url);
      this.frurl =this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
      
  }

  printToPdf(){

    var key:string = this.data.data.queryparam.split("=")[0];
    var val:string = this.data.data.queryparam.split("=")[1];

    var obj = {
      [key] :val
    }
    this.fRReportService.downloadReport(this.reportno, obj);
  }

  onClose(){
    this.dialogService.closePrintDialog();
  }

}
