import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, Inject, Optional} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuditInfoService } from 'src/app/services/base/audit-info.service';
import { ScreenConfService } from 'src/app/services/base/screen-conf.service';
import { ThemeService } from 'src/app/services/base/theme.service';
import { ConfTableService } from 'src/app/services/inventory/conf-table.service';

@Component({
  selector: 'app-audit-info',
  templateUrl: './audit-info.component.html',
  styleUrls: ['./audit-info.component.scss']
})
export class AuditInfoComponent implements OnInit, OnChanges  {


  //Scode: string = "";
  primaryKey: number = 0;
  FullName: string = null;
  ModifiedDate: string = null;
  CreatedDate: string = null;
  DocumentNo:string = null;
  @Output() audit:EventEmitter<any> = new EventEmitter<any>();
  DialogPrimaryKey:number = 0;
  isLoading:boolean = false;

  constructor(
    public route: ActivatedRoute,    
    public screenConfService: ScreenConfService,
    public auditInfoService: AuditInfoService,  
    public theme: ThemeService,      
    public cd: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: any | null,
  ) {
    
    this.route.queryParams.subscribe(params => {
      this.primaryKey = Number(params['ID']) || 0;
    });

    if(this.data && this.data.primaryKey > 0)
      this.primaryKey = this.data.primaryKey;

  }

  ngOnChanges(changes: SimpleChanges): void {
    

    this.getScodeData();
  }

  ngOnInit(): void {
    this.getScodeData();
  }

  getScodeData() {
  

    if(this.screenConfService.ActiveScode == '')
    return;

    if(this.primaryKey == 0)
    return;

    this.isLoading = true;

    this.auditInfoService.getAuditData(this.screenConfService.ActiveScode, this.primaryKey).then(a => {
      var auditData = a['value'] as any[];
      if (auditData.length > 0) {
        this.FullName = auditData[0].FullName;
        this.ModifiedDate = auditData[0].ModifiedDate;
        // this.CreatedDate = auditData[0].CreatedDate;
        this.DocumentNo = auditData[0].DocumentNo;
        this.audit.emit(this.DocumentNo);
        this.cd.detectChanges();            
      }
      this.isLoading = false;
    })

    // this.screenConfService.getConfScodeData(this.screenConfService.ActiveScode).toPromise().then(res => {
    //   var data = res['value'] as any[];
    //   if (data.length > 0) {
    //     var auditQuery = "select Z.ID, Z.DocumentNo, U.FullName, Z.ModifiedDate, Z.CreatedDate from " + data[0].SqlTable + " AS Z " +
    //       "LEFT JOIN RBAC_User AS U ON U.ID = Z.ModifiedBy " +
    //       "where Z.ID =  " + this.primaryKey;

    //     this.auditInfoService.getAuditData(this.screenConfService.ActiveScode, this.primaryKey).then(a => {
    //       var auditData = a['value'] as any[];
    //       if (auditData.length > 0) {
    //         this.FullName = auditData[0].FullName;
    //         this.ModifiedDate = auditData[0].ModifiedDate;
    //         // this.CreatedDate = auditData[0].CreatedDate;
    //         this.DocumentNo = auditData[0].DocumentNo;
    //         this.audit.emit(this.DocumentNo);
    //         this.cd.detectChanges();            
    //       }
    //       this.isLoading = false;
    //     })
    //   }
    // })


  }

}
