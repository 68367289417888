import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticateService } from './authenticate.service';
import { LocalStorageService } from './local-storage.service';
import { PermissionService } from './permission.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardHomeGuard implements CanActivate {

  constructor(
    public router: Router, 
    public authenticate: AuthenticateService,
    public tokenService:TokenService,
    public permissionService:PermissionService,
    private localStorageService: LocalStorageService,
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(!this.localStorageService.getCurrentUser()){
      this.router.navigate(["signin"]);
    }
      /*
    if (this.tokenService.getToken() == "") {
      this.authenticate.isLoggedIn = false;
      this.router.navigate(["/login"]);
      return false;
    }
    else 
    */{
      //check if user has form access
      //return true;

        return this.permissionService.getHomePermission().pipe(
          map((res) => {
            var hasRole = res['value'] as any[]
            if (hasRole.length > 0) {
              return true;

            } else {

              // redirect user to unauthorized compoenent
              this.router.navigate(["/accessdenied"]); // replace url with unauthorized

              return false;
            }
          }));
      

    }
  }
}
  
  
    
