import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from './base/dialog.service';
import { CustomerComponent } from '../modules/customer/customer/customer.component';
import { SupplierComponent } from '../modules/supplier/supplier/supplier.component';
import { ItemComponent } from '../modules/inventory/item/item.component';
import { CategoryComponent } from '../modules/inventory/category/category.component';
import { BrandsComponent } from '../modules/inventory/brands/brands.component';
import { GLComponent } from '../modules/finance/gl/gl.component';
import { BankComponent } from '../modules/finance/bank/bank.component';
import { BankAccountComponent } from '../modules/finance/bank-account/bank-account.component';
import { UnitsComponent } from '../modules/inventory/units/units.component';
import { PlantsComponent } from '../modules/inventory/plants/plants.component';
import { BrokerComponent } from '../modules/sale/broker/broker.component';



@Injectable({
  providedIn: 'root'
})
export class AddAllDialogService {




  constructor(private dialogService:DialogService) { }


  openAnyDialog(scode: string) {
    if (scode == 'inv_01') {
      this.addItem(scode);
    }
    else if (scode == 'inv_02') {
      this.addCategory(scode);
    }
    else if (scode == 'inv_03') {
      this.addUnits(scode);
    }
    else if (scode == 'inv_05') {
      this.addBrands(scode);
    }
    else if (scode == 'pur_01') {
      this.addSupplier(scode);
    }
    else if (scode == 'sal_04') {
      this.addCustomer(scode);
    }
    else if (scode == 'fin_01') {
      this.addGL(scode);
    }
    else if (scode == 'fin_02') {
      this.addBank(scode);
    }
    else if (scode == 'fin_03') {
      this.addBankAccount(scode);
    }
    else if (scode == 'inv_04') {
      this.addPlant(scode);
    }
    else if (scode == 'sal_06') {
      this.addBroker(scode);
    }
  }

  addCustomer(scode:string){
  this.dialogService.openDialog_List(CustomerComponent, scode, null, {scode: scode})
  }

  addSupplier(scode:string){
    this.dialogService.openDialog_List(SupplierComponent, scode, null, {scode: scode})
  }

  addItem(scode:string){
    this.dialogService.openDialog_List(ItemComponent, scode, null, {scode: scode})
  }
  addCategory(scode:string){
    this.dialogService.openDialog_List(CategoryComponent, scode, null, {scode: scode})
  }
  addBrands(scode:string){
    this.dialogService.openDialog_List(BrandsComponent, scode, null, {scode: scode})
  }
  addGL(scode:string){
    this.dialogService.openDialog_List(GLComponent, scode, null, {scode: scode})
  }
  addBank(scode:string){
    this.dialogService.openDialog_List(BankComponent, scode, null, {scode: scode})
  }
  addBankAccount(scode:string){
    this.dialogService.openDialog_List(BankAccountComponent, scode, null, {scode: scode})
  }

  addUnits(scode:string){
    this.dialogService.openDialog_List(UnitsComponent, scode, null, {scode: scode})
  }

  addPlant(scode:string){
    this.dialogService.openDialog_List(PlantsComponent, scode, null, {scode: scode})
  }

  addBroker(scode:string){
    this.dialogService.openDialog_List(BrokerComponent, scode, null, {scode: scode})
  }
 
 
}
