import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Supplier } from 'src/app/models/finance/supplier.model';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { SuppliersService } from 'src/app/services/finance/suppliers.service';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {

  primaryKey:number = 0;
  obj: Supplier;

  constructor(
    public supplierSerice:SuppliersService,
    public dialogService:DialogService,
    public loaderService:LoaderService,

    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      this.obj = new Supplier();
      this.loaderService.startLoader();
    }

  ngOnInit(): void {
    if(this.data.primaryKey > 0)
      this.getData();
    else 
      this.loaderService.endLoader();
  }

  getData(){
    this.primaryKey = this.data.primaryKey;
    this.supplierSerice.getById(this.primaryKey).then(res => {
      this.obj = res as Supplier;
      this.loaderService.endLoader();
    })
  }

  onSave(){
    this.loaderService.startLoaderOnSave();
    if (this.primaryKey == 0) {
      this.supplierSerice.post(this.obj).subscribe(res => {
        this.dialogService.closeDialog_List('pur_01', "save");
        // this.dialogService.closeDialog("save");
      });
    }
    else {
      this.supplierSerice.update(this.obj, this.primaryKey).subscribe(res=>{
        this.loaderService.endLoaderOnSave();
        this.dialogService.closeDialog_List('pur_01', "save");
        // this.dialogService.closeDialog("save");
      });
    }
  }


  onDelete(){
    if (this.primaryKey > 0 ) {
      this.dialogService.openSmallDialog(YesNoComponent);

      this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
        if (result) {
            if (result == "yes") {
              this.supplierSerice.delete(this.primaryKey).subscribe(res => {
               // this.dialogService.closeDialog("delete");
               this.dialogService.closeDialog_List('pur_01', "delete");
              })
            }
        }
      })
    }
  }

}