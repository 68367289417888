import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Units } from 'src/app/models/inventory/units.model';
import { DialogService } from 'src/app/services/base/dialog.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { UnitService } from 'src/app/services/unit.service';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent implements OnInit {
  primaryKey:number = 0;
  obj: Units

  constructor(
    public unitService:UnitService,    
    public dialogService:DialogService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.obj = new Units();
    this.loaderService.startLoader();
    
   }

  ngOnInit(): void {
    if(this.data.primaryKey > 0)
    this.getData();
    else 
    this.loaderService.endLoader();
  }

  async getData(){
    this.primaryKey = this.data.primaryKey;
    this.unitService.getById(this.primaryKey).then(res => {
      this.obj = res as Units; 
      this.loaderService.endLoader();     
    });
  }

  onSave() {
    this.loaderService.startLoaderOnSave();
    if (this.primaryKey == 0) {
      this.unitService.post(this.obj).subscribe(res => {
       // this.dialogService.closeDialog("save");
       this.dialogService.closeDialog_List('inv_03', "save");
      });
    }
    else {
      this.unitService.update(this.obj, this.primaryKey).subscribe(res=>{
        this.loaderService.endLoaderOnSave();
        this.dialogService.closeDialog_List('inv_03', "save");
        // this.dialogService.closeDialog("save");
      });
    }
  }

  onDelete() {
    if (this.primaryKey > 0) {
      this.dialogService.openSmallDialog(YesNoComponent);

      this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
        if (result) {
          if (result == "yes") {
            this.unitService.delete(this.primaryKey).subscribe(res => {
             this.dialogService.closeDialog_List('inv_03', "delete");
            })
          }
        }
      });

    }
  }

}
