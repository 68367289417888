<button mat-stroked-button type="button" [disabled]="valid" (click)="onClick()" class="w-100 text-start mb-3"
    style="height:45px;">
    <!-- <div class="my-form-field-outline-gap"></div> -->
    <span class="lbl">{{label}}</span>

    <div class="textarea">
        <mat-icon *ngIf="icon" class="me-1" [class]="theme.myIconColor">{{icon}}</mat-icon>
        <span>{{text}}</span>
    </div>

</button>