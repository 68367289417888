import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  readonly controllerName:string = "Companies";
    
  constructor(private base:BaseService) {    
   }

   async getAll() {
    return await this.base.sql.get(this.controllerName);
  }

  async getById(ID: number) {
    return await this.base.sql.getById(this.controllerName, ID);
  }


  
  getQuickDashboard(){

     var apiRoute = "api/QuickDashboard/GetRecentInvoicesData";

     return this.base.sql.http.get(this.base.sql.rootUrl + apiRoute)

  }

  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody);

  }

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  delete(ID: number) {
    return this.base.sql.delete(this.controllerName, ID)
  }

}
