import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-new-dash',
  templateUrl: './new-dash.component.html',
  styleUrls: ['./new-dash.component.scss']
})
export class NewDashComponent implements OnInit {


  dataPlant:string = "";
  dataCustomer:string = "";
  dataSupplier:string = "";
  dataStock:string = "";
  dataSales:string = "";
  dataPR:string = "";
  dataSR:string = "";
  data:any[] = [ 
    {name:'Today', value: 0},
    {name:'Yesterday', value: -1},
    {name:'Last 7 Days', value: -7},
    {name:'Last 30 Days', value: -30},
    {name:'Last 3 Month', value: -90},
  ];
  selectedPeriod:any = null;

  ShowStock:boolean =false;

  constructor(
    public dashboardService:DashboardService
  ) { }

  ngOnInit(): void {
    this.selectedPeriod = this.data[0]
   this.getData();
  }

getData(){
  //this.getTotalPlant();
  //this.getCustomer();
  //this.getSupplier();
  //this.getStock();
  //this.getPR();
  //this.getSales();
  //this.getSR();
}

  getTotalPlant(){
    this.dashboardService.getPlants().subscribe(res=> {
      this.dataPlant = res['value'][0]['TotalPlant']
    })
  }

  getCustomer(){
    this.dashboardService.getCustomer(this.selectedPeriod.value).subscribe(res=> {
      this.dataCustomer = res['value'][0]['RecentCustomers']
    })
  }

  getSupplier(){
    this.dashboardService.getSupplier().subscribe(res=> {
      this.dataSupplier = res['value'][0]['RecentSupplier']
    })
  }

  getSales(){
    this.dashboardService.getSales(this.selectedPeriod.value).subscribe(res=> {
      this.dataSales = res['value'][0]['TotalNetAmount']
    })
  }

  getStock(){
    this.dashboardService.getStock().subscribe(res=> {
      this.dataStock = res['value'][0]['TotalStock']
      this.ShowStock = true;
    })
  }

  getPR(){
    this.dashboardService.getPurchaseReturn(this.selectedPeriod.value).subscribe(res=> {
      this.dataPR = res['value'][0]['TotalNetAmount']
    })
  }

  getSR(){
    this.dashboardService.getSaleReturn(this.selectedPeriod.value).subscribe(res=> {
      this.dataSR = res['value'][0]['TotalNetAmount']
    })
  }

  

}
