import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-my-table-search',
  templateUrl: './my-table-search.component.html',
  styleUrls: ['./my-table-search.component.scss']
})
export class MyTableSearchComponent implements OnInit,AfterViewInit, OnChanges {
  
  @ViewChild('swiper') swiper: ElementRef;
  @Output() change = new EventEmitter<String>();
  @Input() filter:boolean = false;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges){
    
  }

  ngAfterViewInit(): void {
    //this.swiper.nativeElement.focus();
  }

  onChange(event){
    this.change.emit( (event.target as HTMLInputElement).value);
  }
}
