import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {
  readonly controllerName:string = "ItemMakes";

  constructor( private base:BaseService) { }

  async getAll(){
    var query = {
      expand: "ItemCategory"
    }
    return await this.base.sql.get(this.controllerName, query);
  }

  async getById(ID: number) {
    return await this.base.sql.getById(this.controllerName, ID);
  }

  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody,'inv_05');

  }

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  delete(ID: number) {
    return this.base.sql.delete(this.controllerName, ID)
  }
}
