import { Injectable } from '@angular/core';
import { RBAC_User } from 'src/app/models/rbac_user.model';
import { AuthenticateService } from './authenticate.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    public authenticate:AuthenticateService 
  ) { }

  setCurrentUser(rbac_user: any) {
    var user = JSON.stringify(rbac_user);
    localStorage.setItem('currentUser', user);
    this.authenticate.user = rbac_user;
  }


  getCurrentUser(): any {
    var user = localStorage.getItem("currentUser");
    return JSON.parse(user) as any;
  }

  removeCurrentUser(){
    this.authenticate.user =  new RBAC_User();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("tbl");
    localStorage.removeItem("rpt");
  }


  // Cruptography
  private readonly secretKey = '9d85f3ad-ab0b-431c-8864-fccb339a97b9';

  // Save data to local storage with encryption
  setData(key: string, data: any): void {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
    localStorage.setItem(key, encryptedData);
  }

  // Get and decrypt data from local storage
  getData(key: string): any {
    const storedData = localStorage.getItem(key);
    if (storedData) {
      const decryptedData = CryptoJS.AES.decrypt(storedData, this.secretKey).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    }
    return null;
  }

  // Remove data from local storage
  removeData(key: string): void {
    localStorage.removeItem(key);
  }
}
