<div class="top-ribbon-dialog">
    <app-ribbon-save-dialog [hidden]="glAcc.invalid" (myClick)="onSave()" [valid]="glAcc.invalid"></app-ribbon-save-dialog>
    <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>
<app-my-heading-dialoge mat-dialog-title heading="GL Accounts"></app-my-heading-dialoge>


<form #glAcc="ngForm" class="rounded needs-validation ">

    <div class="row g-2">
        <app-my-select (valueChanged)="onHeadChange()" [search]="true" class="col-md-6" required="true" label="Account" title="AccountHeadName"  name="FN_AccountHead" [(ngModel)]="selectedAccountHead"   [data]="dataAccountHead">
        </app-my-select>
        <div class="col-md-6"></div>

        <app-my-input  class="col-md-6" label="GL Account Name"  required="true"  name="GLAccountName" [(ngModel)]="obj.GLAccountName">
        </app-my-input>
        <div class="col-md-6"></div>

        <mat-checkbox class="col-md-6 cstmCheck"  [(ngModel)]="obj.IsSubAccount" [ngModelOptions]="{standalone: true}">Is Sub-account</mat-checkbox>
        <div class="col-md-6"></div>

        <app-my-select [search]="true" class="col-md-6" [isDisabled]="!obj?.IsSubAccount" [required]="obj?.IsSubAccount == true" label="Parent Account" title="GLAccountName"  name="GLAccountName" [(ngModel)]="selectedParentGL"   [data]="dataParentGL">
        </app-my-select> 
        <div class="col-md-6"></div>

        <app-my-select [search]="true" class="col-md-6" required="true" label="Nature" title="NatureName"  name="FN_Nature" [(ngModel)]="selectedNature"   [data]="dataNature">
        </app-my-select> 
        <div class="col-md-6"></div>
     
        <app-my-select [search]="true" class="col-md-6" required="false" label="Dimension" title="DimensionName"  name="FN_DimensionType" [(ngModel)]="selectedDimensionType"   [data]="dataDimensionType">
        </app-my-select>                             
    </div>        
    <div aligna="end" >                
        <app-button-save-dialog (myClick)="onSave()" [valid]="glAcc.invalid"></app-button-save-dialog>
    </div>
</form>


