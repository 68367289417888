<button 
*ngIf="add || edit"
mat-button style="    border-top-right-radius: 0px;
border-bottom-right-radius: 0px;margin-right: 0px;
    border-right: solid 1px #e9e9e963;" class="ribbon-button-padding" type="button" [disabled]="valid" (click)="ClickSaveOnly()" >
        <mat-icon class="save-icon me-1" [class]="theme.myIconColor">save</mat-icon> 
        <span>Save</span> 
</button>

<button style="  border-top-left-radius: 0px;
border-bottom-left-radius: 0px;" class="ribbon-button-padding" mat-button   [matMenuTriggerFor]="menu">
       <mat-icon >expand_more</mat-icon>
       <mat-menu #menu="matMenu" xPosition="before" class="btnsavediv">
          
              <button mat-menu-item (click)="ClickSaveandprint()" >
                     <mat-icon class="saveprint">print</mat-icon>
                     <span class="me-2">Save & Print</span>
              </button>
              <button mat-menu-item (click)="ClickSaveAndNew()">
                     <mat-icon class="saveedit">add</mat-icon>
                    <span class="me-2">Save & New</span> 
              </button>
       </mat-menu>

</button>


