import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { FRReportService } from 'src/app/services/base/frreport.service';
import { ScreenConfService } from 'src/app/services/base/screen-conf.service';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

  reportcode:string = "";
  report_filters:any[] = [];

  constructor(
    public dialogService:DialogService,
    public route:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public screenConfService:ScreenConfService,
    public frreportService:FRReportService,
    public confHelpService:ConfHelpService
    
  ) { }

  ngOnInit(): void {
  
    console.log('screencode',  this.screenConfService.ActiveScode);
    console.log('filter data',  this.data.data);
    this.report_filters = this.data.data;
   //this.getFilters();

  }

 



  applyFilter(){

    var objfilter:any = {};
    this.report_filters.forEach(a => {
      if(a['val']){
        if (a["FieldType"] == "date") {
          objfilter[a['FieldName']] = moment(a['val']).format('YYYY/MM/DD');
        } else if (a["FieldType"] == "dropdown"){
          objfilter[a['FieldName']] = a['val'][a.ValueMember];
        }
  
      }
      else {
        if (a["FieldType"] == "date" ) 
          objfilter[a['FieldName']] = '';
        else  if (a["FieldType"] == "dropdown" ) 
          objfilter[a['FieldName']] = 0;
        
      }
    
    })

   
    this.dialogService.closeDialog(objfilter);
   
  }

  clearFilter(){
    this.report_filters.forEach(element => {
        element.val = null;
    });
    this.dialogService.closeDialog(this.report_filters);
  }

}
