<div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 mt-5">
                <div class="py-4 text-center border mt-5">
                        <div class="d-flex justify-content-center">
                                <h1>Sorry! You don't have access to this Component </h1>
                                <mat-icon class="ms-2" style="color: red; font-size: 30px;">block</mat-icon>
                        </div>
                        <div class="d-flex justify-content-evenly">
                                <button class="btn btn-primary mt-4">Request Access</button>
                                <button class="ms-2 btn btn-outline-secondary mt-4" (click)="logout()">
                                        <div class="d-flex align-items-center">
                                      <mat-icon>logout</mat-icon>   Log Out
                                        </div>
                                </button>
                        </div>
                </div>
        </div>
</div>