import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-my-list',
  templateUrl: './my-list.component.html',
  styleUrls: ['./my-list.component.scss']
})
export class MyListComponent implements OnInit, OnChanges {
 

  @Input() data:any[]=[];
  @Input() title:string="";
  @Input() icon:string="";
  @Input() search:boolean=false;
  @Output() myClick = new EventEmitter<any>();;
  @Input() image:boolean=false;
  searchList:any[]=[];

  @Input() val1: any = null;
  @Input() val2: any = null;
  @Input() val3: any = null;

  constructor(public theme:ThemeService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.searchList = this.data;
  }
  

  onClick(row:any){
    this.myClick.emit(row);
  }

  getValue(option){
    //return option[this.title];


    if (this.title) {
      if (this.title.includes('.')) {
        var multi_keys = this.title.split('.');
        for (let index = 0; index < multi_keys.length; index++) {
          const element = multi_keys[index];
          multi_keys[index] = element.replace('?', '');
        }
        
        if (multi_keys.length == 2) {
          if (option[multi_keys[0]][multi_keys[1]] != null) {
            return option[multi_keys[0]][multi_keys[1]];
          }
        }
        else if (multi_keys.length == 3) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]];
          }
        }
        else if (multi_keys.length == 4) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]];
          }
        }
      }
      else
        return option[this.title] == null ? '' : option[this.title];
    }      
    else
      return '';

  }

  applyFilter(value) {
    if (value != '' && value != null && value != undefined) {
      this.searchList = this.data.filter(res => {
        return res[this.title].toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
      })
    }
    else
      this.searchList = this.data;
  }

  getVal1(option){    
    if (this.val1) {
      if (this.val1.name.includes('.')) {
        var multi_keys = this.val1.name.split('.');
        for (let index = 0; index < multi_keys.length; index++) {
          const element = multi_keys[index];
          multi_keys[index] = element.replace('?', '');
        }
        
        if (multi_keys.length == 2) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]] != null) {
            return option?.[multi_keys[0]]?.[multi_keys[1]];
          }
        }
        else if (multi_keys.length == 3) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]] != null) {
            return option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]];
          }
        }
        else if (multi_keys.length == 4) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]];
          }
        }
      }
      else
        return option[this.val1.name] == null ? '' : option[this.val1.name];
    }
      
    else
      return '';
  }

  getVal2(option){    
    if (this.val2){
      if (this.val2.name.includes('.')) {
        var multi_keys = this.val2.name.split('.');
        if (multi_keys.length == 2) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]] != null) {
            return option?.[multi_keys[0]]?.[multi_keys[1]];
          }
        }
        else if (multi_keys.length == 3) {
          if (option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]] != null) {
            return option?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]];
          }
        }
        else if (multi_keys.length == 4) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]];
          }
        }
      }
       else
        return option[this.val2.name] == null ? '' : option[this.val2.name];
    }      
    else
      return '';
  }

  getVal3(option){
    if(this.val3){
      if (this.val3.name.includes('.')) {
        var multi_keys = this.val3.name.split('.');
        if (multi_keys.length == 2) {
          if (option[multi_keys[0]][multi_keys[1]] != null) {
            return option[multi_keys[0]][multi_keys[1]];
          }
        }
        else if (multi_keys.length == 3) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]];
          }
        }
        else if (multi_keys.length == 4) {
          if (option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]] != null) {
            return option[multi_keys[0]][multi_keys[1]][multi_keys[2]][multi_keys[3]];
          }
        }
      }
      else
        return option[this.val3.name] == null ? '' : option[this.val3.name];
    }      
    else 
      return '';
  }

}
