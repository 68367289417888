import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { ThemeService } from 'src/app/services/base/theme.service';
import { RBAC_User } from 'src/app/models/rbac_user.model';
import { BaseComponent } from '../../base/base.component';
import { RbacUserService } from 'src/app/services/rbacUser.service';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/base/token.service';
import { RouteService } from 'src/app/services/base/route.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent extends BaseComponent implements OnInit {

  obj:RBAC_User;
  repeatPassword:string = "";
  password:string = null;
  constructor(
    public router: Router, 
    public authenticate:AuthenticateService,
    public theme:ThemeService,
    public route:ActivatedRoute,
    public rbacUserService:RbacUserService,
    public tokenService:TokenService,
    public routeService:RouteService,
    ) { 
      super(route, router);
      this.obj =  new RBAC_User();
    }

  ngOnInit(): void {
  }

  openLink(){
    this.authenticate.Authenticate(this.obj.Phone, this.obj.Password).then(res => {
      
      this.tokenService.setToken(res['headers'].get('Token'));
      this.router.navigate([this.routeService.getHomeRoute()]);
    }, error => {

    });     
  }

  validate() {
    if (this.obj.FullName == "" || this.obj.FullName == null)
      return true;

    if (this.obj.Phone == "" || this.obj.Phone == null)
      return true;

    if (this.obj.Email == ""  || this.obj.Email == null)
      return true;

    if (this.password == ""  || this.password == null)
      return true;

    if (this.repeatPassword == ""  || this.repeatPassword == null)
      return true;             

    if(this.otherError() == true)
      return true;

    return false;
  }

  errorMessage: string = "";
  otherError() {

    var errorMsg: string = "";

    if (this.password == "" || this.password == null) {
      return false;
    }

    if(this.getPasswordLength() > 0 && this.getPasswordLength() < 8){
      this.errorMessage = "Password length should be between 8-20 characters.";
      return true;
    }

    if (this.repeatPassword == "" || this.repeatPassword == null) {
      return false;
    }

    

    if (this.repeatPassword != this.password) {
      this.errorMessage = "Password and Repeat Password should be same.";
      return true;
    }    

 

    return false;
  }

  Save(): Observable<Object> {
    this.obj.Password = btoa(this.password);
    if (this.primaryKey == 0)
      return this.rbacUserService.post(this.obj); // Inserting Data  
  }

  async AfterSave(){
    this.router.navigate(['/login']);
  }

  getPasswordLength(){
    return this.password ? this.password.length : 0
  }
  

}
