import { Component, OnInit, Inject } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { CustomerService } from '../../../services/customer.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';
import { LoaderService } from 'src/app/services/base/loader.service';
import { ViewDialogDataService } from 'src/app/services/view-dialog-data.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  primaryKey:number = 0;
  obj: Customer;


  constructor(
    public service:CustomerService,
    public dialogService:DialogService,
    public loaderService:LoaderService,

    public viewDialogDataService:ViewDialogDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 

    // Initialize object
    this.obj = new Customer();
    this.loaderService.startLoader();

    }
  
  ngOnInit(): void {
    if(this.data.primaryKey > 0)
      this.getData();
      else 
      this.loaderService.endLoader();
  }

  getData() {    
    this.primaryKey = this.data.primaryKey;
    this.service.getById(this.primaryKey).then(res => {
      this.obj = res as Customer;
      this.loaderService.endLoader();
    });
  }
 
  onSave() {
    this.loaderService.startLoaderOnSave();
    // Inserting Data
    if (this.primaryKey == 0) {
      this.service.post(this.obj).subscribe(res => {
       
        this.loaderService.endLoaderOnSave();
        // this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('sal_04', "save");
        
      });
    }
    else {
      this.service.update(this.obj, this.primaryKey).subscribe(res=>{
        this.loaderService.endLoaderOnSave();
        this.dialogService.closeDialog_List('sal_04', "save");
        // this.dialogService.closeDialog("save");
      }); // Updating Data
    }
  }

  onCustomerBulk(){
   this.viewDialogDataService.openBulkView('sal_04'),
   this.dialogService.closeDialog_List('sal_04',"")
  }
  onDelete() {
    if (this.primaryKey > 0) {
      this.dialogService.openSmallDialog(YesNoComponent);

      this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
        if (result) {
          if (result == "yes") {
            this.service.delete(this.primaryKey).subscribe(res => {
              //this.dialogService.closeDialog("delete");
              this.dialogService.closeDialog_List('sal_04', "delete");
            })
          }
        }
      });

    }
  }

  

}
