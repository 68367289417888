export class Bank_Account {
        ID:number = 0;
        DocumentNo: string = null;
        BankID: number = null;
        AccountName: string = null;
        AccountNo : string = null;
        IBAN : string = null;
        Area : string = null;
        Block : string = null;
        CompanyID : number = null;
        CreatedDate: any = null;
        CreatedBy: number = null;
        ModifiedDate: any = null;
        ModifiedBy: number = null;
}