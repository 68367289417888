<div class="top-ribbon-dialog">
    <app-ribbon-save-dialog [hidden]="client.invalid" (myClick)="onSave()" [valid]="client.invalid"></app-ribbon-save-dialog>
    <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>

<app-my-heading-dialoge mat-dialog-title heading="Supplier Information"></app-my-heading-dialoge>



<form #client="ngForm" class="rounded needs-validation ">

    <div class="row g-2">

            <div class="col-md-12">
                    <app-my-input label="Supplier ID" class="col-md-6" [hidden]="true">
                    </app-my-input>                        
                    <app-my-input label="Supplier Name" class="col-md-6" required="true" name="CustomerName" [(ngModel)]="obj.SupplierName">
                    </app-my-input>                                                
                    <app-my-input label="Phone" class="col-md-6" name="Phone" [(ngModel)]="obj.Phone">
                    </app-my-input>
                    <app-my-input label="City" class="col-md-6" name="City" [(ngModel)]="obj.City">
                    </app-my-input>
            </div>
            <app-my-input label="State" class="col-md-6 col-6" name="State" [(ngModel)]="obj.State">
            </app-my-input>
            <app-my-input label="Zip Code" class="col-md-6 col-6" name="ZipCode" [(ngModel)]="obj.ZipCode">
            </app-my-input>
            <app-my-input label="Address 1" class="col-md-6" name="Address" [(ngModel)]="obj.Address">
            </app-my-input>
            <app-my-input label="Address 2" class="col-md-6" name="Address2" [(ngModel)]="obj.Address2">
            </app-my-input>
            <app-my-input label="Email" class="col-md-6" name="Email" [(ngModel)]="obj.Email">
            </app-my-input>
            <app-my-input label="Website" class="col-md-6"  name="Website" [(ngModel)]="obj.Website">
            </app-my-input>   
     
            
             <!-- <mat-accordion>                        
                <mat-expansion-panel>
                        <mat-expansion-panel-header>
                        <mat-panel-title>Openning Balance</mat-panel-title>                                
                        </mat-expansion-panel-header>
                        <app-my-input-date label="Date" class="col-md-6" name="OpenningDate" [(ngModel)]="obj.OpenningDate">
                        </app-my-input-date>
                        <app-my-input-number label="Amount" class="col-md-6" name="OpenningAmount" [(ngModel)]="obj.OpenningAmount">
                        </app-my-input-number>
                </mat-expansion-panel>
        </mat-accordion>      -->
                                          
    </div>     
    <br><br>   
    <div align="end" >                
            <app-button-save-dialog (myClick)="onSave()" [valid]="client.invalid"></app-button-save-dialog>
    </div>
</form>