<mat-form-field appearance="outline" class="w-100 " >
  <mat-label>{{label}}</mat-label>
  
  <mat-select 
  [appFocus]="isFocused"
  [multiple]="multiple"
  [required]="required" 
  [disabled]="isDisabled"
  #name="ngModel"     
  [(ngModel)]="model"              
  (ngModelChange)="onChange(model)">
    
      <!-- Global overried for .mat-select-panel max-height at style.css -->
			
    <mat-option *ngIf="search">                          
      <ngx-mat-select-search                                                    
      name="searchName"
      #searchName="ngModel"
      [(ngModel)]="searchValue"
      (ngModelChange)="AutoComp.searchResult.next($event)"
      placeholderLabel="Search..." 
      noEntriesFoundLabel="Not Found!">  
      <div class="mat-select-search-custom-header-content float-end">
        <!-- <button mat-button color="primary">
        <mat-icon>add</mat-icon> Create 
      </button> -->
      </div> 
                                                       
      </ngx-mat-select-search>
    </mat-option>
    <mat-option  [value]="">
        -- Clear --
    </mat-option>          
    <mat-option *ngFor="let option of AutoComp.results | async" [value]="option">
        {{getValue(option)}}       
       
    </mat-option> 
   <mat-option class="fixedOption" *ngIf="createOption" (click)="create()">
    <div class="newOptionDiv">
      <hr>
      <mat-icon style="color: #023e8a; margin-right: 0px !important; font-size: 22px;">add</mat-icon>
       Create New {{optionName}}
    </div>   
   </mat-option>        
  </mat-select>  
  <mat-hint>{{hint}}</mat-hint>
  <mat-error *ngIf="name.invalid || name.dirty">This field is required</mat-error>      
</mat-form-field>



<!-- 
<label for="">
  {{label}} <span *ngIf="required" style="color: #f44336;">*</span>
</label>

  <select
  class="form-select form-select-sm  my-2 myinput w-100"
  [required]="required" 
  #name="ngModel"     
  [(ngModel)]="model"              
  (ngModelChange)="onChange($event)">
    <option  [value]=""> - </option>        
    <option  [value]="option" *ngFor="let option of data" >
      {{getValue(option)}}
    </option>
  
  </select> -->



