import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { empty, Observable, of } from 'rxjs';


import { DialogService } from 'src/app/services/base/dialog.service';
import { Router } from '@angular/router';
import { RouteService } from 'src/app/services/base/route.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SearchDialogComponent } from '../Dialog/search-dialog/search-dialog.component';


@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  searchMoviesCtrl = new FormControl();
  EventData: any[] = [];
  isLoading = false;
  errorMsg: string;

  constructor(
    //public homeService: HomeService,
    public dialogService: DialogService,
    public route: Router,
    public routeService: RouteService,
    public dashboardService:DashboardService,
    private _bottomSheet: MatBottomSheet    
  ) { }

  openBottomSheet(): void {
    this._bottomSheet.open(SearchDialogComponent, {
      panelClass:"dheight"
    });
  }

  ngOnInit(): void {
    this.searchMoviesCtrl.setValue('')
    this.searchMoviesCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.EventData = [];
          this.isLoading = true;
        }),

        switchMap((value) =>
          this.search(value)
            .pipe(
              catchError(error => {
                console.log(error);
                return empty;
              }),
              finalize(() => {

                this.isLoading = false
              }),
            )
        )
      )
      .subscribe(data => {
        if (data['value'] == undefined) {
          this.errorMsg = data['Error'];
          this.EventData = [];
        } else {
          this.errorMsg = "";
          this.EventData = data['value'];
        }
        
        console.log(this.EventData);
      },
        (error) => {
          console.log(error);
        }
      );
  }

  search(searchValue: string = ""):Observable<object> {
    if(searchValue == ""){
      this.EventData = [];
      return of();
    }
    

    return this.dashboardService.GlobalSearch(searchValue);
  }

  openGroundProfile(ID: number) {
    this.route.navigate([this.routeService.openRoute('youplay', 'GroundProfile')], { queryParams: { groundID: ID } });
  }

  openPlayerProfile(ID: number) {
    // this.dialogService.openDialogCenter(PlayerProfileComponent, ID);

    // this.dialogService.dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //   }
    // });
  }

  onEventClick(item) {
    // if (item.searchType == "Ground")
    //   this.openGroundProfile(item.ID);
    // else if (item.searchType == "Player")
    //   this.openPlayerProfile(item.ID);

    this.searchMoviesCtrl.setValue('')
  }

  openRoute(route, recordid){
    this.searchMoviesCtrl.setValue('');
    this.route.navigate([route], { queryParams: { ID: recordid } });
    
  }

}
