import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MyFormDialogComponent } from 'src/app/shared/Dialog/my-form-dialog/my-form-dialog.component';
import { SnackbarService } from './snackbar.service';
import { LoaderService } from './loader.service';
import { MyQuickDialogComponent } from 'src/app/shared/Dialog/my-quick-dialog/my-quick-dialog.component';
import { ConfDialogService } from '../conf-dialog.service';

interface IDialogList {
  key:string;
  dialogRef:any;
  scode:string;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dialogRef:any;
  dialogRefSmall:any;
  dialogRefQuickCreate:any;


  constructor(
    public router:Router,
    public dialog: MatDialog,
    public snackBar: SnackbarService,
    public loaderService:LoaderService,
    public confDialogService:ConfDialogService
  ) { }

  openDialog(component, Id?:number, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     this.dialogRef = this.dialog.open(MyFormDialogComponent, {
      disableClose: true ,      
      height:"100%",
      maxHeight:"100vh",
      panelClass:'dialog-panel',
      position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
      data: { component: component, primaryKey: Id, data: data}
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');      

      if(result == "save" || result == "delete")
        this.reloadCurrentRoute();
    });
    
  }

  reloadCurrentRoute(){
    const currentUrl = this.router.url;
    var url:string[] = currentUrl.split('?');
    var qryObj = null;
    if(url.length > 1){
      qryObj = this.getParams(url[1]);
    }

    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      if(qryObj){
        this.router.navigate([url[0]], {queryParams: qryObj });  
      }  
      else
        this.router.navigate([url[0]]);
    });
  }

  getParams(url:string){
    var qp = url.split("&");
    var obj:any = {};
    for (let index = 0; index < qp.length; index++) {
      const element = qp[index];
      var kv = element.split('=')
      if(kv.length > 0){
        obj[kv[0]] = kv[1];
      }      
      
    }

    return obj;

  }


  openDialogForEmployee(component, Id?:number, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     this.dialogRef = this.dialog.open(MyFormDialogComponent, {
      disableClose: true, 
      width:"100%",     
      maxWidth:"500px",   
      height:"100%",
      maxHeight:"100vh",
      panelClass:'dialog-panel',
      position: { right: 0 + 'px', top: 0 + 'px'},   
      autoFocus: false ,
      data: { component: component, primaryKey: Id, data: data}
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');    
      if(result == "save" || result == "delete")
        this.reloadCurrentRoute();  
    });
    
  }

  closeDialog(data?:any){
    if(this.dialogRefQuickCreate && this.dialogRefQuickCreate.componentInstance)
      this.dialogRefQuickCreate.close(data);
    else if(this.dialogRef)
      this.dialogRef.close(data);
    else if(this.dialogRefQuickCreate)
      this.dialogRefQuickCreate.close(data);

    
    if(data == "save" ){
      this.snackBar.openSuccess();
    }
  }

  openPrintDialog(component, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     this.dialogRef = this.dialog.open(MyFormDialogComponent, {
      disableClose: true ,      
      height:"100%",
      maxHeight:"100vh",
      panelClass:'dialog-panel',
      //position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
      data: { component: component, data: data}
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');      
    });
    
  }

  closePrintDialog(data?:any){
    this.dialogRef.close(data);
  }

  openSmallDialog(component, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     this.dialogRefSmall = this.dialog.open(MyFormDialogComponent, {
      //disableClose: true ,      
      //height:"100%",
      //maxHeight:"100vh",
      //panelClass:'dialog-panel',
      //position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
      data: { component: component, data: data }
    });

    this.dialogRefSmall.afterClosed().subscribe(result => {
      console.log('The dialog was closed');      
      // if(result == "save" || result == "delete")
      //   this.reloadCurrentRoute();
    });
    
  }

  closeSmallDialog(data?:any){
    this.dialogRefSmall.close(data);
  }

  openQuickCreateDialog(component, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     this.dialogRefQuickCreate = this.dialog.open(MyQuickDialogComponent, {
      //disableClose: true ,      
      //height:"100%",
      //maxHeight:"100vh",
      //panelClass:'dialog-panel',
      //position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
      data: { component: component, data: data }
    });

    this.dialogRefQuickCreate.afterClosed().subscribe(result => {
      console.log('The dialog was closed');      
      this.loaderService.endLoaderOnSave();
      // if(result == "save" || result == "delete")
      //   this.reloadCurrentRoute();
    });
    
  }

  closeQuickCreateDialog(data?:any){
    this.dialogRefQuickCreate.close(data);
  }

  openQuickCreateDialog_2(component, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     
     this.dialogRefQuickCreate = this.dialog.open(MyQuickDialogComponent, {
      //disableClose: true ,      
      //height:"100%",
      //maxHeight:"100vh",
      //panelClass:'dialog-panel',
      //position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
      data: { component: component, data: data }
    });

    this.dialogRefQuickCreate.afterClosed().subscribe(result => {
      console.log('The dialog was closed');      
      this.loaderService.endLoaderOnSave();
      // if(result == "save" || result == "delete")
      //   this.reloadCurrentRoute();
    });
    
  }


  openFilterDialog(component, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     this.dialogRef = this.dialog.open(MyFormDialogComponent, {
      hasBackdrop:false,
      disableClose: false ,      
      height:"100%",
      maxHeight:"100vh",
      panelClass:'dialog-filter-panel',
      position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
     
      data: { component: component, data: data}
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');      
    });
    
  }


  dialogList:IDialogList[] = [];

  openDialog_List(component, scode:string, Id?:number, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
    //var component_name: string = component.name;
     var dialogRef = this.dialog.open(MyFormDialogComponent, {
      disableClose: true ,      
      height:"100%",
      maxHeight:"100vh",
      panelClass:'dialog-panel',
      position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
      data: { component: component, primaryKey: Id, data: data}
    });

    //var scode = this.confDialogService.getDialog(component_name);
    var objDialog:IDialogList = {
      key:scode,  
      dialogRef:dialogRef,
      scode: scode     
    }

    this.dialogList.push(objDialog); // Push the dialog reference to the array

    this.getDialog_List(scode).afterClosed().subscribe(result => {
      console.log('The dialog was closed');     
      var index = this.dialogList.findIndex(a => a.key == scode); 
      if(index > -1){
        this.dialogList.splice(index, 1);
      }
      if(result == "save" || result == "delete")
        this.reloadCurrentRoute();
    });;

    
  }

  closeDialog_List(key:string, data?:any){
    // if(this.dialogRefQuickCreate && this.dialogRefQuickCreate.componentInstance)
    //   this.dialogRefQuickCreate.close(data);
    // else if(this.dialogRef)
    //   this.dialogRef.close(data);
    // else if(this.dialogRefQuickCreate)
    //   this.dialogRefQuickCreate.close(data);

      this.getDialog_List(key).close(data);
    
    if(data == "save"){
      this.snackBar.openSuccess();
    }
    else if(data == "delete"){
      this.snackBar.openDelete();
    }
  }

  getDialog_List(key:string){
    var obj_arr = this.dialogList.filter(a => a.key == key);
    if(obj_arr.length > 0){
     return obj_arr[0].dialogRef;
    }
  }

  getDialogScode():string{
    // Here we are getting the latest opened dialog scode from the last index
    if(this.dialogList.length > 0)
      return this.dialogList[this.dialogList.length - 1].scode;
    else 
      return '';
      
  }

  openQuickCreateDialog_List(component, scode:string, data?:any): void {

    //var component_name: string = component.name;
     var dialogRefQuickCreate = this.dialog.open(MyQuickDialogComponent, {
      autoFocus: false ,
      data: { component: component, data: data }
    });

    //var scode = this.confDialogService.getDialog(scode);
    var objDialog:IDialogList = {
      key:scode,  
      dialogRef:dialogRefQuickCreate,
      scode: scode
    }

    this.dialogList.push(objDialog); // Push the dialog reference to the array

    this.getDialog_List(scode).afterClosed().subscribe(result => {
      console.log('The dialog was closed');   
      var index = this.dialogList.findIndex(a => a.key == scode); 
      if(index > -1){
        this.dialogList.splice(index, 1);
      }   
      this.loaderService.endLoaderOnSave();
    });;

    
  }

}
