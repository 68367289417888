import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bottom-audit',
  templateUrl: './bottom-audit.component.html',
  styleUrls: ['./bottom-audit.component.scss']
})
export class BottomAuditComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomAuditComponent>
  ) { }

  ngOnInit(): void {
  }

}
