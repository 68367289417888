import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './modules/main/home/home.component';
import { LoginComponent } from './modules/main/login/login.component';
import { SignupComponent } from './modules/main/signup/signup.component';
import { PageNotFoundComponent } from './modules/main/page-not-found/page-not-found.component';
import { LandingPageComponent } from './modules/main/landing-page/landing-page.component';
import { AuthGuardService } from './services/base/auth-guard.service';
import { DashboardComponent } from './modules/main/dashboard/dashboard.component';
import { NewDashComponent } from './modules/main/new-dash/new-dash.component';
import { SalesDashComponent } from './modules/main/sales-dash/sales-dash.component';
import { Salev3DashComponent } from './modules/main/salev3-dash/salev3-dash.component';
import { LoginNewComponent } from './modules/main/login-new/login-new.component';
import { UnauthorizedComponent } from './modules/main/unauthorized/unauthorized.component';
import { AuthGuardHomeGuard } from './services/base/auth-guard-home.guard';
import { SigninComponent } from './modules/main/signin/signin.component';
import { MsalGuard } from '@azure/msal-angular';
import { RouteService } from './services/base/route.service';
import { RegisterComponent } from './modules/main/register/register.component';
import { WelcomeBoardComponent } from './modules/main/welcome-board/welcome-board.component';
import { CustomerDetailComponent } from './modules/main/customer-detail/customer-detail.component';
import { SaleDashboardComponent } from './modules/main/sale-dashboard/sale-dashboard.component';
import { QuickDashboardComponent } from './modules/sale/quick-dashboard/quick-dashboard.component';
import { IdTokenComponent } from './modules/main/id-token/id-token.component';
import { LoaderComponent } from './modules/main/loader/loader.component';



const routes: Routes = [

  
  { path: "", redirectTo: "/app/home", pathMatch: "full" },
  //{ path: "", redirectTo: "/app/hr/dashboard", pathMatch: "full" },    
  {
    path: "app", component: HomeComponent, runGuardsAndResolvers: 'always', 
    children: [
      { path: "home", component: QuickDashboardComponent, canActivate: [ AuthGuardHomeGuard, AuthGuardService, MsalGuard] },  
      { path: "dashboard", component: DashboardComponent, canActivate: [ AuthGuardHomeGuard, MsalGuard]}, 
     
      
      { path: "newdash", component: NewDashComponent, canActivate: [AuthGuardService] },
      { path: "sales-dash", component: SalesDashComponent, canActivate: [AuthGuardService] },
      { path: "salev3-dash", component: Salev3DashComponent, canActivate: [AuthGuardService] },
      { path: 'sale',  loadChildren: () => import('./modules/sale/sale.module').then(m => m.SaleModule), canLoad:[MsalGuard] },
      { path: 'inventory', loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule) , canLoad:[MsalGuard]},
      { path: 'purchase',  loadChildren: () => import('./modules/purchase/purchase.module').then(m => m.PurchaseModule), canLoad:[MsalGuard] },
      { path: 'production',  loadChildren: () => import('./modules/production/production.module').then(m => m.ProductionModule), canLoad:[MsalGuard] },
      { path: 'finance',  loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule), canLoad:[MsalGuard] },
      { path: 'supplier',  loadChildren: () => import('./modules/supplier/supplier.module').then(m => m.SupplierModule), canLoad:[MsalGuard] },
      { path: 'customer',  loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule), canLoad:[MsalGuard] },
      { path: 'finance-report',  loadChildren: () => import('./reports/finance-report/finance-report.module').then(m => m.FinanceReportModule), canLoad:[MsalGuard] },
      { path: 'sale-report',  loadChildren: () => import('./reports/sale-report/sale-report.module').then(m => m.SaleReportModule), canLoad:[MsalGuard] },
      { path: 'purchase-report',  loadChildren: () => import('./reports/purchase-report/purchase-report.module').then(m => m.PurchaseReportModule), canLoad:[MsalGuard] },
      { path: 'inventory-report',  loadChildren: () => import('./reports/inventory-report/inventory-report.module').then(m => m.InventoryReportModule), canLoad:[MsalGuard] },
      //event management
      { path: 'adminpanel',  loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
      //HR
      { path: 'hr',  loadChildren: () => import('./modules/hr/hr.module').then(m => m.HRModule), canLoad:[MsalGuard]  },
      { path: 'user',  loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule), canLoad:[MsalGuard]  },
      { path: 'em',  loadChildren: () => import('./modules/em/em.module').then(m => m.EmModule), canLoad:[MsalGuard]  },
      { path: 'loader', component: LoaderComponent },    
      
    ]
  },

  { path: "signin", component: SigninComponent ,canActivate: [MsalGuard] },
  { path: "state", component: SigninComponent ,canActivate: [MsalGuard] },
  { path: "register", component: RegisterComponent  },
  { path: "login", component: LoginComponent,canActivate: [] },
  { path: "login/new", component: LoginNewComponent },
  { path: "signup", component: SignupComponent },  
  { path: "customerDetail", component: CustomerDetailComponent },  
  { path: "saleDashboard", component: SaleDashboardComponent },  
  { path: "accessdenied", component:UnauthorizedComponent},
  { path: "id_token", component:IdTokenComponent},

  { path: '**', component: PageNotFoundComponent },  
 
  

];

@NgModule({
  imports: [
    RouterModule.forRoot(
    routes, {
      useHash: true, 
      onSameUrlNavigation: 'reload',
     // preloadingStrategy:PreloadAllModules
    })
  ],
  exports: [RouterModule]
})


export class AppRoutingModule { 
  constructor(){}
}

export const RoutingComponents = [
  HomeComponent,
  LoginComponent,
  LandingPageComponent,
]
