export class ItemCategory {
    ID: number = 0;
    CompanyID: number = null;
    CategoryName: string = null;    
    CreatedDate: string = null;
    CreatedBy: number = null;
    CreatedTerminal: string = null;
    CreatedTerminalIp: string = null;
    ModifiedDate: string = null;
    ModifiedBy: number = null;
    ModifiedTerminal: string = null;
    ModifiedTerminalIp: string = null;
}