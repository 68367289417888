<div class="row card-margin">
    <div class="col-md-12 content-area">
        <br>
        <div class="d-flex justify-content-between px-3 mb-2">

            <div>
                <h2>Print</h2>
            </div>
            <div>
                <app-my-button class="me-2" title="Print" icon="print" (click)="printToPdf()"></app-my-button>
                <app-my-button title="OK" icon="done" (click)="onClose()"></app-my-button>
            </div>
        </div>
       
        <iframe
            style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:950px;width:100%;top:0px;left:0px;right:0px;bottom:0px"
            height="100%" width="100%" [src]="frurl" frameborder="0"></iframe>

        <!-- <div style="display: flex;justify-content: space-evenly;">
           
        </div> -->

    </div>
</div>