<mat-form-field appearance="outline" class="w-100" >
        <mat-label>{{label}}</mat-label>
        <input 
        type="text"
        [disabled]="disabled"
        matInput  
        autocomplete="off"
        #name="ngModel"
        [required]="required" 
        [(ngModel)]="model"     
        (ngModelChange)="onChange(model)">    
        <mat-hint>{{hint}}</mat-hint>
        <mat-error *ngIf="name.invalid || name.dirty">This field is required</mat-error>
        
        
        
    </mat-form-field>

    <!-- <label for="">
        {{label}}
        <span *ngIf="required" style="color: #f44336;">*</span>
    </label>
    <input  
    type="text" 
    [disabled]="disabled"
    placeholder="Enter" 
    autocomplete="off" 
    class="form-control form-control-sm my-2 myinput w-100" 
    
    #name="ngModel"
    [required]="required" 
    [(ngModel)]="model"     
    (ngModelChange)="onChange(model)"
    >

    <p *ngIf="name.invalid && (name.dirty || name.touched)" style="color: #f44336;"> This field is required</p> -->
    <!-- <mat-error *ngIf="name.invalid || name.dirty">This field is required</mat-error> -->
  

<!-- <div class="row my-1">
    <div class="col-md-5 d-flex align-self-center">
        <label>{{label}}:</label>
    </div>
    <div class="col-md-7">
        <input type="text" [disabled]="disabled" autocomplete="off" #name="ngModel" class="form-control form-control-sm"
            [required]="required" [(ngModel)]="model" (ngModelChange)="onChange(model)">
    </div>
</div> -->