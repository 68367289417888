import { Component, OnInit, Inject } from '@angular/core';
import { ItemCategory } from 'src/app/models/item-category.model';
import { CategoryService } from 'src/app/services/category.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';
import { DialogBaseComponent } from '../../base/dialog-base/dialog-base.component';
import { LoaderService } from 'src/app/services/base/loader.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  primaryKey:number = 0;
  obj: ItemCategory;


  constructor(
    public service:CategoryService,    
    public dialogService:DialogService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.obj = new ItemCategory();
    this.loaderService.startLoader();
  }

  ngOnInit(): void {
    if(this.data.primaryKey > 0)
        this.getData();
        else 
      this.loaderService.endLoader();
  }

  getData() {    
    this.primaryKey = this.data.primaryKey;
    this.service.getById(this.primaryKey).then(res => {
      this.obj = res as ItemCategory; 
      this.loaderService.endLoader();     
    });
  }

  onSave() {
    // Inserting Data   
    this.loaderService.startLoaderOnSave();     
    if (this.primaryKey == 0) {
      this.service.post(this.obj).subscribe(res => {
        // this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('inv_02', "save");
      });
    }
    else {
      this.service.update(this.obj, this.primaryKey).subscribe(res=>{
        this.loaderService.endLoaderOnSave();
        this.dialogService.closeDialog_List('inv_02', "save");
        // this.dialogService.closeDialog("save");
      }); // Updating Data
    }
  }

  onDelete() {
    if (this.primaryKey > 0) {
      this.dialogService.openSmallDialog(YesNoComponent);

      this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
        if (result) {
          if (result == "yes") {
            this.service.delete(this.primaryKey).subscribe(res => {
              //this.dialogService.closeDialog("delete");
              this.dialogService.closeDialog_List('inv_02', "delete");
            })
          }
        }
      });

    }
  }

}
