import { Injectable } from '@angular/core';
import * as customConfig from 'src/assets/files/customConfig.json';
import { AuthenticateService } from './authenticate.service';
import { TokenService } from './token.service';
import { MsalService } from '@azure/msal-angular';
import { LocalStorageService } from './local-storage.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class SignoutService {

  constructor(
    private authenticate:AuthenticateService,
    private authService: MsalService,
    private localStorageService: LocalStorageService,
    private loaderService:LoaderService
  ) { }

  signout(){
    this.loaderService.startLoaderOnSave();
    this.authenticate.isLoggedIn = false;
    this.localStorageService.removeCurrentUser();
    this.authService.logoutRedirect({
      postLogoutRedirectUri: customConfig.appurl
    });
  }
}
