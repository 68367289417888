import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { element } from 'protractor';
import { Item } from 'src/app/models/item.model';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { ItemTypeService } from 'src/app/services/inventory/item-type.service';
import { ItemService } from 'src/app/services/item.service';
import { UnitService } from 'src/app/services/unit.service';
import { ErrorDialogComponent } from 'src/app/shared/Dialog/error-dialog/error-dialog.component';
import { InfoDialogComponent } from 'src/app/shared/Dialog/info-dialog/info-dialog.component';
import { MessageboxComponent } from 'src/app/shared/Dialog/messagebox/messagebox.component';
import { SuccessDialogComponent } from 'src/app/shared/Dialog/success-dialog/success-dialog.component';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';

@Component({
  selector: 'app-item-bulk-screen',
  templateUrl: './item-bulk-screen.component.html',
  styleUrls: ['./item-bulk-screen.component.scss']
})
export class ItemBulkScreenComponent implements OnInit {


  primaryKey:number = 0;
  obj: Item;
  dataItem : any[] = [];

  selectedItem: any = null;

  dataCatogery: any[] = [];

  selectedCatogery : any = null;

  dataBrand: any [] = [];
  selectedBrand: any = null;

  dataItemType: any [] = [];
  selectedItemType: any = null;

  dataUnit: any [] = [];
  selectedUnit: any = null;



  objDetail: Item_Detail_DTO[] = [];

  constructor(
    public unitService:UnitService,

    public itemService:ItemService,
    public hlp : ConfHelpService,

    
    public loaderService:LoaderService,

    public itemTypeService : ItemTypeService,


    public dialogService:DialogService,

    public dialog:MatDialog

  ) { 
    
  }

  

  ngOnInit(): void {
    Promise.all([
     this.getItem(),
     this.getCatogery(),
     this.getBrand(),
     this.getUnit(),
     this.getItemType(),
     this.setDefaultValues(),
    ])
  }

async  getItem(){
    await this.hlp.getFormDataByHelpNo("invitem").toPromise().then(res =>{
      this.dataItem = res['value'] as any[];

    })
  }

  async getItemType(){
    await this.itemTypeService.getAll().then(res=> {
      this.dataItemType = res['value'] as any[];
    })

  }

 async getCatogery(){
   await this.hlp.getFormDataByHelpNo("invitemcategory").toPromise().then(res =>{
      this.dataCatogery = res['value'] as any [];
    } )
}

async getBrand(){
 await this.hlp.getFormDataByHelpNo("invmake").toPromise().then(res =>{
    this.dataBrand = res ['value'] as any[];
  })
}

async getUnit(){
  await this.hlp.getFormDataByHelpNo("invunit").toPromise().then(res =>{
    this.dataUnit = res['value'] as any[];
  })
}
  onItemAdd(){
    this.objDetail.push(new Item_Detail_DTO());
  }

  

  ValidateBeforeSave():boolean{
    var isValid:boolean = true;

    
    if (this.objDetail.length == 0) {
      isValid = false;
      this.dialogService.openSmallDialog(MessageboxComponent, "Atleast 1 row required.");
      return isValid;
    }

 

    for (let index = 0; index < this.objDetail.length; index++) {
      
      const element = this.objDetail[index];
      var LineNo = index + 1

      if(index == 0){
        if (element.ItemName == null || element.ItemName == "") {
          isValid = false;
          this.dialogService.openSmallDialog(MessageboxComponent, "Item Name is Required  at Line # : " + LineNo);
          break;
        }
      }
      
     
      if(element.ItemName != null && element.ItemName != "") {
        if(element.selectedUnit == null  || element.selectedUnit.length == 0 ){
          isValid = false;
          this.dialogService.openSmallDialog(MessageboxComponent, "Unit is Required  at Line # : " + LineNo);        
          break;
        }
        else {
          if (element.selectedUnit[0].ID == null || element.selectedUnit[0].ID == undefined) {
            isValid = false;
            this.dialogService.openSmallDialog(MessageboxComponent, "Unit is Required  at Line # : " + LineNo);
            break;
          }
        }
  
        if(element.selectedCatogery == null  || element.selectedCatogery.length == 0 ){
          isValid = false;
          this.dialogService.openSmallDialog(MessageboxComponent, "Category is Required  at Line # : " + LineNo);        
          break;
        }
        else {
          if (element.selectedCatogery[0].ID == null || element.selectedCatogery[0].ID == undefined) {
            isValid = false;
            this.dialogService.openSmallDialog(MessageboxComponent, "Category is Required  at Line # : " + LineNo);
            break;
          }
        }
  
        if(element.selectedBrand == null  || element.selectedBrand.length == 0 ){
          isValid = false;
          this.dialogService.openSmallDialog(MessageboxComponent, "Brand is Required  at Line # : " + LineNo);        
          break;
        }
        else {
          if (element.selectedBrand[0].ID == null || element.selectedBrand[0].ID == undefined) {
            isValid = false;
            this.dialogService.openSmallDialog(MessageboxComponent, "Category is Required  at Line # : " + LineNo);
            break;
          }
        }
  
        if(element.selectedItemType == null  || element.selectedItemType.length == 0 ){
          isValid = false;
          this.dialogService.openSmallDialog(MessageboxComponent, "ItemType is Required  at Line # : " + LineNo);        
          break;
        }
        else {
          if (element.selectedItemType[0].ID == null || element.selectedItemType[0].ID == undefined) {
            isValid = false;
            this.dialogService.openSmallDialog(MessageboxComponent, "ItemType is Required  at Line # : " + LineNo);
            break;
          }
        }
        
      }

     

    }
      
      

      return isValid;
  }


  onSave() {

    var isValidForSave = this.ValidateBeforeSave();
    if (isValidForSave == false)
      return;

   

    var item_arr:Item[] =[];

    for (let index = 0; index < this.objDetail.length; index++) {
      const element = this.objDetail[index];
      var dtl = new Item();
      if(element.ItemName){
        dtl.ItemName = element.ItemName;
        dtl.UnitID = element.selectedUnit[0].ID;
        dtl.ItemCategoryID = element.selectedCatogery[0].ID;
        dtl.ItemTypeID =  element.selectedItemType[0].ID;
        dtl.MakeID =  element.selectedBrand[0].ID;
        dtl.CostPrice =  element.CostPrice;
        dtl.SalePrice =  element.SalePrice;   
        item_arr.push(dtl);
      }
     
    }

    this.loaderService.startLoaderOnSave();
    this.itemService.upsert(item_arr).subscribe(res => {
      this.dialogService.closeDialog_List('inv_01', "save");
       this.loaderService.endLoaderOnSave(); 
    })
    

  }
  onRowDelete(index){
    
    this.dialogService.openSmallDialog(YesNoComponent);
  
    this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
      if (result) {
        if (result == "yes") {
          this.objDetail.splice(index, 1);      
        }
      }
    });    
  }

  onInfoDialog(){
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      width: '600px',
      height: 'auto',
      panelClass: 'custom-mat-dialog-container'

    });
  }

  setDefaultValues(){
    for (let index = 0; index < 10; index++) {
      var obj = new Item_Detail_DTO;    
      this.objDetail.push(obj);
    }
  }

  onRowDuplicate(index: number) {
    const duplicatedItem = { ...this.objDetail[index] }; // Deep copy 
    this.objDetail.splice(index + 1, 0, duplicatedItem); 
  }

}


export class Item_Detail_DTO{
  ID: number = 0;

  ItemCode: string = null;
  ItemName: string = null;

  CategoryName: string = null;    
  ItemTypeID: number = null;
  ItemCategoryID: number = null;
  MakeName: string = null;
  MakeID:number = null;

  ItemTypeName: string = null;
  UnitID: number = null;
  CostPrice: number = null;

  
  UnitName: string = null;
  SalePrice: number = null;
    
  CreatedDate: string = null;
  CreatedBy: number = null;
  CreatedTerminal: string = null;
  CreatedTerminalIp: string = null;
  ModifiedDate: string = null;
  ModifiedBy: number = null;
  ModifiedTerminal: string = null;
  ModifiedTerminalIp: string = null;
  selectedUnit:any = null;
  selectedCatogery:any = null;
  selectedBrand:any = null;

  selectedItemType:any = null;
}
