<div class="d-flex justify-content-between align-items-end">
        <h2 class="ms-2 d-flex align-items-center ">
                <mat-icon class="cstmReportIcon me-3">report</mat-icon>
               <span>
                        Alerts!
               </span> 
        </h2>
        <button class="me-2 closeBtn" mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>


<br>

<mat-chip-list aria-label="Data" class="my-3">
        <mat-chip [ngClass]="{'selectedChip': selectedChip == 'cheque'}" [matBadge]="dataChequeDue.length" [matBadgeHidden]="dataChequeDue.length == 0" (click)="selectedChip = 'cheque'">Available Cheques</mat-chip>
        <mat-chip [ngClass]="{'selectedChip': selectedChip == 'payment'}" [matBadge]="dataDueDays.length" [matBadgeHidden]="dataDueDays.length == 0" (click)="selectedChip = 'payment'">Payment Dues within 3 days</mat-chip>
        <mat-chip [ngClass]="{'selectedChip': selectedChip == 'issued'}" [matBadge]="datachequeissued.length" [matBadgeHidden]="datachequeissued.length == 0" (click)="selectedChip = 'issued'">Cheque Issued</mat-chip>
</mat-chip-list>
<br>

<div class="content d-flex flex-column ">
       
      <!-- <div [hidden]="selectedChip != 'payment'" class="listDiv" *ngFor="let data of dataDueDays;">
                <div class="iconDiv col-md-1">
                        <mat-icon class="cstmReportIcon">report</mat-icon>
                </div>
                <div class="col-md-10">
                        <div class="msgContent">
                        <span class="cstmBold"> {{data?.Dimension}} </span>  has a payment due on <span class="cstmBold">{{data?.DueDate | date}}</span>
                        for <span class="cstmBold text-decoration-underline"> {{data?.InvoiceNo}} </span> 
                       
                        </div>
                </div>
        </div> -->
          <!-- 
        <div [hidden]="selectedChip != 'cheque'" class="listDiv" *ngFor="let data of dataChequeDue;">
                <div class="iconDiv col-md-1">
                        <mat-icon class="cstmReportIcon">report</mat-icon>
                </div>
                <div class="col-md-10">
                        <div class="msgContent">
                        <span> Your Cheque No. </span> <span class="cstmBold text-decoration-underline">{{data?.ChequeNo}}</span>
                        is available for collection on <span class="cstmBold"> {{data?.ChequeDate | date}} </span> 
                       
                        </div>
                </div>
        </div> -->

        <table *ngIf="selectedChip == 'cheque'" class="table table-striped table-bordered w-100">
                <thead>
                        <tr>
                                <th width="70" class="text-center">S #</th>
                               
                                <th width="300">Cheque #</th>
                                <th>Cheque Date</th>
                                <th>Cheque Amount</th>
                                <th>Description</th>
                        </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let item of dataChequeDue; let i = index" class="">
                                <td class="text-center">{{i + 1}}</td>
                               
                                <td class="d-flex justify-content-between  align-content-center ">
                                       <span>
                                        {{item.ChequeNo}}
                                       </span> 
                                     
                                </td>
                                <td>{{item.ChequeDate | date:'dd/MM/yyyy'}}</td>
                                <td>{{item.Amount | number}}</td>
                                <td>{{item.Description}}</td>
                        </tr>
                </tbody>
        </table>

        <table *ngIf="selectedChip == 'payment'" class="table table-striped table-bordered w-100">

                <thead>
                        <tr>
                                <th width="70" class="text-center">S #</th>
                               
                                <th width="300">Invoice #</th>
                                <th>Invoice Date </th>
                                <th>Customer Name</th>
                        </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let data of dataDueDays; let i = index" class="">
                                <td class="text-center">{{i + 1}}</td>
                               
                                <td class="d-flex justify-content-between  align-content-center ">
                                       <span>
                                        {{data.InvoiceNo}}
                                       </span> 
                                     
                                </td>
                                <td>{{data.DueDate | date:'dd/MM/yyyy'}}</td>
                                <td>{{data.Dimension }}</td>
                        </tr>
                </tbody>

        </table>



        <table *ngIf="selectedChip == 'issued'" class="table table-striped table-bordered w-100">

                <thead>
                        <tr>
                                <th width="70" class="text-center">S #</th>
                               
                                <th width="300">Cheque #</th>
                                <th>Cheque Date </th>
                                <th>Issued To</th>
                                <th>Bank</th>
                                <th>Amount</th>
                                <th>Description</th>
                        </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let data of datachequeissued; let i = index" class="">
                                <td class="text-center">{{i + 1}}</td>
                               
                                <td class="d-flex justify-content-between  align-content-center ">
                                       <span>
                                        {{data.ChequeNo}}
                                       </span> 
                                     
                                </td>
                                <td>{{data.ChequeDate | date:'dd/MM/yyyy'}}</td>
                                <td>{{data.SupplierName }}</td>
                                <td>{{data.BankName }}</td>
                                <td>{{data.DebitAmount }}</td>
                                <td>{{data.Description }}</td>

                                
                        </tr>
                </tbody>

        </table>

        <!-- <div *ngIf="dataDueDays.length == 0 && dataChequeDue.length == 0">
                <h3 class="text-center ">No dues within 3 days!</h3>
        </div>
      
        <div *ngIf="dataChequeDue.length == 0">
                <h3 class="text-center ">No Cheques available!</h3>
        </div> -->
</div>
