import { Component, OnInit, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ThemeService } from 'src/app/services/base/theme.service';
import { BottomAuditComponent } from '../../audit-info/bottom-audit/bottom-audit.component';

@Component({
  selector: 'app-my-heading-dialoge',
  templateUrl: './my-heading-dialoge.component.html',
  styleUrls: ['./my-heading-dialoge.component.scss']
})
export class MyHeadingDialogeComponent implements OnInit {  
  
  @Input() heading:string ="";
  DocumentNo:string = "";

  constructor(
    public theme:ThemeService, 
    private bottomSheet: MatBottomSheet

  ) { }

  ngOnInit(): void {
  }

  openBottomSheet(){
    this.bottomSheet.open(BottomAuditComponent)
  }

  setDocumentNo(obj:any){
    this.DocumentNo = obj;
  }


}
