import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public isLoading:BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

  loading:boolean = false;
  constructor(
    private spinner: NgxSpinnerService,
  ) { }
  
  startLoader() {
    this.loading = true;
  }

  endLoader() {
    this.loading = false;
  }

  startLoaderOnSave() {
    this.spinner.show();
  }

  endLoaderOnSave() {
    this.spinner.hide();
  }

}
