import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RBAC_User } from 'src/app/models/rbac_user.model';

import * as customConfig from 'src/assets/files/customConfig.json';
import { MsalService } from '@azure/msal-angular';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  
  //readonly rootUrl: string = "http://192.168.1.101:45455/";
  // readonly rootUrl: string = "http://localhost:57046/";
  //readonly rootUrl: string = "https://bmsapi.samy-soft.com/";
  readonly rootUrl: string = customConfig.api;


  isLoggedIn: boolean = false;
  user: RBAC_User = null;
  permissionSection: any[] = [];

  userPermissions: any [] = [];

  constructor(public http: HttpClient,) { }

  async Authenticate(userName: any, password: any) {
    const httpOptions = {
      headers: new HttpHeaders({        
        'Authorization': 'Basic ' + btoa(userName + ':' + password),       
      })
      , observe: 'response' as 'response'
    };
    
    return await this.http.post(this.rootUrl + 'authenticate', null, httpOptions).toPromise()      
  }

  

  async logout() {    
    
    return await this.http.post(this.rootUrl + 'logout', null).toPromise()      

   


  }
  
}
