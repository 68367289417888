<div></div>
<div class="container-fluid">
        <div class="d-flex justify-content-between">
                <h2 style="color:rgb(90, 90, 104)">Sales Dashboard</h2>
        
                <div class="d-flex gap-3 align-items-center ">
                        <button type="button" class="btnDaysFilter" [class.active]="selectedButton === 0"
                                (click)="setDaysAndGetData(0)">Today</button>
                        <button type="button" class="btnDaysFilter" [class.active]="selectedButton === -7"
                                (click)="setDaysAndGetData(-7)">Last 7 Days</button>
                        <button type="button" class="btnDaysFilter" [class.active]="selectedButton === -15"
                                (click)="setDaysAndGetData(-15)">Last 15 Days</button>
                        <button type="button" class="btnDaysFilter" [class.active]="selectedButton === -30"
                                (click)="setDaysAndGetData(-30)">Last 30 Days</button>
                        <button type="button" class="btnDaysFilter" [class.active]="selectedButton === 'ALL'"
                                (click)="setDaysAndGetData('ALL')">ALL</button>
                </div>
        
        </div>
        <mat-divider class="mt-3 mb-3"></mat-divider>

        <div class="row gx-2">
                <div class="col-md-8 d-flex gap-2 flex-nowrap">
                        <div class="cstmWidthofCard">
                                <div class="card cardHeight shadow-sm">
                
                                        <div class="cardFlex">
                                                <div class="titleText">
                                                        <span><mat-icon color="iconReceipt">receipt_long</mat-icon></span>
                                                        <span>Total Sales</span>
                                                </div>
                
                
                                                <div class="d-flex align-items-baseline ms-2">
                                                        <div class="amountDiv me-1"> {{dataAllSales | number}} </div>
                                                        <p class="text-muted">PKR</p>
                                                </div>
                
                
                
                                                <div class="d-flex align-items-end growthSubText">
                                                        <mat-icon class="me-1" style="color: #43cca8; font-weight: 500 !important;">
                                                                trending_up</mat-icon>
                                                        <div class="me-2">13%</div> <span style="color:rgb(106, 106, 125)"> this month
                                                        </span>
                
                                                </div>
                
                                        </div>
                
                
                
                
                
                                </div>
                        </div>
                        <div class="cstmWidthofCard">
                                <div class="card cardHeight shadow-sm">
                
                                        <div class="cardFlex">
                                                <div class="titleText">
                                                        <span><mat-icon color="iconCustomer">group</mat-icon></span>
                                                        <span>Total Customers</span>
                                                </div>
                
                
                                                <div class="d-flex align-items-baseline ms-2">
                                                        <div class="amountDiv me-1"> {{dataAllCustomer | number}} </div>
                                                        <p class="text-muted">CU</p>
                                                </div>
                
                
                
                                                <div class="d-flex align-items-end growthSubText">
                                                        <mat-icon class="me-1" style="color: #43cca8; font-weight: 500 !important;">
                                                                trending_up</mat-icon>
                                                        <div class="me-2">2%</div> <span style="color:rgb(106, 106, 125)"> this month
                                                        </span>
                
                                                </div>
                
                                        </div>
                
                
                
                
                
                                </div>
                        </div>
                        <div class="cstmWidthofCard">
                                <div class="card cardHeight shadow-sm">
                
                                        <div class="cardFlex">
                                                <div class="titleText">
                                                        <span><mat-icon color="iconProducts">inventory</mat-icon></span>
                                                        <span>Total Products</span>
                                                </div>
                
                
                                                <div class="d-flex align-items-baseline ms-2">
                                                        <div class="amountDiv me-1"> {{dataTotalProduct | number}} </div>
                                                        <p class="text-muted">PR</p>
                                                </div>
                
                
                
                                                <div class="d-flex align-items-end noGrowthSubText">
                                                        <mat-icon class="me-1"
                                                                style="color:rgb(106, 106, 125); font-weight: 500 !important;">
                                                                trending_flat</mat-icon>
                                                        <div class="me-2">0</div> <span style="color:rgb(106, 106, 125)"> this month
                                                        </span>
                
                                                </div>
                
                                        </div>
                
                
                
                
                
                                </div>
                        </div>
                </div>
                <!-- <div class="col-md-2">
                        <div class="card cardHeight shadow-sm">
                                
                                <div class="cardFlex">
                                        <div class="titleText">
                                                <span><mat-icon color="iconExpense">account_balance_wallet</mat-icon></span>
                                              <span>Total Expense</span>  
                                        </div>


                                        <div class="d-flex align-items-baseline ms-2">
                                                <div class="amountDiv me-1"> 21,805 </div>
                                                <p class="text-muted">PKR</p>
                                        </div>
                                
                                
                                
                                        <div class="d-flex align-items-end badGrowthSubText">
                                                <mat-icon class="me-1" style="color: #ff4444; font-weight: 500 !important;">
                                                        trending_down</mat-icon>
                                                <div class="me-2">9%</div> <span style="color:rgb(106, 106, 125)"> this month </span>
                                
                                        </div>
                                
                                </div>
                                
                                



                        </div>
                </div> -->
                <div class="col-md-4">
                        <div class="card cardHeightRevenue shadow-sm">
                                
                                <div class="cardFlex">
                                        <div class="titleText">
                                                <span><mat-icon color="iconRevenue">currency_exchange</mat-icon></span>
                                              <span>Total Revenue</span>  
                                        </div>


                                        <div class="d-flex align-items-baseline ms-2">
                                                <div class="amountDiv me-1"> {{dataTotalRevenue | number}} </div>
                                                <p class="text-muted">PKR</p>
                                        </div>
                                
                                
                                
                                        <div class="d-flex align-items-end growthSubText">
                                                <mat-icon class="me-1" style="color: #43cca8; font-weight: 500 !important;">
                                                        trending_up</mat-icon>
                                                <div class="me-2">3%</div> <span style="color:rgb(106, 106, 125)"> this month </span>
                                
                                        </div>
                                
                                </div>
                                
                                



                        </div>
                </div>

        </div>
        <div class="row gx-2 mt-2">
                <div class="col-md-8">

                      
                <div id="chart" class="card iconCardsWelcome shadow-sm">
                        <div class="titleText">Total Sales</div>
                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                        [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid" [stroke]="chartOptions.stroke"
                        [markers]="chartOptions.markers"
                        [fill]="chartOptions.fill">
                </apx-chart>
                </div>
                </div>
                <div class="col-md-4">
                        <div class="card cardTopTableHeight shadow-sm">
                                <div class="titleText align-items-start"><mat-icon style="font-size: 18px; color:#ff9e3d">star</mat-icon> Top Products</div>
                                <div class="table-responsive scrollColor scrollHide">
                                        <table class="table topProdTable">
                                                <thead>
                                                        <tr>
                                                                <th> Product</th>
                                                                <th>Unit Sold</th>
                                                                <th>Sales</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        <tr *ngFor="let data of dataTopProducts">
                                                                <td> {{data?.ItemName}}</td>
                                                                <td>{{data?.UnitSold | number}}</td>
                                                                <td>{{data?.Sales | number}}<sup class="ms-1" style="font-size:11px">PKR</sup></td>
                                                        </tr>

                                                        <tr *ngIf="dataTopProducts.length === 0">
                                                        <td style="color: rgb(118, 118, 118) !important;"> No data!</td>
                                                        </tr>
                                        
                                                </tbody>

                                        </table>
                                </div>
                                
                        </div>
                </div>
        </div>
        <div class="row gx-2 mt-2 mb-3">
                <div class="col-md-8">
                        <div class="card cardTopTableHeight shadow-sm">
                                <div class="titleText align-items-start mb-1"> Recent Orders</div>
                                <div class="table-responsive scrollColor scrollHide">
                                        <table class="table orderTable">
                                                <thead>
                                                <tr>
                                                        <th>Doc No.</th>
                                                        <th>Date</th>
                                                        <th>Customer</th>
                                                        <th>Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                        <tr *ngFor="let data of dataRecentOrders">
                                                        <td>
                                                                <span (click)="onLinkClick(data)">
                                                                        {{data?.DocNo}}
                                                                </span>
                                                        </td>
                                                        <td>{{data?.TransactionDate}}</td>
                                                        <td>{{data?.CustomerName}}</td>
                                                        <td>{{data?.Amount | number}}</td>
                                                       
                                                        
                                                        </tr>
                                                      
                                                </tbody>
                                        </table>

                                </div>
                        </div>
                </div>

                <div class="col-md-4">
                        <div id="chart2" class="card iconCardsWelcome shadow-sm">
                                <div class="titleText">Customers Type</div>
                                <apx-chart
                                [series]="radialChartOptions.nonSeries"
                                [chart]="radialChartOptions.chart"
                                [plotOptions]="radialChartOptions.plotOptions"
                                [labels]="radialChartOptions.labels"
                                [legend]="radialChartOptions.legend"
                                [colors]="radialChartOptions.colors"
                                [responsive]="radialChartOptions.responsive"
                              ></apx-chart>
                        </div>
                </div>
        </div>

</div>