import { Injectable } from '@angular/core';
// import { QuickJournalVoucherComponent } from '../modules/finance/quick-journal-voucher/quick-journal-voucher.component';
// import { ItemComponent } from '../modules/inventory/item/item.component';
// import { CustomerComponent } from '../modules/customer/customer/customer.component';
// import { GLComponent } from '../modules/finance/gl/gl.component';
// import { BrandsComponent } from '../modules/inventory/brands/brands.component';
// import { CategoryComponent } from '../modules/inventory/category/category.component';
// import { SupplierComponent } from '../modules/supplier/supplier/supplier.component';
// import { BankAccountComponent } from '../modules/finance/bank-account/bank-account.component';
// import { BankComponent } from '../modules/finance/bank/bank.component';
// import { PlantsComponent } from '../modules/inventory/plants/plants.component';
// import { UnitsComponent } from '../modules/inventory/units/units.component';
// import { BrokerComponent } from '../modules/sale/broker/broker.component';


interface IDialogConfInterface{
  scode:string;
  component_name:string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfDialogService {

  dialogsConf:IDialogConfInterface[] = [
    { scode: 'inv_01', component_name:'ItemComponent' },
    { scode: 'fin_04', component_name: 'QuickJournalVoucherComponent' },
    { scode: 'sal_04', component_name: 'CustomerComponent' },
    { scode: 'pur_01', component_name: 'SupplierComponent' },
    { scode: 'inv_02', component_name: 'CategoryComponent' },
    { scode: 'inv_05', component_name: 'BrandsComponent' },
    { scode: 'fin_01', component_name: 'GLComponent' },
    { scode: 'fin_02', component_name: 'BankComponent' },
    { scode: 'fin_03', component_name: 'BankAccountComponent' },
    { scode: 'inv_03', component_name: 'UnitsComponent' },
    { scode: 'inv_04', component_name: 'PlantsComponent' },
    { scode: 'sal_06', component_name: 'BrokerComponent' },
  ]

  constructor() { }

  getDialog(component_name:string){
    return this.dialogsConf.filter(a => a.component_name == component_name)[0].scode;
  }

  
}
