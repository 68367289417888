

<div class="top-ribbon-dialog">
    <app-ribbon-save-dialog [hidden]="Bank.invalid" (myClick)="onSave()" [valid]="Bank.invalid"></app-ribbon-save-dialog>
    <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>

<app-my-heading-dialoge mat-dialog-title heading="Bank Information"></app-my-heading-dialoge>

<form #Bank="ngForm" class="rounded needs-validation">
    <div class="row g-2">

        <div class="col-md-12">
           
                
           
              
                    
                    <app-my-input label="Bank Name" class="col-md-6" name="BankName" required="true" [(ngModel)]="obj.BankName">
                    </app-my-input>
                    <app-my-input label="Contact # " class="col-md-6" name="ContactNo" [(ngModel)]="obj.ContactNo">
                    </app-my-input>
                    <app-my-input-number label="Fax" class="col-md-6"  name="Fax"
                    [(ngModel)]="obj.Fax">
                    </app-my-input-number>
                    <app-my-input label="Email" class="col-md-6" name="Email" [(ngModel)]="obj.Email">
                    </app-my-input>
                    <app-my-input label="Website" class="col-md-6" name="Website" [(ngModel)]="obj.Website">
                    </app-my-input>
            
         

        </div>

    </div>

    <br><br>   
        <div align="end" >                
                <app-button-save-dialog (myClick)="onSave()" [valid]="Bank.invalid"></app-button-save-dialog>
        </div>

   
  



</form>