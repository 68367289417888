import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from 'src/app/services/base/permission.service';
import { ScreenConfService } from 'src/app/services/base/screen-conf.service';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-ribbon-edit',
  templateUrl: './ribbon-edit.component.html',
  styleUrls: ['./ribbon-edit.component.scss']
})
export class RibbonEditComponent implements OnInit {
  
  @Output() myClick = new EventEmitter<any>();
  edit:boolean=true;

  constructor(
    public theme:ThemeService,
    public permissionService:PermissionService,
    public screenConfService: ScreenConfService,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getEditperimssion();
  }

  onClick(){
    this.myClick.emit();
  }

  getEditperimssion() {
    
    this.permissionService.getDeletePerm(this.screenConfService.getscodeAc(this.activatedRoute)).then(res => {
      var hasRole = res['value'] as any[];
      //this.edit = hasRole.length > 0 ? hasRole[0].HasPermission : false;
    });
  }


}
