import { RBAC_User_Group } from "./Users/rbac_user_group.model";

export class RBAC_User {
    ID: number = 0;
    FullName: string = null;
    CompanyID: number = null;
    DocumentNo: string = null;
    Phone: string = null;
    Email: string = null;
    Password: string = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    CreatedDate: any = null;
    ModifiedDate: any = null;
    IsSysAdmin: boolean = null;
    UserObjectId: string = null;
    IsOwner: string = null;
    RBAC_User_Group:RBAC_User_Group[] = [];

}
