<div class="container-fluid">
    <div class="row mb-5">
        <div class=" shadow col-md-12 card upperHead">
            <h1 class="mb-2"> Dashboard </h1>
            <h4 class="mb-4">Welcome, Ahmed </h4>
        </div>

    </div>

    <div class="col-md-3">
        <app-my-select  
        [(ngModel)]="selectedPeriod" 
        label="Select Period"
         title="name" 
        [data]="data"
        (valueChanged)="getData()">             
        </app-my-select> 
    </div>

    <div class="row gx-5 gy-4 mb-4">
        <div class="col-md-3">
            <div class="card shadow iconCards">
                <div class="d-flex align-items-center mt-3 mb-2">
                    <div class="iconDiv me-5">
                        <mat-icon class="iconSize">price_check</mat-icon>
                    </div>
                    <div class="textDiv">
                        <h2 class="mainH2"> Gross Profit </h2>
                        <h4 class="fw-bold"> 130,220 PKR </h4>
                        
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-md-3">
            <div class="card shadow iconCards">
                <div class="d-flex align-items-center mt-3 mb-2">
                    <div class="iconDiv me-5">
                        <mat-icon class="iconSize">inventory_2</mat-icon>
                    </div>
                    <div class="textDiv">
                        <h2 class="mainH2"> Total Stock </h2>
                        <h4 class="fw-bold" *ngIf="ShowStock"> {{dataStock}} </h4>
                        <mat-spinner *ngIf="!ShowStock"></mat-spinner>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-md-3">
            <div class="card shadow iconCards">
                <div class="d-flex align-items-center mt-3 mb-2">
                    <div class="iconDiv me-5">
                        <mat-icon class="iconSize">local_atm</mat-icon>
                    </div>
                    <div class="textDiv">
                        <h2 class="mainH2"> Total Expense </h2>
                        <h4 class="fw-bold"> 40,000 PKR </h4>
                    </div>
                </div>
              
            </div>
        </div>
        <div class="col-md-3">
            <div class="card shadow iconCards">
                <div class="d-flex align-items-center mt-3 mb-2">
                    <div class="iconDiv me-5">
                        <mat-icon class="iconSize">warehouse</mat-icon>
                    </div>
                    <div class="textDiv">
                        <h2 class="mainH2"> Plants </h2>
                        <h4 class="fw-bold"> {{dataPlant}} </h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card shadow iconCards">
                <div class="d-flex align-items-center mt-3 mb-2">
                    <div class="iconDiv me-5">
                        <mat-icon class="iconSize">handshake</mat-icon>
                    </div>
                    <div class="textDiv">
                        <h2 class="mainH2"> Suppliers </h2>
                        <h4 class="fw-bold"> {{dataSupplier}} </h4>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-md-3">
            <div class="card shadow iconCards">
                <div class="d-flex align-items-center mt-3 mb-2">
                    <div class="iconDiv me-5">
                        <mat-icon class="iconSize">emoji_people</mat-icon>
                    </div>
                    <div class="textDiv">
                        <h2 class="mainH2"> Customers </h2>
                        <h4 class="fw-bold"> {{dataCustomer}} </h4>
                    </div>
                </div>
             
            </div>
        </div>
        <div class="col-md-3">
            <div class="card shadow iconCards">
                <div class="d-flex align-items-center mt-3 mb-2">
                    <div class="iconDiv me-5">
                        <mat-icon class="iconSize">low_priority</mat-icon>
                    </div>
                    <div class="textDiv">
                        <h2 class="mainH2"> Sales Return </h2>
                        <h4 class="fw-bold"> {{dataSR}} PKR </h4>
                    </div>
                </div>
              
            </div>
        </div>
        <div class="col-md-3">
            <div class="card shadow iconCards">
                <div class="d-flex align-items-center mt-3 mb-2">
                    <div class="iconDiv me-5">
                        <mat-icon class="iconSize">low_priority</mat-icon>
                    </div>
                    <div class="textDiv">
                        <h2 class="mainH2"> Purchase Return </h2>
                        <h4 class="fw-bold"> {{dataPR}} PKR </h4>
                    </div>
                </div>
              
            </div>
        </div>


    </div>
    <app-newdash-more></app-newdash-more>
</div>