import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, SortDirection} from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThemeService } from 'src/app/services/base/theme.service';
import * as moment from 'moment';
import { ConfTableService } from 'src/app/services/inventory/conf-table.service';
import { merge, observable, of as observableOf, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { PermissionService } from 'src/app/services/base/permission.service';
import { ScreenConfService } from 'src/app/services/base/screen-conf.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DialogService } from 'src/app/services/base/dialog.service';
import { MyTableFilterModalComponent } from '../my-table-filter-modal/my-table-filter-modal.component';
import { SnackbarService } from 'src/app/services/base/snackbar.service';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';

@Component({
  selector: 'app-my-table',
  templateUrl: './my-table.component.html',
  styleUrls: ['./my-table.component.scss']
})
export class MyTableComponent implements OnInit,AfterViewInit, OnChanges {
 
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() scode: string = "";
  @Input() columns: any[] =[];
  @Input() dataSource:any[]=[];
  @Input() excel:boolean=false;
  @Input() search:boolean=true;
  @Input() filter:boolean=true;
  @Input() paging:boolean=true;
  @Input() delete:boolean=false;
  add:boolean = false;
  edit:boolean = false;
  data: MatTableDataSource<any>;
  displayedColumns: string[] =[]; 
  //actionColumns: string[] =['Add']; 
  
  @Output() onAdd = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();  
  @Output() onImport = new EventEmitter<any>();  
  
  @Input() listTitle:string="";
  @Input() listIcon:string="";  
  @Input() listVal1: any = null;
  @Input() listVal2: any = null;
  @Input() listVal3: any = null;
  @Input() CardImage: any = null;
  @Output() onListClick = new EventEmitter<any>();  

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  tableQueryData:any[]=[];

  page:number = null;
  sortby:string = null;
  direction:SortDirection = null;


  panelOpenState = false;

  viewType:string = "list";
  isImage:boolean = false;
  isFilter:boolean = false;
  isImport:boolean = false;
  isExport:boolean = false;
  
  constructor(
    public theme:ThemeService,
    public confTableService:ConfTableService,

    public authenticate:AuthenticateService,
    public permissionService:PermissionService,
    public screenConfService: ScreenConfService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public dialogService:DialogService,
    public snackbarService:SnackbarService,
    ) {
    
   }

  isConfLoaded:boolean = false;
  
  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      if(params['page']){
        this.page = Number(params['page']) - 1 ;
        this.sortby = params['sort'];
        this.direction = params['direction'];
      }            
    });

    this.getAddperimssion();
    this.getEditperimssion();
    //this.getPermissionAll();
    // this.getDeleteperimssion();
    

    // if (this.activatedRoute.data.Scode) {

    //   var Scode = this.activatedRoute.data.Scode;
    // }
    
    
  
     // Inserting a column for add and edit button
     this.columns.splice(0, 0, {
      columnDef: 'action',
      header: '',
      type: 'button',
      cell: (element: any) => `${element.action}`,
      filterData: [],
    })


    //data = ELEMENT_DATA;   
    //this.getConfData().then(a=>{
      this.setTable();
   // })

    // Overrride default filter behaviour of Material Datatable
    //this.data.filterPredicate = this.createFilter();

    // for (let index = 0; index < this.columns.length; index++) {
    //   const element = this.columns[index];
    //   element.filterData = this.getUnique(this.dataSource, element.columnDef);
    // }


  }

  getAddperimssion() {

   // var scode = this.screenConfService.getscodeAc(this.activatedRoute);
    this.add = this.permissionService.getAddPerm(this.screenConfService.getscodeAc(this.activatedRoute))

    /*
    var data  = this.authenticate.userPermissions.filter(a => a.scode == this.screenConfService.getscodeAc(this.activatedRoute) &&
     a.Action == 'add')
     if(data.length > 0){
      var hasRole = data[0].HasPermission;
      this.add = hasRole; // hasRole > 0 ? hasRole[0].HasPermission : false;
     }
     */

     
  }

  getEditperimssion() {
    this.edit = this.permissionService.getEditPerm(this.screenConfService.getscodeAc(this.activatedRoute));
     /*
    this.permissionService.getEditPerm(this.screenConfService.getscodeAc(this.activatedRoute)).then(res => {
      var hasRole = res['value'] as any[];
      this.edit = hasRole.length > 0 ? hasRole[0].HasPermission : false;
    });
    */
  }

  getPermissionAll(){
    this.permissionService.getPermissionAll(this.screenConfService.getscodeAc(this.activatedRoute)).then(res =>{
      var hasRole = res['value'] as any[];
      this.edit = hasRole.length > 0 ? hasRole[0].HasPermission : false;
    })
  }

  // getDeleteperimssion() {
  //   this.permissionService.getDeletePerm(this.screenConfService.getscodeAc(this.activatedRoute)).then(res => {
  //     var hasRole = res['value'] as any[];
  //     this.delete = hasRole.length > 0 ? hasRole[0].HasPermission : false;
  //   });
  // }
  


  
  async getConfData(){
    if(this.scode == "")
      return;

    if(this.isConfLoaded == true)
    return;
      
    this.isConfLoaded = true;
    await this.confTableService.getTableConfData(this.scode).then( a => {
      var colData = a['value'] as any[];
      this.tableQueryData = a['Table1'] as any[];

      // Start Conf_table_detail data
      colData.forEach(item => {

        var obj = null;
        if (String(item.cell).includes('.')) {
          var cels = String(item.cell).split('.');
          obj =
          {
            columnDef: item.columnDef,
            header: item.header,
            type: item.type,
            cell: (element: any) => `${element?.[cels[0]]?.[cels[1]]}`,
            filterData: [],
            IsImage:item.IsImage
          }
        }
        else {
          obj =
          {
            columnDef: item.columnDef,
            header: item.header,
            type: item.type,
            cell: (element: any) => `${element[item.cell]}`,
            filterData: [],
            IsImage:item.IsImage
          }
        }
        this.columns.push(obj);
      });

      // set List Title if Mobile
      var lstTitle = colData.filter(a=> a.listTitle == true);
      if(lstTitle.length > 0){
        this.listTitle = lstTitle[0].columnDef;
      } 

      // set List Val 1 if Mobile
      var lstVal1 = colData.filter(a=> a.listVal1 == true);
      if(lstVal1.length > 0){
        this.listVal1 = {title: lstVal1[0].header, type: lstVal1[0].type, name: lstVal1[0].columnDef};        
      } 

      // set List Val 1 if Mobile
      var lstVal2 = colData.filter(a=> a.listVal2 == true);
      if(lstVal2.length > 0){
        this.listVal2 = {title: lstVal2[0].header, type: lstVal2[0].type, name: lstVal2[0].columnDef};        
      } 

      var lstVal3 = colData.filter(a=> a.listVal3 == true);
      if(lstVal3.length > 0){
        this.listVal3 = {title: lstVal3[0].header, type: lstVal3[0].type, name: lstVal3[0].columnDef};        
      } 

       // set List Title if Mobile
       var isImage = colData.filter(a=> a.IsImage == true);
       if(isImage.length > 0){
         this.CardImage = isImage[0].columnDef;
       } 
 

      

      if (this.tableQueryData.length > 0) {
        this.viewType = this.tableQueryData[0].ViewTypeDefault;
        this.isImage = this.tableQueryData[0].IsImage ? this.tableQueryData[0].IsImage : false;
        this.isFilter = this.tableQueryData[0].IsFilter ? this.tableQueryData[0].IsFilter : false;
        this.isImport = this.tableQueryData[0].IsImport ? this.tableQueryData[0].IsImport : false;
        this.isExport = this.tableQueryData[0].IsExport ? this.tableQueryData[0].IsExport : false;
        this.getData(this.tableQueryData[0].scode);
      }


    })   
  }

    // 22/08/2023
  async getData(scode:string){
    if(this.scode == "")
      return;
      
      if(this.tableQueryData[0].QueryType == "sql"){
        // 08/23/2024 committed code 
       //await  this.confTableService.getDataByScode_Cache(scode).then(res =>{
        await  this.confTableService.getDataByScode(scode).toPromise().then(res =>{

          var data = res['value'];

            // Flip flag to show that loading has finished.            
            this.isLoadingResults = false;
            this.isRateLimitReached = data === null;
            this.dataSource = data;
            this.setTable();
            //this.snackbarService.openSnackBar("Total length: " + this.resultsLength);
        })
      }        
  }

  async refreshData(){
    await this.confTableService.getDataByScode(this.scode);
    this.getData(this.scode);
  }

  // 22/08/2023
  /*
  async getData(query){
    if(this.scode == "")
      return;
  
      if(this.tableQueryData[0].QueryType == "odata")
      {
        merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.confTableService.getData(query, this.sort.active, this.sort.direction, this.paginator.pageIndex).pipe( 
              catchError(() => observableOf(null)));
          }), 
          map(data => {
            // Flip flag to show that loading has finished.
            this.isLoadingResults = false;
            this.isRateLimitReached = data === null;
  
            const queryParams: Params = { page: this.paginator.pageIndex + 1, sort:this.sort.active, direction:this.sort.direction };
  
            this.router.navigate(
              [],
              {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              });
    
            if (data === null) {
              return [];
            }
    
            // Only refresh the result length if there is new data. In case of rate
            // limit errors, we do not want to reset the paginator to zero, as that
            // would prevent users from re-triggering requests.
            this.resultsLength = data['@odata.count'];
            return data['value'];
          }),
        )
        .subscribe(data => {
          this.dataSource = data;
          this.setTable();
        });
      }
      else  if(this.tableQueryData[0].QueryType == "sql"){
        this.confTableService.getDataSQL(query).toPromise().then(res =>{

          var data = res['value'];

            // Flip flag to show that loading has finished.            
            this.isLoadingResults = false;
            this.isRateLimitReached = data === null;
            this.dataSource = data;
            this.setTable();
        })
      }        
  }
  */
 // 22/0/2023


  msg(row){
    console.log(row)
  }

  ngAfterViewInit() {
    if(this.tableQueryData.length == 0){
      return;
    }

    if(this.tableQueryData.length > 0 && this.tableQueryData[0].QueryType == "sql") {
      this.data.paginator = this.paginator;
      return;
    }
    
    if (this.sortby) {
      this.sort.active = this.sortby;
    }

    if (this.direction) {
      this.sort.direction = this.direction;
    }

    if (this.page > -1) {
      // If user has page param
      this.paginator.pageIndex = this.page;
    }
    else {
      // If the user changes the sort order, reset back to the first page.
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;

      });
    }

    //this.data.paginator = this.paginator;

  }

  

  ngOnChanges(changes: SimpleChanges): void {
    this.getConfData().then(a=> {
      //this.setTable();
    })

   // this.IfEditPermission();
  
  }

  

  setTable(){
    this.data = new MatTableDataSource(this.dataSource);
    this.displayedColumns = this.columns.map(c => c.columnDef);   
    this.data.paginator = this.paginator; 
    this.data.sort = this.sort;
    //this.data.paginator = this.paginator;

    // for (let index = 0; index < this.columns.length; index++) {
    //   const element = this.columns[index];
    //   element.filterData = [];
    //   element.filterData = this.getUnique(this.dataSource, element.columnDef);
    // }

   // this.data.filterPredicate = this.createFilter();
  }

  onFilterClick(){
    // Overrride default filter behaviour of Material Datatable
    if(this.filter == false)
    {  
      this.filter = true
      this.setTable()
      this.data.filterPredicate = this.createFilter();
    }
    else
      {
        this.filter = false;
        this.setTable();
        //this.data.filterPredicate = null;
      }
    
  }

  onClearFilter(){
    // this.columns.forEach(a => {
    //   a.filterData = [];
    //   a.filterValue = null;
    // })         

    // this.getData(this.tableQueryData[0].MyQuery);

    // const currentUrl = this.router.url;
    //   this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    //       this.router.navigate([currentUrl]);
    //   });

    window.location.reload();

  }

  applyFilter(event: string) {
    if(typeof(event) == 'object') return;

   

    //const filterValue = (event.target as HTMLInputElement).value;
    this.data.filter = event.trim().toLowerCase();
  }

  applyFilter2(event: String) {
    if(typeof(event) == 'object') return;

    const filterValue = event;
    //this.data.filter = filterValue.trim().toLowerCase();
    var filter_qry = "";
    this.columns.forEach(column => {
      
      if(this.tableQueryData[0].QueryType == "odata"){
        if(column.columnDef.includes('.') == false && column.type == "text"){
          if(filter_qry == "")
            filter_qry = "startswith(" + column.columnDef + ",'"+filterValue+"') OR endswith(" + column.columnDef + ",'"+filterValue+"' )"
          else 
            filter_qry += " or startswith(" + column.columnDef + ",'"+filterValue+"') OR endswith(" + column.columnDef + ",'"+filterValue+"' )"
        }
      } else if(this.tableQueryData[0].QueryType == "sql"){
        if(column.columnDef.includes('.') == false && column.type == "text"){
          if(filter_qry == "")
            filter_qry = column.columnDef + " LIKE '%" + filterValue + "%'";
          else 
            filter_qry += " or " + column.columnDef + " LIKE '%" + filterValue + "%'";
        }
      }

      

    });

    var myqry: string = this.tableQueryData[0].MyQuery;
    if (filter_qry != "") {
      if (this.tableQueryData[0].QueryType == "odata") {
        if (this.tableQueryData[0].MyQuery.includes("?"))
          myqry += "&$filter=" + filter_qry;
        else
          myqry += "?$filter=" + filter_qry;
      } else if (this.tableQueryData[0].QueryType == "sql") {
        myqry += " WHERE " + filter_qry;
      }

    }

    this.getData(myqry);    
  }


  getUnique(data:any[], columnName:string):any[]{
    if(data.length == 0)
      return;
      
    if(columnName.includes('.'))
    {
      var multi_keys = columnName.split('.');
      if(multi_keys.length == 2){
        return data.map(item => item?.[multi_keys[0]]?.[multi_keys[1]]).filter((value,index,self) => self.indexOf(value) === index);            
      }
      else if(multi_keys.length > 3){
        return data.map(item => item?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]]).filter((value,index,self) => self.indexOf(value) === index);            
      }
      else if(multi_keys.length > 4){
        return data.map(item => item?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]]?.[multi_keys[3]]).filter((value,index,self) => self.indexOf(value) === index);            
      }      
    }
    else
      return data.map(item => item[columnName]).filter((value,index,self) => self.indexOf(value) === index);          
   }
 
   filterValues = {};
  // filterN:string = "";
   // Called on Filter change
  filterChange(column, event) {
    //let filterValues = {}
    if (event == undefined ||  event == null) {

      // if (this.filterValues[column.columnDef]) {
      //   delete this.filterValues[column.columnDef]
      // }

      // if (this.filterValues == null || this.filterValues == undefined || this.filterValues == "" ||  (this.filterValues && (Object.keys(this.filterValues).length === 0)))
      //   this.data.filter = "";
      // else {        
      //   this.filterValues[column.columnDef] = String(event);
      //   this.data.filter = JSON.stringify(this.filterValues)
      // }

      this.setFilter(event,column); 

    }
    else {

     this.setFilter(event,column); 

     
      // this.filterValues[column.columnDef] = String(event);      
      // this.data.filter = JSON.stringify(this.filterValues)
    }
    
  
  }

  setFilter(event, column){
     ///////-------------------------------
     column.filterData = [];
    event.forEach(element => {
      var col = column.columnDef;
      var val = '';
      if (col.includes('.')) {
        var multi_keys = col.split('.');
        if (multi_keys.length == 2) {
          if (element?.[multi_keys[0]]?.[multi_keys[1]] != null) {
           val = element?.[multi_keys[0]]?.[multi_keys[1]];
          }
        }
        else if (multi_keys.length == 3) {
          if (element?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]] != null) {
            val = element?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]];
           }
        }
        else if (multi_keys.length == 4) {
          if (element?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]]?.[multi_keys[3]] != null) {
            val = element?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]];
           }
        }
      }
      else {
        val = element[column.columnDef];
      }

      column.filterData.push(val);
    });
    ///////-------------------------------

    var filter_qry:string = "";
    this.columns.forEach(a => {              

      if (a.filterData.length > 0) {
        var qry: string = "";
        a.filterData.forEach(element => {
          var colName = column.columnDef.includes('.') ? column.columnDef.replace('.', '/') : column.columnDef;
          if (qry == ""){
            if(column.type == "text")
              qry = " " + colName + " eq '" + element + "' ";
            else if(column.type == "number" || column.type == "currency")
              qry = " " + colName + " eq " + element;
            else if(column.type == "date")
              qry = " " + colName + " eq " + moment(element).format('YYYY-MM-DD') + "";
          }            
          else{
            if(column.type == "text")
              qry += " or " + colName + " eq '" + element + "' ";
            else if(column.type == "number" || column.type == "currency")
              qry += " or " + colName + " eq " + element;
            else if(column.type == "date")
              qry += " or " + colName + " eq " + moment(element).format('YYYY-MM-DD') + "";
          }
            

        });

        if (filter_qry == "")
          filter_qry += " (" + qry + ") ";
        else
          filter_qry += " and (" + qry + ") ";
      }

    })

    var myqry: string = this.tableQueryData[0].MyQuery;
    if(filter_qry != ""){
     
      if(this.tableQueryData[0].MyQuery.includes("?"))
        myqry += "&$filter=" + filter_qry;
      else 
        myqry += "?$filter=" + filter_qry;               
    }

    this.getData(myqry);
  }

  getDate(val:any){     
      if(moment.isDate(val))
        return val
      else
        return '';
  }

  getText(val:any){
    if(val)
        return val
      else
        return '';
  }

  getNumber(val){
    if(val)
        return val
      else
        return '';
  }


  getObj(column, row){
    console.log('column', column);
    console.log('row', row);    
  }
  
  /*
   // Custom filter method fot Angular Material Datatable
   createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      return data.name.toLowerCase().indexOf(searchTerms.name) !== -1
        && data.id.toString().toLowerCase().indexOf(searchTerms.id) !== -1
        && data.colour.toLowerCase().indexOf(searchTerms.colour) !== -1
        && data.pet.toLowerCase().indexOf(searchTerms.pet) !== -1;
    }
    return filterFunction;
    }
    */

   
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      //console.log(searchTerms);

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              // if (data[col].toString().toLowerCase().indexOf(word) !== -1 && isFilterSet) {
              //   found = true
              // }

              if(col.includes('.'))
              {
                var multi_keys = col.split('.');
                if(multi_keys.length == 2){
                  if (data?.[multi_keys[0]]?.[multi_keys[1]] != null && data?.[multi_keys[0]]?.[multi_keys[1]].toString().toLowerCase().indexOf(word) !== -1 && isFilterSet) { 
                    found = true; 
                  }
                }
                else if(multi_keys.length == 3){
                  if (data?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]] != null && data?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]].toString().toLowerCase().indexOf(word) !== -1 && isFilterSet) { 
                    found = true; 
                  }
                }
                else if(multi_keys.length == 4){
                  if (data?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]]?.[multi_keys[3]] != null && data?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]]?.[multi_keys[3]].toString().toLowerCase().indexOf(word) !== -1 && isFilterSet) { 
                    found = true; 
                  }
                }      
              }
              else  if (data[col] != null && data[col].toString().toLowerCase().indexOf(word) !== -1 && isFilterSet) {
                found = true
              }
             
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }
  
  onAddClick(){
    this.onAdd.emit();
  }

  onEditClick(row){
    this.onEdit.emit(row);
  }

  onDeleteClick(row){
    this.onDelete.emit(row);
  }

  onListItemClick(row){

    if(this.permissionService.getAddPerm(this.screenConfService.getscode())){
      this.onListClick.emit(row);
    }    
  }

  IfEditPermission(){
    if(!this.permissionService.getAddPerm(this.screenConfService.getscode())){
      this.listIcon = "";
    }

  }

  openFiltersDialog(){
    var obj = {
      columns:this.columns,
      tableQueryData:this.tableQueryData
    }
  this.dialogService.openFilterDialog(MyTableFilterModalComponent, obj);

  this.dialogService.dialogRef.afterClosed().subscribe(result => {
    if(result){
      console.log("apply filter", result);
    }
  });
  }


  setFilterDialog(event, column){
    ///////-------------------------------
    column.filterData = [];
   event.forEach(element => {
     var col = column.columnDef;
     var val = '';
     if (col.includes('.')) {
       var multi_keys = col.split('.');
       if (multi_keys.length == 2) {
         if (element?.[multi_keys[0]]?.[multi_keys[1]] != null) {
          val = element?.[multi_keys[0]]?.[multi_keys[1]];
         }
       }
       else if (multi_keys.length == 3) {
         if (element?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]] != null) {
           val = element?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]];
          }
       }
       else if (multi_keys.length == 4) {
         if (element?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]]?.[multi_keys[3]] != null) {
           val = element?.[multi_keys[0]]?.[multi_keys[1]]?.[multi_keys[2]];
          }
       }
     }
     else {
       val = element[column.columnDef];
     }

     column.filterData.push(val);
   });
   ///////-------------------------------

   var filter_qry:string = "";
   this.columns.forEach(a => {              

     if (a.filterData.length > 0) {
       var qry: string = "";
       a.filterData.forEach(element => {
         var colName = column.columnDef.includes('.') ? column.columnDef.replace('.', '/') : column.columnDef;
         if (qry == ""){
           if(column.type == "text")
             qry = " " + colName + " eq '" + element + "' ";
           else if(column.type == "number" || column.type == "currency")
             qry = " " + colName + " eq " + element;
           else if(column.type == "date")
             qry = " " + colName + " eq " + moment(element).format('YYYY-MM-DD') + "";
         }            
         else{
           if(column.type == "text")
             qry += " or " + colName + " eq '" + element + "' ";
           else if(column.type == "number" || column.type == "currency")
             qry += " or " + colName + " eq " + element;
           else if(column.type == "date")
             qry += " or " + colName + " eq " + moment(element).format('YYYY-MM-DD') + "";
         }
           

       });

       if (filter_qry == "")
         filter_qry += " (" + qry + ") ";
       else
         filter_qry += " and (" + qry + ") ";
     }

   })

   var myqry: string = this.tableQueryData[0].MyQuery;
   if(filter_qry != ""){
    
     if(this.tableQueryData[0].MyQuery.includes("?"))
       myqry += "&$filter=" + filter_qry;
     else 
       myqry += "?$filter=" + filter_qry;               
   }

   this.getData(myqry);
 }

  onImportClick(){
    this.onImport.emit();
  }

 
}
