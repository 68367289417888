import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddAllDialogService } from 'src/app/services/add-all-dialog.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';

@Component({
  selector: 'app-all-forms-dialog',
  templateUrl: './all-forms-dialog.component.html',
  styleUrls: ['./all-forms-dialog.component.scss']
})
export class AllFormsDialogComponent implements OnInit {
  dataInventory : any[] = [];
  
  dataSale : any[] = [];
  
  dataPurchase : any[] = [];
  
  dataFinance : any[] = [];
  
  dataUser : any[] = [];
  constructor(
    public dialogRef: MatDialogRef<AllFormsDialogComponent>,
    public routeService:RouteService,
    public router: Router,

    public addAllDialogService:AddAllDialogService,
    public dialogService:DialogService,
  ) { }

  ngOnInit(): void {
    this.getRoutes();
  }

getRoutes(){
  this.dataInventory = this.routeService.getRouteByType('inventory' , 'form').filter(item => item.Scode !== 'inv_04');;
  this.dataSale = this.routeService.getRouteByType('sales' , 'form');
  this.dataPurchase= this.routeService.getRouteByType('purchase' , 'form');
  this.dataFinance = this.routeService.getRouteByType('finance' , 'form');
  this.dataUser = this.routeService.getRouteByType('users' , 'form');

  console.log(this.dataInventory)

}
  
  // dataInventory : any[] = [];

  // ngOnInit(): void {
  
  //   this.dataInventory = this.routeService.getRouteByType('inventory', 'form');
  // }




  openLink(row, module) {

    if(row.IsDialog == true){
      this.addAllDialogService.openAnyDialog(row?.Scode)
    } else {
      this.router.navigate([this.routeService.openRoute(module, row?.name)]);
      this.dialogRef.close();
    }
    this.dialogRef.close();
  
   }

}
