import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mobile-box',
  templateUrl: './mobile-box.component.html',
  styleUrls: ['./mobile-box.component.scss']
})
export class MobileBoxComponent implements OnInit {
  @Input() heading:string = ""; 
  @Input() icon:string = "";
  @Output() myClick =  new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(){
    this.myClick.emit();
  }

}
