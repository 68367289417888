// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDpXMGcpAwFfswPfYM1W4QcCYE2-GaVt9E",
    authDomain: "bizmanto.firebaseapp.com",
    projectId: "bizmanto",
    storageBucket: "bizmanto.appspot.com",
    messagingSenderId: "689701540237",
    appId: "1:689701540237:web:e2971c7a76e02260b8edc4",
    measurementId: "G-NG38JJ8HT7"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
