import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { PermissionService } from 'src/app/services/base/permission.service';
import { ScreenConfService } from 'src/app/services/base/screen-conf.service';
import { ThemeService } from 'src/app/services/base/theme.service';
import { ConfTableService } from 'src/app/services/inventory/conf-table.service';

@Component({
  selector: 'app-ribbon-add',
  templateUrl: './ribbon-add.component.html',
  styleUrls: ['./ribbon-add.component.scss']
})
export class RibbonAddComponent implements OnInit {

  @Output() myClick = new EventEmitter<any>();
  HasPermission:boolean = false;

  add:boolean=false;
  

  constructor(
    public theme:ThemeService,
    public permissionService:PermissionService,
    public screenConfService: ScreenConfService,
    public activatedRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.getAddperimssion();
  }
  

  onClick(){
    this.myClick.emit();
  }

  getAddperimssion() {
    this.add = this.permissionService.getAddPerm(this.screenConfService.getscodeAc(this.activatedRoute));
  
  }

 




}
