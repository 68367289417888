import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { NotifciationMessageService } from 'src/app/services/notification/notifciation-message.service';

@Component({
  selector: 'app-due-days',
  templateUrl: './due-days.component.html',
  styleUrls: ['./due-days.component.scss']
})
export class DueDaysComponent implements OnInit {
  currentDate: Date = new Date();

  selectedChip:string = "cheque"; 

  dataDueDays:any[] = [];
  dataChequeDue:any[] = [];

  datachequeissued:any[] = [];
  constructor(
    public helpservice:ConfHelpService
  ) { }

  ngOnInit(): void {
    this.getDueDays()
    
  }

  async getDueDays() {
    await this.helpservice.getFormDataByHelpNo("spduedays").toPromise().then(res => {
      this.dataDueDays = res['value'] as any[];
      this.dataChequeDue = res['Table1'] as any[];
      this.datachequeissued = res['Table2'] as any[];
      
      console.log(this.dataDueDays)
    })
  }

}
