import { Injectable } from '@angular/core';
import { AuthenticateService } from './base/authenticate.service';
import { BaseService } from './base/base.service';
import { LocalStorageService } from './base/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RecentActivityService {

  recentCount:number = 0;
  dataNotification:any[] = [];
  constructor(
    private base:BaseService,
      private authenticate:AuthenticateService,
      private localStorageService:LocalStorageService,
      ) { }

  getData(){
   // var query = "exec sp_RecentActivity " + this.localStorageService.getCurrentUser().ID;
   //return this.base.sql.getBySqlQueryBody(query);

  return this.base.sql.http.get(this.base.sql.rootUrl + "api/SignIn/RecentActivity?userid=" + this.localStorageService.getCurrentUser().ID);
 }

 getAllActivities(){
  var query = "exec sp_ALLActivity " + this.localStorageService.getCurrentUser().ID;
 return this.base.sql.getBySqlQueryBody(query);
}

async GetAllActivity(){
  // await this.getAllActivities().toPromise().then(res => {
  //   this.dataNotification = res['value'] as any[];
  //   this.recentCount = 0;
  // })
  await this.getData().toPromise().then(res => {
    this.dataNotification = res['value'] as any[];
    this.recentCount = 0;
  })
}

}
