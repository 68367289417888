import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { DialogService } from './dialog.service';
import { LoaderService } from './loader.service';
import { MessageboxComponent } from 'src/app/shared/Dialog/messagebox/messagebox.component';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHanderService {

  constructor(    public dialogService:DialogService,
    public loaderService:LoaderService) { }

  public handleError(error: HttpErrorResponse) {

    if(error.status == 406){ // Not Acceptable
      this.loaderService.endLoaderOnSave();
      this.dialogService.openSmallDialog(MessageboxComponent, error.error)
    }

    // if (error.error instanceof ErrorEvent) {
    //   // Client-side error occurred
    //   console.error('An error occurred:', error.error.message);
    // } else {
    //   // Server-side error occurred
    //   console.error(
    //     `Backend returned code ${error.status}, ` +
    //     `body was: ${error.error}`
    //   );
    // }
    // Return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
