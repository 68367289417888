<div class="d-flex justify-content-between align-items-center ">
        <h2>Reports</h2>
<button class="btn" matDialogClose><mat-icon>close</mat-icon></button>
</div>

<hr>

<div class="d-flex mobColumn">

<mat-accordion *ngIf="routeService.inventoryRoutes.length > 0"  >
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                        <mat-panel-title>
                                <span>
                                        <mat-icon class="iconColor"
                                                [class]="themeService.myUserText">inventory_2</mat-icon>
                                </span>
                                Inventory
                        </mat-panel-title>

                </mat-expansion-panel-header>

                <p class="exitem" (click)="openLink(r.name, 'inventory')"
                        *ngFor="let r of routeService.getRouteByType('inventory', 'report')">
                        <mat-icon>navigate_next</mat-icon> {{r.label}}
                </p>
        </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="routeService.purchaseRoutes.length > 0" >
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                        <mat-panel-title>
                                <span>
                                        <mat-icon class="iconColor"
                                                [class]="themeService.myUserText">shopping_cart</mat-icon>
                                </span>
                                Purchass
                        </mat-panel-title>

                </mat-expansion-panel-header>
                <p class="exitem" (click)="openLink(r.name, 'purchase')"
                        *ngFor="let r of routeService.getRouteByType('purchase', 'report')">
                        <mat-icon>navigate_next</mat-icon>{{r.label}}
                </p>
        </mat-expansion-panel>
</mat-accordion>


<mat-accordion *ngIf="routeService.salesRoutes.length > 0"  > 
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                        <mat-panel-title>
                                <span>
                                        <mat-icon class="iconColor"
                                                [class]="themeService.myUserText">point_of_sale</mat-icon>
                                </span>
                                Sales
                        </mat-panel-title>

                </mat-expansion-panel-header>
                <p class="exitem" (click)="openLink(r.name, 'sales')"
                        *ngFor="let r of routeService.getRouteByType('sales', 'report')">
                        <mat-icon>navigate_next</mat-icon> {{r.label}}
                </p>
        </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="routeService.financeRoutes.length > 0"  >
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                        <mat-panel-title>
                                <span>
                                        <mat-icon class="iconColor"
                                                [class]="themeService.myUserText">payments</mat-icon>
                                </span>
                                Finance
                        </mat-panel-title>

                </mat-expansion-panel-header>
                <p class="exitem" (click)="openLink(r.name, 'finance')"
                        *ngFor="let r of routeService.getRouteByType('finance', 'report')">
                        <mat-icon>navigate_next</mat-icon> {{r.label}}
                </p>

        </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="routeService.productionRoutes.length > 0"  >
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                        <mat-panel-title>
                                <span>
                                        <mat-icon class="iconColor"
                                                [class]="themeService.myUserText">precision_manufacturing</mat-icon>
                                </span>
                                Production
                        </mat-panel-title>

                </mat-expansion-panel-header>
                <p class="exitem" (click)="openLink(r.name, 'production')"
                        *ngFor="let r of routeService.getRouteByType('production', 'report')">
                        <mat-icon>navigate_next</mat-icon> {{r.label}}
                </p>

        </mat-expansion-panel>
</mat-accordion>



<mat-accordion *ngIf="routeService.hrRoutes.length > 0">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                        <mat-panel-title>
                                <span>
                                        <mat-icon class="iconColor"
                                                [class]="themeService.myUserText">person_search</mat-icon>
                                </span>
                                HRM
                        </mat-panel-title>

                </mat-expansion-panel-header>
                <p class="exitem" (click)="openLink(r.name, 'hr')"
                        *ngFor="let r of routeService.getRouteByType('hr', 'report')">
                        <mat-icon>navigate_next</mat-icon> {{r.label}}
                </p>

        </mat-expansion-panel>
</mat-accordion>

<!-- <mat-accordion *ngIf="routeService.userRoutes.length > 0"> 
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                        <mat-panel-title>
                                <span>
                                        <mat-icon class="iconColor"
                                                [class]="themeService.myUserText">account_box</mat-icon>
                                </span>
                                Users
                        </mat-panel-title>

                </mat-expansion-panel-header>
                <p class="exitem" (click)="openLink(r.name, 'users')"
                        *ngFor="let r of routeService.getRouteByType('users', 'report')">
                        <mat-icon>navigate_next</mat-icon> {{r.label}}
                </p>

        </mat-expansion-panel>
</mat-accordion> -->


<mat-accordion *ngIf="routeService.adminRoutes.length > 0">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                        <mat-panel-title>
                                <span>
                                        <mat-icon class="iconColor"
                                                [class]="themeService.myUserText">language</mat-icon>
                                </span>
                                Admin
                        </mat-panel-title>

                </mat-expansion-panel-header>
                <p class="exitem" (click)="openLink(r.name, 'adminpanel')"
                        *ngFor="let r of routeService.getRouteByType('adminpanel', 'report')" >
                        <mat-icon>navigate_next</mat-icon> {{r.label}}
                </p>

        </mat-expansion-panel>
</mat-accordion>


<mat-accordion *ngIf="routeService.emRoutes.length > 0">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true"> 
                <mat-expansion-panel-header>
                        <mat-panel-title>
                                <span>
                                        <mat-icon class="iconColor"
                                                [class]="themeService.myUserText">date_range</mat-icon>
                                </span>
                                Event
                        </mat-panel-title>

                </mat-expansion-panel-header>
                <p class="exitem" (click)="openLink(r.name, 'em')"
                        *ngFor="let r of routeService.getRouteByType('em', 'report')">
                        <mat-icon>navigate_next</mat-icon> {{r.label}}
                </p>

        </mat-expansion-panel>
</mat-accordion>

</div>
