<div>
        <!-- <mat-form-field appearance="outline">
        <mat-icon matPrefix class="me-2">search</mat-icon> -->
        
        <input class="form-control form-control-sm searchdialog"  placeholder="Search ex SI-101" 
            [formControl]="searchMoviesCtrl" type="search">
        <!-- <button  *ngIf="searchMoviesCtrl.value" aria-label="Clear"
            (click)="searchMoviesCtrl.setValue('')">
            <mat-icon>close</mat-icon>
        </button> -->

        <div *ngIf="isLoading">
            <ngx-skeleton-loader count="4" appearance="line"></ngx-skeleton-loader>
        </div>
        
        <app-my-list 
        [search]="true" 
        [data]="EventData" 
        title="Title" 
        icon="edit"
        [val1]="{title: 'Document No', type: 'text', name: 'DocumentNo'}"
        [val2]="{title: 'Document Date', type: 'date', name: 'Documentdate'}"
        (myClick)="openRoute($event.route, $event.ID)">
        </app-my-list>



        <!-- <mat-autocomplete class="matSearchFieldsOptions" #auto="matAutocomplete"
            (optionSelected)="onEventClick($event.option.value)">
            <mat-option *ngIf="isLoading" class="is-loading" disabled><span matSuffix
                    class="spinner-border spinner-border-sm mr-2"></span>Searching... </mat-option>
            <mat-option disabled *ngIf="EventData.length > 0 && !isLoading"
                style=" position: sticky;top: 0;z-index: 1;background-color: white; line-height:22px;border-bottom: 1px solid #d6d6d8; text-align: center; color: gray;">
                Results found: {{EventData.length}}
            </mat-option>
            <ng-container *ngIf="!isLoading">

                <mat-option *ngFor="let event of EventData" [value]="event" style="font-size:14px;line-height:22px;border-bottom: 1px solid #d6d6d8;padding-top: 4px;
              padding-bottom: 4px; height:78px;">
                    <a>
                      
                        <b style=" font-size: 16px">
                            {{event.Title}}
                        </b>
                        <br>
                        <b style="font-weight: 500; font-size: 13px; color:gray">
                            {{event.DocumentNo}} | Date: {{event.Documentdate | date:'MM/dd/yyyy'}}                            
                        </b>

                    </a>
                </mat-option>
            </ng-container>
        </mat-autocomplete> -->
    <!-- </mat-form-field> -->
</div>
