<mat-form-field appearance="outline" class="w-100">
    <mat-label>Date Range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      
      <input matStartDate [(ngModel)]="startDate" 
      (ngModelChange)="onChange(startDate)" placeholder="Start date">

      <input matEndDate [(ngModel)]="endDate"  
      (ngModelChange)="onChange(endDate)"
      placeholder="End date">      

    </mat-date-range-input>
    <!-- <mat-icon matPrefix class="me-2 icon-color">event</mat-icon> -->
    <mat-datepicker-toggle matSuffix [for]="picker">
      <!-- <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon> -->
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>