<!-- <div class=" rounded heading">
        <span>{{heading}}</span> 
</div>  -->


<div class="  rounded headingtop d-flex d-row justify-content-between align-items-center" style="margin-top: 40px;" [class]="theme.myHeadingColor">
        <span class="d-flex flex-column" > 
                <span> {{heading}} </span> 
                <span *ngIf="DocumentNo" class="mob" style="font-size: .7rem;padding-top: 0.5px;">{{DocumentNo}}</span> 
        </span>
        <span class="spacer"></span>
        <app-audit-info class="desktop" (audit)="setDocumentNo($event)"></app-audit-info>
        <mat-icon   class="mob" matRipple  [class]="theme.myHeadingColor" (click)="openBottomSheet()">info</mat-icon>
     
</div> 
