import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Quick_Journal_Voucher } from 'src/app/models/finance/quick-journal-voucher.model';
import { FN_JVHeader } from 'src/app/models/finance/fn_jvHeader.model';
import { EnFN_JVType, EnFN_Nature, EnFN_PostStatus } from 'src/app/models/enums/enum.model';
import { FN_JvDetail } from 'src/app/models/finance/fn_jvDetail.model';

@Injectable({
  providedIn: 'root'
})
export class JvHeaderService {

  readonly controllerName:string = "FN_JVHeader";

  constructor( private base:BaseService) { }

  async getAll(){
    return await this.base.sql.get(this.controllerName);
  }

  async getById(ID: number) {
    var query = {
      expand: "FN_JVDetail"
    }
    return await this.base.sql.getById(this.controllerName, ID,query);
  }

  sendEmail(invoiceNo:string, invoiceDate:string){

    const objFilter = {
      InvoiceNo:invoiceNo,
      InvoiceDate:invoiceDate
    }

     var apiRoute = "api/SaleEmail/GetSaleEmail";

   return this.base.sql.postAny(apiRoute, objFilter).toPromise();
  }


  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody, 'fin_04');

  }

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  delete(ID: number) {
    return this.base.sql.delete(this.controllerName, ID)
  }

  getTreeData(){
    var qry = "exec sp_TreeTable 41";
    return  this.base.sql.getBySqlQueryBody(qry);
  }

  async saveQuickJV(obj_quick:Quick_Journal_Voucher){

    //  Fill Header
    var obj:FN_JVHeader = new FN_JVHeader();
    obj.DocumentDate = obj_quick.Date;
    obj.PostDate = obj_quick.Date;
    obj.PostStatusID = EnFN_PostStatus.Park;
    obj.JVTypeID = EnFN_JVType.JV;
    obj.Description = obj_quick.Notes;
    

    // Fill Debit Row
    var dtl_debit:FN_JvDetail =  new FN_JvDetail();
    dtl_debit.AccountHeadID = obj_quick.DebitAccountHeadID;
    dtl_debit.GLAccountID = obj_quick.DebitAccountID;
    dtl_debit.NatureID = EnFN_Nature.Debit;
    dtl_debit.Amount = obj_quick.Amount;
    obj.FN_JVDetail.push(dtl_debit);

    // FIll Credit Row
    var dtl_credit:FN_JvDetail =  new FN_JvDetail();
    dtl_credit.AccountHeadID = obj_quick.CreditAccountHeadID;
    dtl_credit.GLAccountID = obj_quick.CreditAccountID;
    dtl_credit.NatureID = EnFN_Nature.Credit;
    dtl_credit.Amount = obj_quick.Amount;
    obj.FN_JVDetail.push(dtl_credit);

    await this.post(obj).subscribe(res => {

    })

  }
}
