import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { TokenService } from 'src/app/services/base/token.service';
import { RBAC_User } from 'src/app/models/rbac_user.model';
import { NotifciationMessageService } from 'src/app/services/notification/notifciation-message.service';
import { MsalService } from '@azure/msal-angular';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SearchDialogComponent } from '../../Dialog/search-dialog/search-dialog.component';
import * as customConfig from 'src/assets/files/customConfig.json';
import { LocalStorageService } from 'src/app/services/base/local-storage.service';
import { RecentActivityService } from 'src/app/services/recent-activity.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { TestViewscreenComponent } from 'src/app/modules/main/test-viewscreen/test-viewscreen.component';
import { WelcomeBoardComponent } from 'src/app/modules/main/welcome-board/welcome-board.component';
import { DueDaysComponent } from 'src/app/modules/main/due-days/due-days.component';
import { SignoutService } from 'src/app/services/base/signout.service';


@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  @Input() dataLength: number = 0;
  @Input() appName: string = "";
  @Output() menuClick =  new EventEmitter<any>();
  @Output() bellClick =  new EventEmitter<any>();
  @Output() appClick =  new EventEmitter<any>();  
  userShortCode:string = "";
  dataNotification:any[] = [];

  brandImageTop = customConfig.brandImageTop;
  brandToolTip = customConfig.brandToolTip;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    public theme:ThemeService , 
    public router: Router,
    public dialog: MatDialog,
    public notificationService: NotifciationMessageService,
    public authenticate:AuthenticateService,
    public tokenService:TokenService,
    private authService: MsalService,
    public localStorageService: LocalStorageService,
    public recentActivityService:RecentActivityService,
    public signoutService:SignoutService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher
     ) { 
      this.mobileQuery = media.matchMedia('(max-width: 1024px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
     }

     ngOnDestroy(): void {
      this.mobileQuery.removeListener(this._mobileQueryListener);
    }

  ngOnInit(): void {
    this.getShorName();
    // this.getNotification();
  }

  

  getShorName(){
    var first_code: string = "";
    var second_code: string = "";

    if (this.localStorageService.getCurrentUser()?.FullName) {
      // get first code
      var name: string = this.localStorageService.getCurrentUser()?.FullName;
      var split_name: string[] = name.split(" ");
      if (split_name.length > 0) {
        first_code = split_name[0].substring(0, 1);

        // get second code
        if (split_name.length > 1) {
          second_code = split_name[1].substring(0, 1);
        }
        
    }
    
      this.userShortCode = first_code + second_code;
    }

  }

  isOpenMenu:boolean = true;

  onMenuClick(){
    if(this.mobileQuery.matches){
      this.isOpenMenu = false;
    }
    else if(this.isOpenMenu == true){
      this.isOpenMenu = false;
    }
    else {
      this.isOpenMenu = true;
    }

    this.menuClick.emit();
    
  }

  onBellClick(){
    this.recentActivityService.GetAllActivity();
    this.bellClick.emit();
  }

  onAppClick(){
    this.appClick.emit();
  }

  logout() {


    this.signoutService.signout();
/*
    this.authenticate.logout().then(res => {
      this.tokenService.setToken('');

      this.authenticate.isLoggedIn = false;
      this.authenticate.user = new RBAC_User();
      this.router.navigate(['/login']);      
    }, error => {
      console.log(error);
    })
    */
  }

  async getNotification(){
    await this.notificationService.getAlldataByUser().toPromise().then(res => {
           this.dataNotification = res['value'] as any[];
        })
    
    // await this.notificationService.getAll().then(res => {
    //   this.dataNotification = res['value'] as any[];
    // })
  }

  openDueDays(){
    const dialogRef = this.dialog.open(DueDaysComponent, {
      disableClose: true ,      
      height:"100%",
      maxHeight:"100vh",
      width:'80vw',
      panelClass:'dueDialog',
      position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
    })
}
  

  login(){
    this.router.navigate(['/login']);
  }

  signup(formType:string){
    this.router.navigate(['/signup'], { queryParams: { type: formType } });
  }

  openViewScreen(){
    const dialogRef = this.dialog.open(TestViewscreenComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass:'scrollable-dialog',
      autoFocus: false
    })
  }

  WelcomeScreen(){
    const dialogRef = this.dialog.open(WelcomeBoardComponent, {
      width: "550px",
   
      autoFocus: false
    })
  }




}
