
<app-ribbon-cancel-dialog mat-dialog-close id="closeBtn"></app-ribbon-cancel-dialog>
<mat-dialog-content class="cstmLoader quickDialog" [style.opacity]="loaderService.loading ? '0' : '1'" [style.visibility]="loaderService.loading ? 'hidden' : 'visible'">
        <ng-container *ngComponentOutlet="data.component"></ng-container>
</mat-dialog-content>

 <!-- loader work sufyan  -->
 <app-loader *ngIf="loaderService.loading == true"></app-loader>
<!-- <ngx-skeleton-loader *ngIf="loaderService.loading == true"  count="6" appearance="line"></ngx-skeleton-loader>  -->
 <!-- loader work sufyan  -->
