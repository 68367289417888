<div class="topBar">
        <div class="d-flex align-items-center justify-content-between">
                <div>
                        <div class="d-flex align-items-center">

                                <h2 class="ms-3">Goods Openning</h2>
                        </div>
                </div>
                <div>
                        <div class="d-flex">
                                <div class="topBtns">

                                        <button class="btn btnTopIcons" (click)="print()"> <mat-icon
                                                        class="topNavIcon printIconColor">print</mat-icon> Print
                                        </button>
                                        <button class="btn btnTopIcons" (click)="onEdit()"> <mat-icon
                                                        class="topNavIcon editIconColor">edit</mat-icon> Edit
                                        </button>

                                </div>
                                <div class="topBtnForMob">
                                        <button class="btn btnTopIcons" (click)="print()"> 
                                                <mat-icon class="topNavIcon printIconColor">print</mat-icon>
                                        </button>
                                        <button class="btn btnTopIcons" (click)="onEdit()"> 
                                                <mat-icon class="topNavIcon editIconColor">edit</mat-icon>
                                        </button>
                                </div>

                                <div class="closeBtnDiv">
                                        <button class="btn" mat-dialog-close> <mat-icon
                                                        class="closeColor">close</mat-icon>
                                        </button>
                                </div>

                        </div>

                </div>
        </div>
        <hr>


</div>


<div class="contentDiv">
        <div class="row">
                <div class="col-md-4">

                        <div class="card mb-3 cardHeight ">
                                <div class="txtTitle">
                                        Goods Openning
                                </div>
                                <hr>
                                <table class="table table-borderless">
                                        <tbody class="leftTable">
                                                <tr>
                                                        <td>Entry #</td>
                                                        <td>{{dataHdr?.DocumentNo}}</td>
                                                </tr>
                                                <tr>
                                                        <td>Plants</td>
                                                        <td>{{dataHdr?.PlantName}}</td>
                                                </tr>
                                                <tr>
                                                        <td>Date</td>
                                                        <td>{{dataHdr?.DocumentDate | date}}</td>
                                                </tr>
                                                <tr>
                                                        <td>Notes</td>
                                                        <td>{{dataHdr?.Note}}</td>
                                                </tr>


                                        </tbody>
                                </table>

                        </div>


                </div>

                <div class="col-md-8">
                        <div class="forMobOnly">
                                Item Detail
                        </div>
                        <table class="tableDetail  mb-3">
                                <thead class="detailTH">
                                        <tr>
                                                <th>#</th>
                                                <th class="item">Item</th>
                                                <th>Unit</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Amount</th>


                                        </tr>
                                </thead>
                                <tbody>
                                        <tr *ngFor="let data of dataAll;let index = index">
                                                <td data-label="S#" class="serial">{{index+1}}</td>
                                                <td data-label="Item" class="item">{{data?.ItemName}}</td>
                                                <td data-label="Unit" class="unit">{{data?.UnitName }}</td>
                                                <td data-label="Qty" class="qty">{{data?.Quantity}}</td>
                                                <td data-label="Price" class="price">{{data?.CostPrice | number }} </td>
                                                <td data-label="Amount" class="price">{{data?.CostAmount | number}}</td>

                                        </tr>

                                </tbody>
                        </table>







                </div>
        </div>
</div>