import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexMarkers, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { SaleSummaryService } from 'src/app/services/report/sale-summary.service';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels:ApexDataLabels;
  grid:ApexGrid;
  stroke:ApexStroke;
  title:ApexTitleSubtitle;
  markers:ApexMarkers;
  labels: string[];
  legend: ApexLegend;

}

@Component({
  selector: 'app-sales-chart',
  templateUrl: './sales-chart.component.html',
  styleUrls: ['./sales-chart.component.scss']
})
export class SalesChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions>;
  public areaChartOptions: Partial<ChartOptions>;
  
  public barchartOptions: Partial<ChartOptions>;

  constructor(
    public saleSummary:SaleSummaryService
    ) {
    
  
    
    this.chartOptions  = {
      series: [
        {
          name: "Keyboard",
          data: [80, 51, 35, 51, 49, 62, 69, 91, 148],
        },
        {
          name: "Mouse",
          data: [20, 31, 95, 30, 69, 88, 109, 60, 100]
        }
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false,
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "OCT",
        ]
      }
    };


  }

  ngOnInit(): void {
    this.getSalesData();

  }

  async getSalesData(){
    var itemID:number = 0;

    /*
    if(this.selectedItem){
      itemID =  this.selectedItem.ID
    }

    var datefrom:string = moment(this.dateFrom).format('YYYY/MM/DD');
    var datetill:string = moment(this.dateTo).format('YYYY/MM/DD');
    */


    var datefrom:string = moment().startOf('M').format('YYYY/MM/DD');
    var datetill:string = moment().endOf('M').format('YYYY/MM/DD');

    await this.saleSummary.getReportData(datefrom, datetill, itemID).subscribe(res=>{
      var data = res['value'] as any[];
      var categoryArray:string []= [];
      var dataArray:number []= [];
      data.forEach(element => {
        categoryArray.push(element['ItemName']);
        dataArray.push(element['SaleNetAmount']);
      });
      //this.barchartOptions.xaxis.categories = categoryArray;
      //this.barchartOptions.series[0].data = dataArray;
      this.getBarChart(dataArray, categoryArray);
      
    })
  }

  getBarChart(data, categories){
    this.barchartOptions = {

      series: [
        {
          name: "Sales",
          //data: [340, 298, 280, 254, 310, 260]
          data:data
        }
      ],

      chart: {
        height: 350,
        type: "bar"
      },
      
      xaxis: {
        //categories: ["Jan", "Feb", "March", "April", "May" , "June"]
        categories: categories
      }

    }
  }

}
