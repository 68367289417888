
import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class RbacUserService {  

  readonly controllerName:string = "RBACUsers";
  constructor(private base:BaseService) {    
   }

   
   async getAll() {
    return await this.base.sql.get(this.controllerName);
  }

  async getUserByEmail(email:string) {
    var qry = {
      filter:"Email eq '" + email + "'"
    }
    return await this.base.sql.get(this.controllerName, qry);
  }


  async getById(ID: number) {
    var query = {
      expand:'RBAC_User_Group'
    }
    return await this.base.sql.getById(this.controllerName, ID, query);
  }

  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody);   
  }

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  resetPassword(userObjectid:string, newPassword:string){

    var obj = {
      "UserId":userObjectid,
      "NewPassword":newPassword
    }

    return this.base.sql.postAny("api/UserFlow/reset-password", obj)
  }
 

}
