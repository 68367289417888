import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PurchaseViewComponent } from '../modules/purchase/purchase-view/purchase-view.component';
import { SaleViewDialogComponent } from '../modules/sale/sale-view-dialog/sale-view-dialog.component';
import { CustomerReceiptViewComponent } from '../modules/sale/customer-receipt-view/customer-receipt-view.component';
import { SupplierPaymentViewComponent } from '../modules/purchase/supplier-payment-view/supplier-payment-view.component';
import { SaleReturnViewComponent } from '../modules/sale/sale-return-view/sale-return-view.component';
import { PurchaseReturnViewComponent } from '../modules/purchase/purchase-return-view/purchase-return-view.component';
import { DeliveryChallanViewComponent } from '../modules/sale/delivery-challan-view/delivery-challan-view.component';
import { JVViewComponent } from '../modules/finance/jv-view/jv-view.component';
import { GoodsOpeningViewComponent } from '../modules/inventory/goods-opening-view/goods-opening-view.component';
import { ItemBulkScreenComponent } from '../modules/inventory/item-bulk-screen/item-bulk-screen.component';
import { CustomerBulkScreenComponent } from '../modules/customer/customer-bulk-screen/customer-bulk-screen.component';
import { MyViewDialogComponent } from '../shared/Dialog/my-view-dialog/my-view-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ViewDialogDataService {


  private commonDialogOptions: MatDialogConfig = {
    maxWidth: '100vw',
    maxHeight: '100vh',
    width: '100%',
    height:'100vh',
    panelClass: ['scrollable-dialog', 'cstmDialogs'],
    autoFocus: false,
    data:{}
    // Add other common options if needed
  };


  constructor(private dialog: MatDialog) {
   }

 
  openViewDialog(scode: string, id?: number) {
    if (scode == 'pur_02')
      this.viewPurchase(id);
    else if (scode == 'sal_01')
      this.viewSale(id);
    else if (scode == 'sal_03')
      this.viewCustomerReceipt(id);
    else if (scode == 'sal_02')
      this.viewSaleReturn(id);
    else if (scode == 'pur_04')
      this.viewSupplierPayment(id);
    else if (scode == 'pur_03')
      this.viewPurchaseReturn(id);
    else if (scode == 'sal_08')
      this.viewDelveryChallan(id);
    else if (scode == 'fin_04')
      this.viewJV(id);
    else if (scode == 'inv_06')
      this.viewGOP(id);
    else if (scode == 'inv_01')
      this.onItemBulk(id);

  }



  openBulkView(scode:string){
    if (scode == 'inv_01')
      this.viewBulk(ItemBulkScreenComponent);
    else if (scode == 'sal_04')
      this.viewBulk(CustomerBulkScreenComponent);
    
  }

  viewGOP(ID: number) {

    this.commonDialogOptions.data = { "ID": ID, "component": GoodsOpeningViewComponent};

    const dialogRef = this.dialog.open(
      MyViewDialogComponent,
      this.commonDialogOptions
    )
  }


viewPurchase(ID:number){
  
  this.commonDialogOptions.data = { "ID": ID, "component": PurchaseViewComponent};
  
  const dialogRef = this.dialog.open(
    MyViewDialogComponent, 
    this.commonDialogOptions
    )
}

viewSale(ID:number){
  
  this.commonDialogOptions.data = { "ID": ID, "component":SaleViewDialogComponent};
  
  const dialogRef = this.dialog.open(
    MyViewDialogComponent, 
    this.commonDialogOptions
    )
}

viewSaleReturn(ID:number){
  
  this.commonDialogOptions.data = { "ID": ID, "component": SaleReturnViewComponent};
  
  const dialogRef = this.dialog.open(
    MyViewDialogComponent, 
    this.commonDialogOptions
    )
}

viewPurchaseReturn(ID:number){
  
  this.commonDialogOptions.data = { "ID": ID,"component": PurchaseReturnViewComponent};
  
  const dialogRef = this.dialog.open(
    MyViewDialogComponent, 
    this.commonDialogOptions
    )
}



viewSupplierPayment(ID:number){
  
  this.commonDialogOptions.data = { "ID": ID, "component":SupplierPaymentViewComponent};
  
  const dialogRef = this.dialog.open(
    MyViewDialogComponent, 
    this.commonDialogOptions
    )
}

viewCustomerReceipt(ID:number){
  
  this.commonDialogOptions.data = { "ID": ID, "component":CustomerReceiptViewComponent};
  
  const dialogRef = this.dialog.open(
    MyViewDialogComponent, 
    this.commonDialogOptions
    )
}

viewDelveryChallan(ID:number){
  
  this.commonDialogOptions.data = { "ID": ID, "component":DeliveryChallanViewComponent};
  
  const dialogRef = this.dialog.open(
    MyViewDialogComponent, 
    this.commonDialogOptions
    )
}


viewJV(ID:number){
  
  this.commonDialogOptions.data = { "ID": ID, "component":JVViewComponent};
  
 const dialogRef = this.dialog.open(
    MyViewDialogComponent, 
    this.commonDialogOptions
    )
}

onItemBulk(ID:number){
  
  this.commonDialogOptions.data = { "ID": ID, "component":ItemBulkScreenComponent};
  
 const dialogRef = this.dialog.open(
    MyViewDialogComponent, 
    this.commonDialogOptions
    )
}


  viewBulk(component: any) {
    const dialogRef = this.dialog.open(
      component,
      this.commonDialogOptions
    )
  }







}
