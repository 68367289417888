import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements AfterViewInit {
  @Input('appFocus') isFocused: boolean;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.isFocused) {
      this.el.nativeElement.focus();
    }
  }
}