<div class="row gx-2 mb-4">
  <div class="col-md-4">
    <div class="card shadow">
      <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
        [title]="chartOptions.title">
      </apx-chart>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card shadow">
      <apx-chart [series]="pieChartOptions.pieSeries" [chart]="pieChartOptions.chart" [labels]="pieChartOptions.labels"
        [title]="pieChartOptions.title">
      </apx-chart>
    </div>
  </div>
</div>