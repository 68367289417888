export class Broker {
        ID: number = 0;
        CompanyID: number = null;
        BrokerName: string = null;
        ContactNo: string = null;
        Email: string = null;
        CreatedBy: number = null;
        CreatedDate: string = null;
        ModifiedBy: number = null;
        ModifiedDate: string = null;
        DocumentNo: string = null;
        Scode: string = null;
        City:string = null;
        State:string = null;
        ZipCode:string = null;
        Address1:string = null;
        Address2:string = null;

}
