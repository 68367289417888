import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexNonAxisChartSeries, ApexPlotOptions, ApexResponsive, ApexStroke, ApexTitleSubtitle, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { type } from 'os';
import { RouteService } from 'src/app/services/base/route.service';
import { SaleService } from 'src/app/services/sale.service';
import { ViewDialogDataService } from 'src/app/services/view-dialog-data.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  nonSeries:ApexNonAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels:ApexDataLabels;
  grid:ApexGrid;
  stroke:ApexStroke;
  title:ApexTitleSubtitle;
  markers:ApexMarkers;
  labels: string[];
  legend: ApexLegend;
  fill: ApexFill;
  colors:string[];
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive | ApexResponsive[];
};


@Component({
  selector: 'app-salev3-dash',
  templateUrl: './salev3-dash.component.html',
  styleUrls: ['./salev3-dash.component.scss']
})
export class Salev3DashComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions>;
  public radialChartOptions: Partial<ChartOptions>;


  dataAllSales:number = null;
  dataTotalProduct:number = null;
  dataAllCustomer:number = null;
  dataTotalRevenue:number = null;
  dataTopProducts:any[]  = [];
  dataSalesChart:any[] = [];
  dataRecentOrders:any[] = [];

  fromdate:string = "";
  tilldate:string = "";



clsStock :Stock;
  constructor(
    public saleService:SaleService,
    public route:Router,
    public routeService:RouteService,
    public viewDialogDataService:ViewDialogDataService
  ) { 
    this.clsStock =  new Stock();


   

    this.radialChartOptions= {
      nonSeries: [50,178],
      chart: {
        type: "radialBar",
        height: 350
        

      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          dataLabels:{
            total: {
              show:true,
              label:"Total"
            }
          },
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined
          },
          
          
        }
        
      },
      labels: ["Loyal Customer", "New Customer"],
      legend: {
        show: true,
        position: 'bottom',
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + ''
        }
      },
      colors: ["#1ab7ea", "#F765A3",],

   };

}

  ngOnInit(): void {
   this.setDaysAndGetData('ALL')
    // this.getData();
  }

// getData(){
//   this.saleService.getSaleSum().toPromise().then(res =>{
//     console.log(res);
//     var data =  res['value'] as any[];

//     /*
//     var valSold = data.filter(a => a.Title == 'Sold');
//     if(valSold.length > 0){
//       this.clsStock.Sold = valSold[0];
//     }

//     var valStock = data.filter(a => a.Title == 'Stock');
//     if(valStock.length > 0){
//       this.clsStock.Stock = valStock[0];
//     }*/

//     this.clsStock.Sold = this.getValue(data, "Sold");
//     this.clsStock.Stock = this.getValue(data, "Stock");
//     this.clsStock.RemainingStock = this.clsStock.Sold - this.clsStock.Stock;
//     this.clsStock.Revenue = this.getValue(data, "Revenue");



//   })
// }

// getValue(data:any[], title:string):number{
//   var val = data.filter(a => a.Title == title);
//   if(val.length > 0){
//     return val[0].Value;
//   }

//   return 0;
// }
selectedButton:any

setDaysAndGetData(lastDays: number | string) {

  if (lastDays === 'ALL') {
  
    this.fromdate = '';
    this.tilldate = moment().format('YYYY-MM-DD'); 
  } else {
    this.fromdate = moment().add('days', lastDays).format('YYYY-MM-DD');
    this.tilldate = moment().format('YYYY-MM-DD');
  }

  this.getSalesDashData();
  this.selectedButton = lastDays;
}


async getSalesDashData(){
  this.saleService.getSalesDashboard(this.fromdate, this.tilldate).then(res =>{
    this.dataAllSales = res['value'][0]['TotalSales'] as number;
    this.dataTotalProduct = res['Table1'][0]['TotalProduct'] as number;
    this.dataAllCustomer = res['Table2'][0]['TotalCustomers'] as number;
    this.dataTotalRevenue = res['Table3'][0]['TotalSales'] as number;
    this.dataTopProducts = res['Table4'] as any[];
    this.dataSalesChart = res['Table5'] as any[];
    this.dataRecentOrders = res['Table6'] as any[];

    var categoryArray:string []= [];
    var dataArray:number []= [];
    this.dataSalesChart.forEach(element => {
      categoryArray.push(element['Month']);
      dataArray.push(element['TotalSales']);
    });
    this.getBarChart(dataArray, categoryArray);

    console.log(res)
  })
}

getBarChart(data, categories){
 


  this.chartOptions  = {
    title: {
      text:"Sales By Month",
      style: {
        fontSize:  '16px',
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500
      },
    },
    series: [
      {
        data:data,
        name: "Sales"
        
      }
    ],
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false,
      },
      
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      colors: ['#8f5fea']
    },
    grid: {
      show: false
    },
    fill:{
      
      colors: ['#8f5fea']
      
    },
    xaxis: {
      categories: categories
    }
  };
}

onLinkClick(row:any){
  this.viewDialogDataService.openViewDialog('sal_01', row.ID);
}





}

export class Stock{
  Sold:number = 0;
  Stock:number = 0;
  RemainingStock:number = 0;
  Revenue:number = 0;
}
