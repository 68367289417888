import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-board',
  templateUrl: './welcome-board.component.html',
  styleUrls: ['./welcome-board.component.scss']
})
export class WelcomeBoardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
