import { Injectable } from '@angular/core';
// import * as jspdf from 'jspdf';
// import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class PdfprintService {

  constructor() { }

  generatePDF(elementId: string, filename: string) {
    // const element = document.getElementById(elementId);

    // html2canvas(element).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jspdf.jsPDF();
    //   const imgProps = pdf.getImageProperties(imgData);

    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  

    //   pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //   pdf.save(`${filename}.pdf`);
    // });
  }

  
}
