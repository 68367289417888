import { Component, OnInit, Inject } from '@angular/core';
import { Item } from 'src/app/models/item.model';
import { ItemService } from 'src/app/services/item.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';
import { UnitService } from 'src/app/services/unit.service';
import { CategoryService } from 'src/app/services/category.service';
import { ItemTypeService } from 'src/app/services/inventory/item-type.service';
import { BrandsService } from 'src/app/services/inventory/brands.service';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { UnitsComponent } from '../units/units.component';
import { CategoryComponent } from '../category/category.component';
import { BrandsComponent } from '../brands/brands.component';
import { Router } from '@angular/router';
import { ItemBulkScreenComponent } from '../item-bulk-screen/item-bulk-screen.component';
import { ViewDialogDataService } from 'src/app/services/view-dialog-data.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  primaryKey:number = 0;
  obj: Item;

  dataCategory:any[] = []
  dataUnit:any[] = []
  dataItemType:any[] = []
  dataBrand:any[] = []
  selectedBrand: any = null;
  selectedCategory: any = null;
  selectedUnit: any = null;
  selectedItemType: any = null;
  


  constructor(    
    public service:ItemService,
    public unitService:UnitService,
    public categoryService:CategoryService,   
    public itemTypeService:ItemTypeService, 
    public brandService:BrandsService,
    public dialogService:DialogService,    
    
    public viewDialogDataService:ViewDialogDataService,
    
    public router:Router, 
    public hlp:ConfHelpService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.obj = new Item();
    this.loaderService.startLoader();
  }

  ngOnInit(): void {

    Promise.all([
      this.getCategories(),
      this.getUnits(),  
      this.getItemType(),
      this.getBrands(),
    
    ]).then(res=>{

      if(this.data.primaryKey > 0)
          this.getData();
      else{
        this.loaderService.endLoader();
        this.setDefaultValues();
      }
        
       

    })
  
  }

  setDefaultValues(){
    this.obj.IsActive = true;
    if(this.dataCategory.length > 0){
      this.selectedCategory = this.dataCategory[0];      
    }
  
    if(this.dataBrand.length > 0 ){ 
      this.selectedBrand = this.dataBrand[0];
    }
    
    if(this.dataUnit.length > 0){
      this.selectedUnit = this.dataUnit[0];    
      }

    if(this.dataItemType.length > 0){
        this.selectedItemType = this.dataItemType[0];    
      }
    }

  getData() {    
    this.primaryKey = this.data.primaryKey;
    this.service.getById(this.primaryKey).then(res => {
      this.obj = res as Item;
      this.setDropDowns();
      this.loaderService.endLoader();
    });
  }

  

  setDropDowns(){
    if (this.dataUnit.filter(res => res.ID == this.obj.UnitID).length > 0) {
      this.selectedUnit = this.dataUnit.filter(res => res.ID == this.obj.UnitID)[0];      
    }

    if (this.dataCategory.filter(res => res.ID == this.obj.ItemCategoryID).length > 0) {
      this.selectedCategory = this.dataCategory.filter(res => res.ID == this.obj.ItemCategoryID)[0];      
    }

    if (this.dataItemType.filter(res => res.ID == this.obj.ItemTypeID).length > 0) {
      this.selectedItemType = this.dataItemType.filter(res => res.ID == this.obj.ItemTypeID)[0];      
    }

    if (this.dataBrand.filter(res => res.ID == this.obj.MakeID).length > 0) {
      this.selectedBrand = this.dataBrand.filter(res => res.ID == this.obj.MakeID)[0];      
    }

  }

  async getUnits(){
  //   await this.unitService.getAll().then(res=>{      
  //     this.dataUnit = res['value'] as any[];
  //   })
    
  // }
  await this.hlp.getFormDataByHelpNo("invunit").toPromise().then(res=>{          
    this.dataUnit = res['value'] as any[];    
  })

}


  async getCategories(){
    // sameer: 09102023
    /*
    await this.categoryService.getAll().then(res=>{          
      this.dataCategory = res['value'] as any[];    
    })
    */

    await this.hlp.getFormDataByHelpNo("invitemcategory").toPromise().then(res=>{          
      this.dataCategory = res['value'] as any[];    
    })

  }
 
  async getItemType(){
    await this.itemTypeService.getAll().then(res=> {
      this.dataItemType = res['value'] as any[];
    })

  }

  async getBrands(){
    // sameer: 09102023
    /*
        await this.brandService.getAll().then(res=> {
          this.dataBrand = res['value'] as any[];
        })   
    */

    await this.hlp.getFormDataByHelpNo("invmake").toPromise().then(res=> {
      this.dataBrand = res['value'] as any[];
    })   
      
  }

  onSave() {
    // Inserting Data
    this.loaderService.startLoaderOnSave();

    this.obj.UnitID = this.selectedUnit.ID;
    this.obj.ItemCategoryID = this.selectedCategory.ID;
    this.obj.ItemTypeID = this.selectedItemType.ID;
    this.obj.MakeID = this.selectedBrand.ID;

    if (this.primaryKey == 0) {
      this.service.post(this.obj).subscribe(res => {
        this.loaderService.endLoaderOnSave();
        //this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('inv_01', "save");
      });
    }
    else {
      this.service.update(this.obj, this.primaryKey).subscribe(res=>{
        this.loaderService.endLoaderOnSave();
        // this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('inv_01', "save");
      }); // Updating Data
    }
  }


  onDelete() {
    if (this.primaryKey > 0) {
      this.dialogService.openSmallDialog(YesNoComponent);

      this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
        if (result) {
          if (result == "yes") {
            this.service.delete(this.primaryKey).subscribe(res => {
              //this.dialogService.closeDialog("delete");
              this.dialogService.closeDialog_List('inv_01', "delete");
            })
          }
        }
      });

    }
  }

  onUnitCreate(){
    this.dialogService.openQuickCreateDialog_List(UnitsComponent, 'inv_03');
    this.dialogService.getDialog_List( 'inv_03').afterClosed().subscribe(result => {
      if (result) {
        if (result == "save") {
         this.getUnits().then(res=>{
          this.selectedUnit = this.dataUnit[this.dataUnit.length - 1];
         })
        }
      }
    });

  }


  onCategoryCreate(){
    this.dialogService.openQuickCreateDialog_List(CategoryComponent, 'inv_02');
    this.dialogService.getDialog_List('inv_02').afterClosed().subscribe(result => {
      if (result) {
        if (result == "save") {
         this.getCategories().then(res=>{
          this.selectedCategory = this.dataCategory[this.dataCategory.length - 1];
         })
        }
      }
    });

  }

  onBrandCreate(){
    this.dialogService.openQuickCreateDialog_List(BrandsComponent, 'inv_05');
    this.dialogService.getDialog_List('inv_05').afterClosed().subscribe(result => {
      if (result) {
        if (result == "save") {
         this.getBrands().then(res=>{
          this.selectedBrand = this.dataBrand[this.dataBrand.length - 1];
         })
        }
      }
    });

  }



}
