<div class="m-4 ">
        <h1 class="toptitle">Sales Dashboard</h1>
</div>

<ul class="nav nav-tabs ">
        <li class="nav-item">
                <a class="nav-link active " href="#">Sale Dashboard</a>
        </li>
        <li class="nav-item">
                <a class="nav-link" href="#">Sale Report</a>
        </li>
        <li class="nav-item">
                <a class="nav-link" href="#">Order History</a>
        </li>
        <li class="nav-item">
                <a class="nav-link " href="#">Time Report</a>
        </li>
        <li class="nav-item">
                <a class="nav-link " href="#">Customer Report</a>
        </li>
</ul>


<div class="card mt-4 btnss">
        <div>
                <button class="btn  btn-light  ">Today</button>

                <button class="btn  btn-light ">Week</button>

                <button class="btn  btn-light  ">1 month </button>

                <button class="btn  btn-light ">3 month</button>
        </div>
        <div>
                <input type="text" placeholder="Search" class="form-control form-control-sm ">


        </div>
</div>


<div class="row mt-4 ">
        <div class="col-md-4">
                <div class="card cardProgress p-3  ">
                        <p class="total">Sale total</p>
                        <h4 class="sar">12080.00 PKR</h4>
 
                <div class="d-flex flex-column gap-3 ">
                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Sales</p>
                                        <p>12080.00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:80%"></div>
                                </div>
                        </div>
                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Sale Return</p>
                                        <p>800.00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:15%"></div>
                                </div>
                        </div>

                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Discount</p>
                                        <p>00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:0%"></div>
                                </div>
                        </div>

                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Net Amount</p>
                                        <p>10000.00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:100%"></div>
                                </div>
                        </div>
                </div>

                </div>

        </div>
        <div class="col-md-4">
                <div class="card cardProgress p-3">
                        <p class="total">Purchase Total</p>
                        <h4 class="sar">12080.00 PKR</h4>
 
                <div class="d-flex flex-column gap-3 ">
                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Purchase</p>
                                        <p>6040.00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:50%"></div>
                                </div>
                        </div>
                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Purchase Return</p>
                                        <p>400.00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:20%"></div>
                                </div>
                        </div>

                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Discount</p>
                                        <p>200 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:15%"></div>
                                </div>
                        </div>

                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Net Amount </p>
                                        <p>6040.00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:100%"></div>
                                </div>
                        </div>
                </div>

                </div>

        </div>

        <div class="col-md-4">
                <div class="card cardProgress p-3">
                        <p class="total">Finance Total</p>
                        <h4 class="sar">6040.00 PKR</h4>
 
                <div class="d-flex flex-column gap-3 ">
                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Revenue</p>
                                        <p>6040.00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:50%"></div>
                                </div>
                        </div>
                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Expense</p>
                                        <p>400.00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:20%"></div>
                                </div>
                        </div>

                        <div class="itemProgress">
                                <div class="progrssDiv">
                                        <p>Net Profit</p>
                                        <p>2000.00 PKR</p>
                                </div>
                                <div class="progress">

                                        <div class="progress-bar bg-warning" style="width:40%"></div>
                                </div>
                        </div>

                       
                </div>

                </div>

        </div>


</div>