<div class="WholeDiv" *ngIf="showContent">

        <div class="col-md-3 leftDiv">
                
                <img class="logoImg" src="./assets/img/isolated-layout.svg">

                <div class="leftContent">

                <div class="">
                        <div class="leftTitle">
                                A few clicks away from creating your business workspace.
                        </div>
                        <div class="leftSubTitle">
                                Set up your company information and start quicky.
                        </div>
                </div>
                <div>

                        <img  class="illustImg" src="./assets/img/illust.png"> 
                </div>

                        

                </div>

                

                

        </div>

        <div class="col-md-9 rightDiv">



                <div class="rightContent">

                        <mat-horizontal-stepper  #stepper [selectedIndex]="selectedIndex"  (selectionChange)="onStepChange($event)">
                        
                                <mat-step #step1>
                                        <div class="stepContent">
                                                <div class="textContent">
                                                        <div class="textTitle">
                                                        First, Let's start with your name
                                                        </div>
                                                        <div class="textDescription">
                                                                We're excited to help you get started with bizmanto and achieve your business goals
                                                        </div>
                                                </div>

                                                <div>
                                                        <input class="companyInput" type="text" placeholder="Name" [(ngModel)]="usernName">
                                                        <p *ngIf="showError">The name should be more than 3 letters </p>
                                                </div>

                                                <div>
                                                        <button class="continueBtn" (click)="nextAndSaveManual()">Continue <mat-icon>arrow_right_alt</mat-icon></button>
                                                </div>
                                        </div>
                                 
                                </mat-step>

                                <mat-step #step2>
                                        <div class="stepContent">
                                                <div class="textContent">
                                                        <div class="textTitle">
                                                        Set up your Company identity and Business Currency
                                                        </div>
                                                        <div class="textDescription">
                                                                Your company information is essential, This will help you to get the most out of bizmanto
                                                        </div>
                                                </div>

                                                <div class="companyDiv">
                                                
                                                        <div class="companyImageDiv">
                                                                <input id="imageUpload" type="file" (change)="onFileSelected($event)" accept="image/*">
                                                                
                                                                <img class="imgPreview" [src]="imagePreview" *ngIf="imagePreview">
                                                                <div class="beforePreview" *ngIf="!imagePreview">Your logo Here</div>
                                                                <label for="imageUpload"><mat-icon>edit</mat-icon> Upload</label>
                                                        </div>
                                                
                                                        <div class="seperatorDiv"></div>

                                                        <div class="companyInformationDiv">
                                                                <input class="companyInput" type="text" placeholder="Company Name">
                                                
                                                                <div class="dropdown">
                                                                        <button class="btn currencyBtn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                                Cuurency
                                                                        </button>
                                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                <li><a class="dropdown-item" href="#">USD</a></li>
                                                                                <li><a class="dropdown-item" href="#">PKR</a></li>
                                                                                <li><a class="dropdown-item" href="#">EURO</a></li>
                                                                        </ul>
                                                                </div>
                                                
                                                        </div>
                                                
                                                </div>

                                                <div>
                                                        <button class="continueBtn" matStepperNext>Continue <mat-icon>arrow_right_alt</mat-icon></button>
                                                </div>
                                        </div>
                                 
                                </mat-step>

                                <mat-step #step3>
                                        <div class="stepContentCards">
                                                <div class="textContent">
                                                        <div class="textTitle">
                                                        What modules You wanna choose
                                                        </div>
                                                        <div class="textDescription">
                                                                We're excited to help you get started with bizmanto and achieve your business goals
                                                        </div>
                                                </div> 

                                                <div class="checkBoxCard form">
                                                        <div class="form-input">
                                                                <input type="checkbox" id="check1" name="check1" value="1">

                                                                <label for="check1" class="checkBoxDiv">
                                                                        <mat-icon class="checkIcon">receipt_long</mat-icon>
                                                                        <div class="checkText">
                                                                                <div class="checkTitle">Sales</div>
                                                                                <div class="checkDescrip">Manage Sales Pipeline and streamline Sales Order</div>
                                                                        </div>
                                                                </label>
                                                        </div>
                                                        <div class="form-input">
                                                                <input type="checkbox" id="check2" name="check2" value="2">

                                                                <label for="check2" class="checkBoxDiv">
                                                                        <mat-icon class="checkIcon">production_quantity_limits</mat-icon>
                                                                        <div class="checkText">
                                                                                <div class="checkTitle">Purchase</div>
                                                                                <div class="checkDescrip">Drive purchase management with bizmanto</div>
                                                                        </div>
                                                                </label>
                                                        </div>
                                                        <div class="form-input">
                                                                <input type="checkbox" id="check3" name="check3" value="3">

                                                                <label for="check3" class="checkBoxDiv">
                                                                        <mat-icon class="checkIcon">precision_manufacturing</mat-icon>
                                                                        <div class="checkText">
                                                                                <div class="checkTitle">Production</div>
                                                                                <div class="checkDescrip">Check every process of your production easily and simply</div>
                                                                        </div>
                                                                </label>
                                                        </div>
                                                        <div class="form-input">
                                                                <input type="checkbox" id="check4" name="check4" value="4">

                                                                <label for="check4" class="checkBoxDiv">
                                                                        <mat-icon class="checkIcon">manage_accounts</mat-icon>
                                                                        <div class="checkText">
                                                                                <div class="checkTitle">RBAC</div>
                                                                                <div class="checkDescrip">Role Based User Access</div>
                                                                        </div>
                                                                </label>
                                                        </div>
                                                </div>

                                                <div>
                                                        
                                                        <button class="continueBtn" (click)="AfterModule()">Continue</button>
                                                </div>

                                        </div>
                                </mat-step>
                               

                                <mat-step #step4>
                                        <div class="stepContent" *ngIf="showDivLoading">
                                        
                                                <div>
                                                        <div class="cssload-jumping">
                                                                <span></span><span></span><span></span><span></span><span></span>
                                                        </div>
                                                </div>
                                        
                                                <div class="textContent">
                                                        <div class="textTitle lastText" [ngClass]="{ 'text-focus-in': stepper.selectedIndex === 1 }">
                                                                Initializing ERP Modules
                                                        </div>
                                                        <div class="checkDescrip" [ngClass]="{ 'text-focus-in': stepper.selectedIndex === 1 }">
                                                                Please Wait, We're Setting Up Your Workspace
                                                        </div>
                                                </div>
                                        
                                        
                                        </div>
                                        
                                        <div class="stepContent" *ngIf="showDivDone">
                                        
                                                <div>
                                                        <div class="doneIconDiv text-center">
                                                                <mat-icon class="iconDone">task_alt</mat-icon>
                                                        </div>
                                                </div>
                                        
                                                <div class="textContent">
                                                        <div class="textTitle">
                                                                Setup Completed
                                                        </div>
                                                        <div class="checkDescrip">
                                                                You can use the app now.
                                                        </div>
                                                </div>
                                        
                                                <div class="d-flex justify-content-center">
                                                        <button class="continueBtn" (click)="onFinish()">Go to App <mat-icon>arrow_right_alt</mat-icon></button>
                                                </div>
                                        
                                        
                                        </div>

                                        

                                </mat-step>

                </mat-horizontal-stepper>

                </div>
               
               
        </div>

</div>

<div class="fullLoader" *ngIf="showLoader">
   <div class="d-flex flex-column align-items-center justify-content-center h-75">
        <div>
                <div class="cssload-jumping">
                        <span></span><span></span><span></span><span></span><span></span>
                </div>
        </div>

        <div class="textContent">
                <div class="checkDescrip">
                        Redirecting to the app...
                </div>
        </div>


   </div>
</div>









<!-- 
sameer html -->
<!-- <div class="container h-100" >
        <div class="row align-items-center justify-content-center">
                <div class="col">

                </div>
                <div class="col">
                        <label for="">Enter Your Name:</label>
                        <input required type="text" class="form-control" [(ngModel)]="usernName">
                        <button class="btn btn-primary" (click)="signup()"> Next </button>
                </div>
                <div class="col">

                </div>
        </div>

</div> -->