import { Component, OnInit } from '@angular/core';
import { SignoutService } from 'src/app/services/base/signout.service';

@Component({
  selector: 'app-id-token',
  templateUrl: './id-token.component.html',
  styleUrls: ['./id-token.component.scss']
})
export class IdTokenComponent implements OnInit {

  constructor(
    public signoutService:SignoutService,
  ) { }

  ngOnInit(): void {
    this.signoutService.signout();
  }

}
