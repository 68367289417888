<div class="top-ribbon-dialog">
      
        <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>
    <app-my-heading-dialoge mat-dialog-title heading="Journal Entry Information"></app-my-heading-dialoge>
    
    <form #JournalEntry="ngForm" class="rounded needs-validation">
        <!-- <div class="row"> -->
        <app-my-input-date label="Date" name="Date" [(ngModel)]="obj.Date" class="col-md-6" required="true">
        </app-my-input-date><div class="col-md-6"></div>

        <app-my-select [createOption]="true" (onCreate)="onGlAccountCreate()" optionName="GL" [search]="true" label="Debit Account" title="GLAccountName" [data]="dataglaccount"
            name="GLAccountName" [(ngModel)]="selectedDebit" class="col-md-6" required="true">
        </app-my-select><div class="col-md-6"></div>
        
        <app-my-select  [createOption]="true" (onCreate)="onGlAccountCreate()" optionName="GL" [createOption]="true" [search]="true" label="Credit Account" title="GLAccountName" [data]="dataglaccount"
            name="GLAccountName" [(ngModel)]="selectedCredit" class="col-md-6" required="true">
        </app-my-select><div class="col-md-6"></div>
        
        
        <app-my-input label="Amount" name="Amount" [(ngModel)]="obj.Amount" class="col-md-6" required="true">
        </app-my-input><div class="col-md-6"></div>

        <app-my-text-area label="Description" class="col-md-6" name="Notes" [(ngModel)]="obj.Notes">
        </app-my-text-area><div class="col-md-6"></div>
        
    <!-- </div> -->

         <div align="end">                
                <app-button-save-dialog (myClick)="onSave()" [valid]="JournalEntry.invalid"></app-button-save-dialog>
        </div>
        



      </form>
    