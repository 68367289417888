import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/base/dialog.service';

@Component({
  selector: 'app-add-home',
  templateUrl: './add-home.component.html',
  styleUrls: ['./add-home.component.scss']
})
export class AddHomeComponent implements OnInit {

  constructor(public dialogService:DialogService) { }

  ngOnInit(): void {
  }

  onClick(mode:string){
    this.dialogService.closeSmallDialog(mode);    
  }

}
