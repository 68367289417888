<div class="top-ribbon-dialog">
    <app-ribbon-save-dialog [hidden]="client.invalid" (myClick)="onSave()" [valid]="client.invalid"></app-ribbon-save-dialog>
    <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>

<app-my-heading-dialoge mat-dialog-title heading="Plants Information"></app-my-heading-dialoge>



<form #client="ngForm" class="rounded needs-validation ">

    <div class="row g-2">
                
                    <app-my-input label="PlantID" class="col-md-6 col-6" [hidden]="true">
                    </app-my-input>                                                                    
                    <app-my-input label="Plant Name" class="col-md-6" name="PlantName" [(ngModel)]="obj.PlantName">
                    </app-my-input>
                    <app-my-input label="Location" class="col-md-6" name="Location" [(ngModel)]="obj.Location">
                    </app-my-input>
                    <app-my-input label="Description" class="col-md-12" name="Description" [(ngModel)]="obj.Description">
                    </app-my-input> 
               
                                          
    </div>     
    <br><br>   
    <div align="end" >                
            <app-button-save-dialog (myClick)="onSave()" [valid]="client.invalid"></app-button-save-dialog>
    </div>
</form>
