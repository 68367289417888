<button class="btn float-end " mat-dialog-close> <mat-icon class="closeColor">close</mat-icon>
</button>
<div class="cstmSearch d-flex align-items-center col-md-6">
        <mat-icon style="position: absolute;
        padding-left: 15px;">search</mat-icon>
        <input [(ngModel)]="searchText" type="search" class="form-control cstmSearchInput form-control-sm m-2 mysearch" style="padding-left: 34px;"  placeholder="Search"  autocomplete="off">
    </div>

<h1 class="m-3">Items</h1>

<div class="tableContainer">
        <table class="table custom-table">
                <thead>
                        <tr >    
                            <th *ngFor="let data of objColumn;">
                                {{data?.ColumnName}}
                            </th>
                        </tr>
                </thead>

                <tbody>
                                <tr *ngFor="let data of objData | customSearchFilter:searchText;let index = index;"  [ngClass]="{ 'selected': data.isSelected }" >
                                        <td>
                                                <mat-checkbox [checked]="data?.isSelected" (change)="toggleSelection(data, $event)" color="primary"></mat-checkbox>
                                                
                                        </td>
                                        <td>{{index+1}}</td>
                                        <td>
                                        {{data?.ItemName}}
                                        </td>
                                        <!-- <td>{{data?.Brand}}</td>
                                        <td>{{data?.Category}}</td> -->
                                        <td>{{data?.UnitID}}</td>
                                        <td>{{data?.CostPrice}}</td>
                                        <td>{{data?.SalePrice}}</td>
                                </tr>
                             
                </tbody>
        </table>
</div>

<div class="footerSec mt-3">
        <div class="d-flex align-items-center">
                        <div class="col-md-6 d-flex">
                                Selected Items:
                                <div *ngFor="let data of selectedRows; let index = index" class="d-flex">
                                        <!-- <h3>{{}}</h3> -->
                                      
                                </div>
                        </div>

                        <div class="col-md-6 ">
                                        <button (click)="onDone()" class="float-end btn doneBtn"> 
                                                <mat-icon>download</mat-icon> Get All 
                                        </button>
                        </div>
        </div>
</div>
