import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { RouteService } from 'src/app/services/base/route.service';
import { ViewDialogDataService } from 'src/app/services/view-dialog-data.service';
import { PrintDialogComponent } from 'src/app/shared/Dialog/print-dialog/print-dialog.component';

@Component({
  selector: 'app-jv-view',
  templateUrl: './jv-view.component.html',
  styleUrls: ['./jv-view.component.scss']
})
export class JVViewComponent implements OnInit {

  dataHdr:any= null;
  dataAll:any[] = [];

  constructor(
    public router:Router,
    public routerService:RouteService,
    public dialogService:DialogService,
  
    public helpservice:ConfHelpService,
    public viewDialogDataService:ViewDialogDataService,
    public dialogRef: MatDialogRef<JVViewComponent>,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getViewData()
  }

  async getViewData() {
    this.loaderService.startLoader();
    var filter = {
      id : this.data.ID,
      
    }

    await this.helpservice.getFormDataByHelpNo("vw_generalentry", filter).toPromise().then(res => {
      this.dataHdr = res['value'][0] as any;
      this.dataAll = res['value'] as any[];
      this.loaderService.endLoader();
      console.log(this.dataAll)
      this.calculateTotals();
    })
  }




  // onRefClick(){

  // }
  totalCredit: number = 0;
  totalDebit: number = 0;

  calculateTotals() {
    this.totalCredit = 0;
    this.totalDebit = 0;

    this.dataAll.forEach((arrayOrItem) => {
      // Check if the element is an array before using forEach
      if (Array.isArray(arrayOrItem)) {
        arrayOrItem.forEach((item) => {
          // Assuming each item has "CreditAmount" and "DebitAmount" properties
          this.totalCredit += item.CreditAmount || 0;
          this.totalDebit += item.DebitAmount || 0;
        });
      } else {
        // If it's not an array, handle it accordingly (assuming it has "CreditAmount" and "DebitAmount" properties)
        this.totalCredit += arrayOrItem.CreditAmount || 0;
        this.totalDebit += arrayOrItem.DebitAmount || 0;
      }
    });
  }

  print(){
    if(this.data.ID == 0)
      return;

    var obj = {
      reportno:'rpt28',
      queryparam:'id='+this.data.ID,
    }
    this.dialogService.openPrintDialog(PrintDialogComponent, obj);  
   
  }

  onEdit(){
    this.dialogRef.close();
    this.router.navigate([this.routerService.openRoute("finance", "journal-voucher")], { queryParams: { ID: this.data.ID, parent: 'journal-voucher-list' } });
  }

}
