<div class="topBar">
        <div class="d-flex align-items-center justify-content-between">
                <div>
                        <div class="d-flex align-items-center">
                        
                        <h2 class="ms-3">Voucher</h2>
                         </div>
                </div>
                <div>
                        <div class="d-flex">
                                <div class="topBtns">
                                        <button class="btn btnTopIcons" (click)="onEdit()"> <mat-icon class="topNavIcon editEmailColor">drafts</mat-icon> Send Email
                                        </button>
                                        <button class="btn btnTopIcons" (click)="print()"> <mat-icon class="topNavIcon printIconColor">print</mat-icon> Print
                                        </button>
                                        <button *ngIf="dataHdr?.ReferenceNo == null" class="btn btnTopIcons" (click)="onEdit()"> <mat-icon class="topNavIcon editIconColor">edit</mat-icon> Edit
                                        </button>
                                       
                                        <!-- <button class="btn btnTopIcons"> <mat-icon class="topNavIcon deleteIconColor">delete</mat-icon> Delete
                                        </button> -->
                                </div>

                                <div class="topBtnForMob">
                                        <button class="btn btnTopIcons" (click)="print()"> 
                                                <mat-icon class="topNavIcon printIconColor">print</mat-icon>
                                        </button>
                                        <button class="btn btnTopIcons" (click)="onEdit()"> 
                                                <mat-icon class="topNavIcon editIconColor">edit</mat-icon>
                                        </button>
                                </div>
                        
                                <div class="closeBtnDiv">
                                        <button class="btn" mat-dialog-close> <mat-icon class="closeColor">close</mat-icon>
                                        </button>
                                </div>
                        
                        </div>
                        
                </div>
        </div>
        <hr>


</div>

<div class="contentDiv">
        <div class="row">
                <div class="col-md-4">

                        <div class="card mb-3 cardHeight shadow">
                                <div class="txtTitle">
                                     {{dataHdr?.JVTypeName}}
                                </div>
                                <hr>
                                <table class="table table-borderless">                                 
                                        <tbody class="leftTable">
                                            <tr>
                                                <td>Entry #</td>
                                                <td>{{dataHdr?.DocumentNo}}</td>
                                            </tr>
                                            <tr>
                                                <td>Document Date</td>
                                                <td>{{dataHdr?.DocumentDate | date}}</td>
                                            </tr>
                                            <tr>
                                                <td>Post Date</td>
                                                <td>{{dataHdr?.PostDate | date}}</td>
                                            </tr>
                                            <tr>
                                                <td>Post Status</td>
                                                <td>{{dataHdr?.PostStatusName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Reference</td>
                                                <td>{{dataHdr?.ReferenceName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Reference #</td>
                                                <td>{{dataHdr?.ReferenceNo}}</td>
                                            </tr>
                                            <tr>
                                                <td>Description</td>
                                                <td>{{dataHdr?.Description}}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td>Payment Mode</td>
                                                <td>{{dataHdr?.PaymentModeName}}</td>
                                            </tr> -->
                                            <!-- <tr>
                                                <td>Due Date</td>
                                                <td class="dueDateColor">{{dataHdr?.DueDays}}</td>
                                            </tr>                     -->
                                        </tbody>                
                                    </table> 
                               
                        </div>

                      
                </div>

                <div class="col-md-8">
                        <div class="forMobOnly">
                                Item Detail
                        </div>
                
                <table class="tableDetail mb-3">
                        <thead class="detailTH">
                                <tr>
                                   
                                        <th class="item">GL Account</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Dimension</th>
                                        <th>Bank</th>
                                        <th>Cheque #</th>
                                        <th>Cheque Date</th>
                                       
                                </tr>
                        </thead>  
                        <tbody>
                                
                                <tr *ngFor="let data of dataAll;let index = index">
                                   
                                        <td data-label="GL Account" class="item">{{data?.GLAccountName}}</td>
                                        <td data-label="Debit" class="debit">{{data?.DebitAmount | number}}</td>
                                        <td data-label="Credit" class="debit">{{data?.CreditAmount | number}}</td>
                                        <td data-label="Dimension" class="dimension">{{data?.DimensionName}}</td>
                                        <td data-label="Bank" class="bank">{{data?.BankName}}</td>
                                        <td data-label="Cheque #" class="chequeNo">{{data?.ChequeNo}}</td>
                                        <td data-label="Cheque Date" class="chequeDate">{{data?.ChequeDate | date}}</td>             
                                </tr>
                                
                        </tbody>
                </table>

                <div class="cstmCard">
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Debit</div>
                                <div class="txtMainSummary">{{totalDebit | number}}</div>
                        </div>
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Credit</div>
                                <div class="txtMainSummary">{{totalCredit | number}}</div>
                        </div>
                   
                </div>






                </div>
        </div>
</div>


