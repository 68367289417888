<!-- <div class="row desktop" style="margin-top: -4px;">
  <div class="col-md-7">
    <div class="d-flex bd-highlight" *ngIf="search">
     
      <div class="p-2 flex-grow-1"  >
       
        <app-my-table-search (change)="applyFilter($event)" *ngIf="!filter"></app-my-table-search>
      </div>
    </div>
  </div>
  <div class="col-md-5 " >
    <div class="d-flex bd-highlight justify-content-end">
      <div class="p-2 bd-highlight">        
        <button mat-raised-button style="margin-top: 7px;" (click)="onFilterClick()" class="shadow ms-1" >
          <mat-icon class="add-icon" [class]="theme.myIconColor">filter_alt</mat-icon> Filter
        </button>
        <button 
          *ngIf="add"
          type="button" (click)="onAddClick()" mat-raised-button style="margin-top: 7px;" class="shadow btnAdd">
          <mat-icon class="add-icon" [class]="theme.myIconColor">add</mat-icon> Add
        </button>
      </div>      
    </div>
  </div>
</div> -->


<div class="desktop">

        <!-- filter panel -->
        <!-- <mat-accordion>    
         <mat-expansion-panel (opened)="panelOpenState = true"
                              (closed)="panelOpenState = false">
           <mat-expansion-panel-header>
             <mat-panel-title class="d-flex align-items-center">
               <mat-icon class="me-1" [class]="theme.myIconColor">filter_alt</mat-icon> <span> Filter </span>
             </mat-panel-title>
            
           </mat-expansion-panel-header>
           <div class="row">
             <div *ngFor="let column of columns" class="col-md-4" [hidden]="column?.columnDef == 'action' " >
             <app-my-table-filter 
                 
                 [hidden]="false" 
                 label="Filter" 
                 [data]="[]" 
                 [title]="column.header"
                 [name]="column.columnDef" 
                 [model]="column.filterValue" 
                 (change)="filterChange(column,$event)"
                 [column] = "column"
                 [tableQueryData]="tableQueryData"
                 class="w-100 mt-3 columnFilter">`
               </app-my-table-filter>
             </div>
           </div>
         </mat-expansion-panel>
       </mat-accordion>
        -->
         <!-- filter panel -->
     
         <div *ngIf="!isLoadingResults" class="d-flex w-100 align-items-center justify-content-between" style="background-color: white;">
           <app-my-table-search (change)="applyFilter($event)"  class="w-50"></app-my-table-search>
          
          
           <div>
            <ng-content select="[ribbon-area]"></ng-content>
             <button 
             mat-button
             *ngIf="add  && !isLoadingResults"
             (click)="onAddClick()"
             type="button"    
             class="btnAdd hdrbtn me-2 pe-4"
             [class]="theme.myIconBgColor">
             <mat-icon class="add-icon" [class]="theme.myIconColor">add</mat-icon> Create
           </button>
     
           
     <!-- 
           <button 
           *ngIf="!isLoadingResults && isFilter"
             mat-raised-button 
             (click)="onFilterClick()" 
             type="button"    
             class="hdrbtn me-1" 
             [matTooltip]="filter == true ? 'Hide Filter' : 'Filter'" >
             <mat-icon class="add-icon" *ngIf="!filter" [class]="theme.myIconColor">filter_alt</mat-icon>
             <mat-icon class="add-icon" *ngIf="filter" [class]="theme.myIconColor">filter_alt_off</mat-icon>
           </button> -->
     
          
     
           <button 
           *ngIf="!isLoadingResults && isImport"
             mat-button 
             [class]="theme.myIconBgColor"
             type="button"    
             class="hdrbtn me-1" 
             matTooltip="Import Data"
             (click)="onImportClick()" >
             <mat-icon class="add-icon"  [class]="theme.myIconColor">file_download</mat-icon>
           </button>
     
           <button 
           *ngIf="!isLoadingResults && isExport"
             mat-button 
             [class]="theme.myIconBgColor"
             type="button"    
             class="hdrbtn me-1" 
             matTooltip="Export Data" >
             <mat-icon class="add-icon"  [class]="theme.myIconColor">open_in_new</mat-icon>
           </button>
          
           <!-- <mat-button-toggle-group [(ngModel)]="viewType"  name="favoriteColor" aria-label="Favorite Color" class="me-2">
             <mat-button-toggle value="list" matTooltip="List"> <mat-icon>format_list_bulleted</mat-icon> </mat-button-toggle>
             <mat-button-toggle value="card" matTooltip="Card" > <mat-icon>list_alt</mat-icon> </mat-button-toggle>          
           </mat-button-toggle-group>       -->
           </div>
                 
           
         </div>
     
          <!-- filter panel -->
        <!-- <mat-accordion *ngIf="isFilter && filter" >    
         <mat-expansion-panel expanded="true" (opened)="panelOpenState = true"
                              (closed)="panelOpenState = false">
           <mat-expansion-panel-header>
             <mat-panel-title class="d-flex align-items-center">
               <mat-icon class="me-1" [class]="theme.myIconColor">filter_alt</mat-icon> <span> Filter </span>
             </mat-panel-title>
            
           </mat-expansion-panel-header>
           <div class="row" >
             <div *ngFor="let column of columns" class="col-md-3" [hidden]="column?.columnDef == 'action' || column?.IsImage == true " >
               <app-my-table-filter             
                 [hidden]="false" 
                 label="Filter" 
                 [data]="[]" 
                 [title]="column.header"
                 [name]="column.columnDef" 
                 [model]="column.filterValue" 
                 (change)="filterChange(column,$event)"
                 [column] = "column"
                 [tableQueryData]="tableQueryData"
                 class="w-100 mt-3 columnFilter">`
               </app-my-table-filter>          
     
             </div>
             <div class="col-md-3 text-center mt-3 pe-4">
               <button mat-stroked-button type="button" (click)="onClearFilter()">
                 <mat-icon>close</mat-icon>            
                 Clear Filters
               </button>
             </div>
             
     
           </div>
         </mat-expansion-panel>
       </mat-accordion> -->
       
         <!-- filter panel -->
     
         <!-- Table -->
         <div class="table-responsive shadow mt-4" >
           
           <div class="d-flex">
             <div class="px-2 pt-3 w-25"  *ngIf="isLoadingResults">                  
                   <ngx-skeleton-loader  count="6" appearance="line"></ngx-skeleton-loader>                                      
             </div>
             <div class="px-2 pt-3 w-25"  *ngIf="isLoadingResults">                  
               <ngx-skeleton-loader  count="6" appearance="line"></ngx-skeleton-loader>                                      
             </div>
             <div class="px-2 pt-3 w-25"  *ngIf="isLoadingResults">                  
               <ngx-skeleton-loader  count="6" appearance="line"></ngx-skeleton-loader>                                      
             </div>
             <div class="px-2 pt-3 w-25"  *ngIf="isLoadingResults">                  
               <ngx-skeleton-loader  count="6" appearance="line"></ngx-skeleton-loader>                                      
             </div>        
           </div>
                       
            <!-- <app-my-table-filter-modal></app-my-table-filter-modal> -->
     
             <table [hidden]="isLoadingResults || viewType == 'card'" mat-table [dataSource]="data" matSort class="mat-elevation-z8 w-100"
             matSort matSortActive="ID" matSortDisableClear >
           
               <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
           
                 <th mat-header-cell *matHeaderCellDef>
                    
                   
                   <div class=" pt-3 pb-1" mat-sort-header *ngIf="column.columnDef != 'action' && column?.IsImage != true" >
                     {{column.header}}
                   </div>
                    <!-- <app-my-table-filter 
                     *ngIf="filter && column.columnDef != 'action' " 
                     [hidden]="false" 
                     label="Filter" 
                     [data]="[]" 
                     [title]="column.header"
                     [name]="column.columnDef" 
                     [model]="column.filterValue" 
                     (change)="filterChange(column,$event)"
                     [column] = "column"
                     [tableQueryData]="tableQueryData"
                     class="w-100 mt-3 columnFilter">`
                   </app-my-table-filter> -->
                 </th>
           
                 <td mat-cell *matCellDef="let row" >
                   <span *ngIf="column.columnDef != 'action' || column?.IsImage != true" >
                     <!-- {{ column.type == 'currency' ? (column.cell(row) | currency) : column.type == 'date' ?  (column.cell(row) | date:'MM/dd/yyyy') : column.cell(row) == 'null' ? '' : column.cell(row) }} -->                
                     <!-- {{ column.type == 'currency' ? (getNumber(column.cell(row)) | number: '1.2-2')  : column.type == 'date' ?  (getDate(column.cell(row)) | date:'MM/dd/yyyy') : column.type == 'time' ?  (getDate(column.cell(row)) | date:'hh:mm a') : column.cell(row) == 'null' || column.cell(row) == "null" || column.cell(row) == null || column.cell(row) == undefined ? '' : column.cell(row) }} -->
                     <!-- {{ column.type == 'currency' ? (getNumber(column.cell(row)) | number: '1.2-2')  : column.type == 'date' ?  (getDate(column.cell(row)) | date:'MM/dd/yyyy') : column.type == 'time' ?  (getDate(column.cell(row)) | date:'hh:mm a') : column.cell(row) == 'null' || column.cell(row) == "null" || column.cell(row) == null || column.cell(row) == undefined || column.cell(row) == 'undefined' ? '' : column.cell(row) }} -->
                     {{ column.cell(row) == 'null' || column.cell(row) == "null" || column.cell(row) == null || column.cell(row) == undefined || column.cell(row) == 'undefined' ? '' : column.type == 'currency' ? (getNumber(column.cell(row)) | number: '1.2-2')  : column.type == 'number' ? (getNumber(column.cell(row)) | number: '1.2-2') : column.type == 'date' ?  (column.cell(row) | date:'MM/dd/yyyy') : column.type == 'time' ?  (column.cell(row) | date:'hh:mm a')  : column.cell(row) }}
                     <!-- {{getObj(column, row)}} -->
                   </span>
                   <span *ngIf="column.columnDef == 'action'">
                     <div class="d-flex">
                         
                       <!-- <button 
                         mat-icon-button 
                         matTooltip="Delete" 
                         (click)="onDeleteClick(row)"  
                         *ngIf="delete"
                         >
                           <mat-icon class="btn-delete table-button" [class]="theme.myIconColor">delete_outline</mat-icon>
                         </button> -->
     
                         <button 
                         *ngIf="edit"
                         mat-icon-button 
                         matTooltip="Edit" 
                         (click)="onEditClick(row)"
                         >
                           <mat-icon class="btn-edit table-button" [class]="theme.myIconColor">mode_edit_outline</mat-icon>
                         </button>
                         
                     </div>           
                   </span>
                  
                 </td>
                 
                 
                 
               </ng-container>
               <ng-container matColumnDef="item-description" >
                 <!-- <th mat-header-cell *matHeaderCellDef [attr.colspan]="columns.length">
                   <div class="d-flex w-100 align-items-center">
                     <app-my-table-search (change)="applyFilter($event)" [filter]="filter" class="w-100"></app-my-table-search>
                    
     
                     <button 
                       mat-raised-button
                       *ngIf="add  && !isLoadingResults"
                       (click)="onAddClick()"
                       type="button"    
                       class="btnAdd hdrbtn me-2 pe-4">
                       <mat-icon class="add-icon" [class]="theme.myIconColor">add</mat-icon> Add
                     </button>
     
                     
                     
                   </div>
                   
                 </th> -->
                 <th mat-header-cell *matHeaderCellDef [attr.colspan]="columns.length" >                
                     <h3 style="color: black;                                
                                     font-weight: 500;
                                     text-align: center;">
                       No Data Found!
                     </h3>
                 </th>
     
               </ng-container>
           
               
               <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
               <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
               <tr mat-header-row *matHeaderRowDef="['item-description']" [hidden]="dataSource.length > 0"></tr>
               
             </table>  
     
             
                                   
         </div>  
         
         <!-- Card -->
         <app-my-table-card
         [hidden]="isLoadingResults || viewType == 'list'"
         [search]="!isLoadingResults"
         [data]="data.filteredData" 
         [title]="listTitle" 
         [icon]="listIcon" 
         [val1]="listVal1"
         [val2]="listVal2"
         [val3]="listVal3"
         [isImage]="isImage"
         [CardImage]="CardImage"
         [edit]="edit"
         (onEdit)="onEditClick($event)"
         >
     
         </app-my-table-card>
     
     </div>
     
     <div class="mobile">
       <div class="row">
         <div class="col-12">
             <button        
             type="button" (click)="onAddClick()"  mat-mini-fab class="shadow topbtn" [class]="theme.myButtonBgColor" >
                 <mat-icon matRipple   class="add-icon" >add</mat-icon>
             </button>
         </div>          
       </div>
     
       <div  *ngIf="isLoadingResults">
         <ngx-skeleton-loader  count="4" appearance="line"></ngx-skeleton-loader>          
       </div>
         
       <app-my-list 
       [search]="!isLoadingResults"
       [data]="data.data" 
       [title]="listTitle" 
       [icon]="listIcon" 
       [val1]="listVal1"
       [val2]="listVal2"
       [val3]="listVal3"
       (myClick)="onListItemClick($event)">
       </app-my-list>
     </div>
     
     <mat-paginator [hidden]="isLoadingResults" [length]="resultsLength" class="shadow" *ngIf="paging" [pageSizeOptions]="[5, 10, 20, 50]" [pageSize]="10" showFirstLastButtons
               aria-label="Select page of periodic elements">
       </mat-paginator>
     
     
     
     <!-- <div class="showOnMob">
       <div class="roundbtn">
           <button class="btn"> <mat-icon class="add-icon" >add</mat-icon> </button>
       </div>
     </div> -->
     
     