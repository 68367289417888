import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AuditInfoService {

  constructor(
    public base: BaseService
  ) { }

  // getAuditData(query:string){
  //   return  this.base.sql.getBySqlQueryBody(query);
  // }

  getAuditData(scode:string, id: number){

    var apiRoute = `api/audit/GetAuditData/${scode}/${id}`;
    return this.base.sql.get(apiRoute);
  }

}
