import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, RoutesRecognized } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfTableService } from '../inventory/conf-table.service';
import { AuthenticateService } from './authenticate.service';
import { LocalStorageService } from './local-storage.service';
import { PermissionService } from './permission.service';
import { ScreenConfService } from './screen-conf.service';
import { SqlService } from './sql.service';
import { TokenService } from './token.service';
import { SignoutService } from './signout.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {



  constructor(
    public router: Router, 
    public authenticate: AuthenticateService,
    public tokenService:TokenService,
    public permissionService:PermissionService,
    public screenConfService:ScreenConfService,
    public sqlService: SqlService,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private signoutService: SignoutService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     

      if(!this.localStorageService.getCurrentUser()){
        this.router.navigate(["signin"]);
      }

      if(this.tokenService.IsvalidSession(this.localStorageService.getCurrentUser()) == false){
        this.signoutService.signout();
        return;
      }

      // Check if user is logged in
      /*
      if (this.tokenService.getToken() == "")  {      
        this.authenticate.isLoggedIn = false;
        this.router.navigate(["/login"]);
        return false;
      }
      else 
      */
      {
        //check if user has form access
 

          //return true;

          this.spinner.show();
          return this.permissionService.getAllSectionPermission().pipe(
            map((res) => {
              this.spinner.hide();
              if(res['value']){
                this.authenticate.permissionSection = res['value'] as any[];
                this.authenticate.userPermissions = res['Table1'] as any[];
              }

              if(this.authenticate.permissionSection.length > 0){

                if (route.data.Scode) {
                  //return true;
                  var Scode = route.data.Scode;
                  this.screenConfService.ActiveScode = Scode;
                  this.sqlService.Scode = Scode;

                  var scodeArr = this.authenticate.permissionSection.filter(a => a.scode == Scode);
               
                  if (scodeArr.length > 0){
                    return scodeArr[0].HasPermission;
                  }
                  else {

                    // redirect user to unauthorized compoenent
                    this.router.navigate(["/accessdenied"]); // replace url with unauthorized

                    return false; 
                  }
                }        

               
               
              }
              

            }));
          // return this.permissionService.getSectionPermission(Scode).pipe(
          //   map((res) => {
          //     this.spinner.hide();
          //     var hasRole = res['value'] as any[]
          //       if (hasRole.length > 0) {
          //           return hasRole[0].HasPermission;
                    
          //       } else {

          //         // redirect user to unauthorized compoenent
          //         this.router.navigate(["/accessdenied"]); // replace url with unauthorized

          //         return false; 
          //       }
          //   }));
  
          // this.confTableService.getSectionPermission(Scode, this.authenticate.user.ID).toPromise().then(res => {
          //   var data = res['value'] as any[];
          //   if (data.length > 0) {
          //     if (data[0].HasPermission == true) {
          //       this.authenticate.isLoggedIn = true;
          //       return true;
          //     }
          //   }
          // })
        
  
  
        
      }
  
  
      

   return true;
  }


}
