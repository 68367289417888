import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FN_GLAccount } from 'src/app/models/finance/fn_glAccount.model';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { AccountHeadService } from 'src/app/services/finance/account-head.service';
import { GlAccountsService } from 'src/app/services/finance/gl-accounts.service';
import { NatureService } from 'src/app/services/finance/nature.service';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';

@Component({
  selector: 'app-gl',
  templateUrl: './gl.component.html',
  styleUrls: ['./gl.component.scss']
})
export class GLComponent implements OnInit {

  primaryKey:number = 0;
  obj: FN_GLAccount;
  
  dataAccountHead:any[] = [];
  selectedAccountHead: any = null;
  dataNature:any[] = [];
  selectedNature: any = null;

  dataParentGL:any[] = [];
  dataParentGL_All:any[] = [];
  selectedParentGL:any = null;


  dataDimensionType: any[] = [
    {
      ID: 1,
      DimensionName: 'SUP'
    },
    {
      ID: 2,
      DimensionName: 'CST'
    },
    {
      ID: 3,
      DimensionName: 'BNK'
    }
  ];
  selectedDimensionType: any = null;
  

  constructor(
    public glAccountService:GlAccountsService,
    public accHeadService:AccountHeadService,
    public natureService:NatureService,
    public dialogService:DialogService,
    public hlp : ConfHelpService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.obj = new FN_GLAccount();
    this.loaderService.startLoader();
   }

  ngOnInit(): void {

    Promise.all([
      this.getAccHead(),
      this.getNature(),
      this.getGLaccParent()
    ]
    ).then(res => {

      if (this.data.primaryKey > 0)
        this.getData();
      else
        this.loaderService.endLoader();

    })

    
  }      

  setDefaultValues(){
    if(this.dataAccountHead.length > 0){
      this.selectedAccountHead = this.dataAccountHead[0];
    }

    if(this.dataNature.length > 0){
      this.selectedNature = this.dataNature[0];
    }
  }

  setDropDowns(){
    if(this.dataAccountHead.filter(res => res.ID == this.obj.AccountHeadID).length > 0){
      this.selectedAccountHead =  this.dataAccountHead.filter(res => res.ID == this.obj.AccountHeadID)[0];
    }

    if(this.dataNature.filter(res => res.ID == this.obj.NatureID).length > 0){
      this.selectedNature = this.dataNature.filter(res => res.ID == this.obj.NatureID)[0];
    }

    if(this.obj.DimensionType != null && this.obj.DimensionType != "")
      this.selectedDimensionType = this.dataDimensionType.filter(res => res.DimensionName == this.obj.DimensionType)[0];
    
  }

  getData(){
    this.primaryKey = this.data.primaryKey;
      this.glAccountService.getById(this.primaryKey).then(res => {
        this.obj = res as FN_GLAccount;
        this.setDropDowns()
        this.loaderService.endLoader();
      })
  }

   async getGLaccParent(){
    await this.hlp.getFormDataByHelpNo("invglaccount").toPromise().then(res=>{          
      this.dataParentGL_All = res['value'] as any[]; 
      this.dataParentGL = res['value'] as any[];  
        
    })
  }
  onHeadChange() {
    if (this.selectedAccountHead) {
      this.dataParentGL = this.dataParentGL_All.filter(a => a.AccountHeadID === this.selectedAccountHead.ID);
    } else {
      this.dataParentGL = [];
    }
  }

  // async getAccHead(){
  //   await this.accHeadService.getAll().then(res=> {
  //     this.dataAccountHead = res['value'] as any[];
  //   })
  // }

  async getAccHead() {
    
    await this.hlp.getFormDataByHelpNo("finaccountheads").toPromise().then(res => {
      this.dataAccountHead = res['value'] as any[];
    })

  }

  async getNature(){
    await this.natureService.getAll().then(res=> {
      this.dataNature = res['value'] as any[];
    })
  }

  onSave(){

    if(this.selectedNature)
        this.obj.NatureID = this.selectedNature.ID;

    if(this.selectedDimensionType)
        this.obj.DimensionType = this.selectedDimensionType.DimensionName;

        if(this.selectedAccountHead)
        this.obj.AccountHeadID = this.selectedAccountHead.ID;

    if(this.selectedParentGL)
        this.obj.ParentAccountID = this.selectedParentGL.ID;

    if (this.primaryKey == 0) {
      this.glAccountService.post(this.obj).subscribe(res => {
        // this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('fin_01', "save");
      });
    }
    else {
      this.glAccountService.update(this.obj, this.primaryKey).subscribe(res=> {
        // this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('fin_01', "save");
      })
    }
}


onDelete(){
  if (this.primaryKey > 0 ) {
    this.dialogService.openSmallDialog(YesNoComponent);

    this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
      if (result) {
          if (result == "yes") {
            this.glAccountService.delete(this.primaryKey).subscribe(res => {
              //this.dialogService.closeDialog("delete");
              this.dialogService.closeDialog_List('fin_01', "delete");
            })
          }
      }
    })
  }
}


}