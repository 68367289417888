<div class="col-md-11 col-sm-4 col-1 text-right align-middle">

    <mat-form-field appearance="outline" class="matSearchFields d-none d-sm-none d-md-inline d-xl-inline d-lg-inline"
        style="float:right">
        <mat-icon matPrefix class="me-2">search</mat-icon>
        <input matInput placeholder="Search ex SI-101" [matAutocomplete]="auto"
            [formControl]="searchMoviesCtrl">
        <button mat-button *ngIf="searchMoviesCtrl.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="searchMoviesCtrl.setValue('')">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete class="matSearchFieldsOptions" #auto="matAutocomplete"
            (optionSelected)="openRoute($event.option.value.route, $event.option.value.ID)">
            <!-- (optionSelected)="openRoute($event.option.value.route, $event.option.value.RecordID)" -->
            <mat-option *ngIf="isLoading" class="is-loading" disabled><span matSuffix
                    class="spinner-border spinner-border-sm mr-2"></span>Searching... </mat-option>
            <mat-option disabled *ngIf="EventData.length > 0 && !isLoading"
                style=" position: sticky;top: 0;z-index: 1;background-color: white; line-height:22px;border-bottom: 1px solid #d6d6d8; text-align: center; color: gray;">
                Results found: {{EventData.length}}
            </mat-option>
            <ng-container *ngIf="!isLoading">

                <mat-option *ngFor="let event of EventData" [value]="event" style="font-size:14px;line-height:22px;border-bottom: 1px solid #d6d6d8;padding-top: 4px;
              padding-bottom: 4px; height:78px;">
                    <a>
                      
                        <b style=" font-size: 16px">
                            {{event.Title}}
                        </b>
                        <br>
                        <b style="font-weight: 500; font-size: 13px; color:gray">
                            {{event.DocumentNo}} | Date: {{event.Documentdate | date:'MM/dd/yyyy'}}                            
                        </b>

                    </a>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>
    <mat-icon matRipple class="d-md-none d-lg-none mt-2" (click)="openBottomSheet()">search</mat-icon>
    <!-- <input type="text" class="form-control"> -->
</div>