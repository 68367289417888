<mat-drawer-container class="example-container" >
        <!-- <mat-drawer #drawer mode="over" class="sidenav" [class]="theme.myLeftNavColor">
                <app-side-nav [drawer]="drawer" *ngIf="authenticate.isLoggedIn"></app-side-nav>
        </mat-drawer> -->
        <!-- [ngClass]="{'sidenav' : var_sidenav == true, 'sidenav-small' : var_sidenav == false}" -->

        <!-- <mat-drawer 
        #drawer 
        [opened]="mobileQuery.matches ? false : true" 
        [mode]="mobileQuery.matches ? 'over' : 'side'"    
        class="sidenav">
            <app-side-nav [drawer]="drawer" *ngIf="authenticate.isLoggedIn"></app-side-nav>            
        </mat-drawer> -->

        <mat-drawer 
        #drawer 
        [mode]="sidenav?.mode"    
        class="sidenav">
            <app-side-nav [drawer]="drawer" *ngIf="authenticate.isLoggedIn"></app-side-nav>            
        </mat-drawer>


        <mat-drawer  #drawer2 position="end" mode="over" hasBackdrop haB class="rightnav" >            
            <app-right-nav  [drawer]="drawer2"></app-right-nav>
        </mat-drawer>
        <mat-drawer-content>

            <!-- <app-topnav appName="BMS" 
            (menuClick)="togglesidenav()"
            (bellClick)="drawer2.toggle()"           
            (appClick)="onAppClick()"           
            class="fixed-top">
            </app-topnav> -->

            <app-topnav appName="Bizmanto" 
            (menuClick)="drawer.toggle()"
            (bellClick)="drawer2.toggle()"           
            (appClick)="onAppClick()"
            [dataLength]="duesLength"           
            class="fixed-top">
            </app-topnav>
          
            <!-- loader work sufyan  -->
           <app-loader *ngIf="loaderService.loading == true"></app-loader>
            <!-- <ngx-skeleton-loader *ngIf="loaderService.loading == true"  count="6" appearance="line"></ngx-skeleton-loader>  -->
            <!-- loader work sufyan  -->

            <div class="container-fluid cstmLoader mt-6" [style.opacity]="loaderService.loading ? '0' : '1'" [style.visibility]="loaderService.loading ? 'hidden' : 'visible'">
     
                <router-outlet> </router-outlet>

            </div>
        </mat-drawer-content>
</mat-drawer-container>



