<div class="top-ribbon-dialog">
    <app-ribbon-save-dialog [hidden]="BankAccount.invalid" (myClick)="onSave()"
        [valid]="BankAccount.invalid"></app-ribbon-save-dialog>
    <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>
<app-my-heading-dialoge mat-dialog-title heading="Bank Account Information"></app-my-heading-dialoge>

<form #BankAccount="ngForm" class="rounded needs-validation">
    <div class="row g-2">



        <app-my-select [createOption]="true" optionName="Bank" (onCreate)="onBankCreate()" [search]="true" label="Select Bank" title="BankName" [data]="dataBank" name="BankName"
            [(ngModel)]="selectedBank" class="col-md-6" required="true">
        </app-my-select>

        <app-my-input label="Account Name" name="AccountName" [(ngModel)]="obj.AccountName" class="col-md-6"
            required="true">
        </app-my-input>
        <app-my-input label="Account #" name="AccountNo" [(ngModel)]="obj.AccountNo" class="col-md-6" required="true">
        </app-my-input>
        <app-my-input label="IBAN" name="IBAN" [(ngModel)]="obj.IBAN" class="col-md-6">
        </app-my-input>
        <app-my-input label="Area" name="Area" [(ngModel)]="obj.Area" class="col-md-6">
        </app-my-input>
        <app-my-input label="Block" name="Block" [(ngModel)]="obj.Block" class="col-md-6">
        </app-my-input>

    </div>
    <div align="end">
        <app-button-save-dialog (myClick)="onSave()" [valid]="BankAccount.invalid"></app-button-save-dialog>
    </div>



</form>