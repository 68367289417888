<!-- <mat-form-field appearance="outline" class="w-100" [style.fontSize.px]="12" autocomplete="off">
    <input matInput #swiper  (keyup)="onChange($event)" placeholder="Search"  autocomplete="off">
</mat-form-field> -->

<div class="d-flex align-items-center" [class]="filter ? 'mysearchdisabled' : ''">
        <mat-icon *ngIf="!filter" style="position: absolute;
        padding-left: 15px;">search</mat-icon>
        <input [disabled]="filter" [(ngModel)]="search" [class]="filter ? 'mysearchdisabled' : ''" type="search" class="form-control form-control-sm  m-2 mysearch" style="    padding-left: 34px;"  #swiper   placeholder="Search"  autocomplete="off">
        <button class="cstmSearch" (click)="onSearch()"  aria-label="Example icon button with a home icon">   
                Search
        </button>
    </div>
      