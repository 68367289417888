<div class="header3 ">
        <div class="d-flex  justify-content-between  ">
                <h2 style="color: red; padding-left: 12px;  padding-top: 2px;">Error Dialog</h2>

                <button class="btn mat-button " mat-dialog-close> <mat-icon class="closeColor">close</mat-icon>
                </button>

        </div>

</div>
<div>
        <div class="row gx-0">
                <div class="col-md-3">
                        <span class="material-symbols-outlined">
                                <mat-icon class="icon3">error_med</mat-icon>
                        </span>
                </div>
                <div class="col-md-9">
                        <h2 class="title3">You have unsaved data </h2>
                        <p class="mt-1">Do You Know Material X System Contains
                                material design components re-styled
                                as it should look and behave for today
                        </p>
                </div>
        </div>
</div>

<div class="footer">



        <mat-dialog-actions class="Divbtn1">
                <button class="btn btn-outline-secondary ">
                        Secondary

                </button>

                <button class="btn btn-primary action1 ms-3  ">
                        Primary Action
                </button>
        </mat-dialog-actions>



</div>