import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { publicDecrypt } from 'crypto';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { ItemComponent } from '../../inventory/item/item.component';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-quick-action-component',
  templateUrl: './quick-action-component.component.html',
  styleUrls: ['./quick-action-component.component.scss']
})
export class QuickActionComponentComponent implements OnInit {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<QuickActionComponentComponent>,
      public routeService:RouteService,
      public router:Router,

      
    public dialogService:DialogService,
        
    
    ) { }

  ngOnInit(): void {
  }

  openLink(module:string, routeName:string ){
    this._bottomSheetRef.dismiss();
    this.router.navigate([this.routeService.openRoute(module, routeName)]);

  }

  onItemCreate(){
    this.dialogService.openQuickCreateDialog_List(ItemComponent, 'inv_01');
    this.dialogService.getDialog_List('inv_01').afterClosed().subscribe(result => {
      if (result) {
        if (result == "save") {
          console.log("ITEM SAVED");
         }
        }
      });
  }

}
