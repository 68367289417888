import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import * as customConfig from 'src/assets/files/customConfig.json'
import { msalConfig, protectedResources } from '../../auth-config';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    public authService:MsalService
  ) { }

  getToken():string{
    var token = localStorage.getItem(customConfig.myBmsTokenName);
    if (token == "" || token == null || token == undefined) {
      return "";
    }
    else {
      return token;
    }
  }

  setToken(token:string){
    localStorage.setItem(customConfig.myBmsTokenName, token);
  }

  getId_Token(url:string){
    if (url.includes("#id_token=")) {              
          var token: string[] = url.split("id_token=");
          return token[1];            
    }
    return "";
  }

  setAccessToken(token:any){

    localStorage.removeItem('x_token');

    var obj = {
      access_token: token.accessToken,
      expiresOn: token.expiresOn
    }
    var token_stringfy = JSON.stringify(obj);
    localStorage.setItem('x_token', token_stringfy);
  }

  getAccessToken():any{
    var token = localStorage.getItem('x_token');
    if (token == "" || token == null || token == undefined) {
      return "";
    }
    else {
      token= localStorage.getItem("x_token");
      return JSON.parse(token) as any;
    }
  }

  validateAccessToken(): boolean {

    if (!this.getAccessToken())
      return false;

    if (!this.getAccessToken().expiresOn)
      return false;

    const now = new Date();
    const sessionTimeout = new Date(this.getAccessToken().expiresOn);

    if (now > sessionTimeout) {
      return false;
    }

    return true;

  }

   acquireTokenSilently(): Observable<AuthenticationResult | null>{
    return this.authService.acquireTokenSilent({ scopes: [protectedResources.todoListApi.scopes[0], 'openid', 'profile', 'offline_access'] })
    .pipe(
      map(token => {
        if (token)
          return token;
        else 
          return null;
       
      })
    );

  }

  acuireSilentToken(){
    return this.authService.acquireTokenSilent({ scopes: [protectedResources.todoListApi.scopes[0], 'openid', 'profile', 'offline_access'] })
  }

  IsvalidSession(currUser: any) :boolean{

    if(!currUser.sessionTimeout){
      return false;
    }

    const now = new Date();
    const sessionTimeout = new Date(currUser.sessionTimeout);

    if (now > sessionTimeout) {
      return false;
    }

    return true;
  }


}
