<div class="main">
        <div class="shortCodeDiv round3">
                S
        </div>
        <div class="titleDiv">
                Welcome abroad Sameer Patel!
        </div>
        <div class="descriptionDiv">
                Thank You For Choosing Bizmanto. Before you start. we`d love to show
                you around  and help you navigate the app.
        </div>
        <div class="btnDiv">
                <button class="btnn">Show Me Around</button>
        </div>
        <div class="linkDiv">
         <a href="#" class="linkText"> No thanks. i'll explore it.</a>
        </div>

</div>
