import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-my-table-search-http',
  templateUrl: './my-table-search-http.component.html',
  styleUrls: ['./my-table-search-http.component.scss']
})
export class MyTableSearchHttpComponent  implements OnInit,AfterViewInit, OnChanges {
  @ViewChild('swiper') swiper: ElementRef;
  @Output() change = new EventEmitter<String>();
  @Input() filter:boolean = false;
  search:string = '';
  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges){
    
  }

  ngAfterViewInit(): void {
    //this.swiper.nativeElement.focus();
  }


  onChange(event){
    //this.change.emit( (event.target as HTMLInputElement).value);
    this.change.emit( event);
  }

  onSearch(){
    this.onChange(this.search)
  }

}
