

export class Quick_Journal_Voucher{
        ID: number = 0;
        Date: any = null;
        DebitAccountHeadID: number = null;
        DebitAccountID: number = null;
        CreditAccountHeadID: number = null;
        CreditAccountID: number = null;
        Amount : number = null;
        Notes : string = null;
        Scode : string = null;
}