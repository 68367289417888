import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JwtHelperService {

  private urlBase64Decode(str: string) {

      let result = '';

      let output = str.replace(/-/g, '+').replace(/_/g, '/');

      switch (output.length % 4) {
          case 0:
              break;
          case 2:
              output += '==';
              break;
          case 3:
              output += '=';
              break;
          default:
              break;
      }

      try {
          result = decodeURIComponent((<any>window).escape(window.atob(output)));
      }
      catch{}

      return result;
  }

  public decodeToken(token: string = '') {

      if (token === null || token === '') { return { 'upn': '' }; }

      const parts = token.split('.');
      const decoded = this.urlBase64Decode(parts[1]);

      if (!decoded) {
          return '';
      }

      return JSON.parse(decoded);
  }
}