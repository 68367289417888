import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-demo-save',
  templateUrl: './demo-save.component.html',
  styleUrls: ['./demo-save.component.scss']
})
export class DemoSaveComponent implements OnInit {

  @Input() button_text:string = '';
  @Input() array_data:any[] = [];

  @Output() btn_click:EventEmitter<any> =  new EventEmitter<any>(); 

  @Output() btn_display_click:EventEmitter<any> =  new EventEmitter<any>(); 

  constructor() { }

  ngOnInit(): void {
  }

  onClick(){
    this.btn_click.emit();
  }

  displayClick(){
    this.btn_display_click.emit();
  }

}
