import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Bank } from 'src/app/models/finance/bank.model';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { BanksService } from 'src/app/services/finance/banks.service';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';
import { BaseComponent } from '../../base/base.component';
import { LoaderService } from 'src/app/services/base/loader.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {
  obj:Bank;
  primaryKey:number = 0;

  parent:string="";
  constructor(
    public bankService:BanksService,
    public dialogService:DialogService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.obj = new Bank();
    this.loaderService.startLoader();
   }

  ngOnInit(): void {
 
      if(this.data.primaryKey > 0)
        this.getData();
        else 
        this.loaderService.endLoader();
      
  }

  async getData() {    
    this.primaryKey = this.data.primaryKey;
    this.bankService.getById(this.primaryKey).then(res => {
      this.obj = res as Bank;
      this.loaderService.endLoader();
    });
  }

  onSave() {

    this.loaderService.startLoaderOnSave();
    if (this.primaryKey == 0) {
      this.bankService.post(this.obj).subscribe(res => {
        this.loaderService.endLoaderOnSave();
        // this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('fin_02', "save");

      });
    }
    else {
      this.bankService.update(this.obj, this.primaryKey).subscribe(res => {
        this.loaderService.endLoaderOnSave();
        this.dialogService.closeDialog_List('fin_02', "save");
        // this.dialogService.closeDialog("save");
      }); // Updating Data
    }
  }

  



onDelete() {
  if (this.primaryKey > 0) {
    this.dialogService.openSmallDialog(YesNoComponent);

    this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
      if (result) {
        if (result == "yes") {
          this.bankService.delete(this.primaryKey).subscribe(res => {
            //this.dialogService.closeDialog("delete");
            this.dialogService.closeDialog_List('fin_02', "delete");
          })
        }
      }
    });

  }
}

  // onBack(){
  //   if(this.parent == "financehome")
  //     this.router.navigate([this.routeService.openRoute("finance", "Home")])  
  //   else if(this.parent == "bank-list")
  //     this.router.navigate([this.routeService.openRoute("finance", "bank-list")]);
  //   else if(this.parent == "home")
  //     this.router.navigate([this.routeService.getHomeRoute()]) 
  // }

  // onAdd(){
  //   this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  //     this.router.navigate([this.routeService.openRoute("finance", "bank")], { queryParams: { parent: this.parent } })
  //   );
  // }

}
