import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-th',
  templateUrl: './th.component.html',
  styleUrls: ['./th.component.scss']
})
export class ThComponent implements OnInit {
@Input() text:string = "";
@Input() icon:string = "";
@Input() width:string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
