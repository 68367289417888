import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-my-ribbon-button',
  templateUrl: './my-ribbon-button.component.html',
  styleUrls: ['./my-ribbon-button.component.scss']
})
export class MyRibbonButtonComponent implements OnInit {

  @Input() title:string = "";
  @Input() icon:string = "";
  @Output() myClick =  new EventEmitter();
  
  constructor(public theme:ThemeService) { }

  ngOnInit(): void {
  }

  
  onClick(){
    this.myClick.emit();
  }

}
