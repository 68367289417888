import { Injectable } from '@angular/core';
import { SqlService } from './sql.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  
  constructor(
    public sql:SqlService,
    
    ) { }
}
