
<div class="topBar">
        <div class="d-flex align-items-center justify-content-between">
                <div>
                        <div class="d-flex align-items-center">
                        
                        <h2 class="ms-3">Items</h2>
                         </div>
                </div>
                <div>
                        <div class="d-flex">
                                <div class="topBtns">
                                        <button class="btn btnTopIcons"> <mat-icon class="topNavIcon printIconColor">print</mat-icon> Print
                                        </button>
                                        <button class="btn btnTopIcons"> <mat-icon class="topNavIcon editIconColor">edit</mat-icon> Edit
                                        </button>
                                        <button class="btn btnTopIcons"> <mat-icon class="topNavIcon deleteIconColor">delete</mat-icon> Delete
                                        </button>
                                </div>
                        
                                <div class="closeBtnDiv">
                                        <button class="btn" mat-dialog-close> <mat-icon class="closeColor">close</mat-icon>
                                        </button>
                                </div>
                        
                        </div>
                        
                </div>
        </div>
        <hr>


</div>

<div class="contentDiv">
        <div class="row">
                <div class="col-md-4">

                        <div class="card mb-3 cardHeight shadow">
                                <div class="txtTitle">
                                        Walking Customer
                                </div>
                                <hr>
                                <table class="table table-borderless">                                 
                                        <tbody class="leftTable">
                                            <tr>
                                                <td>Invoice #</td>
                                                <td>SI-101</td>
                                            </tr>
                                            <tr>
                                                <td>Date</td>
                                                <td>12-25-2000</td>
                                            </tr>
                                            <tr>
                                                <td>Payment Mode</td>
                                                <td>Cash</td>
                                            </tr>
                                            <tr>
                                                <td>Due Date</td>
                                                <td class="dueDateColor">12-28-2000</td>
                                            </tr>                    
                                        </tbody>                
                                    </table> 
                                    <hr>
                                <h3 class="mb-2" style="color: #595e64;">Description</h3>     
                                <p>NA</p>
                        </div>

                      
                </div>

                <div class="col-md-8">
                
                <table class="tableDetail shadow-sm mb-3">
                        <thead class="detailTH">
                                <tr>
                                        <th>#</th>
                                        <th class="item">Item</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Amount</th>
                                        <th>Discount</th>
                                        <th>Net Amount</th>
                                </tr>
                        </thead>  
                        <tbody>
                                <tr>
                                        <td class="serial">1</td>
                                        <td class="item">Bio Amla Shampoo</td>
                                        <td class="qty">8</td>
                                        <td class="price">275</td>
                                        <td class="amount">1,200</td>
                                        <td class="discount">0</td>
                                        <td class="netamount">1,200</td>
                                </tr>
                                <tr>
                                        <td class="serial">2</td>
                                        <td class="item">Keratin and Biotin Clear Shampoo</td>
                                        <td class="qty">2</td>
                                        <td class="price">460</td>
                                        <td class="amount">820</td>
                                        <td class="discount">15</td>
                                        <td class="netamount">805</td>
                                </tr>
                        </tbody>
                </table>

                <div class="cstmCard">
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Amount</div>
                                <div class="txtMainSummary">$1,450</div>
                        </div>
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Discount</div>
                                <div class="txtMainSummary">$0.00</div>
                        </div> <hr style="margin: 0 !important;">
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Net Amount</div>
                                <div class="txtNetSummary">$1,450</div>
                        </div>
                </div>






                </div>
        </div>
</div>