<div class="top-ribbon-dialog">
      
        <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>
    <app-my-heading-dialoge mat-dialog-title heading="Journal Entry Information"></app-my-heading-dialoge>
    

    <form #JournalEntry="ngForm" class="rounded needs-validation">

        <div class="d-flex justify-content-between">
        
        
                <div>
                       
                                <app-my-input-date label="Date" name="Date" [(ngModel)]="obj.Date" class="col-md-6"
                                        required="true">
                                </app-my-input-date>
                       
        
        
                       
                                <app-my-select [search]="true" label="Expense Account" title="GLAccountName"
                                        [data]="dataExpense" name="GLAccountName" [(ngModel)]="selectedExpense"
                                        class="col-md-6" required="true">
                                </app-my-select>
                        
                </div>
        
                <div>
                        <div class="d-flex gap-3 mb-3">
                                <button class="chipsBox" *ngFor="let data of dataPayment" [class.active]="isSelected(data)"
                                        (click)="onPayment(data)">
                                        {{data.PaymentTypeName}}
                                </button>
        
                        </div>
        
                      
                                <app-my-input label="Amount" name="Amount" [(ngModel)]="obj.Amount" class="col-md-6"
                                        required="true">
                                </app-my-input>
                     
        
                      
                                <app-my-input label="Cheque No" name="ChequeNo" [(ngModel)]="obj.ChequeNo" class="col-md-6">
                                </app-my-input>
                       
        
        
                       
                                <app-my-input-date label="Cheque Date" name="ChequeDate" [(ngModel)]="obj.ChequeDate"
                                        class="col-md-6">
                                </app-my-input-date>
                     
                      
                                <app-my-select [search]="true" label="Bank" title="BankName" [data]="dataBank" name="BankName"
                                        [(ngModel)]="selectedExpense" class="col-md-6">
                                </app-my-select>
                      
                      
                                <app-my-select [search]="true" label="Bank Account" title="AccountName" [data]="dataBankAccount"
                                        name="AccountName" [(ngModel)]="selectedExpense" class="col-md-6">
                                </app-my-select>
                        
                </div>
        </div>
       
   

     
       

    </form>
