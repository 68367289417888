
export class Company {
    ID: number = 0;
    ClientID: number = null;
    CompanyName: string = null;
    City: string = null;
    State: string = null;
    ZipCode: number = null;
    Phone: string = null;
    ContactNo: string = null;
    Email: string = null;
    Website: string = null;
    Address: string = null;   
    Fax: string = null;
    CreatedDate: string = null;
    CreatedBy: number = null;
    CreatedTerminal: string = null;
    CreatedTerminalIp: string = null;
    ModifiedDate: string = null;
    ModifiedBy: number = null;
    ModifiedTerminal: string = null;
    ModifiedTerminalIp: string = null;
    OwnerName: string = null;
    CompanyObjectId: string = null;
    StartDate: any = null;
    EndDate: any = null;
    IsTrial: boolean = null;
    StripeSubscriptionID: string = null;
    StripeCustomerID: string = null;
}