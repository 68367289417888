import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-my-icon-button-table',
  templateUrl: './my-icon-button-table.component.html',
  styleUrls: ['./my-icon-button-table.component.scss']
})
export class MyIconButtonTableComponent implements OnInit {
  
  @Input() icon:string = "";
  @Input() tooltip:string = "";
  @Output() myClick =  new EventEmitter();
  
  constructor(public theme:ThemeService) { }

  ngOnInit(): void {
  }

  onClick(){
    this.myClick.emit();
  }

}