import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../loader.service';
import { finalize, mergeMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenService } from '../token.service';
import * as customConfig from 'src/assets/files/customConfig.json';
import { ThemeService } from '../theme.service';
import { LocalStorageService } from '../local-storage.service';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    public loaderService:LoaderService,
    private spinner: NgxSpinnerService,
    public tokenService:TokenService,
    public themeService:ThemeService,
    public localStorageService: LocalStorageService,
    private authService: MsalService
  ) {}

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    var CompanyObjectID:string = '';
    var Token:string = '';
    
    if(this.localStorageService.getCurrentUser())
     CompanyObjectID =  this.localStorageService.getCurrentUser().CompanyObjectId;


    if (this.tokenService.validateAccessToken()) {
      Token = this.tokenService.getAccessToken().access_token;
      var request = this.setHeader(request, Token, CompanyObjectID);
      return this.executerequest(request, next);
    } else{


      return this.tokenService.acuireSilentToken()
      .pipe(
        mergeMap(token => {
          if (token) {
            this.tokenService.setAccessToken(token);
            Token = this.tokenService.getAccessToken().access_token;
            request = this.setHeader(request, Token, CompanyObjectID);
            return  this.executerequest(request, next);
          }
          
        })
      );

      /*
      this.tokenService.acquireTokenSilently().subscribe(res=>{
        this.tokenService.setAccessToken(res);
        Token = this.tokenService.getAccessToken().access_token;
        var request = this.setHeader(request, Token, CompanyObjectID);
        return this.executerequest(request, next);
      });

      */
     
      
    }

    
    
   
    

  }

  executerequest(request: HttpRequest<unknown>, next: HttpHandler){
    this.themeService.showSpinner();
    return next.handle(request).pipe(
      finalize(
        () => {
          //this.loaderService.isLoading.next(false);
          //this.spinner.hide();
          this.themeService.hideSpinner();
        }
      )
    );
  }

  setHeader(request: HttpRequest<unknown>, Token, CompanyObjectID){
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${Token}`,
        'Token': Token,
        'SubscriptionKey': customConfig.SubscriptionKey,
        'CompanyObjectID': CompanyObjectID
      },
    });

    return request;

  }


}
