<div class="top-ribbon-dialog">
        <app-ribbon-save-dialog [hidden]="item.invalid" (myClick)="onSave()" [valid]="item.invalid"></app-ribbon-save-dialog>
        <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
        <span class="spacer"></span>
        <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>
<app-my-heading-dialoge mat-dialog-title heading="Item Information"></app-my-heading-dialoge>


<form #item="ngForm" class="rounded needs-validation ">

        <div class="row g-2">

                    <app-my-input label="Item Name" class="col-md-12" required="true"  name="ItemName" [(ngModel)]="obj.ItemName">
                    </app-my-input>
                    <app-my-select [createOption]="true" optionName="Category" (onCreate)="onCategoryCreate()" [search]="true" label="Category" title="CategoryName"  name="Category" [(ngModel)]="selectedCategory"   [data]="dataCategory" class="col-md-6">
                </app-my-select>
                    <app-my-select [createOption]="true" optionName="Brand" (onCreate)="onBrandCreate()" [search]="true" label="Brand" title="MakeName"  name="Brand" [(ngModel)]="selectedBrand"   [data]="dataBrand" class="col-md-6">
                </app-my-select>
                 
                    <app-my-select [search]="true"  label="Item Type" title="ItemTypeName"  name="ItemType" [(ngModel)]="selectedItemType"   [data]="dataItemType" class="col-md-6">
                    </app-my-select>

                <app-my-select [createOption]="true" optionName="Unit" (onCreate)="onUnitCreate()" [search]="true" label="Unit" title="UnitName" name="Unit"
                        [(ngModel)]="selectedUnit" [data]="dataUnit" class="col-md-6">
                </app-my-select>

                    <app-my-input-currency label="Cost Price" class="col-md-6 col-6" name="CostPrice" [(ngModel)]="obj.CostPrice">
                    </app-my-input-currency>
                    <app-my-input-currency label="Sale Price" class="col-md-6 col-6" name="SalePrice" [(ngModel)]="obj.SalePrice">
                    </app-my-input-currency>

                    <app-my-input  class="col-md-6" label="Description"  name="Description" [(ngModel)]="obj.Note">
                    </app-my-input>   
                    <span class="checkbox">
                                      <mat-checkbox [(ngModel)]="obj.IsActive"
                                      name="IsActive">
                                      <h3 class="mx-2">Active</h3> 
                                        
                                      </mat-checkbox>
                    </span>
          
               
        </div>        
        <div align="end" >                
                <app-button-save-dialog (myClick)="onSave()" [valid]="item.invalid"></app-button-save-dialog>
        </div>
</form>
