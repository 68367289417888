import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parent-base',
  templateUrl: './parent-base.component.html',
  styleUrls: ['./parent-base.component.scss']
})
export class ParentBaseComponent implements OnInit {

  constructor(public router?: Router) {        

    console.log(this.router.url);
    window.onpopstate = function (event) {
        console.log("Forward/Back is button pressed. That's why reloading the page.");
        window.location.reload();
    }

    if (router.navigated) {

        if(this.router.getCurrentNavigation() == null)
        return;

        const navigationURL = {
            route: this.router.url.split('?')[0],
            queryParams: this.router.getCurrentNavigation().extractedUrl.queryParams
        };

        sessionStorage.setItem('navigationURL', JSON.stringify(navigationURL));
    } else {
        //To maintain routing after page refresh
        const savedNavigationURL = sessionStorage.getItem('navigationURL');
        if (savedNavigationURL) {
            const navigationURL = JSON.parse(savedNavigationURL);
            console.log('navigationURL after page reload:', navigationURL);
            this.router.navigate([navigationURL.route], { queryParams: navigationURL.queryParams });

            sessionStorage.removeItem('navigationURL');
        }
    }
   
}

  ngOnInit(): void {
  }

}
