<div class="auditTop d-flex py-1" style="margin-bottom: 18px;">
 <h2 class="w-100 ps-3">Recent Activity</h2>
 <button class="me-2" mat-icon-button (click)="drawer.close()"><mat-icon>close</mat-icon></button>
</div>

<a *ngFor="let data of recentActivityService.dataNotification" class="py-2" (click)="openRoute(data.route, data.RecordID)">
        <div class="d-flex px-1" style="margin-bottom: 26px;">
            <div class="col-md-2 me-2">
                <div class="d-flex px-2">
                    <span class="iconDiv d-flex align-items-center justify-content-center mt-1">
                        <!-- <mat-icon style="color: #82be84;">add</mat-icon> -->
                        <mat-icon *ngIf="data.Action == 'ADD'" style="color: #095e8f">add</mat-icon>
                        <mat-icon *ngIf="data.Action == 'UPDATE'" style="color: #095e8f">edit</mat-icon>
                        <mat-icon *ngIf="data.Action == 'DELETE'" style="color: #095e8f">delete</mat-icon>
                    </span>
                    
                </div>
            </div>
            <div class="col-md-9">
                <div>
                    <div class="d-flex justify-content-between">
                        <p>{{data.SectionName}}</p>
                        <p style="font-size: 12px;" class="text-muted">{{data.CreatedDate | date:'short'}}</p>
                    </div>
                    <p style="margin-top:-14px" class="textMute">{{data.Message}}</p>
                </div>
                <div style="margin-top: 8px;">
    
    
                    <div *ngIf="data.Action == 'ADD'" class="addIconDiv">
    
                        <p style="margin-bottom: 0rem; font-size: 14px; color: #1da152; ">
                            Created</p>
                    </div>
    
                    <div *ngIf="data.Action == 'DELETE'" class="removeIconDiv">
    
                        <p style="margin-bottom: 0rem; font-size: 14px; color: #9b170c;">
                            Removed</p>
                    </div>
    
                    <div *ngIf="data.Action == 'UPDATE'" class="editIconDiv">
    
                        <p style="margin-bottom: 0rem; font-size: 14px; color: #a3a30c;">
                            Modified</p>
                    </div>
    
                </div>
            </div>
        </div>
</a>


<!-- <button mat-icon-button color="primary" (click)="drawer.close()" aria-label="Example icon button with a home icon">
        <mat-icon >close</mat-icon>
      </button> -->
<!-- 
<a *ngFor="let data of dataNotification" class="card cardDesign py-1" (click)="openRoute(data.route, data.RecordID)">
                
                <div class=" card-header d-flex justify-content-between px-2">
                        <div class="d-flex align-items-center">

                                <h4 class="me-2">{{data.SectionName}} </h4>

                               
                        </div>
                       
                </div>
               
                <div class="d-flex align-items-center mt-2 px-2">
                  
                        <div class="mt-2"> 
                                 
                                <p style="font-size: 11px;">{{data.Message}}</p>
                        </div>
                </div> 

                <div class="card-footer cardfootDiv">
                        <div class="d-flex justify-content-between">

                                <div *ngIf="data.Action==='ADD'" class="d-flex align-items-center">
                                        <mat-icon class=" actionIcons" style="color: #4BB543;">add</mat-icon>
                                        <p> {{data.Action}}</p>
                                
                                </div>
                                
                                <div *ngIf="data.Action==='UPDATE'" class="d-flex align-items-center">
                                        <mat-icon class=" actionIcons" style="color: #FFBF00;">update</mat-icon>
                                        <p> {{data.Action}}</p>
                                
                                </div>
                                
                                <div *ngIf="data.Action==='DELETE'" class="d-flex align-items-center">
                                        <p> {{data.Action}}</p>
                                        <mat-icon class=" actionIcons" style="color: red;">delete</mat-icon>
                                </div>
                                <p class="text-muted">{{data.CreatedDate | date:'MM/dd/yyyy'}} </p>
                        </div>
                </div>

        

</a> -->
<!-- <a class="card px-2 py-2 cardDesign">
                
        <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                        <h3 class="me-2">PI-121 - </h3>
                        <h5> ADD</h5>
                </div>
                <p class="text-muted">20/7/2011</p>
        </div>
       
        <div class="d-flex align-items-center mt-2">
                <div class="vl me-2"></div>
                <div class="mt-2"> <p>Your Item PI-212 Successed</p>
                </div>
        </div> 



</a> -->