import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringDecoder } from 'string_decoder';
import { AuthenticateService } from './authenticate.service';
import { BaseService } from './base.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  userPermissions: any []=[];

  constructor(
    public base:BaseService,
    public authenticate:AuthenticateService,
    public localStorageService:LocalStorageService,
  
  ) { }

  getSectionPermission(scode:string){
    return this.base.sql.getBySqlQueryBody("exec sp_UserSectionPermission '" + scode + "' , " + this.localStorageService.getCurrentUser().ID);
  }

  getAllSectionPermission(){
    if(this.authenticate.permissionSection.length == 0)
      return this.base.sql.getBySqlQueryBody("exec sp_AllUserSectionPermission " + this.localStorageService.getCurrentUser().ID);
    else
      return  of([]);
  }

  getAllUserDashboard(){
   return this.base.sql.http.get(this.base.sql.rootUrl + "api/SignIn/AllUserDashboard?userid=" + this.localStorageService.getCurrentUser().ID);
    //return this.base.sql.getBySqlQueryBody("exec sp_AllUserDashboard  " + this.localStorageService.getCurrentUser().ID);
  }

  getHomePermission(){
    return this.base.sql.getBySqlQueryBody("exec sp_getUserGroup " + this.localStorageService.getCurrentUser().ID);
  }

   getAddPerm(scode: string) {
    //return await this.base.sql.getBySqlQueryBody("exec sp_UserActionPermission '" + scode + "' , " + this.localStorageService.getCurrentUser().ID + ", 'add'").toPromise();


    var data  = this.authenticate.userPermissions.filter(a => a.scode == scode && a.Action == 'add')
    if(data.length > 0){
     return data[0].HasPermission;
     //this.add = hasRole; // hasRole > 0 ? hasRole[0].HasPermission : false;
    }

  }

   getEditPerm(scode: string) {

    var data  = this.authenticate.userPermissions.filter(a => a.scode == scode && a.Action == 'edit')
    if(data.length > 0){
     return data[0].HasPermission;

    // return await this.base.sql.getBySqlQueryBody("exec sp_UserActionPermission '" + scode + "' , " + this.localStorageService.getCurrentUser().ID + ", 'edit'").toPromise();
  }
  }
   getViewPerm(scode: string) {
    var data  = this.authenticate.userPermissions.filter(a => a.scode == scode && a.Action == 'view')
    if(data.length > 0){
     return data[0].HasPermission;
    // return await this.base.sql.getBySqlQueryBody("exec sp_UserActionPermission '" + scode + "' , " + this.localStorageService.getCurrentUser().ID + ", 'view'").toPromise();
  }}

   getDeletePerm(scode: string) {
    var data  = this.authenticate.userPermissions.filter(a => a.scode == scode && a.Action == 'delete')
    if(data.length > 0){
     return data[0].HasPermission;
    // return await this.base.sql.getBySqlQueryBody("exec sp_UserActionPermission '" + scode + "' , " + this.localStorageService.getCurrentUser().ID + ", 'delete'").toPromise();
  }}

  async getPermissionAll(scode: string){
    var url: string = `exec sp_UserActionPermissionAll '${scode}'`;
    return await this.base.sql.getBySqlQueryBody(url).toPromise().then(res =>{
       this.userPermissions = res['value'];
    });

  }

  

}
