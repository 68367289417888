import { Component, OnInit } from '@angular/core';
import { RouteService } from 'src/app/services/base/route.service';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/base/dialog.service';
import { AddHomeComponent } from '../../dialogs/add-home/add-home.component';
import { ItemComponent } from '../../inventory/item/item.component';
import { CustomerComponent } from '../../customer/customer/customer.component';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(
    public routeSerice:RouteService,
    public router: Router,
    public dialogService:DialogService,
  ) { }

  ngOnInit(): void {
  }

  
  openLink(module:string, routeName:string ){
    this.router.navigate([this.routeSerice.openRoute(module, routeName)]);
  }


  openAddDialog(){
    this.dialogService.openSmallDialog(AddHomeComponent);

    this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
      if (result) {
        if (result == "saleinvoice") {
          this.router.navigate([this.routeSerice.openRoute('sales', 'sale')], { queryParams: { parent: 'home' } });
        } else  if (result == "customerreceipt") {
          this.router.navigate([this.routeSerice.openRoute('sales', 'customerReceipt')], { queryParams: { parent: 'home' } });
        } else if (result == "customer") {
          this.dialogService.openDialog(CustomerComponent);

          // this.dialogService.dialogRef.afterClosed().subscribe(result => {
          //   if(result){
          //     if(result == "save" ){

          //     }                 
          //   }
          // });
          
        } else if (result == "item") {
          this.dialogService.openDialog(ItemComponent);

          // this.dialogService.dialogRef.afterClosed().subscribe(result => {
          //   if(result){
          //     if(result == "save" ){
                
          //     }
                  
          //   }
          // });
        }

      }
    });
  }

  

}
