import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { PurchaseViewComponent } from '../../purchase/purchase-view/purchase-view.component';
import { PrintDialogComponent } from 'src/app/shared/Dialog/print-dialog/print-dialog.component';
import { PdfprintService } from 'src/app/services/pdfprint.service';
import { LoaderService } from 'src/app/services/base/loader.service';


@Component({
  selector: 'app-delivery-challan-view',
  templateUrl: './delivery-challan-view.component.html',
  styleUrls: ['./delivery-challan-view.component.scss']
})
export class DeliveryChallanViewComponent implements OnInit {

  dataHdr:any = null;
  dataAll:any[] = [];

  constructor(
    public dialogService:DialogService,
    public routeService:RouteService,
    public router:Router,
    public routerService:RouteService,
    public dialogRef: MatDialogRef<PurchaseViewComponent>,
    public helpservice:ConfHelpService,
    public loaderService:LoaderService,
    public pdfService:PdfprintService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getViewData();
  }

  async getViewData() {
    this.loaderService.startLoader();
    var filter = {
      id : this.data.ID,
    }
    await this.helpservice.getFormDataByHelpNo("vw_saldeliverychallan", filter).toPromise().then(res => {
      this.dataHdr = res['value'][0] as any;
      this.dataAll = res['value'] as any[];
      this.loaderService.endLoader();
      console.log(this.dataAll)
  })}

  onEdit(){
    this.dialogRef.close();
    this.router.navigate([this.routeService.openRoute("sales", "delivery-challan")], { queryParams: { ID: this.data.ID, parent: 'delivery-challan-list' } });
  }

  printHtml(){
    this.pdfService.generatePDF('pdfContent',this.dataHdr?.DocumentNo)
  }

  onPurchaseRoute(){
    this.dialogRef.close();
    this.router.navigate([this.routeService.openRoute("purchase", "purchase-form")], { queryParams: { ID: this.dataHdr?.PurchaseID, parent: 'purchase-list' } });
  }

  openPurchaseCreate(){
    this.dialogRef.close();
    this.router.navigate([this.routeService.openRoute("purchase", "purchase-form")], { queryParams: { DeliveryChallanID: this.dataHdr?.ID, parent: 'purchase-list' } });
  }


  print(){
    if(this.data.ID == 0)
      return;
  
    var obj = {
      reportno:'rpt27',
      queryparam:'id='+this.data.ID,
    }
    this.dialogService.openPrintDialog(PrintDialogComponent, obj);  
   
}
}
