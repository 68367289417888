<mat-form-field appearance="none" class="w-100 cg" >
 
        <mat-select 
        [multiple]="multiple"
        [required]="required" 
        #name="ngModel"     
        [(ngModel)]="model"              
        (ngModelChange)="onChange(model)">
          
            <!-- Global overried for .mat-select-panel max-height at style.css -->
                              
          <mat-option *ngIf="search">                          
            <ngx-mat-select-search                                                    
            name="searchName"
            #searchName="ngModel"
            [(ngModel)]="searchValue"
            (ngModelChange)="AutoComp.searchResult.next($event)"
            placeholderLabel="Search..." 
            noEntriesFoundLabel="Not Found!">  
            <div class="mat-select-search-custom-header-content float-end">
              <!-- <button mat-button color="primary">
              <mat-icon>add</mat-icon> Create 
            </button> -->
          </div> 
                                                             
            </ngx-mat-select-search>
          </mat-option>
          <mat-option  [value]="">
              -- Clear --
          </mat-option>          
          <mat-option *ngFor="let option of AutoComp.results | async" [value]="option">
              {{getValue(option)}}          
          </mat-option>          
        </mat-select>  
        <mat-hint>{{hint}}</mat-hint>
        <mat-error *ngIf="name.invalid || name.dirty">This field is required</mat-error>      
      </mat-form-field>