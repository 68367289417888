

<button mat-flat-button color="primary" (click)="onClick()"> 
        <mat-icon class="me-2">save</mat-icon>
        <span> {{button_text}}</span>
</button>

<button mat-flat-button color="primary" (click)="displayClick()"> 
        <mat-icon class="me-2">save</mat-icon>
        <span> Display</span>
</button>


<table class="w-100 table table-striped ">
        <thead>
                <tr>
                        <td>ID</td>
                        <td>Name</td>
                </tr>
        </thead>
        <tbody>
                <tr *ngFor="let row of array_data">
                        <td>{{row.id}}</td>
                        <td>{{row.name}}</td>
                </tr>
        </tbody>
</table>