import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Plants } from 'src/app/models/inventory/plants.model';
import { DialogService } from 'src/app/services/base/dialog.service';
import { LoaderService } from 'src/app/services/base/loader.service';
import { PlantsService } from 'src/app/services/inventory/plants.service';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';

@Component({
  selector: 'app-plants',
  templateUrl: './plants.component.html',
  styleUrls: ['./plants.component.scss']
})
export class PlantsComponent implements OnInit {

  primaryKey:number = 0;
  obj: Plants;

  constructor(
    public plantService:PlantsService,
    public dialogService:DialogService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.obj = new Plants();
    this.loaderService.startLoader();
  }

  ngOnInit(): void {
    if(this.data.primaryKey > 0)
    this.getData();
    else 
    this.loaderService.endLoader();
  }

  getData(){
    this.primaryKey = this.data.primaryKey;
    this.plantService.getById(this.primaryKey).then(res => {
      this.obj = res as Plants;
      this.loaderService.endLoader();
    })
  }

  onSave(){
    this.loaderService.startLoaderOnSave();
    if (this.primaryKey == 0) {
      this.plantService.post(this.obj).subscribe(res => {
        //this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('inv_04', "save");
      });
    }
    else {
      this.plantService.update(this.obj, this.primaryKey).subscribe(res=>{
        this.loaderService.endLoaderOnSave();
        //this.dialogService.closeDialog("save");
        this.dialogService.closeDialog_List('inv_04', "save");
      }); // Updating Data
    }

  }

  
  onDelete(){
    if (this.primaryKey > 0 ) {
      this.dialogService.openSmallDialog(YesNoComponent);

      this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
        if (result) {
            if (result == "yes") {
              this.plantService.delete(this.primaryKey).subscribe(res => {
               // this.dialogService.closeDialog("delete");
               this.dialogService.closeDialog_List('inv_04', "delete");
              })
            }
        }
      })
    }
  }


}
