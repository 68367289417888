import { Component,Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Broker } from 'src/app/models/sale/broker';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { BrokerService } from 'src/app/services/sale/broker.service';
import { YesNoComponent } from 'src/app/shared/Dialog/yes-no/yes-no.component';
import { BaseComponent } from '../../base/base.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/base/loader.service';

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.scss']
})
export class BrokerComponent  implements OnInit {
  
  primaryKey:number = 0;
  obj:Broker;

  parent:string="";
  constructor(
    public brokerService:BrokerService,
    // public router: Router, 
    // public route: ActivatedRoute,
    public routeService:RouteService,
    public dialogService:DialogService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  
    this.obj = new Broker();
    this.loaderService.startLoader();
   }

  ngOnInit(): void {
   
  if(this.data.primaryKey > 0)
  this.getData();
  else 
  this.loaderService.endLoader();

}
async getData() {    
  this.primaryKey = this.data.primaryKey;
  this.brokerService.getById(this.primaryKey).then(res => {
    this.obj = res as Broker;
    this.loaderService.endLoader();
  });
}

onSave(){
  this.loaderService.startLoaderOnSave();
  if (this.primaryKey == 0) {
    this.brokerService.post(this.obj).subscribe(res => {
      this.dialogService.closeDialog("save");
    });
  }
  else {
    this.brokerService.update(this.obj, this.primaryKey).subscribe(res=>{
      this.loaderService.startLoaderOnSave();
      this.dialogService.closeDialog("save");
    });
  }}




onDelete() {
  if (this.primaryKey > 0) {
    this.dialogService.openSmallDialog(YesNoComponent);

    this.dialogService.dialogRefSmall.afterClosed().subscribe(result => {
      if (result) {
        if (result == "yes") {
          this.brokerService.delete(this.primaryKey).subscribe(res => {              
            this.dialogService.closeDialog("delete");
           //this.dialogService.closeDialog_List('pur_01', "delete");
          })
        }
      }
    });

  }
}
}