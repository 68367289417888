import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RouteService } from 'src/app/services/base/route.service';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-module-home-card',
  templateUrl: './module-home-card.component.html',
  styleUrls: ['./module-home-card.component.scss']
})
export class ModuleHomeCardComponent implements OnInit {

  @Input() moduleName:string ="";
  @Input() moduleHeading:string ="";
  @Input() moduleIcon:string ="";
  @Output() formClick = new EventEmitter<any>();
  selectedBtn:string = 'form';

  constructor(
    public routeService: RouteService,
    public theme:ThemeService,
    public router: Router,
    ) { }

  ngOnInit(): void {

  }

  openLink(module: string, routeName: string) {
    this.router.navigate([this.routeService.openRoute(module, routeName)]);
  }

  openHome() {
    this.router.navigate([this.routeService.getHomeRoute()]);
  }

  onFormClick(row:any){
    this.formClick.emit(row);
  }

  onBtnClick(type:string){
    this.selectedBtn = type;
  }
}
