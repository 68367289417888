import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
//import { MsalGuard, MsalService } from '@azure/msal-angular';
import { RBAC_User } from 'src/app/models/rbac_user.model';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { LocalStorageService } from 'src/app/services/base/local-storage.service';
import { TokenService } from 'src/app/services/base/token.service';
import * as customConfig from 'src/assets/files/customConfig.json';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(
    public authenticate:AuthenticateService,
    public tokenService:TokenService,
    public router: Router,
    public localStorageService: LocalStorageService,
    private authService: MsalService,
    //public authService: MsalService,
  ) { }

  ngOnInit(): void {
  }

  logout() {


      this.authenticate.isLoggedIn = false;
    this.localStorageService.removeCurrentUser();
    this.authService.logoutRedirect({
      postLogoutRedirectUri: customConfig.appurl
    });

    // this.authService.logoutRedirect({
    //   postLogoutRedirectUri: customConfig.appurl
    // });

    // this.authenticate.logout().then(res => {
    //   this.tokenService.setToken('');

    //   this.authenticate.isLoggedIn = false;
    //   this.authenticate.user = new RBAC_User();
    //   this.router.navigate(['/login']);      
    // }, error => {
    //   console.log(error);
    // })
  }

}
