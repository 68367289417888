import { Component, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexNonAxisChartSeries, ApexTitleSubtitle, ApexXAxis, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  pieSeries: ApexNonAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  title: ApexTitleSubtitle | any;
  labels: any;
}

@Component({
  selector: 'app-newdash-more',
  templateUrl: './newdash-more.component.html',
  styleUrls: ['./newdash-more.component.scss']
})
export class NewdashMoreComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
public chartOptions: Partial<ChartOptions>;
public pieChartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {

      series: [
        {
          name: "Sales",
          data: [340, 298, 280, 254, 310, 260]
        }
      ],

      chart: {
        height: 350,
        type: "bar"
      },

      title: {
        text:"Sale Last 6 Months"
      },

      xaxis: {
        categories: ["Jan", "Feb", "March", "April", "May" , "June"]
      }

    }

    this.pieChartOptions = {
      pieSeries: [120, 341, 70, 155],
      chart: {
        width: 480,
        type: "pie"
      },
      labels: ["Nazimabad Store", "Gulshan Store", "North Store", "Saddar Store"],

      title: {
        text: "Sales in Plants"
      },

    }
  }

  ngOnInit(): void {
  }

}
