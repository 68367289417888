

<mat-card class="shadow">
    <mat-card-content>
        <mat-selection-list #forms [multiple]="false" >

            <div class="d-flex justify-content-between">
                <div mat-subheader class="home-heading">
                    {{moduleHeading}}
                </div>
                <div>
                    <mat-icon class="me-2 home-icon">{{moduleIcon}}</mat-icon>
                </div>
            </div>
            <!-- Start Form -->
            <div class="d-flex justify-content-between py-2" style="margin-bottom:8px;">
                <div mat-subheader style="padding-bottom: 5px;">                                                                                                
                        <button [ngClass]="{'back-button': selectedBtn == 'form'}" (click)="onBtnClick('form')" mat-button color="primary" class="moduleBtn me-1"> <mat-icon class="me-1">article</mat-icon> <span class="me-1"> Forms </span></button>
                        <button [ngClass]="{'back-button': selectedBtn == 'report'}" (click)="onBtnClick('report')" mat-button color="primary" class="moduleBtn"> <mat-icon class="me-1">description</mat-icon> <span class="me-1"> Reports </span></button>                                                                                                         
                </div>               
            </div>
            <mat-list-option *ngFor="let r of routeService.getRouteByType(moduleName, 'list')" [value]="r" [hidden]="selectedBtn == 'report'"
                (click)="onFormClick(r)">
                <div class="d-flex justify-content-between align-items-baseline visible-area" [class]="theme.myCardBtn">
                    <span class="flex-grow-1 bd-highlight">{{r.label}}</span>
                    <button mat-icon-button [class]="theme.myCardBtn" class="card-buttons">
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </mat-list-option>
            <!-- End Form -->
            <!-- <div mat-subheader>
                <mat-icon class="me-2">list</mat-icon> View/Edit
                
            </div>
            <mat-list-option *ngFor="let r of routeService.getRouteByType(moduleName, 'list')" [value]="r"
                (click)="openLink(moduleName, r.name)">
                <div class="d-flex justify-content-between align-items-baseline visible-area">
                    <span class="flex-grow-1 bd-highlight">{{r.label}}</span>
                    <button mat-icon-button class="card-buttons">
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </mat-list-option> -->
            <!-- Start Report -->
            <div *ngIf="routeService.getRouteByType(moduleName, 'report').length > 0">
                
                <mat-list-option *ngFor="let r of routeService.getRouteByType(moduleName, 'report')" [value]="r" [hidden]="selectedBtn == 'form'"
                    (click)="openLink(moduleName, r.name)">
                    <div class="d-flex justify-content-between align-items-baseline visible-area"  [class]="theme.myCardBtn">
                        <span class="flex-grow-1 bd-highlight">{{r.label}}</span>
                        <button mat-icon-button  [class]="theme.myCardBtn" class="card-buttons">
                            <mat-icon>navigate_next</mat-icon>
                        </button>
                    </div>
                </mat-list-option>
            </div>
            <!-- End Report -->
        </mat-selection-list>
    </mat-card-content>
</mat-card>

