<div class="row card-margin">
    <div class="col-md-12 content-area">   

        <div class="dangLogo d-flex justify-content-center ">
            <mat-icon class="cstmLogo">warning</mat-icon>
        </div>
        <h3 style="font-size: 18px;">{{data.data}}</h3>
        <div style="display: flex;justify-content: space-evenly;" class="mt-3">            
            <app-my-button title="Close" icon="close" (click)="onClose()"></app-my-button>                
        </div>
        
    </div>
</div>