import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
 
export const b2cPolicies = {
     names: {
         signUpSignIn: "B2C_1_SIGNIN"
     },
     authorities: {
         signUpSignIn: {
             authority: "https://bizmantodev.b2clogin.com/bizmantodev.onmicrosoft.com/B2C_1_SIGNIN",
         },
         signUp: {
            authority: "https://bizmantodev.b2clogin.com/bizmantodev.onmicrosoft.com/B2C_1_SIGNIN",
        }
     },
     authorityDomain: "bizmantodev.b2clogin.com"
 };
 
 
export const msalConfig: Configuration = {
     auth: {
         clientId: 'e5447d9f-0502-4278-8110-e997ee89738d',
         authority: b2cPolicies.authorities.signUpSignIn.authority,
         knownAuthorities: [b2cPolicies.authorityDomain],
         redirectUri: 'https://app-dev.bizmanto.com/',  
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage,
         storeAuthStateInCookie: isIE, 
     },
     system: {
         loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                console.log(message);
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: false
         }
     }
 }

export const protectedResources = {
  todoListApi: {
    endpoint: "https://api-biz-dev.azurewebsites.net/nouse",
    scopes: ["https://bizmantodev.onmicrosoft.com/api/read_data"],
  },
}
export const loginRequest = {
  scopes: []
};