import { Component, OnInit, ViewChild, Input, HostListener, Inject } from '@angular/core';
import { RouteService } from 'src/app/services/base/route.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { RBAC_User } from 'src/app/models/rbac_user.model';
import { TokenService } from 'src/app/services/base/token.service';
import { MsalService } from '@azure/msal-angular';
import { LocalStorageService } from 'src/app/services/base/local-storage.service';
import * as customConfig from 'src/assets/files/customConfig.json';
import { ThemeService } from 'src/app/services/base/theme.service';
import { MatDialog } from '@angular/material/dialog';
import { AllFormsDialogComponent } from 'src/app/modules/main/all-forms-dialog/all-forms-dialog.component';
import { AllReportsComponent } from 'src/app/modules/main/all-reports/all-reports.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Input('drawer') drawer: MatSidenav;
  panelOpenState = true;
  brandsUrl = customConfig.brandImage;
  brandToolTip = customConfig.brandToolTip

  selectedType:string = 'form';
  selectedName:string = '';


  isMobile = this.document.body.clientWidth < 768;
  minHeight = this.isMobile ? '100vh' : '550px';
  width = this.isMobile ? '100vh' : 'auto';
  minWidth = this.isMobile ? '10vh' : '480px';
  maxWidth = this.isMobile ? '100vh': 'auto';
  height = this.isMobile ? '100vh': 'auto';
 
  constructor(
    public routeService:RouteService,
    public router: Router,
    public authenticate: AuthenticateService,
    public tokenService:TokenService,
    private authService: MsalService,
    public themeService:ThemeService,

    public dialog:MatDialog,
    public localStorageService: LocalStorageService,

    @Inject(DOCUMENT) private document: Document

    ) { }

  ngOnInit(): void {
   
  }

  openReportDialog(){
    const dialogRef = this.dialog.open(AllReportsComponent, {
      minHeight: this.minHeight,
      minWidth:this.minWidth,
      height:this.height,
      width:this.width,
      maxWidth:this.maxWidth,
      autoFocus: false ,
  });
  }


  openAddNew(){
    const dialogRef = this.dialog.open(AllFormsDialogComponent, {
      minHeight: this.minHeight,
      minWidth:this.minWidth,
      height:this.height,
      width:this.width,
      maxWidth:this.maxWidth,
      autoFocus: false ,
  });
  }




  // openLink(module:string, routeName:string ){
  //   this.drawer.close()
  //   this.router.navigate([this.routeSerice.openRoute(module, routeName)]);
  // }

  openLink(name, module) {
    this.selectedName = name;
    this.router.navigate([this.routeService.openRoute(module, name)]);
  }

  

openDashboard(){
  this.router.navigate(['/app/dashboard'])
}
openNewDash(){
  this.router.navigate(['/app/newdash'])
}
openSales(){
  this.router.navigate(['/app/sales-dash'])
}
openSalesv3(){
  this.router.navigate(['/app/salev3-dash'])
}
newLogin(){
  this.router.navigate(['/app/login-new'])
}

  logout() {

    this.authenticate.isLoggedIn = false;
    this.localStorageService.removeCurrentUser();
    this.authService.logoutRedirect({
      postLogoutRedirectUri: customConfig.appurl
    });

 

    /*
    this.authenticate.logout().then(res => {
      this.tokenService.setToken('');

      this.authenticate.isLoggedIn = false;
      this.authenticate.user = new RBAC_User();
      this.router.navigate(['/login']);      
    }, error => {
      console.log(error);
    })
    */
  }

  onBtnClick(type:string){
    this.selectedType = type;
  }

  

}
