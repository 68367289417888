<div class="topBar">
        <div class="d-flex align-items-center justify-content-between">
                <div>
                        <div class="d-flex align-items-center">
                        
                        <h2 class="ms-3">Supplier Payment</h2>
                         </div>
                </div>
                <div>
                        <div class="d-flex">
                                <div class="topBtns">
                                        <button class="btn btnTopIcons" (click)="print()"> <mat-icon class="topNavIcon printIconColor">print</mat-icon> Print
                                        </button>
                                        <button class="btn btnTopIcons" (click)="onEdit()"> <mat-icon class="topNavIcon editIconColor">edit</mat-icon> Edit
                                        </button>
                                        <!-- <button class="btn btnTopIcons"> <mat-icon class="topNavIcon deleteIconColor">delete</mat-icon> Delete
                                        </button> -->
                                </div>

                               
                        
                                <div class="closeBtnDiv">
                                        <button class="btn" mat-dialog-close> <mat-icon class="closeColor">close</mat-icon>
                                        </button>
                                </div>
                        
                        </div>
                        
                </div>
        </div>
        <hr>


</div>

<div class="contentDiv">
        <div class="topBtnForMob">
                <button class="btn btnTopIcons" (click)="print()"> 
                        <mat-icon class="topNavIcon printIconColor">print</mat-icon> Print
                </button>
                <button class="btn btnTopIcons" (click)="onEdit()"> 
                        <mat-icon class="topNavIcon editIconColor">edit</mat-icon> Edit
                </button>
        </div>
        <div class="row">
        <div class="col-md-4">

                <div class="card mb-3 cardHeight ">
                        <div class="txtTitle">
                             <mat-icon class="partyIconForMobOnly">account_circle</mat-icon> {{dataHdr?.SupplierName}}
                        </div>
                        <hr>
                        <table class="table table-borderless">                                 
                                <tbody class="leftTable">
                                    <tr>
                                        <td>Entry #</td>
                                        <td>{{dataHdr?.DocumentNo}}</td>
                                    </tr>
                                    <tr>
                                        <td>Date</td>
                                        <td>{{dataHdr?.DocDate | date}}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>Supplier</td>
                                        <td>{{dataHdr?.SupplierName}}</td>
                                    </tr>              -->
                                </tbody>                
                            </table> 
                            <hr>
                        <h3 class="mb-2" style="color: #595e64;">Description</h3>     
                        <p>{{dataHdr?.Notes}}</p>
                </div>

              
        </div>

        <div class="col-md-8">
                <div class="forMobOnly">
                        Item Detail
                </div>
        <table class="tableDetail  mb-3">
                <thead class="detailTH">
                        <tr>
                                <th>#</th>
                                <th class="item">Payment</th>
                                <th>Amount</th>
                                <th>Bank</th>
                                <th>Bank Account</th>
                                <th>Cheque No.</th>
                                <th>Cheque Date</th>
                                <th>Notes</th>
                                
                        </tr>
                </thead>  
                <tbody>
                        <tr *ngFor="let data of dataAll;let index = index">
                                <td data-label="S #" class="serial">{{index+1}}</td>
                                <td data-label="Payment" class="item">{{data?.PaymentTypeName}}</td>
                                <td data-label="Amount" class="unit">{{data?.Amount | number}}</td>
                                <td data-label="Bank" class="qty">{{data?.BankName}}</td>
                                <td data-label="Account" class="price">{{data?.AccountName}}</td>
                                <td data-label="Cheque #" class="price">{{data?.ChequeNo}}</td>
                                <td data-label="Cheque Date" class="price">{{data?.ChequeDate | date}}</td>
                                <td data-label="Notes" class="price">{{data?.DetailNotes}}</td>
                        </tr>
                        
                </tbody>
        </table>

        <div class="cstmCard">
                <div class="d-flex justify-content-between">
                        <div class="txtSubSummary">Total Cash</div>
                        <div class="txtMainSummary">{{dataHdr?.TotalCash | number}}</div>
                </div>
                <div class="d-flex justify-content-between">
                        <div class="txtSubSummary">Total Cheque</div>
                        <div class="txtMainSummary">{{dataHdr?.TotalCheque | number}}</div>
                </div> 
                <div class="d-flex justify-content-between">
                        <div class="txtSubSummary">Total Online Transfer</div>
                        <div class="txtMainSummary">{{dataHdr?.TotalOnlineTransfer | number}}</div>
                </div> 
                <div class="d-flex justify-content-between">
                        <div class="txtSubSummary">Total Bank Deposit</div>
                        <div class="txtMainSummary">{{dataHdr?.TotalBankDeposit | number}}</div>
                </div>
                <hr style="margin: 0 !important;">
                <div class="d-flex justify-content-between">
                        <div class="txtSubSummary">Total Amount</div>
                        <div class="txtNetSummary">{{dataHdr?.TotalAmount | number}}</div>
                </div>
        </div>






        </div>   
        </div>     
</div>                