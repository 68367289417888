import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from 'src/app/services/base/permission.service';
import { ScreenConfService } from 'src/app/services/base/screen-conf.service';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-ribbon-delete',
  templateUrl: './ribbon-delete.component.html',
  styleUrls: ['./ribbon-delete.component.scss']
})
export class RibbonDeleteComponent implements OnInit {
  
  @Output() myClick = new EventEmitter<any>();

  delete:boolean=false;

  constructor(
    public theme:ThemeService,
    public permissionService:PermissionService,
    public screenConfService: ScreenConfService,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getDeleteperimssion();
  }

  onClick(){
    this.myClick.emit();
  }

  getDeleteperimssion() {
    this.delete = this.permissionService.getAddPerm(this.screenConfService.getscodeAc(this.activatedRoute));
  
  }

}
