export class Plants {
    ID: number = 0;
    CompanyID: number = null;
    PlantName: string = null;
    Location:string = null;
    Description: string = null;
    Incharge: string = null;
    CreatedDate: string = null;
    CreatedBy: number = null;
    CreatedTerminal: string = null;
    CreatedTerminalIp: string = null;
    ModifiedDate: string = null;
    ModifiedBy: number = null;
    ModifiedTerminal: string = null;
    ModifiedTerminalIp: string = null;
}