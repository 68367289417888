import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Autocomplete } from '../../Autocomplete';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-my-select-table',
  templateUrl: './my-select-table.component.html',
  styleUrls: ['./my-select-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MySelectTableComponent),
      multi: true
    }
  ]
})
export class MySelectTableComponent implements OnInit , ControlValueAccessor {

  @Input() label:string = "";
  @Input() data:any[]= null;
  @Input() required: boolean = false;
  @Input() createOption:boolean = false;
  @Input() optionName:string= "";
  @Input() model: any = null;
  @Input() name: string = "";
  @Input() title:string = "";
  @Input() multiple:boolean = false;
  @Output() modelChange = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<any>();
  @Output() onCreate = new EventEmitter<any>();
  @Input() hint: string = null;
  searchValue:any = null;
  @Input() search:boolean = true;

  AutoComp:Autocomplete<any> = new Autocomplete<any>(this.title, this.title);      

  constructor() {
   
   }

  ngOnChanges(): void {

    // For material select - 24/06/2023
    
    this.AutoComp.filterIDName = this.title;
    this.AutoComp.displayValueName = this.title;
    this.AutoComp.data = this.data;
    this.AutoComp.resultObserve();  
    
  }
  
  writeValue(obj: any): void {
    if (obj !== undefined){
      if(obj == null )
        this.model = obj;
      else if(obj.length > 0 )
        this.model = obj[0];

    }
      
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }


  ngOnInit(): void {
  }

  onChange(value: any) {
    // if(value == undefined)
    //   this.valueChanged.emit(null);
    // else 

    /*

    if(value == "create")
        return;

    var obj:any[]= [];
    if(value != undefined){
      obj.push(value);
    }
    // else {
    //   obj.push(value);
    // }

    this.propagateChange(obj);    
    this.valueChanged.emit(obj);

    */

    
    var obj:any[]= [];
    obj.push(value)
    this.propagateChange(obj);
    
    this.valueChanged.emit(obj);
  }

  getValue(option){
     return option[this.title];
  }
  create(){
    this.onCreate.emit();
    
  }
  

  /*
  @Input() label:string = "";
  @Input() data:any[]= null;
  @Input() required: boolean = false;
  @Input() model: any = null;
  @Input() name: string = "";
  @Input() title:string = "";
  @Input() multiple:boolean = false;
  @Output() modelChange = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<any>();
  @Input() hint: string = null;
  search:any = null;

  AutoComp:Autocomplete<any> = new Autocomplete<any>(this.title, this.title);      
  control = new FormControl();
  
  constructor() {
   
   }

  ngOnChanges(): void {
    this.AutoComp.filterIDName = this.title;
    this.AutoComp.displayValueName = this.title;
    this.AutoComp.data = this.data;
    this.AutoComp.resultObserve();  
  }
  
  writeValue(obj: any): void {
    if (obj !== undefined)
      this.model = obj;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit(): void {

    this.AutoComp.results = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.AutoComp.resultObserve();  

  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.AutoComp.data.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  onChange(value: any) {
    this.propagateChange(value);
    this.valueChanged.emit(value);
  }

  getValue(option){
     return option[this.title];
  }
  */

}