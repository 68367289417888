<mat-toolbar [class]="theme.myTopNavColor" class="" >
  <div [class]="isOpenMenu ? 'ml-top-nav-open' : 'ml-top-nav-close' ">
    <button  type="button" mat-icon-button (click)="onMenuClick()">
      <mat-icon>menu</mat-icon>
    </button>
    <span >
      <!-- <button mat-button type="button" class="apptitle" (click)="onAppClick()">{{appName}}</button> -->
      <!-- <button mat-button type="button" class="apptitle" (click)="onAppClick()">Employee Tracking</button> -->
      <img class="logo" [title]="brandToolTip" (click)="onAppClick()"
        [src]="brandImageTop" alt="Business Management Tool">
  
    </span>
  </div>
      
    <span class="spacer"></span>

    <div class="navRightBtns d-flex align-items-center gap-2 me-1">
    

  

    <button type="button"
    class="hideDesktop"
    mat-icon-button
    color="themeColor"
    *ngIf="authenticate.isLoggedIn"  
    (click)="openDueDays()"
    
    >
    <mat-icon
    [matBadge]="dataLength"
    [matBadgeHidden]="dataLength == 0">
     notification_important
    </mat-icon>

    </button>

    <button 
      type="button" 
      class="hideDesktop" 
      mat-icon-button
      color="themeColor" 
      (click)="onBellClick()"  
      *ngIf="authenticate.isLoggedIn" 
      aria-label="">
     <mat-icon 
     [matBadge]="recentActivityService.recentCount"
     [matBadgeHidden]="recentActivityService.recentCount == 0"
     matBadgeSize="medium"
     matBadgeOverlap="true">
      history
     </mat-icon>
    </button>

    
    <mat-icon  
    [matBadge]="dataLength"
    [matBadgeHidden]="dataLength == 0"
    matRipple
    *ngIf="authenticate.isLoggedIn" 
    (click)="onBellClick()" 
    class="d-md-none d-lg-none"
    >
    notifications
    </mat-icon>

    <!-- <button class="me-3" mat-mini-fab color="themeColor" [matMenuTriggerFor]="menuNotify" *ngIf="authenticate.isLoggedIn" aria-label="">
      <i style="font-size: 16px;" class="bi bi-bell-fill"></i>
    </button>
    <mat-menu #menuNotify="matMenu" xPosition="before">
    
      <button *ngFor="let data of dataNotification" mat-menu-item class="customeWidth border-bottom">
    
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
          <p class="me-2">{{data.DocumentNo}} - </p> 
          <p> {{data.Action}}</p>
          </div>
          <p class="text-muted">{{data.CreatedDate | date:'MM/dd/yyyy'}}</p>
        </div>
        <div class="d-flex align-items-center">
           <div class="vl me-2"></div>
          <p>{{data.Message}}</p>
        </div> 
      </button>
      </mat-menu>
 -->
    <!-- <span class="userIcon" ><mat-icon class="ms-1 me-1">person</mat-icon>{{userShortCode}}</span> -->
    <button mat-icon-button [matMenuTriggerFor]="menu" [matTooltip]="localStorageService.getCurrentUser()?.FullName" color="sc" *ngIf="authenticate.isLoggedIn" aria-label="">
      {{userShortCode}}
    </button>
<!-- 
    <button class="btn" (click)="openViewScreen()">Open Welcome</button> -->
   <mat-menu #menu="matMenu" class="matWidth">
      <button (click)="logout()" mat-menu-item class="menuHoverr"> <mat-icon class="ms-1 me-1">logout</mat-icon> Log Out</button>
    </mat-menu> 

  </div>
<!-- 
    <button class="btn btn-warn" (click)="openViewScreen()">View Screen</button> -->
        
</mat-toolbar>