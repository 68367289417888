<div class="container-fluid ">
        <div class="row mt-2 ">
                <div class="col-md-6">
                        <div class="card cardhieght">
                                <div class="card-header header1 ">
                                        <div class="d-flex  justify-content-between  ">
                                                <h2 style="color: black;">Dialog Default</h2>

                                                <button class="btn mat-button " mat-dialog-close> <mat-icon
                                                                class="closeColor">close</mat-icon>
                                                </button>

                                        </div>

                                </div>
                                <div class="card-body ">
                                        <div class="row">
                                                <div class="col-md-4">
                                                        <span class="material-symbols-outlined">
                                                                <mat-icon class="icon1">lightbulb</mat-icon>
                                                        </span>
                                                </div>
                                                <div class="col-md-8">
                                                        <h2 class="title1">Tip Of The Day</h2>
                                                        <p class="mt-1 ">Do You Know Material X System Contains 
                                                           material design components re-styled 
                                                           as it should look and behave for today      
                                                        </p>
                                                </div>
                                        </div>
                                </div>

                                <div class="card-footer footer">
                                  <div class="Divbtn1">
                                        <button class="btn btn-outline-secondary ">
                                                Secondary 

                                        </button>

                                        <button class="btn btn-primary ms-3  ">
                                                   Primary
                                        </button>

                                   </div>

                                </div>

                        </div>

                </div>

                <div class="col-md-3 ms-3 ">
                        <div class="card cardhieght">
                                <div class="card-header header1 ">
                                        <div class="d-flex  justify-content-between  ">
                                                <h2 style="color: black;">Dialog Default</h2>

                                                <button class="btn mat-button " mat-dialog-close> <mat-icon
                                                                class="closeColor">close</mat-icon>
                                                </button>

                                        </div>

                                </div>
                                <div class="card-body defaoult">
                                        <div class="row">
                                                
                                                <div>
                                                        <h2 class="title1">Tip Of The Day</h2>
                                                        <p class="mt-1 ">Do You Know Material X System Contains 
                                                           material design components re-styled 
                                                                
                                                        </p>
                                                </div>
                                        </div>
                                </div>

                                <div class="card-footer footer defaoult">
                                  <div class="Divbtn1">
                                      

                                        <button class="btn btn-primary ms-3  ">
                                                   Primary
                                        </button>

                                   </div>

                                </div>

                        </div>

                </div> 

        </div>

         <div class="row mt-5 ">
                <div class="col-md-6">
                        <div class="card cardhieght">
                                <div class="card-header header2 ">
                                        <div class="d-flex  justify-content-between  ">
                                                <h2 style="color: green;">Positive Dialog</h2>

                                                <button class="btn mat-button " mat-dialog-close> <mat-icon
                                                                class="closeColor">close</mat-icon>
                                                </button>

                                        </div>

                                </div>
                                <div class="card-body ">
                                        <div class="row">
                                                <div class="col-md-4">
                                                        <span class="material-symbols-outlined">
                                                                <mat-icon class="icon2">check</mat-icon>
                                                        </span>
                                                </div>
                                                <div class="col-md-8">
                                                        <h2 class="title2">Successful Purchase!</h2>
                                                        <p class="mt-1 ">Do You Know Material X System Contains 
                                                           material design components re-styled 
                                                           as it should look and behave for today      
                                                        </p>
                                                </div>
                                        </div>
                                </div>

                                <div class="card-footer footer">
                                  <div class="Divbtn1">
                                        <button class="btn btn-outline-secondary ">
                                                Secondary 

                                        </button>

                                        <button class="btn btn-primary action ms-3  ">
                                                   Primary Action
                                        </button>

                                   </div>

                                </div>

                        </div>
                </div>

                <div class="col-md-3 ms-3">
                        <div class="card cardhieght">
                                <div class="card-header header2 ">
                                        <div class="d-flex  justify-content-between  ">
                                                <h2 style="color: green;">Positive Dialog</h2>

                                                <button class="btn mat-button " mat-dialog-close> <mat-icon
                                                                class="closeColor">close</mat-icon>
                                                </button>

                                        </div>

                                </div>
                                <div class="card-body succes">
                                        <div class="row">
                                               
                                                <div >
                                                        <h2 class="title2">Successful Purchase!</h2>
                                                        <p class="mt-1 ">Do You Know Material X System Contains 
                                                           material design components re-styled 
                                                          
                                                        </p>
                                                </div>
                                        </div>
                                </div>

                                <div class="card-footer footer succes">
                                  <div class="Divbtn1">
                                        <button class="btn btn-primary action ms-3  ">
                                                   Primary Action
                                        </button>

                                   </div>

                                </div>

                        </div>

                </div>

        </div>

     
       

</div>