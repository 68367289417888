<div class="desktop ">

    
    <br>

    <div class="row d-flex justify-content-center mt-5">
        <app-card   (myClick)="openLink('inventory', 'Home')" label="Inventory" imageurl="assets/img/Inventory.jpg"
            class="col-md-3 col-12 mt-3"></app-card>
        <app-card   (myClick)="openLink('customer', 'customer')" label="Customers" imageurl="assets/img/customer.jpg"
            class="col-md-3 col-12 mt-3"></app-card>
        <app-card  (myClick)="openLink('sales', 'Home')" label="Sales" imageurl="assets/img/sale.jpg"
            class="col-md-3 col-12 mt-3"></app-card>
        <!-- <app-card (click)="openLink('purchase', 'Home')" label="Purchase" imageurl="assets/img/purchase.png" class="col-md-3 col-12 mt-3"></app-card>
            <app-card (click)="openLink('finance', 'Home')" label="Finance" imageurl="assets/img/finance.jpg" class="col-md-3 col-12 mt-3"></app-card> -->

    </div>

        <div class="row mt-5 d-flex justify-content-center" >    
            <div  class="box">
                    <app-mobile-box matRipple class="w-50 d-flex justify-content-center" style="width: 95px !important;" 
                     heading="Add" icon="add"
                     (click)="openAddDialog()"> </app-mobile-box>
                   
            </div>            
        </div> 
       

    <!-- <div class="row mt-5 d-flex justify-content-center" >    
            <app-card (click)="openLink('inventory', 'Home')" label="Inventory" imageurl="assets/img/Inventory.jpg" class="col-md-3 col-12 mt-3"></app-card>       
            <app-card (click)="openLink('customer', 'Customers')" label="Customer" imageurl="assets/img/customer.jpg" class="col-md-3 col-12 mt-3"></app-card>
            <app-card (click)="openLink('supplier', 'Suppliers')" label="Supplier" imageurl="assets/img/supplier.jpg" class="col-md-3 col-12 mt-3"></app-card>    
        </div>  -->

    <br><br>
</div>
<div class="mobile" style="padding-top: 20px;display: flex;flex-direction: row;justify-content: center; flex-wrap: nowrap;">
    
    <div class="box ">
        <app-mobile-box matRipple class="w-50 d-flex justify-content-center" style="width: 95px !important;" (click)="openLink('sales', 'Home')" heading="Sales" icon="point_of_sale"> </app-mobile-box>
    </div>
    <div class="box ">
        <app-mobile-box matRipple class="w-50  d-flex justify-content-center" style="width: 95px !important;" (click)="openLink('customer', 'customer')" heading="Customers" icon="hail"></app-mobile-box>
    </div>
    <div class="box">
        <app-mobile-box matRipple class="w-50  d-flex justify-content-center" style="width: 95px !important;" (click)="openLink('inventory', 'Home')" heading="Inventory" icon="inventory_2"> </app-mobile-box>        
    </div>
    
    <!-- <div class="box">                
                <app-mobile-box heading="Supplier" icon="people_alt"> </app-mobile-box>
                <app-mobile-box heading="Reports" icon="summarize"></app-mobile-box>
            </div>
            <hr>
            <div class="box">
                <app-mobile-box heading="Purchase" icon="shopping_cart"></app-mobile-box>
                <app-mobile-box heading="Setting" icon="settings"> </app-mobile-box>
            </div> -->

</div>

<div class="mobile" style="padding-top: 20px;display: flex;flex-direction: row;justify-content: center; flex-wrap: nowrap;">
    
        <div class="box ">
            <app-mobile-box  class="w-50 d-flex justify-content-center" style="width: 95px !important;" (click)="openAddDialog()" heading="Add" icon="add"> </app-mobile-box>
        </div>
       
        
        <!-- <div class="box">                
                    <app-mobile-box heading="Supplier" icon="people_alt"> </app-mobile-box>
                    <app-mobile-box heading="Reports" icon="summarize"></app-mobile-box>
                </div>
                <hr>
                <div class="box">
                    <app-mobile-box heading="Purchase" icon="shopping_cart"></app-mobile-box>
                    <app-mobile-box heading="Setting" icon="settings"> </app-mobile-box>
                </div> -->
    
    </div>

