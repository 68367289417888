import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { PurchaseViewComponent } from '../../purchase/purchase-view/purchase-view.component';
import { PdfprintService } from 'src/app/services/pdfprint.service';
import { PrintDialogComponent } from 'src/app/shared/Dialog/print-dialog/print-dialog.component';
import { BaseComponent } from '../../base/base.component';
import { LoaderService } from 'src/app/services/base/loader.service';


@Component({
  selector: 'app-sale-return-view',
  templateUrl: './sale-return-view.component.html',
  styleUrls: ['./sale-return-view.component.scss']
})
export class SaleReturnViewComponent implements OnInit {

  dataHdr:any= null;
  dataAll:any[] = [];
  
  showLoader:boolean=true;
  constructor(
    public router:Router,
    public routerService:RouteService,
    public dialogService:DialogService,
    public helpservice:ConfHelpService,
    public dialogRef: MatDialogRef<SaleReturnViewComponent>,
    public pdfService:PdfprintService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
   }

  ngOnInit(): void {
    this.getViewData();
  }


  async getViewData() {
    this.loaderService.startLoader();
    var filter = {
      id : this.data.ID,
      
    }

    await this.helpservice.getFormDataByHelpNo("vw_salsalereturninv", filter).toPromise().then(res => {
      this.dataHdr = res['value'][0] as any;
      this.dataAll = res['value'] as any[];
      //this.showLoader = false;
      this.loaderService.endLoader();
      console.log(this.dataAll)
    })
   
  }
  onEdit(){
    this.dialogRef.close();
    this.router.navigate([this.routerService.openRoute("sales", "form-return")], { queryParams: { ID: this.data.ID, parent: 'list-return' } });
  }

  // printHtml(){
  //   this.pdfService.generatePDF('pdfContent',this.dataHdr?.DocumentNo)
  // }
  print(){
    if(this.data.ID == 0)
      return;
  
    var obj = {
      reportno:'rpt10',
      queryparam:'id='+this.data.ID,
    }
    this.dialogService.openPrintDialog(PrintDialogComponent, obj);  
   
  }

}
