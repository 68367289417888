import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-dash',
  templateUrl: './sales-dash.component.html',
  styleUrls: ['./sales-dash.component.scss']
})
export class SalesDashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
