import { Item_Unit } from "./item-unit.model";

export class Item {
    ID: number = 0;
    CompanyID: number = null;
    ItemCode: string = null;
    ItemName: string = null;
    ItemTypeID: number = null;
    ItemCategoryID: number = null;
    Picture: string = null;
    MakeID:number = null;
    UnitID: number = null;
    CostPrice: number = null;
    SalePrice: number = null;
    Note: string = null;    
    CreatedDate: string = null;
    CreatedBy: number = null;
    CreatedTerminal: string = null;
    CreatedTerminalIp: string = null;
    ModifiedDate: string = null;
    ModifiedBy: number = null;
    ModifiedTerminal: string = null;
    ModifiedTerminalIp: string = null;
    IsActive: boolean = null;
    IsDeleted: boolean = null;
    Item_Unit: Item_Unit [] = [];
}