<div class="d-flex justify-content-between align-items-center">
        <h3>Create New</h3>
<button class="btn" matDialogClose><mat-icon>close</mat-icon></button>
</div>
<hr>

<div class="listDivs">
        <div class="cstmList">
                <h2 class="mb-2 textTitle"><mat-icon class="titleIcon">inventory_2</mat-icon> Inventory</h2>
                <mat-list *ngFor="let data of dataInventory" role="list">
                        <mat-list-item (click)="openLink(data, 'inventory')" class="cstmListItem" role="listitem"> <mat-icon class="cstmIcon">add</mat-icon> {{data?.label}}</mat-list-item>
                </mat-list>
        </div>


        <div class="cstmList">
                <h2 class="mb-2 textTitle"><mat-icon class="titleIcon">point_of_sale</mat-icon> Sale</h2>
                <mat-list *ngFor="let data of dataSale" role="list">
                        <mat-list-item (click)="openLink(data, 'sales')" class="cstmListItem" role="listitem"><mat-icon class="cstmIcon">add</mat-icon> {{data?.label}}</mat-list-item>
                </mat-list>
        </div>
        <div class="cstmList">
                <h2 class="mb-2 textTitle"><mat-icon class="titleIcon">shopping_cart</mat-icon>Purchase</h2>
                <mat-list *ngFor="let data of dataPurchase" role="list">
                        <mat-list-item (click)="openLink(data, 'purchase')" class="cstmListItem" role="listitem"> <mat-icon class="cstmIcon">add</mat-icon> {{data?.label}}</mat-list-item>
                </mat-list>
        </div>
        <div class="cstmList">
                <h2 class="mb-2 textTitle"><mat-icon class="titleIcon">payments</mat-icon>Finance</h2>
                <mat-list *ngFor="let data of dataFinance" role="list">
                        <mat-list-item (click)="openLink(data, 'finance')" class="cstmListItem" role="listitem"> <mat-icon class="cstmIcon">add</mat-icon> {{data?.label}}</mat-list-item>
                </mat-list>
        </div>
        <div class="cstmList">
                <h2 class="mb-2 textTitle"><mat-icon class="titleIcon">account_box</mat-icon>Users</h2>
                <mat-list *ngFor="let data of dataUser" role="list">
                        <mat-list-item (click)="openLink(data, 'users')" class="cstmListItem" role="listitem"> <mat-icon class="cstmIcon">add</mat-icon> {{data?.label}}</mat-list-item>
                </mat-list>
        </div>
      
</div>
