import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, UrlSerializer } from '@angular/router';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { PurchaseViewComponent } from '../../purchase/purchase-view/purchase-view.component';
import { PdfprintService } from 'src/app/services/pdfprint.service';
import { PrintDialogComponent } from 'src/app/shared/Dialog/print-dialog/print-dialog.component';
import { LoaderService } from 'src/app/services/base/loader.service';
import { FRReportService } from 'src/app/services/base/frreport.service';


@Component({
  selector: 'app-sale-view-dialog',
  templateUrl: './sale-view-dialog.component.html',
  styleUrls: ['./sale-view-dialog.component.scss']
})
export class SaleViewDialogComponent implements OnInit {


  dataHdr:any= null;
  dataAll:any[] = [];

  constructor(
    public router:Router,
    public routerService:RouteService,
    public dialogService:DialogService,
    public helpservice:ConfHelpService,
    public dialogRef: MatDialogRef<PurchaseViewComponent>,
    public pdfService:PdfprintService,
    public loaderService:LoaderService,
    public fRReportService:FRReportService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getViewData();
   
   // this.getUrl();
  }

  async getViewData() {
    this.loaderService.startLoader();
    var filter = {
      id : this.data.ID,
      
    }

    await this.helpservice.getFormDataByHelpNo("vw_salsaleinvoice", filter).toPromise().then(res => {
      this.dataHdr = res['value'][0] as any;
      this.dataAll = res['value'] as any[];
      this.loadPrintContent();
      this.loaderService.endLoader();
      console.log(this.dataHdr)
    })

   
    
    
   
  }

  report_content:string = "";

  loadPrintContent(){
    let obj = {
      "id": this.data.ID,
    }

    let scode:string = this.dataHdr.NonGST ? "rpt32" : "rpt09";

    this.fRReportService.getReportContent(scode, obj).then(res => {
      if (res) {
        this.report_content = res;
      }
    });

   
  }


  directPrint(){
  
      if(this.report_content) {
        const link = document.createElement('a');
        link.href = this.report_content;
        link.download = this.fRReportService.getFileName();
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        this.loaderService.endLoaderOnSave();
      }
  
  }

  print(){
    if(this.data.ID == 0)
      return;

      /*var scode: string = "";
      if (this.dataHdr.NonGST == true)
        scode = 'rpt32'
      else
        scode = 'rpt09';
  
      var obj = {
        reportno: scode,
        queryparam: 'id=' + this.primaryKey,
      }
      this.dialogService.openPrintDialog(PrintDialogComponent, obj);
      */

    if (this.dataHdr.NonGST == true) {
      var obj = {
        reportno: 'rpt32',
        queryparam: 'id=' + this.data.ID,
      }
      this.dialogService.openPrintDialog(PrintDialogComponent, obj);
    }
    else {
      var obj = {
        reportno: 'rpt09',
        queryparam: 'id=' + this.data.ID,
      }
      this.dialogService.openPrintDialog(PrintDialogComponent, obj);
    }
  
  }

   edit_url:string = '';

  getUrl(){

  const navigationUrl = this.router.createUrlTree([this.routerService.openRoute("sales", "sale")], { queryParams: { ID: this.data.ID, parent: 'salelist' }}).toString();
   console.log(window.location.href)
   var urlHost = window.location.origin + '/#' + navigationUrl;
   this.edit_url = urlHost;
   //window.open(urlHost, '_blank');
 }

  onEdit(){

    this.dialogRef.close();
    this.router.navigate([this.routerService.openRoute("sales", "sale")], { queryParams: { ID: this.data.ID, parent: 'salelist' } });

    /*
     const navigationUrl = this.router.createUrlTree([this.routerService.openRoute("sales", "sale")], { queryParams: { ID: this.data.ID, parent: 'salelist' }}).toString();
    console.log(window.location.href)
    var urlHost = '/#' + navigationUrl;
    window.open(urlHost, '_blank');
    */
  }



  // printHtml(){
  //   this.pdfService.generatePDF('pdfContent',this.dataHdr?.DocumentNo)
  // }

}