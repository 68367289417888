import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { RBAC_User } from 'src/app/models/rbac_user.model';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { BaseService } from 'src/app/services/base/base.service';
import { LocalStorageService } from 'src/app/services/base/local-storage.service';
import { PermissionService } from 'src/app/services/base/permission.service';
import { RouteService } from 'src/app/services/base/route.service';
import { SPOperationsService } from 'src/app/services/base/sharepoint/spoperations.service';
import { TokenService } from 'src/app/services/base/token.service';

import { SigninService } from 'src/app/services/base/signin.service';
import { SignupService } from 'src/app/services/base/signup.service';
import { Company } from 'src/app/models/company.model';
import { MatStepper } from '@angular/material/stepper';
import { EnOnboardStepper } from 'src/app/models/enums/enum.model';
import { ConfDictionaryDataService } from 'src/app/services/conf-dictionary-data.service';
import { error } from 'console';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: Claim[] = [];
  private readonly _destroying$ = new Subject<void>();

  spUserEmail:string = "";
  usernName:string = "";
  ifNameExists:boolean = false;
  selectedIndex:number = 0;
  showError:boolean = false;
  showDivLoading:boolean = true;
  showDivDone:boolean = false;
  showLoader:boolean = false;
  showContent:boolean = false;

  imagePreview: string;


  constructor(
    public router: Router, 
    public authenticate:AuthenticateService,    
    public base :BaseService,
    public tokenService:TokenService,
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService,
    private localStorageService: LocalStorageService,
    private permissionService: PermissionService,
    public routeService:RouteService,
    public signinService:SigninService,
    private signupService:SignupService,
    public confDictionaryData:ConfDictionaryDataService
  ) { }

  ngOnInit(): void {

   // start your loader : 1

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) =>  status === InteractionStatus.None || status === InteractionStatus.HandleRedirect),
      takeUntil(this._destroying$),
      catchError((async (err) => console.log(err)))
    )
    .subscribe(async (res) => {

    
      this.checkAndSetActiveAccount();
      this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
      this.getUserName();
      
      // if new user
      if(this.getClaimByName("newUser")){

        this.showContent = true;

        this.selectedIndex = EnOnboardStepper.Name;

        // if has identity provider "idp"
        if(this.getClaimByName("idp")){
          this.selectedIndex =EnOnboardStepper.Company;

         
          // if login with google
          if(this.getClaimByName("idp").value == 'google.com'){
            // this.onStepChange({ selectedIndex: 1 });
            // signup and save company
            this.signup();
          }  
        }
        
         
      } else {
        this.showContent = false;
        this.showLoader = true;
        setTimeout(() => {
        // sign in when user is not new
          this.signin();
        }, 5000)

      }


      


    }, error => {
      console.log('custom error', error);
    })
    

   
  }

  AfterModule(){
    this.selectedIndex = EnOnboardStepper.Last;
    this.onStepChange({ selectedIndex: EnOnboardStepper.Last });
  }



  async signin() {
    var email = this.getClaimByName("emails"); //this.dataSource.filter(a => a.claim == "emails")[0];
    await this.getUserDetailFromSQL(email.value[0]).then(res => {
      console.log(this.localStorageService.getCurrentUser());
      
    });
    this.confDictionaryData.getAllData();
    // console.log(this.localStorageService.setPaymentMode())
  }


  onStepChange(event: any) {
    const stepIndex = event.selectedIndex;
    
    if (stepIndex === EnOnboardStepper.Last) {
      this.showDivLoading = true;
      setTimeout(() => {
        this.showDivLoading = false;
        this.showDivDone = true;
      }, 8000); 
    } 
  }

  onFinish(){

    this.signin();
  }

  


  // onStepChange(event: any) {
  //   const stepIndex = event.selectedIndex;
    
  //   if (stepIndex === 1) {
     
  //     setTimeout(() => {
  //       this.signin();
  //     }, 8000); // 
  //   }
  // }

  nextAndSaveManual(){
    if (this.usernName.trim() !== '' && this.usernName.length >= 3) { 
      this.signup();
      this.selectedIndex = EnOnboardStepper.Company;
      this.showError = false; 
    } else {
      this.showError = true;
    }
  }


  async signup() {

    this.setUserName();

    if(!this.usernName) return;

    var objCompany:Company = new Company();
    this.setCompanyObject(objCompany);
    
    this.signupService.signup(objCompany).subscribe(res =>{
      // we have hide this code because we will sign in after on boarding
      //this.signin();

      // stop your loader : 1
   }, error =>{

   });
  }


  setCompanyObject(objCompany:Company){
    objCompany.OwnerName = this.usernName;
    objCompany.CompanyName = this.usernName + " Company";
    objCompany.Email = this.getClaimByName("emails").value[0];
  }

  getUserName(){
    //this.usernName = this.getClaimByName("name") ? this.getClaimByName("name").value : this.usernName;
    this.ifNameExists = this.getClaimByName("name") ? true : false;
  }


  setUserName(){
    this.usernName = this.getClaimByName("name") ? this.getClaimByName("name").value : this.usernName;
  }

  
 
  getClaimByName(claimName:string):Claim | null{

    if(this.dataSource.length > 0){
      if(this.dataSource.filter(a => a.claim == claimName).length > 0){
        return this.dataSource.filter(a => a.claim == claimName)[0];
      }
    }
    
    return null;
  }

  checkAndSetActiveAccount() {

    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  
  getClaims(claims: any) {

    let list: Claim[]  =  new Array<Claim>();

    Object.keys(claims).forEach(function(k, v){
      
      let c = new Claim()
      c.id = v;
      c.claim = k;
      c.value =  claims ? claims[k]: null;
      list.push(c);
    });
    this.dataSource = list;

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  async getUserDetailFromSQL(email: string) {

    await this.signinService.getSignInUser(email).toPromise().then(res => {

      if (res == null || res == undefined || res['value'] == null || res['value'].length == 0) {
        this.router.navigate([this.routeService.getUnauthorizedAccessRoute()]);
      }



      this.authenticate.isLoggedIn = true;

      var data = res['value'][0] as RBAC_User;
      if (data) {
        data['sessionTimeout'] = this.sessionTimeout();
        debugger;
        this.tokenService.acquireTokenSilently().subscribe(res => {
          debugger;
          this.tokenService.setAccessToken(res);
          this.localStorageService.setCurrentUser(data);
          //this.permissionService.getAllSectionPermission();
          this.router.navigate([this.routeService.getHomeRoute()]);

        })



      } else {
        //this.authenticate.user.IsAdmin = false;
        //resolve(true);
      }


    }, error => {

      if (error) {
        if (error.statusText == "Unauthorized") {
          console.log("User is Unauthorized");
          //alert("Sorry, You Are Not Allowed to Access This Page")
          //document.getElementById("authError").innerHTML = "Sorry, you are not allowed to access this page.";
          //document.getElementById("Apploader").style.display = "none";
        }
      }

    })



  }

  private sessionTimeout(): Date {
    
    var sessionTimeout = moment().add(1380,'minute').toDate();
    
    return sessionTimeout;
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.onload = (event: Event) => {
        this.imagePreview = (<FileReader>event.target).result as string;
      };
    }
  }

}

export class Claim {
  id: number = null!;
  claim: string= null!;
  value: string= null!;
}
