import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/base/loader.service';
import { MyFormDialogComponent } from '../my-form-dialog/my-form-dialog.component';

@Component({
  selector: 'app-my-view-dialog',
  templateUrl: './my-view-dialog.component.html',
  styleUrls: ['./my-view-dialog.component.scss']
})
export class MyViewDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MyFormDialogComponent>,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 

    }

  ngOnInit(): void {
  }


}

