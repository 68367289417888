import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class SaleService {

  readonly controllerName:string = "Sales";
  constructor(private base:BaseService) {    
   }

   async getAll() {
     var query = {
       expand:"Customer, Plant",
       orderby:"ID desc"
     }
    return await this.base.sql.get(this.controllerName, query);
  }

  //StoredProcedure
   getAllBySp(datefrom:string, dateto:string){
    var qry = "exec sp_saleInvoiceList  '" + datefrom + "', '" + dateto + "'";
    return  this.base.sql.getBySqlQueryBody(qry);
  }

  getSalesDashboard(dateFrom:string, dateTill:string){

    const objFilter = {
      DateFrom:dateFrom,
      DateTill:dateTill
    }

     var apiRoute = "api/SaleDashboard/GetSaleDashboardData";

   return this.base.sql.postAny(apiRoute, objFilter).toPromise();
  }

  getAllView(Id:number){
    var qry = "exec sp_saleInvoiceView " + Id;
    return  this.base.sql.getBySqlQueryBody(qry);
  }

  async getById(ID: number) {
    var query = {
      expand:"Sale_Detail",      
    }
    return await this.base.sql.getById(this.controllerName, ID, query);
  }

  getSalePriceHistory(customerID:number, itemID:number){
    var qry = "exec sp_saleInvoicePriceHistory " + customerID + "," + itemID;
    return  this.base.sql.getBySqlQueryBody(qry);
  }

  getSaleSum(){
    var qry = "exec  sp_StockSum";
    return this.base.sql.getBySqlQueryBody(qry);
  }

  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody);

  }

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  delete(ID: number) {
    return this.base.sql.delete(this.controllerName, ID)
  }
}
