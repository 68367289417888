import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThemeService } from 'src/app/services/base/theme.service';

export interface GroupBy {
  initial: string;
  isGroupBy: boolean;
}

import * as customConfig from 'src/assets/files/customConfig.json';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FRReportService } from 'src/app/services/base/frreport.service';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { FilterDialogComponent } from '../../Dialog/filter-dialog/filter-dialog.component';
import moment from 'moment';
import { LocalStorageService } from 'src/app/services/base/local-storage.service';


@Component({
  selector: 'app-my-table-report',
  templateUrl: './my-table-report.component.html',
  styleUrls: ['./my-table-report.component.scss']
})

export class MyTableReportComponent implements OnInit, OnChanges {
  readonly rootreporturl: string = customConfig.reportapi;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() columns: any[] = [];
  @Input() dataSource: any[] = [];
  @Input() search: boolean = true;
  @Input() filter: boolean = false;
  @Input() paging: boolean = true;
  data: any;
  displayedColumns: string[] = [];
  @Input() colspan1:number= 0;
  @Input() colspan2:number= 0;

  collength:number = 0;
  selectedBtn:string = 'web';
 
  @Input() reportno:string=""
  @Input() reportparams:string="";

  @Output() row_click = new EventEmitter<any>();;
  
  report_filters:any[] = [];

  showLoader:boolean=false;

  constructor(
    public theme:ThemeService,
    private sanitizer: DomSanitizer,
    public fRReportService:FRReportService,
    public confHelpService:ConfHelpService,
    public dialogService:DialogService,
    public localStorageService:LocalStorageService
    ) { }

  ngOnInit(): void {
    ////data = ELEMENT_DATA;
    // this.data = new MatTableDataSource(this.dataSource);
    // this.displayedColumns = this.columns.map(c => c.columnDef);
    ////cols = this.columns.map(c => c.columnDef + "1");
    //this.data.sort = this.sort;
    //this.data.paginator = this.paginator;

    //Overrride default filter behaviour of Material Datatable
  

 

  }

  ngAfterViewInit() {
   
  }

  ngOnChanges(): void {
    this.getConfData().then(res =>{
      this.getFilters();
      this.setTable();
    })
  
    // sameer: 10192023
   //this.getFastReportQry();
  }

  async getDataByHelpNo(helpno:string){
    await this.confHelpService.getDataByHelpNo(helpno).toPromise().then(res =>{
      var hlp = this.report_filters.filter( a => a.HelpNo == helpno)  
      if(hlp.length > 0){
          hlp[0].objData = res['value'] as any[];
      }
    
    })
  }

  groups_count:number = -1;
  rootdata:any = {};
  configureData(rawData: any[],) {

    if (rawData.length == 0)
      return;
    // const rawData = [
    //   { ID: 1, Group3: 'CompanyX', Group2: 'PlantA', Group1: 'Item1', OpeningQty: 10, QtyIn: 5, QtyOut: 3, Stock: 12 },
    //   { ID: 1, Group3: 'CompanyX', Group2: 'PlantA', Group1: 'Item1', OpeningQty: 1, QtyIn: 5, QtyOut: 3, Stock: 12 },
    //   { ID: 2, Group3: 'CompanyX', Group2: 'PlantA', Group1: 'Item2', OpeningQty: 15, QtyIn: 8, QtyOut: 6, Stock: 17 },
    //   { ID: 3, Group3: 'CompanyY', Group2: 'PlantB', Group1: 'Item1', OpeningQty: 8, QtyIn: 4, QtyOut: 2, Stock: 10 },
    //   ///{ ID: 4, Group3: 'CompanyZ', Group2: 'PlantC', OpeningQty: 5, QtyIn: 3, QtyOut: 2, Stock: 6 },
    //   // ... more data with or without groups
    //   ];

    // Get SUM columns
    var colSums = this.columns.filter(a => a.isSum == true);
    var objSum = {};
    if (colSums.length > 0) {
      for (let index = 0; index < colSums.length; index++) {
        const element = colSums[index];
        objSum[element.columnDef] = 0;
      }
    }

    // If Group 3
    var masterdata: any[] = [];
    // we are getting 0 index because we already know that there should be atlease 1 row
    if (rawData[0].hasOwnProperty('Group3') == true) {
      this.groups_count = 3;
      masterdata = this.set3Groups(rawData, objSum);
    } else if (rawData[0].hasOwnProperty('Group2') == true) {
      this.groups_count = 2;
      masterdata = this.set2Groups(rawData, objSum);
    } else if (rawData[0].hasOwnProperty('Group1') == true) {
      this.groups_count = 1;
      masterdata = this.set1Groups(rawData, objSum)
    } else {
      this.groups_count = 0;
      masterdata = this.setNogroup(rawData)
      //masterdata['data'] = rawData;
    }


    var root: any = {};
    root['Total'] = Object.assign({}, objSum);
    root['Openning'] = 0;
    root['Closing'] = 0;

    if (this.groups_count == 0)
      this.setWithoutGroupsRootTotal(masterdata, root);
    else
      this.setGroupsRootTotal(masterdata, root);
    /*
    if (masterdata.length > 0) {

      for (let index = 0; index < masterdata.length; index++) {
        let element_for_total = masterdata[index];

        for (const key in element_for_total.Total) {
          root.Total[key] += element_for_total.Total[key];
        }

        root.Openning += element_for_total.Openning;
        root.Closing += element_for_total.Closing;
      }
    }
    */

    root['master'] = masterdata;

    this.rootdata = root;
    console.log('root data', this.rootdata)


  }

  setGroupsRootTotal(masterdata: any[], root: any) {
    if (masterdata.length > 0) {

      for (let index = 0; index < masterdata.length; index++) {
        let element_for_total = masterdata[index];

        for (const key in element_for_total.Total) {
          root.Total[key] += element_for_total.Total[key];
        }

        root.Openning += element_for_total.Openning;
        root.Closing += element_for_total.Closing;
      }
    }
  }

  setWithoutGroupsRootTotal(masterdata: any[], root: any) {
    if (masterdata.length > 0) {

      for (let index = 0; index < masterdata.length; index++) {
        let data = masterdata[index];

        for (let index = 0; index < data.data.length; index++) {
          const element_for_total = data.data[index];
          for (const key in root.Total) {
            root.Total[key] += element_for_total[key];
          }
          root.Openning += element_for_total.Openning;
          root.Closing += element_for_total.Closing;
          
        }

      

        
      }
    }
  }

  set3Groups(rawData: any[], objSum: any):any[] {
    var masterdata:any[] = [];
    const uniqueGroup3 = [...new Set(rawData.map(item => item.Group3))];
    for (let index = 0; index < uniqueGroup3.length; index++) {

      // Adding field in Group 3
      var grp3: any = {};
      let elementG3 = uniqueGroup3[index];
      grp3['Title'] = elementG3;
      grp3['Total'] = Object.assign({}, objSum);
      grp3['Group2'] = [];
      grp3['Openning'] = 0;
      grp3['Closing'] = 0;

      var rawdata_of_grp3 = rawData.filter(a => a.Group3 == elementG3);
      if (rawdata_of_grp3.length > 0) {

        const uniqueGroup2 = [...new Set(rawdata_of_grp3.map(item => item.Group2))];
        for (let index = 0; index < uniqueGroup2.length; index++) {
          // Adding field in Group 2
          var grp2: any = {};
          let elementG2 = uniqueGroup2[index];
          grp2['Title'] = elementG2;
          grp2['Total'] = Object.assign({}, objSum);;
          grp2['Group1'] = [];
          grp2['Openning'] = 0;
          grp3['Closing'] = 0;

          var rawdata_of_grp2 = rawdata_of_grp3.filter(a => a.Group2 == elementG2);
          const uniqueGroup1 = [...new Set(rawdata_of_grp2.map(item => item.Group1))];
          for (let index = 0; index < uniqueGroup1.length; index++) {
            // Adding field in Group 1
            var grp1: any = {};
            let elementG1 = uniqueGroup1[index];
            grp1['Title'] = elementG1;
            grp1['Total'] = Object.assign({}, objSum);;
            grp1['data'] = [];
            grp1['Openning'] = 0;
            grp1['Closing'] = 0;

            var rawdata_of_grp1 = rawdata_of_grp2.filter(a => a.Group1 == elementG1);
            rawdata_of_grp1.forEach(element => {

              // insert row
              grp1['data'].push(element);
              grp1['Openning'] = element.Openning ? element?.Openning : 0;
              grp1['Closing'] = element.Closing ? element?.Closing : 0;;

              // sum row for group 1
              for (const key in objSum) {
                grp1.Total[key] += element[key];
              }

            });

            // Add group 1 in group 2
            grp2['Group1'].push(grp1);
            //var rawdata_of_grp2 = rawdata_of_grp3.filter(a => a.Group1 == elementG1);
          }

          if (grp2.Group1.length > 0) {

            for (let index = 0; index < grp2.Group1.length; index++) {
              let element_for_total = grp2.Group1[index];

              for (const key in element_for_total.Total) {
                grp2.Total[key] += element_for_total.Total[key];
              }

              grp2.Openning += element_for_total.Openning;
              grp2.Closing += element_for_total.Closing;

            }
          }


          // Add group 2 in group 3
          grp3['Group2'].push(grp2);

        }

        if (grp3.Group2.length > 0) {

          for (let index = 0; index < grp3.Group2.length; index++) {
            let element_for_total = grp3.Group2[index];

            for (const key in element_for_total.Total) {
              grp3.Total[key] += element_for_total.Total[key];
            }

            grp3.Openning += element_for_total.Openning;
            grp3.Closing += element_for_total.Closing;

          }
        }

        // add group 3 in master data

        masterdata.push(grp3);


      }
    }

    return masterdata;
  }
  
  set2Groups(rawData: any[], objSum: any):any[] {
    var masterdata:any[] = [];
    const uniqueGroup2 = [...new Set(rawData.map(item => item.Group2))];
    for (let index = 0; index < uniqueGroup2.length; index++) {

      // Adding field in Group 2
      var grp2: any = {};
      let elementG2 = uniqueGroup2[index];
      grp2['Title'] = elementG2;
      grp2['Total'] = Object.assign({}, objSum);
      grp2['Group1'] = [];
      grp2['Openning'] = 0;
      grp2['Closing'] = 0;

      var rawdata_of_grp2 = rawData.filter(a => a.Group2 == elementG2);
      if (rawdata_of_grp2.length > 0) {

        const uniqueGroup1 = [...new Set(rawdata_of_grp2.map(item => item.Group1))];
        for (let index = 0; index < uniqueGroup1.length; index++) {
          // Adding field in Group 1
          var grp1: any = {};
          let elementG1 = uniqueGroup1[index];
          grp1['Title'] = elementG1;
          grp1['Total'] = Object.assign({}, objSum);;
          grp1['data'] = [];
          grp1['Openning'] = 0;
          grp1['Closing'] = 0;

          var rawdata_of_grp1 = rawdata_of_grp2.filter(a => a.Group1 == elementG1);
          rawdata_of_grp1.forEach(element => {

            // insert row
            grp1['data'].push(element);
            grp1['Openning'] = element.Openning ? element?.Openning : 0;
            grp1['Closing'] = element.Closing ? element?.Closing : 0;;

            // sum row for group 1
            for (const key in objSum) {
              grp1.Total[key] += element[key];
            }

          });

          // Add group 1 in group 2
          grp2['Group1'].push(grp1);
          //var rawdata_of_grp2 = rawdata_of_grp3.filter(a => a.Group1 == elementG1);

          /*
          var rawdata_of_grp2 = rawdata_of_grp3.filter(a => a.Group2 == elementG2);
          const uniqueGroup1 = [...new Set(rawdata_of_grp2.map(item => item.Group1))];
          for (let index = 0; index < uniqueGroup1.length; index++) {
            // Adding field in Group 1
            var grp1: any = {};
            let elementG1 = uniqueGroup1[index];
            grp1['Title'] = elementG1;
            grp1['Total'] = Object.assign({}, objSum);;
            grp1['data'] = [];
            grp1['Openning'] = 0;
            grp3['Closing'] = 0;

            var rawdata_of_grp1 = rawdata_of_grp2.filter(a => a.Group1 == elementG1);
            rawdata_of_grp1.forEach(element => {

              // insert row
              grp1['data'].push(element);
              grp1['Openning'] = element.Openning ? element?.Openning : 0;
              grp3['Closing'] = element.Closing ? element?.Closing : 0;;

              // sum row for group 1
              for (const key in objSum) {
                grp1.Total[key] += element[key];
              }

            });

            // Add group 1 in group 2
            grp2['Group1'].push(grp1);
            //var rawdata_of_grp2 = rawdata_of_grp3.filter(a => a.Group1 == elementG1);
            */
          }

          if (grp2.Group1.length > 0) {

            for (let index = 0; index < grp2.Group1.length; index++) {
              let element_for_total = grp2.Group1[index];

              for (const key in element_for_total.Total) {
                grp2.Total[key] += element_for_total.Total[key];
              }

              grp2.Openning += element_for_total.Openning;
              grp2.Closing += element_for_total.Closing;

            }
          }


          // Add group 2 in group 3
          //grp3['Group2'].push(grp2);

        }

      /*
        if (grp3.Group2.length > 0) {

          for (let index = 0; index < grp3.Group2.length; index++) {
            let element_for_total = grp3.Group2[index];

            for (const key in element_for_total.Total) {
              grp3.Total[key] += element_for_total.Total[key];
            }

            grp3.Openning += element_for_total.Openning;
            grp3.Closing += element_for_total.Closing;

          }
        }
        */

        // add group 2 in master data

        masterdata.push(grp2);


      }
    

    return masterdata;
  }

  set1Groups(rawData: any[], objSum: any):any[] {
    var masterdata:any[] = [];
    const uniqueGroup1 = [...new Set(rawData.map(item => item.Group1))];
    for (let index = 0; index < uniqueGroup1.length; index++) {

      // Adding field in Group 2
      var grp1: any = {};
      let elementG1 = uniqueGroup1[index];
      grp1['Title'] = elementG1;
      grp1['Total'] = Object.assign({}, objSum);
      grp1['data'] = [];
      grp1['Openning'] = 0;
      grp1['Closing'] = 0;

      var rawdata_of_grp1 = rawData.filter(a => a.Group1 == elementG1);
      rawdata_of_grp1.forEach(element => {

        // insert row
        grp1['data'].push(element);
        grp1['Openning'] = element.Openning ? element?.Openning : 0;
        grp1['Closing'] = element.Closing ? element?.Closing : 0;;

        // sum row for group 1
        for (const key in objSum) {
          grp1.Total[key] += element[key];
        }

      });

     
        // add group 1 in master data

        masterdata.push(grp1);


      }
    

    return masterdata;
  }

  setNogroup(rawData: any[]):any[] {
    var masterdata:any[] = [];
    var obj: any = {};
    obj['data'] = rawData;
    masterdata.push(obj);
    return masterdata;
  }
 

  groupData(data: any[]) {
    var assignedTo = "";
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      
      if(!('groupon' in element)) 
      break;

      if (index == 0) {
        var openning:number = element.Openning ? element.Openning : 0; 
        data.unshift({ initial: element.groupon, lastHeaderLabel: "Openning", lastHeaderValue:openning, isGroupBy: true })      
        assignedTo = element.groupon;  
        
      }
      else {
        if (assignedTo != element.groupon) {
          var openning:number = element.Openning ? element.Openning : 0; 
          data.splice(index, 0, { initial: element.groupon, lastHeaderLabel: "Openning", lastHeaderValue:openning, isGroupBy: true })         
          assignedTo = element.groupon;  
        }
      }
    }
  }

  getFilters(){

    var promiseCalls:any[] = [];

    this.fRReportService.getFilters(this.reportno).toPromise().then(res =>{
      var data = res['value'] as any[];
      data.forEach(a => {
      if(a['DefaultValue'] ){
        if(a['DefaultValue']  == 'start_of_month'){
          a['val'] = moment().startOf('M').format('YYYY/MM/DD');
        } else
        if(a['DefaultValue']  == 'end_of_month'){
          a['val'] = moment().endOf('M').format('YYYY/MM/DD');
        } else
          a['val'] = a['DefaultValue'];
      } else {
        a['val'] = null;
      }
        
        a['objData'] = null;
        if(a['HelpNo']){
         
         promiseCalls.push( this.getDataByHelpNo(a["HelpNo"]));
        }

      })
      this.report_filters = data;
     Promise.all(promiseCalls).then(res => {
      this.getData();
     })

     
      //console.log('report filters', this.report_filters)
    })
  }

  filters_count:number = 0;

  getData(){
    this.showLoader = true;
    var objfilter:any = {};
    this.filters_count = 0;
    this.reportparams = ""
    this.report_filters.forEach(a => {
   
      if(a['val']){
        if (a["FieldType"] == "date") {
          objfilter[a['FieldName']] = moment(a['val']).format('YYYY/MM/DD');
          this.setReportParams(a['FieldName'], moment(a['val']).format('YYYY/MM/DD'));
          this.filters_count++;
        } else if (a["FieldType"] == "dropdown"){
          objfilter[a['FieldName']] = a['val'][a.ValueMember];
          this.setReportParams(a['FieldName'], a['val'][a.ValueMember]);
          this.filters_count++;
        }

        
  
      }
      else {
        if (a["FieldType"] == "date" ){
          objfilter[a['FieldName']] = '';
          this.setReportParams(a['FieldName'], '');
        }
          
        else  if (a["FieldType"] == "dropdown" ) {
          objfilter[a['FieldName']] = 0;
          this.setReportParams(a['FieldName'], 0);
        }
          
        
      }
    
    })
    this.dataSource= [];

    this.fRReportService.getDataByReportCode(this.reportno, objfilter).subscribe(res =>{
    
      this.configureData(res['value']);
      //this.groupData(res['value']);
      this.dataSource = res['value'] as any[];
      this.getFastReportQry();
      this.showLoader = false;
      //this.reportparams = 'datefrom=' + datefrom + '&datetill=' + datetill + '&glaccountid=' + GLAccountID;
    })
   
  }

  setReportParams(field, value){

    if(this.reportparams == ""){
      var companyobjectid_encode = encodeURIComponent(this.localStorageService.getCurrentUser().CompanyObjectId);
      this.reportparams = "CompanyObjectId=" + companyobjectid_encode;

    }
      

    this.reportparams += "&" +  field + "=" + value; 
  }

  async getConfData(){
    if(this.reportno == "")
      return;


    // if(this.isConfLoaded == true)
    // return;
      
    // this.isConfLoaded = true;
    await this.fRReportService.getConfDataByReportCode(this.reportno).then( a => {
      this.showLoader = true;
      var colData = a['value'] as any[];
      //this.tableQueryData = a['Table1'] as any[];

      // Start Conf_table_detail data
      colData.forEach(item => {

        var obj = null;
       {
          obj =
          {
            columnDef: item.columnDef,
            header: item.header,
            type: item.type,
            cell: (element: any) => `${element[item.cell]}`,
            filterData: [],
            isSum: item.isSum,
            isRunningBalance: item.isRunningBalance,
            width: item.width
          }
        }
        this.columns.push(obj);
      });

    })   
  }

  openFilterDialog() {
    this.dialogService.openFilterDialog(FilterDialogComponent, this.report_filters);

    this.dialogService.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
        /*
        result.forEach(element => {
          if (element.FieldType == 'date') {

          }
        });
        */
      }
    });
  }


  frurl:SafeResourceUrl = null;
  getFastReportQry(){
    if(this.rootreporturl && this.reportno && this.reportparams){
      var url = this.rootreporturl + this.reportno +'&'+ this.reportparams;
      url = encodeURI(url);
      this.frurl =this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
      
  }
  

  setTable(){
    this.data = new MatTableDataSource(this.dataSource);
    this.displayedColumns = this.columns.map(c => c.columnDef);    
    this.data.sort = this.sort;
    this.data.paginator = this.paginator;
    this.data.paginator = this.paginator;

    
    for (let index = 0; index < this.columns.length; index++) {
      const element = this.columns[index];
      element.filterData = this.getUnique(this.dataSource, element.columnDef);
    }

    this.data.filterPredicate = this.createFilter();
    this.collength = this.columns.length;
  }


  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  applyFilter(event: String) {
    const filterValue = event;
    this.data.filter = filterValue.trim().toLowerCase();
  }



  getUnique(data: any[], columnName: string): any[] {
    return data.map(item => item[columnName]).filter((value, index, self) => self.indexOf(value) === index);
  }

  filterValues = {};
  // Called on Filter change
  filterChange(column, event) {
    //let filterValues = {}
    if (event == undefined || event == null) {

      if (this.filterValues[column.columnDef]) {
        delete this.filterValues[column.columnDef]
      }

      if (this.filterValues == null || this.filterValues == undefined || this.filterValues == "" || (this.filterValues && (Object.keys(this.filterValues).length === 0)))
        this.data.filter = "";
      else {
        this.filterValues[column.columnDef] = String(event);
        this.data.filter = JSON.stringify(this.filterValues)
      }

    }
    else {
      var myEvent:string = "";
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        myEvent = myEvent  +' '+ element;
      }

      this.filterValues[column.columnDef] = String(myEvent);
      this.data.filter = JSON.stringify(this.filterValues)
    }


  }


  /*
   // Custom filter method fot Angular Material Datatable
   createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      return data.name.toLowerCase().indexOf(searchTerms.name) !== -1
        && data.id.toString().toLowerCase().indexOf(searchTerms.id) !== -1
        && data.colour.toLowerCase().indexOf(searchTerms.colour) !== -1
        && data.pet.toLowerCase().indexOf(searchTerms.pet) !== -1;
    }
    return filterFunction;
    }
    */


  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      console.log(searchTerms);

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              if (data[col].toString().toLowerCase().indexOf(word) !== -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }

   /** Gets the total cost of all transactions. */
   getTotal(column:any) {
     var total:number = 0;
    if(column.isSum == true){
      this.dataSource.forEach(element => {    
        if(element[column.columnDef] != undefined && element[column.columnDef] !=null)
          total += Number(element[column.columnDef]);
      });
    } else if(column.isRunningBalance == true){
      if(this.dataSource.length > 1){        
        if(this.dataSource[1]['RunningBalanceTotal'] != undefined && this.dataSource[1]['RunningBalanceTotal'] !=null)
          return Number(this.dataSource[1]['RunningBalanceTotal'] );
      }
    }
    
    return total;

    //return column.isSum == true ? this.dataSource.map(t => t[column.columnDef]).reduce((acc, value) => acc + value, 0) : '';
  }

  onBtnClick(type:string){
    this.selectedBtn = type;
  }

  onLinkClick(row:any, column:any){
    if(column.type == "link"){
      this.row_click.emit(row);
    }

  }
}
