import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { DialogService } from './base/dialog.service';
import { MessageboxComponent } from '../shared/Dialog/messagebox/messagebox.component';
import { LoaderService } from './base/loader.service';
import { CustomErrorHanderService } from './base/custom-error-hander.service';
import moment from 'moment';
import { LocalStorageService } from './base/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  readonly controllerName:string = "Items";

  constructor(private base:BaseService,
    public errorService:CustomErrorHanderService,
    
    public localStorageService: LocalStorageService
) 
     {  }

  async getAll() {
    var query = {
      expand: "ItemCategory, Unit, ItemType, ItemMake"
    }
    return await this.base.sql.get(this.controllerName, query);
  }

  async getAllWithImage() {
    var query = {
      expand: "ItemCategory, Unit, ItemType, ItemMake, Item_Image"
    }
    return await this.base.sql.get(this.controllerName, query);
  }

 getAllBySp(customerID:number) {
    var query = "sp_itemListLastPrice " + customerID;
   return this.base.sql.getBySqlQueryBody(query);
 }


  async getById(ID: number) {
    var query = {
      expand: "Item_Unit"
    }
    return await this.base.sql.getById(this.controllerName, ID, query);
  }

  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody, 'inv_01').pipe( catchError((err=> this.errorService.handleError(err))) );
  }
  

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  delete(ID: number) {
    return this.base.sql.delete(this.controllerName, ID)
  }

  upsert(itemArray:any[]){

for (let index = 0; index < itemArray.length; index++) {
  const jsonBody = itemArray[index];
  if (jsonBody['CreatedBy'] == null)
    jsonBody['CreatedBy'] = this.localStorageService.getCurrentUser().ID;

  if (jsonBody['CreatedDate'] == null) {
    console.log(moment().utc(true));
    console.log(
      moment().utc(true).format('YYYY-MM-DDThh:mm:ss.SSS') + '-07:00'
    );
    moment().utc(true);
    jsonBody['CreatedDate'] =
      moment().utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'); //+ '-07:00'; // new Date();
  }

  if (jsonBody['ModifiedBy'] == null)
    jsonBody['ModifiedBy'] = this.localStorageService.getCurrentUser().ID;

  if (jsonBody['ModifiedDate'] == null)
    jsonBody['ModifiedDate'] =
      moment().utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'); //+ '-07:00'; //new Date();

    jsonBody['Scode'] = "inv_01";
}

    

    return this.base.sql.postAny(`api/${this.controllerName}/upsert`, itemArray);
  }
}
