import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { ConfHelpService } from '../base/conf-help.service';
import { LocalStorageService } from '../base/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotifciationMessageService {
  readonly controllerName:string = "Notification_Message";


  constructor( private base:BaseService,
    public hlp : ConfHelpService,
    public localStorageService:LocalStorageService,) { }

  async getAll(){
    var query = {
      expand:"RBAC_User"
    }
    return await this.base.sql.get(this.controllerName, query);
  }

 
 getAlldataByUser(){
  var filter = {
    userid: this.localStorageService.getCurrentUser().ID
  }
  return this.hlp.getFormDataByHelpNo("notification_help",filter)
 }
}
