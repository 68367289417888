import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class AccountHeadService {


  readonly controllerName:string = "FN_AccountHead";

  constructor( private base:BaseService) { }

  async getAll(){
    return await this.base.sql.get(this.controllerName);
  }

  async getById(ID: number) {
    return await this.base.sql.getById(this.controllerName, ID);
  }

}
