<div class="desktop">
    <div class="table-responsive  ">      
        <table mat-table [dataSource]="data" matSort class=" w-100">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
    
            <th mat-header-cell *matHeaderCellDef>
            <div class=" pt-3 pb-1" mat-sort-header *ngIf="column.columnDef != 'action'">
                {{column.header}}
            </div>                
            </th>
    
            <td mat-cell *matCellDef="let row" >
            <span *ngIf="column.columnDef != 'action'">
                {{ column.cell(row) != null ? (column.type == 'currency' ? (column.cell(row) | currency) : column.type == 'date' ?  (column.cell(row) | date:'MM/dd/yyyy') : column.cell(row) == 'null' ? '' : column.cell(row)) : '' }}
            </span>          
            </td>
            
        </ng-container>
    
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        </table>  
    </div>                       
</div>
      
<div class="mobile">                  
    <app-my-list 
    [search]="true"
    [data]="data.data" 
    [title]="listTitle"           
    [val1]="listVal1"
    [val2]="listVal2"
    [val3]="listVal3">
    </app-my-list>
</div>
      
      