<!-- <div class="row" style="margin-top: -4px;">
    <div class="col-md-12">
        <div class="d-flex bd-highlight justify-content-end">
            <div class="p-2 bd-highlight">
                <button mat-raised-button style="margin-top: 7px;" class="shadow ms-1" *ngIf="filter">
                    <mat-icon class="add-icon" [class]="theme.myIconColor">filter_alt</mat-icon> Filter
                </button>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="table-responsive mat-elevation-z8 " style="margin-top: 10px; ">

    <table mat-table [dataSource]="data" matSort class="mat-elevation-z8 w-100">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">

            <th mat-header-cell *matHeaderCellDef>
                <div class=" pt-3 pb-1" mat-sort-header>
                    {{column.header}}
                </div>
                <app-my-table-filter *ngIf="filter" [hidden]="false" label="Filter" [data]="column.filterData"
                    [title]="column.header" [name]="column.columnDef" [model]="column.filterValue"
                    (change)="filterChange(column,$event)" class="w-100 mt-3">
                </app-my-table-filter>
            </th>

            <td mat-cell *matCellDef="let row">
                {{ column.cell(row) == null || column.cell(row) == 'null'? '' : column.type == 'currency' ? (column.cell(row) | currency) : column.type == 'date' ?  (column.cell(row) | date:'MM/dd/yyyy') : column.cell(row) }}
            </td>

            <td mat-footer-cell *matFooterCellDef> 
                <span *ngIf="column.isSum == true">{{getTotal(column) | currency}} </span> 
                <span *ngIf="column.isRunningBalance == true">{{(getTotal(column) | currency)}} </span> 
                <span *ngIf="column.columnDef == 'RowNo'"> Total </span> 
            </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

         
        <ng-container matColumnDef="groupHeader">            
                <td [attr.colspan]="colspan1" mat-cell *matCellDef="let groupBy" class="GroupRowColor">{{groupBy.initial}}</td>
        </ng-container>
        <ng-container matColumnDef="groupHeaderLastCol">            
                <td [attr.colspan]="colspan2" mat-cell *matCellDef="let groupBy" class="GroupRowColor">
                    {{groupBy.lastHeaderValue | currency}} ({{groupBy.lastHeaderLabel}})
                </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['groupHeader', 'groupHeaderLastCol']; when: isGroup"> </tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        
    </table>

    <mat-paginator *ngIf="paging" [pageSizeOptions]="[5, 10, 20]" [pageSize]="20" showFirstLastButtons
        aria-label="Select page of periodic elements">
    </mat-paginator>

</div> -->

<!-- toggle buttons -->
<div class="d-flex justify-content-between" style="margin-bottom:14px;" >
    <!-- <div mat-subheader>                                                                                                
            <button [ngClass]="{'back-button': selectedBtn == 'web'}" (click)="onBtnClick('web')" mat-button color="primary" class="moduleBtn me-1"> 
                <mat-icon class="me-1">article</mat-icon> 
                <span class="me-1"> Web </span>
            </button>
            <button [ngClass]="{'back-button': selectedBtn == 'print'}" (click)="onBtnClick('print')" mat-button color="primary" class="moduleBtn"> 
                <mat-icon class="me-1">description</mat-icon> 
                <span class="me-1"> Print </span>
            </button>                                                                                                         
    </div> 
    <button  mat-icon-button (click)="openFilterDialog()"  class="ms-3"> <mat-icon>filter_alt</mat-icon> Filters </button> -->
   
    <mat-chip-list aria-label="Fish selection">
        <mat-chip class="chipcls" [ngClass]="{'back-button': selectedBtn == 'web'}" (click)="onBtnClick('web')"> Web</mat-chip>
        <mat-chip class="chipcls" [ngClass]="{'back-button': selectedBtn == 'print'}" (click)="onBtnClick('print')"> Print</mat-chip>
        
    </mat-chip-list>

    <span class="d-flex gap-1">
        <app-my-button  (click)="getData()" icon="refresh" title="Refresh"></app-my-button>
        <app-my-button [matBadge]="filters_count" [matBadgeHidden]="filters_count == 0" (click)="openFilterDialog()" icon="filter_alt" title="Filter"></app-my-button>    
    </span>
 
</div>
<!-- Web Report -->
<div class="table-responsive mt-1" [hidden]="selectedBtn == 'print'">
        <table class="table table-bordered" *ngIf="showLoader == false">
            <thead>
                <tr [class]="theme.myRptHdrFtrColorBlue">
                    <th 
                    *ngFor="let column of columns" 
                  
                    class="tbl-rpt-head">
                        {{column.header}}
                    </th>                                                            
                </tr>
            </thead> 

        
            
            <tbody *ngFor="let master_row of rootdata?.master; let index = index">
                 
                    <!-- if 3 Groups  -->
                    <ng-container *ngIf="groups_count == 3">
                        <!-- <tr class="GroupRowColor"> <td [attr.colspan]="collength">{{master_row?.Title}} </td> </tr> -->
                        <tr>
                            <td [attr.colspan]="collength">
                                <span>{{master_row?.Title}} </span>
                                <span class="float-end">Openning: {{master_row?.Openning}} </span>
                            </td>
                        </tr>
                        <ng-container *ngFor="let grp2_row of master_row?.Group2">
                            <tr>
                                <td [attr.colspan]="collength">
                                    <span>{{grp2_row?.Title}} </span>
                                    <span class="float-end">Openning: {{grp2_row?.Openning}} </span>
                                </td>
                            </tr>
                            <ng-container *ngFor="let grp1_row of grp2_row?.Group1">
                    
                                <tr>
                                    <td [attr.colspan]="collength">
                                        <span>{{grp1_row?.Title}} </span>
                                        <span class="float-end">Openning: {{grp1_row?.Openning}} </span>
                                    </td>
                                </tr>
                                <tr *ngFor="let row of grp1_row?.data">
                                    <td *ngFor="let column of columns">
                                        <span (click)="onLinkClick(row, column)" class="w-100 d-block" [ngClass]="{'text-end' : column.type == 'currency' || column.type == 'number', 'linkcls' : column.type == 'link' }">
                                            {{ row.initial == undefined ? (column.cell(row) == null || column.cell(row) == 'null' ||
                                            column.cell(row) == undefined ? '' : column.type == 'currency' ? (column.cell(row) | number :
                                            '1.2-2') : column.type == 'date' ? (column.cell(row) | date:'MM/dd/yyyy') : column.cell(row)) : ''
                                            }}
                                        </span>
                                       
                                    </td>
                                </tr>
                                <tr *ngIf="grp1_row?.Total" class="totals">
                                    <td colspan="2">
                                        Total: {{grp1_row?.Title}}
                                    </td>
                                    <td *ngFor="let column of columns; let index = index;" class=" text-end"
                                        [hidden]="index == 0 || index == 1">
                    
                                        <span *ngIf="column.type == 'currency'"> {{grp1_row?.Total[column.columnDef] | number : '1.2-2'}}
                                        </span>
                                        <span *ngIf="column.type == 'number'"> {{grp1_row?.Total[column.columnDef] | number }} </span>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="grp2_row?.Total" class="totals">
                                <td colspan="2">
                                    Total: {{grp2_row?.Title}}
                                </td>
                                <td *ngFor="let column of columns; let index = index;" class=" text-end"
                                    [hidden]="index == 0 || index == 1 || column.isRunningBalance == true">
                    
                                    <span *ngIf="column.type == 'currency'"> {{grp2_row?.Total[column.columnDef] | number : '1.2-2'}}
                                    </span>
                                    <span *ngIf="column.type == 'number'"> {{grp2_row?.Total[column.columnDef] | number }} </span>
                                </td>
                                <td *ngFor="let column of columns; let index = index;" class=" text-end"
                                    [hidden]="column.isRunningBalance == false">
                    
                                    <span *ngIf="column.type == 'currency'"> {{grp2_row?.Closing | number : '1.2-2'}} </span>
                                    <span *ngIf="column.type == 'number'"> {{grp2_row?.Closing | number }} </span>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="master_row?.Total" class="totals">
                            <td colspan="2">
                                Total: {{master_row?.Title}}
                            </td>
                            <td *ngFor="let column of columns; let index = index;" class=" text-end" [hidden]="index == 0 || index == 1">
                    
                                <span *ngIf="column.type == 'currency'"> {{master_row?.Total[column.columnDef] | number : '1.2-2'}} </span>
                                <span *ngIf="column.type == 'number'"> {{master_row?.Total[column.columnDef] | number }} </span>
                            </td>
                        </tr>
                    </ng-container>

                    <!-- if 2 Groups  -->
                    <ng-container *ngIf="groups_count == 2">
                        <!-- <tr class="GroupRowColor"> <td [attr.colspan]="collength">{{master_row?.Title}} </td> </tr> -->
                        <tr>
                            <td [attr.colspan]="collength" class="grp2">
                                <span>{{master_row?.Title}} </span>
                                <span class="float-end">Openning: {{master_row?.Openning}} </span>
                            </td>
                        </tr>
                        <ng-container *ngFor="let grp1_row of master_row?.Group1">
                            <tr class="grp1">
                                <td [attr.colspan]="collength">
                                    <span>{{grp1_row?.Title}} </span>
                                    <span class="float-end">Openning: {{grp1_row?.Openning}} </span>
                                </td>
                            </tr>
                            <tr *ngFor="let row of grp1_row?.data">
                                <td *ngFor="let column of columns">
                                    <span (click)="onLinkClick(row, column)" class="w-100 d-block" [ngClass]="{'text-end' : column.type == 'currency' || column.type == 'number', 'linkcls' : column.type == 'link' }">
                                    {{ row.initial == undefined ? (column.cell(row) == null || column.cell(row) == 'null' ||
                                    column.cell(row) == undefined ? '' : column.type == 'currency' ? (column.cell(row) | number : '1.2-2') :
                                    column.type == 'date' ? (column.cell(row) | date:'MM/dd/yyyy') : column.cell(row)) : '' }}
                                    </span>
                                </td>
                            </tr>
                    
                            <tr *ngIf="grp1_row?.Total" class="totals grp1">
                                <td colspan="2">
                                    Total: {{grp1_row?.Title}}
                                </td>
                                <td *ngFor="let column of columns; let index = index;" class=" text-end"
                                    [hidden]="index == 0 || index == 1 || column.isRunningBalance == true">
                    
                                    <span *ngIf="column.type == 'currency'"> {{grp1_row?.Total[column.columnDef] | number : '1.2-2'}}
                                    </span>
                                    <span *ngIf="column.type == 'number'"> {{grp1_row?.Total[column.columnDef] | number }} </span>
                                </td>
                                <td *ngFor="let column of columns; let index = index;" class=" text-end"
                                    [hidden]="column.isRunningBalance == false">
                    
                                    <span *ngIf="column.type == 'currency'"> {{grp1_row?.Closing | number : '1.2-2'}} </span>
                                    <span *ngIf="column.type == 'number'"> {{grp1_row?.Closing | number }} </span>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="master_row?.Total" class="totals grp2">
                            <td colspan="2">
                                Total: {{master_row?.Title}}
                            </td>
                            <td *ngFor="let column of columns; let index = index;" class=" text-end" 
                            [hidden]="index == 0 || index == 1  || column.isRunningBalance == true">
                    
                                <span *ngIf="column.type == 'currency'"> {{master_row?.Total[column.columnDef] | number : '1.2-2'}} </span>
                                <span *ngIf="column.type == 'number'"> {{master_row?.Total[column.columnDef] | number }} </span>
                            </td>
                            <td *ngFor="let column of columns; let index = index;" class=" text-end"
                                    [hidden]="column.isRunningBalance == false">
                    
                                    <span *ngIf="column.type == 'currency'"> {{master_row?.Closing | number : '1.2-2'}} </span>
                                    <span *ngIf="column.type == 'number'"> {{master_row?.Closing | number }} </span>
                            </td>
                        </tr>
                    </ng-container>
                   
                    <!-- if 1 Groups  -->
                    <ng-container *ngIf="groups_count == 1">
                        <!-- <tr class="GroupRowColor"> <td [attr.colspan]="collength">{{master_row?.Title}} </td> </tr> -->
                        <tr>
                            <td [attr.colspan]="collength" class="grp1">
                                <span>{{master_row?.Title}} </span>
                                <span class="float-end" *ngIf="master_row?.Openning">Openning: {{master_row?.Openning}} </span>
                            </td>
                        </tr>
                        <tr *ngFor="let row of master_row?.data">
                            <td *ngFor="let column of columns">
                                <span (click)="onLinkClick(row, column)" class="w-100 d-block" [ngClass]="{'text-end' : column.type == 'currency' || column.type == 'number', 'linkcls' : column.type == 'link' }">
                                {{ row.initial == undefined ? (column.cell(row) == null || column.cell(row) == 'null' || column.cell(row) ==
                                undefined ? '' : column.type == 'currency' ? (column.cell(row) | number : '1.2-2') : column.type == 'date' ?
                                (column.cell(row) | date:'MM/dd/yyyy') : column.cell(row)) : '' }}
                                </span>
                            </td>
                        </tr>
                    
                        <tr *ngIf="master_row?.Total" class="totals grp1">
                            <td colspan="2">
                                Total: {{master_row?.Title}}
                            </td>
                            <td *ngFor="let column of columns; let index = index;" class=" text-end" 
                            [hidden]="index == 0 || index == 1  || column.isRunningBalance == true">
                    
                                <span *ngIf="column.type == 'currency'"> {{master_row?.Total[column.columnDef] | number : '1.2-2'}} </span>
                                <span *ngIf="column.type == 'number'"> {{master_row?.Total[column.columnDef] | number }} </span>
                            </td>
                            <td *ngFor="let column of columns; let index = index;" class=" text-end"
                                    [hidden]="column.isRunningBalance == false">
                    
                                    <span *ngIf="column.type == 'currency'"> {{master_row?.Closing | number : '1.2-2'}} </span>
                                    <span *ngIf="column.type == 'number'"> {{master_row?.Closing | number }} </span>
                            </td>
                        </tr>
                    </ng-container>

                     <!-- if No Groups  -->
                     <ng-container *ngIf="groups_count == 0">
                        <!-- <tr class="GroupRowColor"> <td [attr.colspan]="collength">{{master_row?.Title}} </td> </tr> -->
                        
                        <tr *ngFor="let row of master_row?.data">
                            <td *ngFor="let column of columns">
                                <span (click)="onLinkClick(row, column)" style="text-wrap: balance;"  class="w-100 d-block" [ngClass]="{'text-end' : column.type == 'currency' || column.type == 'number', 'linkcls' : column.type == 'link' }">
                                {{ row.initial == undefined ? (column.cell(row) == null || column.cell(row) == 'null' || column.cell(row) ==
                                undefined ? '' : column.type == 'currency' ? (column.cell(row) | number : '1.2-2') : column.type == 'date' ?
                                (column.cell(row) | date:'MM/dd/yyyy') : column.cell(row)) : '' }}
                                </span>
                            </td>
                        </tr>
                    
                      
                    </ng-container>

                  
            </tbody>
            <tfoot>
                <tr *ngIf="rootdata?.Total" class="totals grandtotal" [class]="theme.myRptHdrFtrColorBlue">
                    <td colspan="2">
                        Grand Total
                    </td>
                    <td *ngFor="let column of columns; let index = index;" class=" text-end" 
                    [hidden]="index == 0 || index == 1  || column.isRunningBalance == true">
            
                        <span *ngIf="column.type == 'currency'"> {{rootdata?.Total[column.columnDef] | number : '1.2-2'}} </span>
                        <span *ngIf="column.type == 'number'"> {{rootdata?.Total[column.columnDef] | number }} </span>
                    </td>
                    <td *ngFor="let column of columns; let index = index;" class=" text-end"
                        [hidden]="column.isRunningBalance == false">
        
                        <span *ngIf="column.type == 'currency'"> {{rootdata?.Closing | number : '1.2-2'}} </span>
                        <span *ngIf="column.type == 'number'"> {{rootdata?.Closing | number }} </span>
                    </td>
                </tr>
            </tfoot>
            
            
        </table>
      

        <div class="w-100 d-flex justify-content-center mt-5">
            <div class="loader " *ngIf="showLoader == true"></div>
        </div>
        
        <!-- <div>
            <ngx-skeleton-loader count="3" appearance="line"></ngx-skeleton-loader>
        </div> -->
</div>

<!-- Print Fast Report -->
<div [hidden]="selectedBtn == 'web'" style="margin:0px;padding:0px;overflow:hidden" *ngIf="frurl">
    <iframe 
    style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:1200px;width:100%;top:0px;left:0px;right:0px;bottom:0px" 
    height="100%" 
    width="100%" 
    [src]="frurl" frameborder="0"></iframe>
</div>