<div class="row card-margin">
        <div class="col-md-12 content-area">       
            <app-my-small-button icon="close" mat-dialog-close></app-my-small-button>
            <br>
            <mat-selection-list #forms [multiple]="false">
                <mat-list-option>
                    <div class="list-box">                        
                        <div class="d-flex justify-space-between align-items-center visible-area w-100" (click)="onClick('saleinvoice')">
                            <mat-icon class="me-1">add</mat-icon>         
                            <span class="flex-grow-1 bd-highlight">Sale Invoice</span>                                                                           
                        </div>                         
                    </div>
                </mat-list-option>  
                <mat-list-option>
                    <div class="list-box">                        
                        <div class="d-flex justify-space-between align-items-center visible-area w-100" (click)="onClick('customerreceipt')">
                            <mat-icon class="me-1">add</mat-icon>         
                            <span class="flex-grow-1 bd-highlight">Customer Receipt</span>                                                                           
                        </div>                         
                    </div>
                </mat-list-option>  
                <mat-list-option>
                    <div class="list-box">                        
                        <div class="d-flex justify-space-between align-items-center visible-area w-100" (click)="onClick('item')">
                            <mat-icon class="me-1">add</mat-icon>         
                            <span class="flex-grow-1 bd-highlight">Item</span>                                                                           
                        </div>                         
                    </div>
                </mat-list-option>   
                <mat-list-option>
                    <div class="list-box">                        
                        <div class="d-flex justify-space-between align-items-center visible-area w-100" (click)="onClick('customer')">
                            <mat-icon class="me-1">add</mat-icon>         
                            <span class="flex-grow-1 bd-highlight">Customer</span>                                                                           
                        </div>                         
                    </div>
                </mat-list-option>               
            </mat-selection-list>
        </div>
</div>