<!-- <mat-selection-list #module [multiple]="false">


        
        <mat-list-item style="margin-bottom: 50px">
        <img matTooltip="Business Management Tool" src="assets/icons/bizmanto/without-slogan/default-monochrome-white.svg" alt="" style="width: 500px;margin-top: 50px;">
   
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-option>
                <div class="custom-list" (click)="openSalesv3()">
                        <mat-icon>question_mark</mat-icon> <span>Sale Dasboard v3 (newest)</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option>
        <mat-list-option>
                <div class="custom-list" (click)="openNewDash()">
                        <mat-icon>question_mark</mat-icon> <span>NEWER ! (dash)</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option>
        <mat-list-option>
                <div class="custom-list" (click)="openSales()">
                        <mat-icon>question_mark</mat-icon> <span>Sales-Dashboard</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option>
        <mat-list-option>
                <div class="custom-list" (click)="openDashboard()">
                        <mat-icon>dashboard</mat-icon> <span>Dashboard</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option>
        <mat-list-option *ngFor="let m of modules" [value]="shoe">
                <div class="custom-list" (click)="openLink(m.module,m.routeName);">
                        <mat-icon>{{m.icon}}</mat-icon> <span>{{m.title}}</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option>
        <mat-list-option>
                <div class="custom-list" (click)="newLogin()">
                        <mat-icon>login</mat-icon> <span>Login</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option>
        <mat-list-option>
                <div class="custom-list" (click)="logout()">
                        <mat-icon>logout</mat-icon> <span>Logout</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option>
</mat-selection-list> -->
<mat-selection-list #module [multiple]="false">
<mat-list-item style="margin-bottom: 50px">
        <img [matTooltip]="brandToolTip" [src]="brandsUrl" alt="" style="width: 232px;margin-top: 50px; z-index: -2;">
   
        </mat-list-item>        
</mat-selection-list>
<!-- <div class="py-4">
        <div class="d-flex justify-content-center mb-2">
                <mat-icon class="personIcon" [class]="themeService.myUserText">person</mat-icon>
        </div>

        <div class="d-flex justify-content-center">

                <div class="d-flex flex-column align-items-center gap-1">
                        <h3 class="text-center textName" [class]="themeService.myUserText"> {{localStorageService.getCurrentUser()?.FullName}}</h3>
                        <p class="text-muted">{{localStorageService.getCurrentUser()?.GroupName}}</p>
                </div>
        </div>

</div> -->

<div class="addNewBtnDiv">
        <button (click)="openAddNew()" class="newButtonColor d-flex align-items-center justify-content-center">
                <mat-icon class="newIcon">add</mat-icon>
               New
        </button>

</div>


<!-- Start Form -->
<!-- <div class="d-flex justify-content-between" style="margin-bottom:8px;">
        <div mat-subheader style="padding: 2px 4px;">                                                                                                
                <button [ngClass]="{'back-button': selectedType == 'form'}" (click)="onBtnClick('form')" mat-button color="primary" class="moduleBtn me-1"> <mat-icon class="me-1">article</mat-icon> <span class="me-1"> Forms </span></button>
                <button [ngClass]="{'back-button': selectedType == 'report'}" (click)="onBtnClick('report')" mat-button color="primary" class="moduleBtn"> <mat-icon class="me-1">description</mat-icon> <span class="me-1"> Reports </span></button>                                                                                                         
        </div>               
</div> -->

<!-- Modules -->
<div>

        <!-- skeleton -->
        <mat-accordion *ngIf="routeService.routeLoading == true">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span class="d-flex flex-column-reverse w-25">
                                                <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
                                        </span>
                                        <div class="px-2 pt-3 w-100">
                                                <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
                                        </div>
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <div class="d-flex">
                                <div class="px-2 pt-3 w-100">
                                        <ngx-skeleton-loader count="6" appearance="line"></ngx-skeleton-loader>
                                </div>

                        </div>
                </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion *ngIf="routeService.inventoryRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">inventory_2</mat-icon>
                                        </span>
                                        Inventory
                                </mat-panel-title>

                        </mat-expansion-panel-header>

                        <p class="exitem"  (click)="openLink(r.name, 'inventory')"
                                *ngFor="let r of routeService.getRouteByType('inventory', 'list')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>


                </mat-expansion-panel>
        </mat-accordion>



        <mat-accordion *ngIf="routeService.purchaseRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">shopping_cart</mat-icon>
                                        </span>
                                        Purchase
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'purchase')"
                                *ngFor="let r of routeService.getRouteByType('purchase', 'list')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                
                                <mat-icon>navigate_next</mat-icon>{{r.label}}
                        </p>
                </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion *ngIf="routeService.salesRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">point_of_sale</mat-icon>
                                        </span>
                                        Sales
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'sales')"
                                *ngFor="let r of routeService.getRouteByType('sales', 'list')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>
                </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion *ngIf="routeService.financeRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">payments</mat-icon>
                                        </span>
                                        Finance
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'finance')"
                                *ngFor="let r of routeService.getRouteByType('finance', 'list')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion *ngIf="routeService.productionRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">precision_manufacturing</mat-icon>
                                        </span>
                                        Production
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'production')"
                                *ngFor="let r of routeService.getRouteByType('production', 'list')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>



        <mat-accordion *ngIf="routeService.hrRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">person_search</mat-icon>
                                        </span>
                                        HRM
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'hr')"
                                *ngFor="let r of routeService.getRouteByType('hr', 'list')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion *ngIf="routeService.userRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">account_box</mat-icon>
                                        </span>
                                        Users
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'users')"
                                *ngFor="let r of routeService.getRouteByType('users', 'list')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion *ngIf="routeService.adminRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">language</mat-icon>
                                        </span>
                                        Admin
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'adminpanel')"
                                *ngFor="let r of routeService.getRouteByType('adminpanel', 'list')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion *ngIf="routeService.emRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">date_range</mat-icon>
                                        </span>
                                        Event
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'em')"
                                *ngFor="let r of routeService.getRouteByType('em', 'list')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>
        <hr style="background-color: #837e7e;">
        <!-- <hr> -->
        <div class="reportBtn" (click)="openReportDialog()">
                <div class="d-flex">
                <span>
                        <mat-icon class="iconColor"
                                [class]="themeService.myUserText">analytics</mat-icon>
                </span>
                Reports
                </div>

                <div>
                        <mat-icon style="font-size: 18px;
                        color: rgba(0,0,0,.54);">chevron_right</mat-icon>
                </div>
        </div>

</div>

<!-- Reports -->
<!-- <div [hidden]="selectedType == 'form'">

    


        <mat-accordion *ngIf="routeService.inventoryRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">inventory_2</mat-icon>
                                        </span>
                                        Inventory
                                </mat-panel-title>

                        </mat-expansion-panel-header>

                        <p class="exitem" (click)="openLink(r.name, 'inventory')"
                                *ngFor="let r of routeService.getRouteByType('inventory', 'report')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>


                </mat-expansion-panel>
        </mat-accordion>



        <mat-accordion *ngIf="routeService.purchaseRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">shopping_cart</mat-icon>
                                        </span>
                                        Purchase
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'purchase')"
                                *ngFor="let r of routeService.getRouteByType('purchase', 'report')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon>{{r.label}}
                        </p>
                </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion *ngIf="routeService.salesRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">point_of_sale</mat-icon>
                                        </span>
                                        Sales
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'sales')"
                                *ngFor="let r of routeService.getRouteByType('sales', 'report')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>
                </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion *ngIf="routeService.financeRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">payments</mat-icon>
                                        </span>
                                        Finance
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'finance')"
                                *ngFor="let r of routeService.getRouteByType('finance', 'report')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion *ngIf="routeService.productionRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">precision_manufacturing</mat-icon>
                                        </span>
                                        Production
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'production')"
                                *ngFor="let r of routeService.getRouteByType('production', 'report')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>



        <mat-accordion *ngIf="routeService.hrRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">person_search</mat-icon>
                                        </span>
                                        HRM
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'hr')"
                                *ngFor="let r of routeService.getRouteByType('hr', 'report')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion *ngIf="routeService.userRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">account_box</mat-icon>
                                        </span>
                                        Users
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'users')"
                                *ngFor="let r of routeService.getRouteByType('users', 'report')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion *ngIf="routeService.adminRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">language</mat-icon>
                                        </span>
                                        Admin
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'adminpanel')"
                                *ngFor="let r of routeService.getRouteByType('adminpanel', 'report')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion *ngIf="routeService.emRoutes.length > 0">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                                <mat-panel-title>
                                        <span>
                                                <mat-icon class="iconColor"
                                                        [class]="themeService.myUserText">date_range</mat-icon>
                                        </span>
                                        Event
                                </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p class="exitem" (click)="openLink(r.name, 'em')"
                                *ngFor="let r of routeService.getRouteByType('em', 'report')"
                                [ngClass]="{'back-button': selectedName == r.name}" >
                                <mat-icon>navigate_next</mat-icon> {{r.label}}
                        </p>

                </mat-expansion-panel>
        </mat-accordion>

</div> -->

<!-- 
<div class="needHelpDiv">
        <div>
               <mat-icon class="helpIcon">accessibility_new</mat-icon>
        </div>
        <div class="helpTitle">Need Help?</div>
        <div class="helpDescrip"> Learn how to use this app with video and step-by-step documentation to know how to use all of the features</div>
        <div>
                <button class="helpBtn">Watch</button>
        </div>
</div> -->


