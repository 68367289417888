<div class="topBar">
        <div class="d-flex align-items-center justify-content-between">
                <div>
                        <div class="d-flex align-items-center">
                        
                        <h2 class="ms-3">Purchase Return</h2>
                         </div>
                </div>
                <div>
                        <div class="d-flex">
                                <div class="topBtns">
                                        <button class="btn btnTopIcons" (click)="print()"> <mat-icon class="topNavIcon printIconColor">print</mat-icon> Print
                                        </button>
                                        <button class="btn btnTopIcons "(click)="onEdit()"> <mat-icon class="topNavIcon editIconColor">edit</mat-icon> Edit
                                        </button>
                                        <!-- <button class="btn btnTopIcons"> <mat-icon class="topNavIcon deleteIconColor">delete</mat-icon> Delete
                                        </button> -->
                                </div>

                                <div class="topBtnForMob">
                                        <button class="btn btnTopIcons" (click)="print()"> 
                                                <mat-icon class="topNavIcon printIconColor">print</mat-icon>
                                        </button>
                                        <button class="btn btnTopIcons" (click)="onEdit()"> 
                                                <mat-icon class="topNavIcon editIconColor">edit</mat-icon>
                                        </button>
                                </div>
                        
                        
                                <div class="closeBtnDiv">
                                        <button class="btn" mat-dialog-close> <mat-icon class="closeColor">close</mat-icon>
                                        </button>
                                </div>
                        
                        </div>
                        
                </div>
        </div>
        <hr>


</div>

<div id="pdfContent" class="contentDiv">
        <div class="row">
                <div class="col-md-4">

                        <div class="card mb-3 cardHeight ">
                                <div class="txtTitle">
                                     {{dataHdr?.SupplierName}}
                                </div>
                                <hr>
                                <table class="table table-borderless">                                 
                                        <tbody class="leftTable">
                                            <tr>
                                                <td>Entry #</td>
                                                <td>{{dataHdr?.DocumentNo}}</td>
                                            </tr>
                                            <tr>
                                                <td>Date</td>
                                                <td>{{dataHdr?.TransactionDate | date}}</td>
                                            </tr>
                                            <tr>
                                                <td>Contact Person</td>
                                                <td>{{dataHdr?.ContactPersonName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Contact #</td>
                                                <td>{{dataHdr?.ContactNo}}</td>
                                            </tr>   
                                            <tr>
                                                <td> Ref #</td>
                                                <td>{{dataHdr?.RefenceNo}}</td>
                                            </tr>     
                                            <tr>
                                                <td> Plant</td>
                                                <td>{{dataHdr?.PlantName}}</td>
                                            </tr>    
                                            <tr>
                                                <td> Remarks</td>
                                                <td>{{dataHdr?.Remarks}}</td>
                                            </tr>     
                                                     

                                        </tbody>                
                                    </table> 
                            
                        </div>

                      
                </div>

                <div class="col-md-8">
                        <div class="forMobOnly">
                                Item Detail
                        </div>
                <table class="tableDetail  mb-3">
                        <thead class="detailTH">
                                <tr>
                                        <th>#</th>
                                        <th class="item">Item</th>
                                        <th>Unit</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Amount</th>
                                        <th>Discount</th>
                                        <th>GST</th>
                                        <th>NTN</th>
                                        <th>Net Amount</th>
                                </tr>
                        </thead>  
                        <tbody>
                                <tr *ngFor="let data of dataAll;let index = index">
                                        <td data-label="S#" class="serial">{{index+1}}</td>
                                        <td data-label="Item" class="item">{{data?.ItemName}}</td>
                                        <td data-label="Unit" class="unit">{{data?.UnitName}}</td>
                                        <td data-label="Qty" class="qty">{{data?.Quantity}}</td>
                                        <td data-label="Price" class="price">{{data?.Price | number}}</td>
                                        <td data-label="Amount" class="amount">{{data?.Amount | number}}</td>
                                        <td data-label="Discount" class="discount">{{data?.Discount | number}}</td>
                                        <td data-label="GST" class="GST">{{data?.GST | number}}</td>
                                        <td data-label="NTN" class="GST">{{data?.NTN | number}}</td>
                                        <td data-label="Net Amount" class="netamount">{{data?.NetAmount | number}}</td>
                                </tr>
                                
                        </tbody>
                </table>

                <div class="cstmCard">
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Amount</div>
                                <div class="txtMainSummary">{{dataHdr?.TotalAmount | number}}</div>
                        </div>
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Discount</div>
                                <div class="txtMainSummary">{{dataHdr?.TotalDiscount | number}}</div>
                        </div> 
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total GST</div>
                                <div class="txtMainSummary">{{dataHdr?.TotalGST | number}}</div>
                        </div> 
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total NTN</div>
                                <div class="txtMainSummary">{{dataHdr?.TotalNTN | number}}</div>
                        </div>
                        <hr style="margin: 0 !important;">
                        <div class="d-flex justify-content-between">
                                <div class="txtSubSummary">Total Net Amount</div>
                                <div class="txtNetSummary">{{dataHdr?.TotalNetAmount | number}}</div>
                        </div>
                </div>






                </div>
        </div>
</div>