import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouteService } from 'src/app/services/base/route.service';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.scss']
})
export class AllReportsComponent implements OnInit {
  panelOpenState = true;
  showDiv:boolean = false;
  constructor(
    public routeService:RouteService,
    public router: Router,

    public themeService:ThemeService,

    public dialogRef: MatDialogRef<AllReportsComponent>
  ) { }

  ngOnInit(): void {
  
  }


  openLink(name, module) {
    this.router.navigate([this.routeService.openRoute(module, name)]);
    this.dialogRef.close();
  }


}
