import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomRxjsService {

  constructor() { }

  convertToObservableAndGetData(objArr:any){
    var obj:any = {};
    obj['value'] = objArr;
    return of(obj).toPromise();
  }
}
