<mat-form-field appearance="outline" class="w-100">
        <mat-label>{{label}}</mat-label>
        <input 
        type="number"
        autocomplete="off"
        matInput  
        #name="ngModel"
        [required]="required" 
        [(ngModel)]="model"     
        (ngModelChange)="onChange(model)">        
        <mat-hint>{{hint}}</mat-hint>
        <mat-error *ngIf="name.invalid || name.dirty">This field is required</mat-error>
</mat-form-field>
    

<!-- 
<label for="">
        {{label}}
        <span *ngIf="required" style="color: #f44336;">*</span>
</label>
<input  
type="number" 
[disabled]="disabled"
placeholder="Enter" 
autocomplete="off" 
class="form-control form-control-sm my-2 myinput w-100" 

#name="ngModel"
[required]="required" 
[(ngModel)]="model"     
(ngModelChange)="onChange(model)"
>

<p *ngIf="name.invalid && (name.dirty || name.touched)" style="color: #f44336;"> This field is required</p> -->