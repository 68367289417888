
<div class="wholeDiv">
<h2 class="titleText">
        Internet Connection Lost!
</h2>

<div class="iconDiv">
        <mat-icon class="wifiICON">wifi_off</mat-icon>
</div>


<p style="color: #111d30b8;">
        Seems like something is wrong with your internet connection
</p>

<p style="color: #101c2e;">
        Trying to reconnect...
</p>
</div>
