<div class="container">
        <mat-dialog-content>
           <div class="resetpassword">
                <div class="row">
                        <div class="title">
                                <h2 class="heading">Resset Password</h2>

                                <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
                        </div>

                        <div class="col-md-12">

                                <app-my-input-password [(ngModel)]="newPassword" label="New Password" name="newPassword" >
                                </app-my-input-password>

                                <app-my-input-password [(ngModel)]="confirmPassword" label="Confirm Password" name="confirmPassword">
                                </app-my-input-password>


                        </div>
                </div>

                <ng-container *ngIf="showError === true">
                     <div class="errorDiv">   New password & confirm password does not match </div>
                </ng-container>

                <ng-container *ngIf="showErrorLength === true">
                        <div class="errorDiv">Password should be minimum 8 characters</div>
                   </ng-container>

                <div class="row">
                        <div class="col-md-12 btnupdate">
                                <button style="background-color: aliceblue;" mat-button (click)="onUpdate()">Update</button>
                        </div>

                </div>
            </div>   
        </mat-dialog-content>


</div>