<div class="cstmContainer">

        <!-- <h2 class="hds">Welcome Sameer</h2> -->
        <div class="companyBio">
                <div class="companyTitle">

                        {{companyName}}
                      
                </div>
                <div class="companyDescrip">
                        Welcome, {{username}}
                </div>
        </div>

        <div class="mt-4 row ">
                <div class="col-md-3 hideInMob ">
                        <div class="card  shadow-sm">
                             
                                        <div class="cardFlex">
                                                <div class="titleText">
                                                        <span><mat-icon color="iconProducts">inventory</mat-icon></span>
                                                        <span>Total Items</span>
                                                </div>
                                                <div class="me-2 item-num">{{itemCount | number}}</div> 
                                        </div>
                        </div>
                </div>
                <div class="col-md-3 forDesktop hideInMob">

                        <div class="card  shadow-sm">
                                <div class="cardFlex">
                                        <div class="titleText">
                                                <span><mat-icon color="iconCustomer">group</mat-icon></span>
                                                <span>Total Customers</span>
                                        </div>
                                        <div class="me-2 item-num">{{customerCount | number}}</div> 
                                </div>
                        </div>

                </div>

                <div class="col-md-3 forDesktop hideInMob">

                        <div class="card  shadow-sm">
                                <div class="cardFlex">
                                        <div class="titleText">
                                                <span><mat-icon color="iconSupplier">
                                                        local_shipping
                                                        </mat-icon></span>
                                                <span>Total Suppliers</span>
                                                
                                        </div>
                                        <div class="me-2 item-num">{{supplierCount | number}}</div> 
        
                                </div>
                        </div>

                </div>

                <div class="col-md-3 forDesktop hideInMob">

                        <div class="card  shadow-sm">
                                <div class="cardFlex">
                                        <div class="titleText">
                                                <span><mat-icon color="iconUser">
                                                        person
                                                        </mat-icon></span>
                                                <span>Total Users</span>
                                                
                                        </div>
                                        <div class="me-2 item-num">{{userCount | number}}</div> 
        
                                </div>
                        </div>

                </div>
        </div>
     


        <div class="hideInDesktop HMobile row mt-3 d-flex justify-content-center  flex-wrap ss ">
                <div>
                        <h2 class="heading">Module</h2>
                </div>
                <div class="col-4 mb-2" *ngIf="routeService.inventoryRoutes.length > 0">
                        <div matRipple (click)="onRouteClick('inventory')"
                                class="d-flex flex-column align-items-center mbcard inventrydiv">
                                <mat-icon class="customIcon mbcardText mb-2 iicon">inventory_2</mat-icon>
                                <h4 class="mbcardText ifontdiv"> Inventory </h4>
                        </div>
                </div>

                <div class="col-4" *ngIf="routeService.purchaseRoutes.length > 0">
                        <div matRipple (click)="onRouteClick('purchase')"
                                class="d-flex flex-column align-items-center mbcard purchasediv">
                                <mat-icon class="customIcon mbcardText mb-2 picon">shopping_cart</mat-icon>
                                <h4 class="mbcardText pfontdiv"> Purchase </h4>
                        </div>
                </div>

                <div class="col-4" *ngIf="routeService.salesRoutes.length > 0">
                        <div matRipple (click)="onRouteClick('sales')"
                                class="d-flex flex-column align-items-center mbcard salediv">
                                <mat-icon class="customIcon mbcardText mb-2 sicon">point_of_sale</mat-icon>
                                <h4 class="mbcardText sfontdiv"> Sales </h4>
                        </div>
                </div>

                <div class="col-4" *ngIf="routeService.financeRoutes.length > 0">
                        <div matRipple (click)="onRouteClick('finance')"
                                class="d-flex flex-column align-items-center mbcard financediv">
                                <mat-icon class="customIcon mbcardText mb-2 ficon">payments</mat-icon>
                                <h4 class="mbcardText ffontdiv"> Finance </h4>
                        </div>
                </div>

                <div class="col-4 mb-2" *ngIf="routeService.productionRoutes.length > 0">
                        <div matRipple (click)="onRouteClick('production')"
                                class="d-flex flex-column align-items-center mbcard">
                                <mat-icon class="customIcon mbcardText mb-2">precision_manufacturing</mat-icon>
                                <h4 class="mbcardText"> Production </h4>
                        </div>
                </div>

                <div class="col-4" *ngIf="routeService.userRoutes.length > 0">
                        <div matRipple (click)="onRouteClick('users')"
                                class="d-flex flex-column align-items-center mbcard usersdiv">
                                <mat-icon class="customIcon mbcardText mb-2 uicon">account_box</mat-icon>
                                <h4 class="mbcardText ufontdiv"> Users </h4>
                        </div>
                </div>

                <div class="col-4" *ngIf="routeService.adminRoutes.length > 0">
                        <div matRipple (click)="onRouteClick('adminpanel')"
                                class="d-flex flex-column align-items-center mbcard">
                                <mat-icon class="customIcon mbcardText mb-2">language</mat-icon>
                                <h4 class="mbcardText"> Admin </h4>
                        </div>
                </div>

                <div class="col-4" *ngIf="routeService.hrRoutes.length > 0">
                        <div matRipple (click)="onRouteClick('hr')"
                                class="d-flex flex-column align-items-center mbcard">
                                <mat-icon class="customIcon mbcardText mb-2">person_search</mat-icon>
                                <h4 class="mbcardText">HRM</h4>
                        </div>
                </div>

                <div class="col-4" *ngIf="routeService.emRoutes.length > 0">
                        <div matRipple (click)="onRouteClick('em')"
                                class="d-flex flex-column align-items-center mbcard">
                                <mat-icon class="customIcon mbcardText mb-2">date_range</mat-icon>
                                <h4 class="mbcardText">Event </h4>
                        </div>
                </div>
                <!-- <div class="col-md-3  ">
                        <div class="card cstmQuickCard mt-3 mobilediv">
                                <h2 class="heading"> Quick Action </h2>
                                <div class="quickActions scrollColor scrollHide">

                                       
                                        <div (click)="onItemCreate()"
                                                class="d-flex align-items-center gap-2 cstmActionDiv itemdiv">
                                                <mat-icon class="cstmActionIcon"></mat-icon>
                                                <div>New Item</div>
                                        </div>
                                        <div (click)="openLink('sales', 'sale')"
                                                class="d-flex align-items-center gap-2 cstmActionDiv salediv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Sale Invoice</div>
                                        </div>
                                        <div (click)="openLink('sales', 'delivery-challan')"
                                                class="d-flex align-items-center gap-2 cstmActionDiv dcdiv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Delievery Challan</div>
                                        </div>
                                        <div (click)="openLink('purchase', 'purchase-form')"
                                                class="d-flex align-items-center gap-2 cstmActionDiv purchasediv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Purchase Invoice</div>
                                        </div>
                                        <div (click)="openLink('sales', 'customerReceipt')"
                                                class="d-flex align-items-center gap-2 cstmActionDiv customerdiv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Customer Receipt</div>
                                        </div>

                                </div>
                        </div>
                </div> -->

                <div class="adbtndiv my-3 ">

                        <button (click)="openBottomSheet()" class="addbtn"> <mat-icon>add</mat-icon> Add</button>

                </div>
                <div class="col-md-9  ">

                        <div class="card cstmQuickCard recentvcediv  ">
                                <h2 class="hdiv"> Recent Invoices </h2>

                                <div *ngIf="RecentInvoiceData.length > 0"
                                        class="table-responsive scrollColor scrollHide mt-2">
                                        <table class="table orderTable">
                                                <thead>
                                                        <tr>
                                                                <th>Entry No</th>
                                                                <th class="hideInMob">Party</th>
                                                                <th>Screen</th>
                                                                <th>Date</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        <tr *ngFor="let data of RecentInvoiceData">
                                                                <td>
                                                                        <span type="button" (click)="onLinkClick(data)">
                                                                                {{data?.DocumentNo}}
                                                                        </span>
                                                                </td>
                                                                <td class="hideInMob">{{data?.Pname}}</td>
                                                                <td>{{data?.Screen}}</td>
                                                                <td class="hideInDesktop">{{data?.createdDate |
                                                                        date:'short'}}</td>
                                                                <td class="hideInMob">{{data?.createdDate |
                                                                        date:'medium'}}</td>


                                                        </tr>

                                                </tbody>
                                        </table>

                                </div>

                                <div *ngIf="RecentInvoiceData.length  == 0 || RecentInvoiceData == null" class="mt-2">
                                        No Invoice Created Recently!
                                </div>
                        </div>

                </div>

        </div>
        <div class="row mt-3 hideInDesktop">
                <div class="col-md-12">
                        <div class="cstmQuickCardz  pb-4" style="height: auto !important;">
                                <h2 class="heading">Quick Access</h2>
                                <div class="d-flex gap-4 mt-3 cstmAccessCard">
                                        <div class="quickAccessDiv" (click)="onSaleDashboard()">
                                                <mat-icon class="accessIcon">analytics</mat-icon>
                                                <div class="quickAcessTitle">Sales Dashboard
                                                        <!-- <mat-icon class="me-1 hideInMob">navigate_next</mat-icon> -->
                                                </div>
                                                <div class="quickAcessDescrip companyDescripForMob">Track your sales,
                                                        revenue in a visuals for better view</div>
                                        </div>
                                        <div class="quickAccessDiv" (click)="openReportDialog()">
                                                <mat-icon class="accessIcon">description</mat-icon>
                                                <div class="quickAcessTitle">Reports
                                                        <!-- <mat-icon class="me-1 hideInMob">navigate_next</mat-icon> -->
                                                </div>
                                                <div class="quickAcessDescrip companyDescripForMob">Follow all your
                                                        happenings in detail and find pain-points</div>
                                        </div>
                                        <!-- <div class="quickAccessDiv">
                                                <mat-icon class="accessIcon">settings</mat-icon>
                                                <div class="quickAcessTitle">User Permissions <mat-icon class="me-1 hideInMob">navigate_next</mat-icon></div>
                                                <div class="quickAcessDescrip companyDescripForMob">Set permission so who can access any action</div>
                                        </div>
                                        <div class="quickAccessDiv">
                                                <mat-icon class="accessIcon">history</mat-icon>
                                                <div class="quickAcessTitle">Recent Logs<mat-icon class="me-1 hideInMob">navigate_next</mat-icon></div>
                                                <div class="quickAcessDescrip companyDescripForMob">View who added, delete or modified records</div>
                                        </div> -->
                                </div>
                        </div>
                </div>
        </div>






        <div class="mt-4 row ">
                <div class="col-md-3 hideInMob ">
                        <div class="card cstmQuickCard shadow-sm">
                                <h2> Quick Action </h2>
                                <div class="quickActions scrollColor scrollHide">

                                        <!-- <div (click)="onSubJv()" class="d-flex align-items-center gap-3 cstmActionDiv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Sub JVs</div>
                                        </div>

                                        <div (click)="onQuickJVCreate()" class="d-flex align-items-center gap-3 cstmActionDiv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Quick General Entry</div>
                                        </div> -->
                                        <div (click)="onItemCreate()"
                                                class="d-flex align-items-center gap-3 cstmActionDiv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Item</div>
                                        </div>
                                        <div (click)="openLink('sales', 'sale')"
                                                class="d-flex align-items-center gap-3 cstmActionDiv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Sale Invoice</div>
                                        </div>
                                        <div (click)="openLink('sales', 'delivery-challan')"
                                                class="d-flex align-items-center gap-3 cstmActionDiv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Delievery Challan</div>
                                        </div>
                                        <div (click)="openLink('purchase', 'purchase-form')"
                                                class="d-flex align-items-center gap-3 cstmActionDiv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Purchase Invoice</div>
                                        </div>
                                        <div (click)="openLink('sales', 'customerReceipt')"
                                                class="d-flex align-items-center gap-3 cstmActionDiv">
                                                <mat-icon class="cstmActionIcon">add</mat-icon>
                                                <div>New Customer Receipt</div>
                                        </div>

                                </div>
                        </div>
                </div>
                <div class="col-md-9 forDesktop hideInMob">

                        <div class="card cstmQuickCard shadow-sm">
                                <h2> Recent Invoices </h2>

                                <div *ngIf="RecentInvoiceData.length > 0"
                                        class="table-responsive scrollColor scrollHide mt-2">
                                        <table class="table orderTable">
                                                <thead>
                                                        <tr>
                                                                <th>Entry No</th>
                                                                <th class="hideInMob">Party</th>
                                                                <th>Screen</th>
                                                                <th>Date</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        <tr *ngFor="let data of RecentInvoiceData">
                                                                <td>
                                                                        <span type="button" (click)="onLinkClick(data)">
                                                                                {{data?.DocumentNo}}
                                                                        </span>
                                                                </td>
                                                                <td class="hideInMob">{{data?.Pname}}</td>
                                                                <td>{{data?.Screen}}</td>
                                                                <td class="hideInDesktop">{{data?.createdDate |
                                                                        date:'short'}}</td>
                                                                <td class="hideInMob">{{data?.createdDate |
                                                                        date:'medium'}}</td>


                                                        </tr>

                                                </tbody>
                                        </table>

                                </div>

                                <div *ngIf="RecentInvoiceData.length  == 0 || RecentInvoiceData == null" class="mt-2">
                                        No Invoice Created Recently!
                                </div>
                        </div>

                </div>











                <!-- <div class="col-md-9">
                        <div class="cstmQuickCard shadow-sm">
                                <h2> Pending Issue</h2>
                                <div class="issueDiv">
                                        <div class="d-flex justify-content-between  align-items-center  gap-2">
                                                <div class="d-flex gap-2 align-items-center w-75 ">
                                                <mat-icon class="cstmIssueIcon">info</mat-icon>
                                                <div><span class="fw-bold">Pepsivo PVT</span> has a payment due on Mon, Dec 13, 2023</div>
                                                </div>

                                                <button class="btn" style="color:#006cdd;">Resolve</button>
                                        </div>
                                        <div class="d-flex justify-content-between  align-items-center  gap-2">
                                                <div class="d-flex gap-2 align-items-center w-75 ">
                                                <mat-icon class="cstmIssueIcon">info</mat-icon>
                                                <div> <span class="fw-bold">Mahmood Garments</span> has a payment due on Wed, Dec 15, 2023</div>
                                                </div>

                                                <button class="btn" style="color:#006cdd;">Resolve</button>
                                        </div>
                                        <div class="d-flex justify-content-between  align-items-center  gap-2">
                                                <div class="d-flex gap-2 align-items-center w-75">
                                                <mat-icon class="cstmIssueIcon">info</mat-icon>
                                                <div> <span class="fw-bold">Nestle Coffee 37g</span> has low stock, Item running out soon</div>
                                                </div>

                                                <button class="btn" style="color:#006cdd;">Resolve</button>
                                        </div>
                                </div>
                        </div>
                </div> -->
        </div>





        <div class="row mt-3 hideInMob">
                <div class="col-md-12">
                        <div class=" pb-4" style="height: auto !important;">

                                <div class="d-flex gap-4 mt-3 ">
                                        <div class="quickAccessDiv1" (click)="onSaleDashboard()">
                                                <mat-icon class="accessIcon1">analytics</mat-icon>
                                                <div class="quickAcessTitle">Sales Dashboard
                                                        <!-- <mat-icon class="me-1 hideInMob">navigate_next</mat-icon> -->
                                                </div>
                                                <div class="quickAcessDescrip companyDescripForMob">Track your sales,
                                                        revenue in a visuals for better view</div>
                                        </div>
                                        <div class="quickAccessDiv" (click)="openReportDialog()">
                                                <mat-icon class="accessIcon">description</mat-icon>
                                                <div class="quickAcessTitle">Reports
                                                        <!-- <mat-icon class="me-1 hideInMob">navigate_next</mat-icon> -->
                                                </div>
                                                <div class="quickAcessDescrip companyDescripForMob">Follow all your
                                                        happenings in detail and find pain-points</div>
                                        </div>
                                        <!-- <div class="quickAccessDiv">
                                                <mat-icon class="accessIcon">settings</mat-icon>
                                                <div class="quickAcessTitle">User Permissions <mat-icon class="me-1 hideInMob">navigate_next</mat-icon></div>
                                                <div class="quickAcessDescrip companyDescripForMob">Set permission so who can access any action</div>
                                        </div>
                                        <div class="quickAccessDiv">
                                                <mat-icon class="accessIcon">history</mat-icon>
                                                <div class="quickAcessTitle">Recent Logs<mat-icon class="me-1 hideInMob">navigate_next</mat-icon></div>
                                                <div class="quickAcessDescrip companyDescripForMob">View who added, delete or modified records</div>
                                        </div> -->
                                </div>
                        </div>
                </div>
        </div>


</div>