<mat-form-field  appearance="outline" class="w-100 pe-2" (click)="getData()">
        <!-- <mat-label (click)="getData()">{{column?.header}}</mat-label> -->
        <mat-select     
        (click)="getData()"    
        multiple    
        #name="ngModel"
        [(ngModel)]="model"
        (ngModelChange)="onChange(name.value)">
          <mat-option *ngIf="data.length > 0"> -- Clear -- </mat-option >
          <mat-option *ngFor="let option of data" [value]="option" >
            <!-- {{option}} -->
            <!-- {{ option == null || option == undefined || option == 'undefined' ? '' : column.type == 'currency' ? (getNumber(option) | number: '1.2-2')  : column.type == 'number' ? (getNumber(option) | number: '1.2-2') : column.type == 'date' ?  (option | date:'MM/dd/yyyy') : column.type == 'time' ?  (option | date:'hh:mm a')  : option }} -->
            {{ option == null || option == undefined || option == 'undefined' ? '' : column.type == 'currency' ? (getNumber(option) | number: '1.2-2')  : column.type == 'number' ? (getNumber(option) | number: '1.2-2') : column.type == 'date' ?  (getValue(option) | date:'MM/dd/yyyy') : column.type == 'time' ?  (getValue(option) | date:'hh:mm a')  : getValue(option) }}
          </mat-option>          
        <mat-option *ngIf="theme.spinner"></mat-option>
          <div *ngIf="theme.spinner" class="item px-2 pt-2">
            <ngx-skeleton-loader  count="3" appearance="line"></ngx-skeleton-loader>
          </div>
          
          
        </mat-select>
        
        <!-- <mat-icon matPrefix [class]="theme.myIconColor">filter_alt</mat-icon> -->
</mat-form-field>