import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as customConfig from 'src/assets/files/customConfig.json';

@Injectable({
  providedIn: 'root',
})
export class ConfHelpService {
  readonly rootUrl: string = customConfig.api;

  constructor(public http: HttpClient) {}

  getDataByHelpNo(helpno: string, filters?: any) {
    var helpmodel = {
      helpno: helpno,
      filters: filters,
    };
    return this.http.post(
      this.rootUrl + 'api/Conf_Help/GetDataByHelpNo',
      helpmodel
    );
  }

  getFormDataByHelpNo(helpno: string, filters?: any) {
    var helpmodel = {
      helpno: helpno,
      filters: filters,
    };
    return this.http.post(
      this.rootUrl + 'api/Conf_Help/GetDataByHelpNo',
      helpmodel
    );
  }
}
