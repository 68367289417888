<div class="top-ribbon-dialog">
    <app-ribbon-save-dialog [hidden]="client.invalid" (myClick)="onSave()" [valid]="client.invalid"></app-ribbon-save-dialog>
    <app-ribbon-delete-dialog (myClick)="onDelete()" *ngIf="primaryKey > 0"></app-ribbon-delete-dialog>
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>

<app-my-heading-dialoge mat-dialog-title heading="Brands Information"></app-my-heading-dialoge>



<form #client="ngForm" class="rounded needs-validation ">

    <div class="row g-2">
                    <app-my-input label="BrandID" class="col-md-6" [hidden]="true">
                    </app-my-input>
                    <!-- <app-my-select [search]="true" class="col-md-12" label="Category" title="CategoryName"  name="Category" [(ngModel)]="selectedCategory"   [data]="dataCategory" class="col-md-6">
                    </app-my-select>   -->
                    <app-my-input label="Brand Name" class="col-md-12" required="true" name="MakeName" [(ngModel)]="obj.MakeName">
                    </app-my-input> 
                                     
                    
                                     
    </div>     
    <br><br>   
    <div align="end" >                
            <app-button-save-dialog (myClick)="onSave()" [valid]="client.invalid"></app-button-save-dialog>
    </div>
</form>
