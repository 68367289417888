import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { RouteService } from 'src/app/services/base/route.service';
import { PrintDialogComponent } from 'src/app/shared/Dialog/print-dialog/print-dialog.component';
import { PdfprintService } from 'src/app/services/pdfprint.service';
import { LoaderService } from 'src/app/services/base/loader.service';

@Component({
  selector: 'app-purchase-view',
  templateUrl: './purchase-view.component.html',
  styleUrls: ['./purchase-view.component.scss']
})
export class PurchaseViewComponent implements OnInit {

  dataHdr:any = null;
  dataAll:any[] = [];
  constructor(
    public router:Router,
    public routerService:RouteService,
    public dialogService:DialogService,
    public helpservice:ConfHelpService,
    public dialogRef: MatDialogRef<PurchaseViewComponent>,
    public pdfService:PdfprintService,
    public loaderService:LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getViewData();
  }

  async getViewData() {
    this.loaderService.startLoader();
    var filter = {
      id : this.data.ID,
    }

    await this.helpservice.getFormDataByHelpNo("vw_purpurchaseinvoice", filter).toPromise().then(res => {
      this.dataHdr = res['value'][0] as any;
      this.dataAll = res['value'] as any[];
      this.loaderService.endLoader();
      console.log(this.dataAll)
    })
  }
  onEdit(){
    this.dialogRef.close();
    this.router.navigate([this.routerService.openRoute("purchase", "purchase-form")], { queryParams: { ID: this.data.ID, parent: 'purchase-list' }})
  
  }

  
  print(){
    if(this.data.ID == 0)
      return;

  
    if (this.dataHdr.NonGST == true) {
      var obj = {
        reportno: 'rpt33',
        queryparam: 'id='+this.data.ID ,
      }
      this.dialogService.openPrintDialog(PrintDialogComponent, obj);
    }
    else {
      var obj = {
        reportno: 'rpt11',
        queryparam: 'id='+this.data.ID ,
      }
      this.dialogService.openPrintDialog(PrintDialogComponent, obj);
    }
   
   
  }

  printHtml(){
    this.pdfService.generatePDF('pdfContent',this.dataHdr?.DocumentNo)
  }

}
