import { Component, OnInit, OnChanges, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { RouteService } from '../../../services/base/route.service';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/base/theme.service';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { TokenService } from 'src/app/services/base/token.service';
import { PermissionService } from 'src/app/services/base/permission.service';
import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { MatDrawer } from '@angular/material/sidenav';
import { LoaderService } from 'src/app/services/base/loader.service';
import { NetworkStatusService } from 'src/app/services/base/network-status.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConnectionStatusComponent } from '../connection-status/connection-status.component';
import { ConfHelpService } from 'src/app/services/base/conf-help.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  
})

export class HomeComponent implements OnInit, AfterViewInit {
  title = 'BMS';
  isNavActive: boolean = false;

  var_sidenav:boolean = true;



  mobileQuery: MediaQueryList;

  duesLength:number = null;
  private _mobileQueryListener: () => void;

  @ViewChild(MatDrawer)
  sidenav!: MatDrawer;

  private connectionStatusDialogRef: MatDialogRef<ConnectionStatusComponent>;

  constructor(
    //public routeSerice:RouteService,
    public theme: ThemeService,
    public router: Router,
    public authenticate: AuthenticateService,
    public tokenService:TokenService,
    public permissionService:PermissionService,
    public routeService:RouteService,
    public loaderService:LoaderService,
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher,
    private observer: BreakpointObserver,
    private networkStatusService:NetworkStatusService,
    public dialog: MatDialog,
    public helpservice:ConfHelpService
  ) { 
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngAfterViewInit() {
    this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = "over";
        this.sidenav.close();
      } else {
        this.sidenav.mode = "side";
        this.sidenav.open();
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }



  ngOnInit(): void {   
    this.networkStatusService.statusChanged.subscribe((isOnline) => {
      if (!isOnline) {
        this.openConnectionDialog();
      } else {
        this.closeConnectionDialog();
      }
    }); 
    /*
    if (this.tokenService.getToken() == "") {
      this.authenticate.isLoggedIn = false;      
    }
    else {
      this.authenticate.isLoggedIn = true;    
    }
    */
   this.getUserDashboard();
   this.getDueDays();
  }


 
    async getDueDays(){
      await this.helpservice.getFormDataByHelpNo("spduedays").toPromise().then(res => {
      const tableLength = res['Table1'].length;
      const valueLength = res['value'].length;
      this.duesLength = tableLength + valueLength;
      console.log(this.duesLength)
      })
    }
  
  
  openConnectionDialog(): void {
    this.connectionStatusDialogRef = this.dialog.open(ConnectionStatusComponent, {
      width: '560px',
      disableClose:true
    });
  }

  closeConnectionDialog(): void {
    if (this.connectionStatusDialogRef) {
      this.connectionStatusDialogRef.close();
    }
  }

  getUserDashboard(){
    this.routeService.routeLoading = true;
    this.permissionService.getAllUserDashboard().toPromise().then(res => {
      this.routeService.allRoutes = res['value'] as any[];
      this.routeService.setRoutes();
      this.routeService.routeLoading = false;
    })
  }

  togglesidenav() {

    if (this.var_sidenav == true) { 
      this.var_sidenav = false; 
    }
    else if (this.var_sidenav == false) { 
      this.var_sidenav = true; 
    }

  }

  // openLink(module:string, routeName:string ){
  //   this.router.navigate([this.routeSerice.openRoute(module, routeName)]);
  // }

  onOpenNav() {
    this.isNavActive = true;
  }

  // onCloseNav() {
  //   this.isNavActive = false;
  // }

  
  onAppClick() {
    this.router.navigate([this.routeService.getHomeRoute()]);
  }

}




