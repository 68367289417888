<div class="container-fluid ">
        <div class="closeBtnDiv mt-2 ">
                <h2 class="mt-2 mx-2 ">Create Bulk Customers</h2>
                <button class="btn" mat-dialog-close> <mat-icon class="closeColor">close</mat-icon>
                </button>
        </div>
        <div class="row mt-2">

                <div class="col-md-12">
                        <div class="card-body ">
                                <div class="table-responsive ">
                                        <table class="table  table-bordered ">
                                                <thead class="title">
                                                        <tr>
                                                                <td width="20"></td>
                                                                <td width="70">Customer Name</td>
                                                                <td width="30">City</td>
                                                                <td width="30">Phone #</td>
                                                                <td width="30">NTN #</td>
                                                                <td width="30">State</td>
                                                                <td width="30">Zip Code</td>
                                                                <td width="70">Address 1</td>
                                                                <td width="70">Address 2</td>
                                                                <td width="70">Email</td>
                                                                <td width="30">Website</td>
                                                                <td width="10"></td>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        <tr *ngFor="let item of objDetail; let index = index">
                                                                <td width="20"
                                                                style="text-align: center; vertical-align: middle;">
                                                                <app-my-icon-button-table
                                                                        (myClick)="onRowDuplicate(index)"
                                                                        icon="content_copy"></app-my-icon-button-table>
                                                                 </td>

                                                                <td width="70">
                                                                        <app-my-input-table label="Customer Name"
                                                                                name="CustomerName"
                                                                                [(ngModel)]="item.CustomerName">

                                                                        </app-my-input-table>
                                                                </td>
                                                                <td width="30">
                                                                        <app-my-input-table label="City" name="City"
                                                                                [(ngModel)]="item.City">

                                                                        </app-my-input-table>
                                                                </td>
                                                                <td width="30">
                                                                        <app-my-input-table label="Phone #" name="Phone"
                                                                                [(ngModel)]="item.Phone">

                                                                        </app-my-input-table>
                                                                </td>
                                                                <td width="30">
                                                                        <app-my-input-table label="NTN #"
                                                                                name="NTNNumber"
                                                                                [(ngModel)]="item.NTNNumber">

                                                                        </app-my-input-table>

                                                                </td>
                                                                <td width="30">
                                                                        <app-my-input-table label="State" name="State"
                                                                                [(ngModel)]="item.State">

                                                                        </app-my-input-table>
                                                                </td>
                                                                <td width="30">
                                                                        <app-my-input-table label="Zip Code"
                                                                                name="ZipCode"
                                                                                [(ngModel)]="item.ZipCode">

                                                                        </app-my-input-table>
                                                                </td>
                                                                <td width="70">
                                                                        <app-my-input-table label="Address 1"
                                                                                name="Address"
                                                                                [(ngModel)]="item.Address"></app-my-input-table>
                                                                </td>
                                                                <td width="70">
                                                                        <app-my-input-table label="Address 2"
                                                                                name="Address2"
                                                                                [(ngmodel)]="item.Address2">

                                                                        </app-my-input-table>
                                                                </td>
                                                                <td width="70">
                                                                        <app-my-input-table label="Email" name="Email"
                                                                                [(ngModel)]="item.Email">

                                                                        </app-my-input-table>
                                                                </td>
                                                                <td width="30">
                                                                        <app-my-input-table label="Website"
                                                                                name="Website"
                                                                                [(ngModel)]="item.Website"></app-my-input-table>
                                                                </td>
                                                                <td width="10" style="text-align: center; vertical-align: middle;">
                                                                        <app-my-icon-button-table
                                                                                (myClick)=" onRowDelete(index)"
                                                                                icon="delete_outline">

                                                                        </app-my-icon-button-table>

                                                                </td>
                                                        </tr>
                                                </tbody>


                                        </table>

                                </div>

                        </div>
                        <div class="divbtn">
                                <app-my-small-button class="addbtn" title="Add Customer" icon="add"
                                        (myClick)="onCustomerAdd()"></app-my-small-button>

                                <button class="btn btn-info savebtn" (click)="onSave()">Save</button>
                        </div>


                </div>
        </div>
</div>