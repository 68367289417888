import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RbacUserService } from 'src/app/services/rbacUser.service';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {


  newPassword:string = '';

  confirmPassword:string = '';

  showError:boolean = false;

  showErrorLength:boolean = false;

  constructor( 
    @Inject(MAT_DIALOG_DATA) public userID,
    public dialog :MatDialogRef<ResetPasswordDialogComponent>,
    public userService:RbacUserService
) { }

  ngOnInit(): void {
    console.log("data",this.userID)
  }

  async onUpdate(){
      

    if(this.newPassword.length < 8){
      this.showErrorLength = true;
    }

   else if (this.newPassword !== this.confirmPassword) {
      this.showErrorLength = false;
       this.showError = true;
     
    }
    else {
      const userId = this.userID.userID;
      const response = await  this.userService.resetPassword(userId, this.newPassword).toPromise();
      console.log("saved success");
      this.dialog.close();
     
    }
    
  }

}
