import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NgForm, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemeService } from 'src/app/services/base/theme.service';
@Component({
  selector: 'app-my-input-cnic',
  templateUrl: './my-input-cnic.component.html',
  styleUrls: ['./my-input-cnic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MyInputCnicComponent),
      multi: true
    }
  ]
})
export class MyInputCnicComponent implements OnInit , ControlValueAccessor {

  @Input() label: string = "";
  @Input() placeHolder: string = "";
  @Input() hint: string = null;
  @Input() cls: string = "";
  @Input() required: boolean = false;
  @Input() model: any = null;
  @Input() name: string = "";
  @Output() modelChange = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<any>();
  @Input() disabled: boolean = false;
  constructor(public theme:ThemeService) { }

  writeValue(obj: any): void {
    if (obj !== undefined)
      this.model = obj;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
  }

  onChange(value: any) {
    this.propagateChange(value);
    this.valueChanged.emit(value);
  }

}
