import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { ThemeService } from 'src/app/services/base/theme.service';
import { RBAC_User } from 'src/app/models/rbac_user.model';
import { BaseService } from 'src/app/services/base/base.service';
import { TokenService } from 'src/app/services/base/token.service';
import { RouteService } from 'src/app/services/base/route.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userId:string = "";
  password:string = "";
  constructor(
    public router: Router, 
    public authenticate:AuthenticateService,
    public theme:ThemeService,
    public base :BaseService,
    public tokenService:TokenService,
    public routeService:RouteService
    ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  errorMessage: string = "";

  openLink(){
    this.authenticate.Authenticate(this.userId, this.password).then(res=>{
      this.authenticate.isLoggedIn = true; 
      this.tokenService.setToken(res['headers'].get('Token'));      
      
      var token = this.tokenService.getToken();
      this.base.sql.getBySqlQueryBody("exec sp_getUserByToken '" + token +"'").subscribe(res=>{
           
        if (res != null) {
        
          var data = res['value'][0] as RBAC_User;
          if (data) {
            //console.log(data);
            this.authenticate.user = data;    
            this.router.navigate([this.routeService.getHomeRoute()]);        
          } else {
            //this.authenticate.user.IsAdmin = false;
            //resolve(true);
          }  
        }else{
          //resolve(true);
        }
      
    }, error => {

      if (error) {
        if (error.statusText == "Unauthorized") {
          console.log("User is Unauthorized");
          //alert("Sorry, You Are Not Allowed to Access This Page")
          //document.getElementById("authError").innerHTML = "Sorry, you are not allowed to access this page.";
          //document.getElementById("Apploader").style.display = "none";
        }
      }

    }) 

                      
    }, error =>{
        if (error.status == 401)
          this.errorMessage = "Email/Mobile Number or Password is incorrect.";
        else {
          this.errorMessage = "Something went wrong!";
          console.log(error);
        }
    });     
  }

  validate() {
    if (this.userId == "")
      return true;

    if (this.password == "")
      return true;

    return false;
  }

  signup(){
    this.router.navigate(['/signup']);
  }

}
