import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import * as conf_dictionary from 'src/assets/files/conf_dictionary.json';
import { CustomRxjsService } from './base/custom-rxjs.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {

  readonly controllerName:string = "PaymentTypes";
  
  constructor(private base:BaseService,
    private rxjsService: CustomRxjsService) {    
   }

   async getAll() {
    // return await this.base.sql.get(this.controllerName);
    return this.rxjsService.convertToObservableAndGetData(conf_dictionary.payment_type);
    

  }

  async getById(ID: number) {
    return await this.base.sql.getById(this.controllerName, ID);
  }

  post(jsonBody: any) {
    return this.base.sql.post(this.controllerName, jsonBody);

  }

  update(jsonBody: any, ID: number) {
    return this.base.sql.update(this.controllerName, jsonBody, ID)
  }

  delete(ID: number) {
    return this.base.sql.delete(this.controllerName, ID)
  }
}
