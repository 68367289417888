import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyInputComponent } from './input//my-input/my-input.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyHeadingComponent } from './my-heading/my-heading.component';
import { MyInputNumberComponent } from './input/my-input-number/my-input-number.component';
import { MyInputCurrencyComponent } from './input//my-input-currency/my-input-currency.component';
import { MatInputCurrencyDirective } from './directives/mat-input-currency.directive';
import { MyInputDateComponent } from './input//my-input-date/my-input-date.component';
import { MySelectComponent } from './dropdown/my-select/my-select.component';
import { MyAutoCompleteComponent } from './dropdown/my-auto-complete/my-auto-complete.component';
import { RibbonSaveComponent } from './buttons/ribbon-save/ribbon-save.component';
import { RibbonEditComponent } from './buttons/ribbon-edit/ribbon-edit.component';
import { RibbonDeleteComponent } from './buttons/ribbon-delete/ribbon-delete.component';
import { RibbonAddComponent } from './buttons/ribbon-add/ribbon-add.component';
import { RibbonReloadComponent } from './buttons/ribbon-reload/ribbon-reload.component';
import { ButtonSaveComponent } from './buttons/button-save/button-save.component';
import { ModuleCardComponent } from './cards/module-card/module-card.component';
import { MyTableComponent } from './table/my-table/my-table.component';
import { MyTableFilterComponent } from './table/my-table-filter/my-table-filter.component';
import { MyTableSearchComponent } from './table/my-table-search/my-table-search.component';
import { MyTableReportComponent } from './table/my-table-report/my-table-report.component';
import { MyInputDateRangeComponent } from './input//my-input-date-range/my-input-date-range.component';
import { MyInputDateMonthComponent } from './input//my-input-date-month/my-input-date-month.component';
import { CardComponent } from './cards/card/card.component';
import { SideNavComponent } from './nav/side-nav/side-nav.component';
import { MyHeadingDialogeComponent } from './Dialog/my-heading-dialoge/my-heading-dialoge.component';
import { ButtonSaveDialogComponent } from './Dialog/button-save-dialog/button-save-dialog.component';
import { ButtonCancelDialogComponent } from './Dialog/button-cancel-dialog/button-cancel-dialog.component';
import { RibbonSaveDialogComponent } from './Dialog/ribbon-save-dialog/ribbon-save-dialog.component';
import { RibbonCancelDialogComponent } from './Dialog/ribbon-cancel-dialog/ribbon-cancel-dialog.component';
import { MyFormDialogComponent } from './Dialog/my-form-dialog/my-form-dialog.component';
import { ModuleHomeCardComponent } from './cards/module-home-card/module-home-card.component';
import { SnackbarComponent } from './Dialog/snackbar/snackbar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TopnavComponent } from './nav/topnav/topnav.component';
import { MyInputPasswordComponent } from './input//my-input-password/my-input-password.component';
import { RibbonBackComponent } from './buttons/ribbon-back/ribbon-back.component';
import { MyBoxComponent } from './box/my-box/my-box.component';
import { MyImageBoxComponent } from './box/my-image-box/my-image-box.component';
import { MyListComponent } from './List/my-list/my-list.component';
import { MobileBoxComponent } from './box/mobile-box/mobile-box.component';
import { AddViewDialogComponent } from './Dialog/add-view-dialog/add-view-dialog.component';
import { EditDeleteDialogComponent } from './Dialog/edit-delete-dialog/edit-delete-dialog.component';
import { MyButtonComponent } from './buttons/my-button/my-button.component';
import { MySmallButtonComponent } from './buttons/my-small-button/my-small-button.component';
import { RibbonDeleteDialogComponent } from './Dialog/ribbon-delete-dialog/ribbon-delete-dialog.component';
import { YesNoComponent } from './Dialog/yes-no/yes-no.component';
import { RibbonBackDialogComponent } from './Dialog/ribbon-back-dialog/ribbon-back-dialog.component';
import { MyIconButtonComponent } from './buttons/my-icon-button/my-icon-button.component';
import { MyListPopupComponent } from './List/my-list-popup/my-list-popup.component';
import { ListButtonComponent } from './List/list-button/list-button.component';
import { TableViewComponent } from './table/table-view/table-view.component';
import { MyTextAreaComponent } from './input/my-text-area/my-text-area.component';
import { MessageboxComponent } from './Dialog/messagebox/messagebox.component';
import { MyRibbonButtonComponent } from './buttons/my-ribbon-button/my-ribbon-button.component';
import { MyInputEmailComponent } from './input/my-input-email/my-input-email.component';
import { MyInputTImeComponent } from './input/my-input-time/my-input-time.component';
import { PasswordStrengthBarComponent } from './input/password-strength-bar/password-strength-bar.component';
import { MyInputTableComponent } from './input/my-input-table/my-input-table.component';
import { MyInputCheckTableComponent } from './input/my-input-check-table/my-input-check-table.component';
import { MyInputCurrencyTableComponent } from './input/my-input-currency-table/my-input-currency-table.component';
import { MyInputDateTableComponent } from './input/my-input-date-table/my-input-date-table.component';
import { MyInputDateMonthTableComponent } from './input/my-input-date-month-table/my-input-date-month-table.component';
import { MyInputDaterangeTableComponent } from './input/my-input-daterange-table/my-input-daterange-table.component';
import { MyInputEmailTableComponent } from './input/my-input-email-table/my-input-email-table.component';
import { MyInputNumberTableComponent } from './input/my-input-number-table/my-input-number-table.component';
import { MyInputTimeTableComponent } from './input/my-input-time-table/my-input-time-table.component';
import { MyTextAreaTableComponent } from './input/my-text-area-table/my-text-area-table.component';
import { MySelectTableComponent } from './dropdown/my-select-table/my-select-table.component';
import { MyIconButtonTableComponent } from './buttons/my-icon-button-table/my-icon-button-table.component';
import { MyButtonTableComponent } from './buttons/my-button-table/my-button-table.component';
import { PieChartComponent } from './apexChart/pie-chart/pie-chart.component';
import { PrintDialogComponent } from './Dialog/print-dialog/print-dialog.component';
import { RibbonPrintComponent } from './buttons/ribbon-print/ribbon-print.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { RightNavComponent } from './nav/right-nav/right-nav.component';
import { AuditInfoComponent } from './audit-info/audit-info.component';
import { FilterDialogComponent } from './Dialog/filter-dialog/filter-dialog.component';
import { SearchDialogComponent } from './Dialog/search-dialog/search-dialog.component';
import { BottomAuditComponent } from './audit-info/bottom-audit/bottom-audit.component';
import { CardSkeletonComponent } from './cards/card-skeleton/card-skeleton.component';
import { ThComponent } from './table/th/th.component';
import { MyTableFilterModalComponent } from './table/my-table-filter-modal/my-table-filter-modal.component';
import { MyTableCardComponent } from './table/my-table-card/my-table-card.component';
import { MyInputCnicComponent } from './input/my-input-cnic/my-input-cnic.component';
// import { HelpGridComponent } from './ag-grid/help-grid/help-grid.component';
// import { AgGridModule } from 'ag-grid-angular';
import { MySelectSearchTableComponent } from './dropdown/my-select-search-table/my-select-search-table.component';

import { MyTableHttpComponent } from './table/my-table-http/my-table-http.component';
import { MyTableSearchHttpComponent } from './table/my-table-search-http/my-table-search-http.component';
import { MyQuickDialogComponent } from './Dialog/my-quick-dialog/my-quick-dialog.component';
import { ViewGridTableComponent } from './Dialog/view-grid-table/view-grid-table.component';
import { CustomSearchFilterPipe } from '../pipes/custom-search-filter.pipe';
import { DemoSaveComponent } from './buttons/demo-save/demo-save.component';
import { SaveBtnComponent } from './buttons/save-btn/save-btn.component';
import { RibbonSaveMenuComponent } from './buttons/ribbon-save-menu/ribbon-save-menu.component';
import { FocusDirective } from './directives/focus-directive';
import { LoaderComponent } from '../modules/main/loader/loader.component';
import { InfoDialogComponent } from './Dialog/info-dialog/info-dialog.component';
import { SuccessDialogComponent } from './Dialog/success-dialog/success-dialog.component';
import { ErrorDialogComponent } from './Dialog/error-dialog/error-dialog.component';
import { MyViewDialogComponent } from './Dialog/my-view-dialog/my-view-dialog.component';
import { ResetPasswordDialogComponent } from './Dialog/reset-password-dialog/reset-password-dialog.component';



const comps: any[] = [
 //Directives
 MatInputCurrencyDirective,
 FocusDirective,
 //Components
 MyInputComponent,
 MyHeadingComponent,
 MyInputNumberComponent,
 MyInputCurrencyComponent,
 MyInputDateComponent,
 MySelectComponent,
 MyAutoCompleteComponent,
 RibbonSaveComponent,
 RibbonEditComponent,
 RibbonDeleteComponent,
 RibbonAddComponent,
 RibbonReloadComponent,
 ButtonSaveComponent,
 ModuleCardComponent,
 MyTableComponent,
 MyTableFilterComponent,
 MyTableSearchComponent,
 MyTableReportComponent,
 MyInputDateRangeComponent,
 MyInputDateMonthComponent,
 CardComponent,
 SideNavComponent,
 MyHeadingDialogeComponent,
 ButtonSaveDialogComponent,
 ButtonCancelDialogComponent,
 RibbonSaveDialogComponent,
 RibbonCancelDialogComponent,
 MyFormDialogComponent,
 ModuleHomeCardComponent,
 SnackbarComponent,
 TopnavComponent,
 MyInputPasswordComponent,
 RibbonBackComponent, 
 MyBoxComponent,
 MyImageBoxComponent,
 MyListComponent,
 MobileBoxComponent,
 AddViewDialogComponent,
 EditDeleteDialogComponent,
 MyButtonComponent,
 MySmallButtonComponent,
 RibbonDeleteDialogComponent,
 YesNoComponent,
 RibbonBackDialogComponent,
 MyIconButtonComponent,
 MyListPopupComponent, 
 ListButtonComponent,
 TableViewComponent,
 MyTextAreaComponent,
 MessageboxComponent,
 MyRibbonButtonComponent,
 MyInputEmailComponent,
 MyInputTImeComponent,
 PasswordStrengthBarComponent,
 MyInputTableComponent,
 MyInputCheckTableComponent,
 MyInputCurrencyTableComponent,
 MyInputDateTableComponent,
 MyInputDateMonthTableComponent,
 MyInputDaterangeTableComponent,
 MyInputEmailTableComponent,
 MyInputNumberTableComponent,
 MyInputTimeTableComponent,
 MyTextAreaTableComponent,
 MySelectTableComponent,
 MyIconButtonTableComponent,
 MyButtonTableComponent,
 RibbonPrintComponent, 
 GlobalSearchComponent,
 RightNavComponent,
 CardSkeletonComponent,
 PieChartComponent,
 PrintDialogComponent,
 RibbonPrintComponent,
 RightNavComponent,
 AuditInfoComponent,
 FilterDialogComponent,
 SearchDialogComponent,
 BottomAuditComponent,
 ThComponent,
 MyTableFilterModalComponent,
 MyTableCardComponent,
 MyInputCnicComponent,
//  HelpGridComponent,
 MySelectSearchTableComponent,
 MyTableHttpComponent,
 MyTableSearchHttpComponent,
 DemoSaveComponent,
 SaveBtnComponent,
 RibbonSaveMenuComponent,
 InfoDialogComponent,
 LoaderComponent]

@NgModule({
  declarations: [
   comps,
   MyQuickDialogComponent,
   ViewGridTableComponent,
   CustomSearchFilterPipe,
   InfoDialogComponent,
   SuccessDialogComponent,
   ErrorDialogComponent,
   MyViewDialogComponent,
   ResetPasswordDialogComponent,

  
  ],
  imports:[
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    // AgGridModule.withComponents([])
  ],
  exports:[
   comps,
  ]
})
export class SharedModule { }
