
<mat-card class="shadow">
        <mat-card-content>
            <mat-selection-list #forms [multiple]="false" >
    
                <div class="d-flex justify-content-between">
                    <div mat-subheader class="home-heading">
                        <div class="px-2 pt-3 w-100"  >                  
                            <ngx-skeleton-loader  count="1" appearance="line"></ngx-skeleton-loader>                                      
                        </div>
                    </div>
                    <span class="d-flex flex-column-reverse w-25" >
                        <ngx-skeleton-loader  count="1" appearance="line"></ngx-skeleton-loader> 
                    </span>
                </div>
                <!-- Start Form -->
                <div class="d-flex justify-content-between mb-5" >
                    <div mat-subheader class="w-100">                                                                                                
                                          
                            <ngx-skeleton-loader  count="1" appearance="line"></ngx-skeleton-loader>                                      
        
                            
                    </div>               
                </div>
                <mat-list-option class="list-skeleton">
                    <div class="d-flex justify-content-between align-items-baseline visible-area">
                        <span class="flex-grow-1 ">
                            <div class=" w-100"  >                  
                                <ngx-skeleton-loader  count="6" appearance="line"></ngx-skeleton-loader>                                      
                            </div>
                        </span>
                       
                    </div>
                </mat-list-option>
             
            </mat-selection-list>
        </mat-card-content>
    </mat-card>