import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor( private base:BaseService) { }

  getPlants(){
    var query = "exec sp_db_TotalPlant ";
    return this.base.sql.getBySqlQueryBody(query);
  }

  getCustomer(days:number){
    var query = "exec sp_db_Customers " + days;
    return this.base.sql.getBySqlQueryBody(query);
  }

  getSupplier(){
    var query = "exec sp_db_Supplier -30";
    return this.base.sql.getBySqlQueryBody(query);
  }

  getSales(days:number){
    var query = "exec sp_db_GetSales " + days;
    return this.base.sql.getBySqlQueryBody(query);
  }

  getStock(){
    var query = "exec sp_db_Stock";
    return this.base.sql.getBySqlQueryBody(query);
  }

  getPurchaseReturn(days:number){
    var query = "exec sp_db_PurchaseReturn " + days;
    return this.base.sql.getBySqlQueryBody(query);
  }

  getSaleReturn(days:number){ 
    var query = "exec sp_db_SaleReturn " + days;
    return this.base.sql.getBySqlQueryBody(query);
  }
  
  GlobalSearch(search:string) {  
    var query = "exec sp_globalsearch '" + search + "'";
    return this.base.sql.getBySqlQueryBody(query);
  }

}
