<div class="background-card-color" [class]="theme.myDashboardBgColor"></div>

<!-- Desktop -->
<div class="row card-margin  d-flex justify-content-center desktop" *ngIf="routeService.routeLoading == false">
    <div class="col-md-3 mt-3" *ngIf="routeService.inventoryRoutes.length > 0">
        <app-module-home-card moduleName="inventory" moduleHeading="Inventory" moduleIcon="inventory_2" (formClick)="openLink($event, 'inventory')"></app-module-home-card>
    </div>
    <div class="col-md-3 mt-3" *ngIf="routeService.purchaseRoutes.length > 0">
        <app-module-home-card moduleName="purchase" moduleHeading="Purchase" moduleIcon="shopping_cart" (formClick)="openLink($event, 'purchase')"></app-module-home-card>
    </div>
    <div class="col-md-3 mt-3" *ngIf="routeService.salesRoutes.length > 0">
        <app-module-home-card moduleName="sales" moduleHeading="Sales" moduleIcon="point_of_sale" (formClick)="openLink($event, 'sales')"></app-module-home-card>
    </div>
    <div class="col-md-3 mt-3" *ngIf="routeService.financeRoutes.length > 0">
        <app-module-home-card moduleName="finance" moduleHeading="Finance"  moduleIcon="payments"  (formClick)="openLink($event, 'finance')"></app-module-home-card>
    </div>
    <div class="col-md-3 mt-3" *ngIf="routeService.productionRoutes.length > 0">
        <app-module-home-card moduleName="production" moduleHeading="Production"  moduleIcon="precision_manufacturing"  (formClick)="openLink($event, 'production')"></app-module-home-card>
    </div>
    <div class="col-md-3 mt-3" *ngIf="routeService.userRoutes.length > 0">
        <app-module-home-card moduleName="users" moduleHeading="Users"  moduleIcon="account_box"  (formClick)="openLink($event, 'users')"></app-module-home-card>
    </div>
    <div class="col-md-3 mt-3" *ngIf="routeService.adminRoutes.length > 0">
        <app-module-home-card moduleName="adminpanel" moduleHeading="Admin"  moduleIcon="language"  (formClick)="openLink($event, 'adminpanel')"></app-module-home-card>
    </div>
    <div class="col-md-3 mt-3" *ngIf="routeService.hrRoutes.length > 0">
        <app-module-home-card moduleName="hr" moduleHeading="HRM"  moduleIcon="person_search"  (formClick)="openLink($event, 'hr')"></app-module-home-card>
    </div>
    <div class="col-md-3 mt-3" *ngIf="routeService.emRoutes.length > 0">
        <app-module-home-card moduleName="em" moduleHeading="Event"  moduleIcon="date_range"  (formClick)="openLink($event, 'em')"></app-module-home-card>
    </div>


    
    
</div>

<!-- Mobile -->
<div class="row card-marginMob  mobile px-2 ">
    <div class="card cardMob  py-2 px-2">

        <div class="row py-3 px-3  mobTable">
            <div class="col-md-12 ">
                <h4 class="mb-3 fw">Welcome {{authenticateService?.user?.FullName}}!</h4>
                <h4 class="border-bottom ms-2 mb-2 py-2">Recent</h4>
                <app-recen-activity-table></app-recen-activity-table>
            </div>
        </div>
        <div class="row px-3 py-2 d-flex justify-content-center" *ngIf="routeService.routeLoading == false">


            <div class="col-4 mb-2" *ngIf="routeService.inventoryRoutes.length > 0">
                <div matRipple (click)="onRouteClick('inventory')" class="d-flex flex-column align-items-center mbcard">
                    <mat-icon class="customIcon mb-2">inventory_2</mat-icon>
                    <h4> Inventory </h4>
                </div>
            </div>

            <div class="col-4" *ngIf="routeService.purchaseRoutes.length > 0">
                <div matRipple (click)="onRouteClick('purchase')" class="d-flex flex-column align-items-center mbcard">
                    <mat-icon class="customIcon mb-2">shopping_cart</mat-icon>
                    <h4> Purchase </h4>
                </div>
            </div>

            <div class="col-4" *ngIf="routeService.salesRoutes.length > 0">
                <div matRipple (click)="onRouteClick('sales')" class="d-flex flex-column align-items-center mbcard">
                    <mat-icon class="customIcon mb-2">point_of_sale</mat-icon>
                    <h4> Sales </h4>
                </div>
            </div>    

            <div class="col-4" *ngIf="routeService.financeRoutes.length > 0">
                <div matRipple (click)="onRouteClick('finance')" class="d-flex flex-column align-items-center mbcard">
                    <mat-icon  class="customIcon mb-2">payments</mat-icon>
                    <h4> Finance </h4>
                </div>
            </div>

            <div class="col-4 mb-2" *ngIf="routeService.productionRoutes.length > 0">
                <div matRipple (click)="onRouteClick('production')" class="d-flex flex-column align-items-center mbcard">
                    <mat-icon class="customIcon mb-2">precision_manufacturing</mat-icon>
                    <h4> Production </h4>
                </div>
            </div>

            <div class="col-4" *ngIf="routeService.userRoutes.length > 0">
                <div matRipple (click)="onRouteClick('users')" class="d-flex flex-column align-items-center mbcard">
                    <mat-icon class="customIcon mb-2">account_box</mat-icon>
                    <h4> Users </h4>
                </div>
            </div>

            <div class="col-4" *ngIf="routeService.adminRoutes.length > 0">
                <div matRipple (click)="onRouteClick('adminpanel')" class="d-flex flex-column align-items-center mbcard">
                    <mat-icon class="customIcon mb-2">language</mat-icon>
                    <h4> Admin  </h4>
                </div>
            </div>

            <div class="col-4" *ngIf="routeService.hrRoutes.length > 0">
                <div matRipple (click)="onRouteClick('hr')" class="d-flex flex-column align-items-center mbcard">
                    <mat-icon class="customIcon mb-2">person_search</mat-icon>
                    <h4>HRM</h4>
                </div>
            </div>

            <div class="col-4" *ngIf="routeService.emRoutes.length > 0">
                <div matRipple (click)="onRouteClick('em')" class="d-flex flex-column align-items-center mbcard">
                    <mat-icon class="customIcon mb-2">date_range</mat-icon>
                    <h4>Event </h4>
                </div>
            </div>

            


            
        </div>

        <!-- Mobile Skeleton -->
        <div class="row px-3 py-2 d-flex justify-content-center" *ngIf="routeService.routeLoading == true">
            <div class="col-4 mb-2" *ngFor="let op of [1,2,3]">
                <div matRipple  class="d-flex flex-column align-items-center mbcard" style="background-color: #ebebeb;">
                    <span class="d-flex flex-column-reverse w-100" >
                        <ngx-skeleton-loader  count="1" appearance="circle"></ngx-skeleton-loader> 
                    </span>
                    <span class="d-flex flex-column-reverse w-100 mt-2" >
                        <ngx-skeleton-loader  count="1" appearance="line"></ngx-skeleton-loader> 
                    </span>
                </div>
            </div>
        </div>

    </div>
</div>


<!-- Desktop skeleton -->
<div class="row card-margin desktop" *ngIf="routeService.routeLoading == true">
    <div class="col-md-3 mt-3">
        <app-card-skeleton ></app-card-skeleton>
    </div>
   
    <div class="col-md-3 mt-3">
        <app-card-skeleton ></app-card-skeleton>
    </div>

    <div class="col-md-3 mt-3">
        <app-card-skeleton ></app-card-skeleton>
    </div>
    
    <div class="col-md-3 mt-3">
        <app-card-skeleton ></app-card-skeleton>
    </div>
</div>