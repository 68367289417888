import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Autocomplete } from '../../Autocomplete';


@Component({
  selector: 'app-my-select',
  templateUrl: './my-select.component.html',
  styleUrls: ['./my-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MySelectComponent),
      multi: true
    }
  ]
})
export class MySelectComponent implements OnInit , ControlValueAccessor {
  
  @Input() label:string = "";
  @Input() data:any[]= null;
  @Input() required: boolean = false;
  @Input() createOption:boolean = false;
  @Input() optionName:string= "";
  @Input() model: any = null;
  @Input() name: string = "";
  @Input() title:string = "";
  @Input() multiple:boolean = false;
  @Output() modelChange = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<any>();
  @Output() onCreate = new EventEmitter<any>();
  @Input() hint: string = null;
  searchValue:any = null;
  @Input() search:boolean = true;
  
  @Input() isDisabled:boolean = false;


  AutoComp:Autocomplete<any> = new Autocomplete<any>(this.title, this.title);      
  @Input() isFocused: boolean;

  constructor() {
   
   }

  ngOnChanges(): void {

    // For material select - 24/06/2023
    
    this.AutoComp.filterIDName = this.title;
    this.AutoComp.displayValueName = this.title;
    this.AutoComp.data = this.data;
    this.AutoComp.resultObserve();  
    
  }
  
  writeValue(obj: any): void {
    if (obj !== undefined)
      this.model = obj;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }


  ngOnInit(): void {
  }

  onChange(value: any) {
    this.propagateChange(value);
    this.valueChanged.emit(value);
  }

  getValue(option){
     return option[this.title];
  }

  create(){
    this.onCreate.emit();
    
  }
  

}


