import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as customConfig from 'src/assets/files/customConfig.json';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(
    private baseService:BaseService
    ) {
   
  }

   getSignInUser(email:string) {
    email = email.replace("+", "%2B");
    return this.baseService.sql.http.get(this.baseService.sql.rootUrl + "api/SignIn/GetUser?email=" + email);
  }
}
